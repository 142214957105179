import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { BasicDocumentViewerSettings } from '../../theme/basic-components/basic-document-viewer/model/basic-document-viewer-settings';
import { AssignedExaminationsManager } from '../../theme/pages/home/terms/visit/selected-term/examinations/model/assigned-examinations-manager';
import { ExaminationViewItem } from '../../theme/pages/home/terms/visit/selected-term/examinations/model/examination-view-item';
import { ManagedExaminationManager } from '../../theme/pages/home/terms/visit/selected-term/examinations/model/managed-examination-manager';
import { DocumentViewerDocument } from '../model';
import { ExaminationResponseDto, ReservationExaminationResponseDto } from '../model/examinations/examination-response-dto';
import { ReservationExaminationFieldDto } from '../model/examinations/reservation-examination-field-dto';
import { SaveReservationExaminationFieldsRequestDto } from '../model/examinations/save-reservation-examination-fields-request-dto';
import { ActiveModulesService } from '../services/activeModules.service';
import { ExaminationsClientService } from '../services/clients/examinations-client.service';

export class ExaminationsManager {
    constructor(
        private examinationsClientService: ExaminationsClientService,
        private am: ActiveModulesService,
    ) {}

    public getContractorExaminations$(contractorId: number): Observable<ExaminationResponseDto[]> {
        return of(null).pipe(
            switchMap(() => this.examinationsClientService.getContractorExaminations(contractorId)),
            catchError(() => of([])),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    public getReservationExaminations$(preReservationId: string, contractorId: number): Observable<ReservationExaminationResponseDto[]> {
        return of(null).pipe(
            switchMap(() => this.examinationsClientService.getReservationExaminations(contractorId, preReservationId)),
            catchError(() => of([])),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    // Assign Examinations
    public saveReservationExaminations$(
        examinationsForReservation: ReservationExaminationResponseDto[],
        preReservationId: string,
        contractorId: number,
        selectedItems: ExaminationViewItem[],
    ): Observable<[number[], number[]]> {
        const delete$ = this.getDeleteObservable(examinationsForReservation, preReservationId, contractorId, selectedItems);
        const create$ = this.getCreateObservable(examinationsForReservation, preReservationId, contractorId, selectedItems);

        return of(examinationsForReservation).pipe(
            switchMap(() => {
                const createWithFallback$ = create$.pipe(
                    switchMap(obs => (obs.length > 0 ? forkJoin(obs) : of([]))),
                    catchError(() => of([])),
                );

                const deleteWithFallback$ = delete$.pipe(
                    switchMap(obs => (obs.length > 0 ? forkJoin(obs) : of([]))),
                    catchError(() => of([])),
                );

                return forkJoin([createWithFallback$, deleteWithFallback$]);
            }),
        );
    }
    private getDeleteObservable(
        examinationsForReservation: ReservationExaminationResponseDto[],
        preReservationId: string,
        contractorId: number,
        selectedItems: ExaminationViewItem[],
    ): Observable<Observable<number>[]> {
        return of(examinationsForReservation).pipe(
            map(examinationsForReservation => {
                const itemsToDeleteIds = new AssignedExaminationsManager().getDiffItemsToDelete(examinationsForReservation, selectedItems);
                return itemsToDeleteIds.map(itemToDeleteId =>
                    this.examinationsClientService.deleteReservationExamination(contractorId, preReservationId, itemToDeleteId),
                );
            }),
        );
    }

    private getCreateObservable(
        examinationsForReservation: ReservationExaminationResponseDto[],
        preReservationId: string,
        contractorId: number,
        selectedItems: ExaminationViewItem[],
    ): Observable<Observable<number>[]> {
        return of(examinationsForReservation).pipe(
            map(examinationsForReservation => {
                const itemsToCreateIds = new AssignedExaminationsManager().getDiffItemsToCreate(examinationsForReservation, selectedItems);
                return itemsToCreateIds.map(itemToCreateId =>
                    this.examinationsClientService.createReservationExamination(contractorId, preReservationId, itemToCreateId),
                );
            }),
        );
    }

    // Manage examination

    // private createDocumentViewerDocuments$(examinationsForReservation: ExaminationResponseDto[]): Observable<Document[]> {
    //     return iif(
    //         () => this.am.isAM(AppModulesType.OCCUPATIONAL_MEDICINE),
    //         of(examinationsForReservation).pipe(map(examinations => examinations.map(exam => exam.documents).flat())),
    //         of([]),
    //     );
    // }

    public saveDocument(
        preReservationId: string,
        contractorId: number,
        selectedReservationExaminationId: number,
        maskedId: string,
    ): Observable<string> {
        return this.examinationsClientService.saveReservationExaminationDocument(
            contractorId,
            preReservationId,
            selectedReservationExaminationId,
            maskedId,
        );
    }

    public deleteDocument(
        preReservationId: string,
        contractorId: number,
        selectedReservationExaminationId: number,
        maskedId: string,
    ): Observable<string> {
        return this.examinationsClientService.deleteReservationExaminationDocument(
            contractorId,
            preReservationId,
            selectedReservationExaminationId,
            maskedId,
        );
    }

    public saveSelectedExamination(
        selectedExaminationFields: ReservationExaminationResponseDto,
        preReservationId: string,
        contractorId: number,
        selectedReservationExaminationId: number,
        fieldsWithValues: ReservationExaminationFieldDto[] | null,
        comment: string | null,
    ): Observable<unknown> {
        return of(selectedExaminationFields).pipe(
            switchMap(allFields => {
                const manager = new ManagedExaminationManager();
                const fieldsToSave = fieldsWithValues ? manager.getFieldsToSave(fieldsWithValues) : null;
                const fieldsToDelete = manager.getFieldsToDelete(allFields.examination.fields, fieldsWithValues);

                const fieldsToSave$ = this.getFieldsToSaveObs(preReservationId, contractorId, selectedReservationExaminationId, fieldsToSave);
                const fieldsToDelete$ = this.getFieldsToDeleteObs(preReservationId, contractorId, selectedReservationExaminationId, fieldsToDelete);
                const comment$ = this.getSaveCommentObs(preReservationId, contractorId, selectedReservationExaminationId, comment);
                return forkJoin([fieldsToSave$, fieldsToDelete$, comment$]);
            }),
        );
    }
    private getFieldsToSaveObs(
        preReservationId: string,
        contractorId: number,
        selectedReservationExaminationId: number,
        fieldsToSave: SaveReservationExaminationFieldsRequestDto | null,
    ): Observable<ReservationExaminationFieldDto[]> {
        if (fieldsToSave === null) {
            return of(null);
        }

        const fieldsToSave$ = fieldsToSave
            ? this.examinationsClientService.saveReservationExaminationFields(
                  contractorId,
                  preReservationId,
                  selectedReservationExaminationId,
                  fieldsToSave,
              )
            : of([] as ReservationExaminationFieldDto[]);
        return fieldsToSave$;
    }

    private getFieldsToDeleteObs(
        preReservationId: string,
        contractorId: number,
        selectedReservationExaminationId: number,
        fieldsToDelete: number[] | null,
    ): Observable<number[]> {
        const fieldsToDelete$ = fieldsToDelete
            ? of(
                  fieldsToDelete.map(field =>
                      this.examinationsClientService.deleteReservationExaminationField(
                          contractorId,
                          preReservationId,
                          selectedReservationExaminationId,
                          field,
                      ),
                  ),
              ).pipe(
                  switchMap(obs => (obs.length > 0 ? forkJoin(obs) : of([] as number[]))),
                  catchError(() => of([] as number[])),
              )
            : of([] as number[]);
        return fieldsToDelete$;
    }

    private getSaveCommentObs(
        preReservationId: string,
        contractorId: number,
        selectedReservationExaminationId: number,
        comment: string | null,
    ): Observable<number> {
        const comment$ =
            comment != null && comment != ''
                ? this.examinationsClientService.saveReservationExaminationComment(
                      contractorId,
                      preReservationId,
                      selectedReservationExaminationId,
                      comment,
                  )
                : this.examinationsClientService.deleteReservationExaminationComment(
                      contractorId,
                      preReservationId,
                      selectedReservationExaminationId,
                  );
        return comment$;
    }

    public createMdpsSurveyViewerSettings(
        contractorId: number,
        examinationsForReservation: ReservationExaminationResponseDto[],
    ): BasicDocumentViewerSettings {
        const documents$ = of(examinationsForReservation).pipe(
            map(examinationsForReservation => {
                return examinationsForReservation
                    .map(examination => {
                        return examination.documents.map(document => {
                            return { ...document, isReadOnly: true } as DocumentViewerDocument;
                        });
                    })
                    .flat();
            }),
        );

        return {
            title: 'Dokumenti',
            contractorId: contractorId,
            documentList$: documents$,
            canUpload: false,
            embeddedPreviewEnabled: true,
            postFileUpload$: maskedId => {
                // read only
            },
            postDeleteFile$: maskedId => {
                // read only
            },
        } as BasicDocumentViewerSettings;
    }

    public createSelectedExaminationDocumentViewerSettings(
        preReservationId: string,
        contractorId: number,
        selectedReservationExaminationId: number,
        examinationsForReservation$: Observable<ReservationExaminationResponseDto[]>,
        reloadCallback: () => void,
    ): BasicDocumentViewerSettings {
        const documents$ = examinationsForReservation$.pipe(
            map(examinationsForReservation => {
                return examinationsForReservation
                    .filter(examination => examination.id === selectedReservationExaminationId)
                    .map(examination => {
                        return examination.documents.map(document => {
                            return { ...document, isReadOnly: false } as DocumentViewerDocument;
                        });
                    })
                    .flat();
            }),
        );

        return {
            title: 'Dokumenti',
            contractorId: contractorId,
            documentList$: documents$,
            canUpload: true,
            embeddedPreviewEnabled: false,
            postFileUpload$: maskedId => {
                return this.saveDocument(preReservationId, contractorId, selectedReservationExaminationId, maskedId).pipe(
                    tap(() => reloadCallback()),
                );
            },
            postDeleteFile$: maskedId => {
                return this.deleteDocument(preReservationId, contractorId, selectedReservationExaminationId, maskedId).pipe(
                    tap(() => reloadCallback()),
                );
            },
        } as BasicDocumentViewerSettings;
    }
}
