import { Serializable } from './deserialize.service';

export class ConsentGroup implements Serializable<ConsentGroup> {
    title: string;
    id: number;
    email: boolean;
    sms: boolean;
    deserialize(input) {
        if (!input || input === null) {
            return null;
        }
        this.title = input.title;
        this.id = input.id;
        this.email = input.email;
        this.sms = input.sms;

        return this;
    }
}
