import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InstantNotification } from '../../../../_shared/model/InstantNotification.model';
import { Notification } from '../../../../_shared/model/notification.model';
import { HelperService } from '../../../../_shared/services';
import { HttpClientService } from '../../../../_shared/services/http-client.service';

@Injectable()
export class NotificationService {
    constructor(
        private httpClient: HttpClientService,
        private helper: HelperService,
    ) {}

    public getInstanNotifications(contractorId: number, from: string, to: string): Observable<InstantNotification[]> {
        return this.httpClient.get(this.helper.getCommunicationUrl() + '/notifications/' + contractorId + '?from=' + from + '&to=' + to);
    }

    public getNotifications(contractorId: number, from: string, to: string): Observable<Notification[]> {
        return this.httpClient.get(this.helper.getCommunicationUrl() + '/notifications/' + contractorId + '?from=' + from + '&to=' + to);
    }

    public getNotificationsItems(contractorId: number, from: number, to: number): Observable<Notification[]> {
        return this.httpClient.get(this.helper.getCommunicationUrl() + `/notifications/${contractorId}/items/?from=${from}&to=${to}`);
    }

    public getUnreadNotifications(contractorId: number): Observable<number> {
        return this.httpClient.get(this.helper.getCommunicationUrl() + `/contractors/${contractorId}/unread`);
    }

    //markNotificationAsSeen
    public markNotificationAsSeen(contractorId: number, notificationId: number): Observable<number> {
        return this.httpClient.put(this.helper.getCommunicationUrl() + `/contractors/${contractorId}/notifications/${notificationId}`);
    }

    //markAllNotificationsAsSeen
    public markAllNotificationsAsSeen(contractorId: number): Observable<number> {
        return this.httpClient.put(this.helper.getCommunicationUrl() + `/contractors/${contractorId}/notifications`);
    }
}
