import { Contractor } from '../contractor.model';
import { Customer } from '../customer.model';
import { Serializable } from '../deserialize.service';
import { Service } from '../service.model';
import { CaseStatusType } from './../../enums/caseStatusType.enum';
import { BCAdditionalData } from './BCAdditionalData.interface';

export class BusinessCaseResponse implements Serializable<BusinessCaseResponse> {
    bsid: number;
    caseDate: Date;
    caseId: string;
    contractor: Contractor;
    customer: Customer;
    externalBsUserId: string;
    services: Service[];
    appUserId: number;
    appUserName: string;
    canceled: boolean;
    caseInternalId: string;
    caseStatus: CaseStatusType;
    originalService: Service;
    additionalData: BCAdditionalData;
    concludedCase: string;
    claimFile?: string;
    customerOptIn?: boolean;
    deserialize(input) {
        if (input) {
            this.bsid = input.bsid;
            this.caseId = input.caseId;
            this.caseDate = input.caseDate;
            if (input.contractor) {
                this.contractor = new Contractor().deserialize(input.contractor);
            }
            if (input.customer) {
                this.customer = new Customer().deserialize(input.customer);
            }
            if (input.services) {
                this.services = input.services.map((el: Service) => new Service().deserialize(el));
            }
            if (input.originalService) {
                this.originalService = new Service().deserialize(input.originalService);
            }
            this.concludedCase = input.concludedCase;
            this.externalBsUserId = input.externalBsUserId;
            this.appUserId = input.appUserId;
            this.appUserName = input.appUserName;
            this.canceled = input.canceled;
            this.caseInternalId = input.caseInternalId;
            this.caseStatus = input.caseStatus;
            this.additionalData = input.additionalData;
            this.claimFile = input.claimFile;
            this.customerOptIn = input.customerOptIn;
        }
        return this;
    }
}
