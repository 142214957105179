import { Pipe, PipeTransform } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { CaseService } from '../../theme/pages/home/_services/case.service';
import { SecondaryContactHelper } from '../helper/secondary-contact-helper';
import { BusinessCaseResponse } from '../model';
import { SecondaryContact } from '../model/case/secondary-contact.model';
import { WaitingQueueItem } from '../model/schedule/WaitingQueueItem.model';

@Pipe({
    name: 'secondaryContact',
})
export class SecondaryContactPipe implements PipeTransform {
    constructor(private caseRest: CaseService) {}

    transform(row: WaitingQueueItem): unknown {
        return this.secondaryContact$(row.bsid, row.caseId);
    }

    private secondaryContact$(bsId: number, caseId: string): Observable<SecondaryContact> {
        const businessCase = {
            bsid: bsId,
            caseId: caseId,
        } as unknown as BusinessCaseResponse;
        const businessCase$ = new BehaviorSubject<BusinessCaseResponse>(businessCase);
        return SecondaryContactHelper.createSecondaryContact$(businessCase$, this.caseRest).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }
}
