import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HelperService } from '../../../../_shared/services';
import { HttpClientService } from '../../../../_shared/services/http-client.service';

@Injectable({
    providedIn: 'root',
})
export class SurveyService {
    constructor(
        private helper: HelperService,
        private httpClient: HttpClientService,
    ) {}

    // getSurveyResultsForVisit()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getSurveyResultsForVisit(visitId: number): Observable<any> {
        return this.httpClient.get(this.helper.getSurveysUrl() + '/visits/' + visitId);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getSurveyResults(queueid: string): Observable<any> {
        return this.httpClient.get(this.helper.getSurveysUrl() + `/queue/${queueid}`);
    }
}
