import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SurveyResultItem } from '../model/survey/surveyResultItem.model';
import { HelperService } from './helper.service';
import { HttpClientService } from './http-client.service';

@Injectable({
    providedIn: 'root',
})
export class SurveysService {
    constructor(
        private httpClient: HttpClientService,
        private helper: HelperService,
    ) {}
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getSurveys(cid: number): Observable<any> {
        return this.httpClient.get(this.helper.getAssistanceUrl(3) + `/api/surveytemplates/cid/${cid}`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateSurvey(id, data): Observable<any> {
        return this.httpClient.put(this.helper.getAssistanceUrl(3) + `/api/surveytemplates/${id}`, data);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteSurvey(id): Observable<any> {
        return this.httpClient.delete(this.helper.getAssistanceUrl(3) + `/api/surveytemplates/${id}`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public createSurvey(data): Observable<any> {
        return this.httpClient.post(this.helper.getAssistanceUrl(3) + `/api/surveytemplates/`, data);
    }

    public getSurveyResults(cid: number, from?: string, to?: string): Observable<SurveyResultItem[]> {
        return this.httpClient.get(this.helper.getAssistanceUrl(3) + `/api/surveys/${from}/${to}/cId/${cid}`);
    }

    public getCustomerResponses(cid: string, from?: string, to?: string): Observable<SurveyResultItem[]> {
        return this.httpClient.get(this.helper.getAssistanceUrl(3) + `/api/surveys/${from}/${to}/customer/${encodeURIComponent(cid)}`);
    }
    public getUnreadSurveysCount(contractorId: number): Observable<number> {
        return this.httpClient.get(this.helper.getErpRootUrl() + `api/contractors/${contractorId}/surveys/count`);
    }
}
