<div class="d-flex justify-content-center">
    <div class="m-portlet">
        <div class="m-portlet__body mf-content-portfolio-overflow">
            <div class="d-flex">
                <div class="w-50">
                    <h3 class="text-center">
                        <span *ngIf="editingType === 'EXISTING_CUSTOMER'; else linkingCustomers" i18n="@@f04d4c5a3aaff30e561199899e6edb6bf421d87d">
                            Nova stranka
                        </span>
                        <ng-template #linkingCustomers><span i18n="@@f04d4c5a3aaff30e561199899e6edb6bf421d87d9a">Nosilna stranka</span></ng-template>
                    </h3>
                    <div class="mf-customer-details" [ngClass]="{ dashed: customersOld }">
                        <cust-customer-details [customerForEdit]="customerNew" [isReadOnly]="true"></cust-customer-details>
                    </div>
                </div>
                <div class="w-50">
                    <div class="d-flex justify-content-between my-2">
                        <div></div>
                        <h3 class="text-center">
                            <span
                                *ngIf="editingType === 'EXISTING_CUSTOMER'; else linkingCustomers2"
                                i18n="@@a804e92ff11bfea1b8bed996f5f1bc870e5faa6a"
                            >
                                Že shranjene stranke
                            </span>
                            <ng-template #linkingCustomers2>
                                <span i18n="@@a804e92ff11bfea1b8bed996f5f1bc870e5faa690">Stranke za povezovanje</span>
                            </ng-template>
                        </h3>
                        <button (click)="toggleSearch()" type="buttons" class="btn m-btn--pill btn-outline-brand ml-3 m-btn m-btn--custom">
                            <span *ngIf="isManualSearchEnabled; else switchToManual">Avtomatsko iskanje</span>
                            <ng-template #switchToManual>Ročno iskanje</ng-template>
                        </button>
                    </div>
                    <hr />
                    <div [hidden]="!isManualSearchEnabled">
                        <!-- Ročno povezovanje strank -->
                        <div class="d-flex mf-customer-details align-items-center">
                            <span>Stranka:</span>
                            <ng-select
                                [(ngModel)]="customersOld[0]"
                                [items]="allCustomers"
                                [clearable]="true"
                                [clearSearchOnAdd]="true"
                                (change)="addCustomerFromSearch($event)"
                                placeholder="Izberite stranko iz seznama"
                                bindLabel="fullName"
                                class="w-100 ml-2"
                            ></ng-select>
                        </div>
                        <hr />
                    </div>
                    <div class="mf-customers-old">
                        <!-- Seznam strank, ki jih lahko povežeš -->
                        <span *ngFor="let customerOld of customersOld">
                            <div (click)="existingCustomer && selectCustomer(customerOld)" class="mf-customer-details">
                                <div style="position: relative; z-index: 10">
                                    <div class="mf-text-overlay">
                                        <div class="d-flex align-items-center justify-content-center h-100">
                                            <h2>Izberi obstoječo stranko</h2>
                                        </div>
                                    </div>
                                    <cust-customer-details
                                        [customerForEdit]="customerOld"
                                        [isReadOnly]="true"
                                        style="z-index: 1"
                                    ></cust-customer-details>
                                </div>
                            </div>

                            <div *ngIf="customersOld.length > 1" class="mf-dashed-line"></div>
                        </span>
                        <div *ngIf="!customersOld?.length && editingType === 'LINKING_CUSTOMERS' && !isManualSearchEnabled" class="px-3">
                            V sistemu ni bilo zaznanih podobnih strank.
                            <br />
                            Poskusite poiskati stranko preko "Ročnega iskanja".
                        </div>
                        <div *ngIf="!customersOld?.length && editingType === 'LINKING_CUSTOMERS' && isManualSearchEnabled" class="px-3">
                            Nobena stranka ni izbrana.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer d-flex justify-content-center">
    <button
        (click)="activeModal.close('Close click')"
        type="button"
        tabindex="8"
        class="btn m-btn--pill btn-danger m-btn m-btn--custom"
        i18n="@@8719e5f3f37cc6a96a4336ad6ed796c743541bd4"
    >
        <span *ngIf="editingType === 'EXISTING_CUSTOMER'; else linkingCustomers3">Prekliči</span>
        <ng-template #linkingCustomers3>Prekliči povezovanje strank</ng-template>
    </button>
    <button
        *ngIf="editingType === 'EXISTING_CUSTOMER'"
        (click)="saveNewCustomer()"
        type="buttons"
        tabindex="6"
        class="btn m-btn--pill btn-accent m-btn m-btn--custom"
        i18n="@@a9f0a233dcaf4e431a3343d441753a7b98cb2422"
    >
        Shrani novo stranko
    </button>
</div>
