import { ErrorHandler, Injectable, Injector } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { environment } from '../../../environments/environment';
import { User } from './../model/user.model';
import { StorageService } from './storage.service';

//https://github.com/bugsnag/bugsnag-js/issues/135#issuecomment-384788669
const bugsnagClient = Bugsnag.start({
    apiKey: '4c8b0c632c20c7d5d987858bb90936a3',
    appVersion: environment.VERSION,
    releaseStage: environment.RELEASE_STAGE,
    enabledReleaseStages: ['production', 'staging'],
    onError: function (event) {
        //TODO preveri če tole deluje
        // const ignoreError = /ChunkLoadError/;
        if (/ChunkLoadError/.test(event.originalError) || /close-no-error/.test(event.originalError)) {
            return false;
        }
        return true;
    },
});

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) {}

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleError(error: any): void {
        const storage = this.injector.get(StorageService);
        const errorToDisplay = 'Napaka.';

        if (storage.getUserData()) {
            // bugsnagClient.user = storage.getUserData();

            const user: User = storage.getUserData();
            bugsnagClient.setUser(user.id + '', user.email, `${user.name} ${user.surname}`);
        }
        if (bugsnagClient) {
            if (error) {
                bugsnagClient?.notify(error);
            } else {
                bugsnagClient?.notify(new Error('neznano'));
            }
        }
        // }
        console.error(errorToDisplay);
        // IMPORTANT: Rethrow the error otherwise it gets swallowed
        throw error;
    }
}
