import * as _ from 'lodash';
import { Serializable } from '../deserialize.service';

export class HolidayEvent implements Serializable<HolidayEvent> {
    holidayDate: Date;
    name: string;

    deserialize(input) {
        if (_.isNil(input)) {
            return this;
        }
        this.holidayDate = input.holidayDate;
        this.name = input.name;

        return this;
    }
}
