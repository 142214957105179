import { Serializable } from './../../../../_shared/model/deserialize.service';
import { WebSocketChat } from './web-socket-chat.model';
import { WebSocketChannel } from './webSocketChannel.model';
import { WebSocketChannelInfo } from './webSocketChannelInfo.model';

export class WsMessage implements Serializable<WsMessage> {
    // messageType: VendorType;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    messageType: any;
    data: WebSocketChannel | WebSocketChannelInfo | WebSocketChat;
    deserialize(input) {
        this.messageType = input.messageType;
        this.data = input.data;
        return this;
    }
}
