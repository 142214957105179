import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Intercom } from 'ng-intercom';
import { environment } from '../../../../environments/environment';
import { StorageService } from '../../../_shared/services';
import { AppModulesType } from './../../../_shared/enums/appModulesType.enum';
import { ActiveModulesService } from './../../../_shared/services/activeModules.service';

// import { DOCUMENT } from '@angular/platform-browser';
// var $_Tawk;
@Component({
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '.m-grid__item.m-grid__item--fluid.m-grid.m-grid--ver-desktop.m-grid--desktop.m-body',
    templateUrl: './home.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
    public isTelephonyModule = false;

    constructor(
        private storage: StorageService,
        private intercom: Intercom,
        private _renderer: Renderer2,
        private activeModules: ActiveModulesService,
        @Inject(DOCUMENT) private _document,
    ) {}

    private id = '5ef99f259e5f694422917d71';
    ngOnInit() {
        this.isTelephonyModule = this.activeModules.isAM(AppModulesType.TELEPHONY);

        const userdata = this.storage.getUserData();
        this.intercom.boot({
            app_id: environment.INTERCOM_KEY,
            name: _.get(userdata, 'name', ''), // Full name
            email: _.get(userdata, 'email', ''), // Email address
            created_at: moment().unix(), // Signup date as a Unix timestamp
            subcontractorId: _.get(userdata, 'subcontractorId', ''),
            APP_version: environment.VERSION,
            phone: _.get(userdata, 'phone', ''),
            // Supports all optional configuration.
            widget: {
                activator: '#intercom',
            },
            Products: 'eAmbulanta',
        });
    }
}
