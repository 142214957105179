<h1 class="mf-popup-title" i18n="@@app.component.popup.send-documents.title.text">Pošlji</h1>
<div class="m-portlet mf-portlet-gray__gray-background">
    <div class="m-portlet__body">
        <div class="m-content">
            <div *ngIf="smsToPayer || emailToPayer" class="m-radio-inline">
                <label class="m-radio m-radio--state-brand mr-0">
                    <input
                        [(ngModel)]="sendToCustomer"
                        [value]="1"
                        [ngModelOptions]="{ standalone: true }"
                        (click)="radioChanged('customer')"
                        type="radio"
                        name="sendToCustomer"
                    />
                    Stranka
                    <span></span>
                </label>
            </div>
            <!-- STRANKA FORM -->
            <form [formGroup]="sendInvoiceForm" [hidden]="!sendToCustomer" (ngSubmit)="onSubmitSendInvoice()" novalidate>
                <div *ngIf="receiver" class="m-input-icon m-input-icon--left mb-2">
                    <input type="text" formControlName="receiver" class="form-control m-input has-danger" />
                    <span class="m-input-icon__icon m-input-icon__icon--left">
                        <span>
                            <i class="la la-user"></i>
                        </span>
                    </span>
                </div>
                <div *ngIf="sendInvoiceForm.controls['receiver'].errors && isSubmited">
                    <small
                        [hidden]="!sendInvoiceForm.controls['receiver'].errors.required"
                        class="form-control-feedback m--font-danger"
                        i18n="@@acc.widget.send.popup.receiverrequired"
                    >
                        Prejemnik je obvezen podatek.
                    </small>
                </div>

                <div class="m-input-icon m-input-icon--left mb-2">
                    <input type="email" formControlName="emailTo" class="form-control m-input has-danger" />
                    <span class="m-input-icon__icon m-input-icon__icon--left">
                        <span>
                            <i class="la la-envelope"></i>
                        </span>
                    </span>
                </div>
                <div *ngIf="sendInvoiceForm.controls['emailTo'].errors && isSubmited">
                    <small
                        [hidden]="!sendInvoiceForm.controls['emailTo'].errors.required"
                        class="form-control-feedback m--font-danger"
                        i18n="@@5466938849361920"
                    >
                        E-pošta je obvezen podatek.
                    </small>
                    <small
                        [hidden]="!sendInvoiceForm.controls['emailTo'].errors.email"
                        class="form-control-feedback m--font-danger"
                        i18n="@@acc.widget.send.popup.notemail"
                    >
                        Napačna oblika epošte.
                    </small>
                </div>

                <div class="m-input-icon m-input-icon--left mb-2">
                    <input type="text" formControlName="phoneTo" class="form-control m-input has-danger" />
                    <span class="m-input-icon__icon m-input-icon__icon--left">
                        <span>
                            <i class="la la-phone"></i>
                        </span>
                    </span>
                </div>
                <div *ngIf="sendInvoiceForm.controls['phoneTo'].errors && isSubmited">
                    <small
                        [hidden]="!sendInvoiceForm.controls['phoneTo'].errors.required"
                        class="form-control-feedback m--font-danger"
                        i18n="@@5919721981476864"
                    >
                        Mobilni telefon je obvezen podatek.
                    </small>
                </div>
            </form>
            <!-- PLAČNIK FORM -->
            <div *ngIf="smsToPayer || emailToPayer" class="m-radio-inline">
                <label class="m-radio m-radio--state-brand mr-0">
                    <input
                        [(ngModel)]="sendToPayer"
                        [value]="1"
                        [ngModelOptions]="{ standalone: true }"
                        (click)="radioChanged('payer')"
                        type="radio"
                        name="sendToPayer"
                    />
                    Plačnik
                    <span></span>
                </label>
            </div>
            <form
                *ngIf="smsToPayer || emailToPayer"
                [hidden]="!sendToPayer"
                [formGroup]="sendPayerInvoiceForm"
                (ngSubmit)="onSubmitSendInvoice()"
                novalidate
            >
                <div class="m-input-icon m-input-icon--left mb-2">
                    <input type="email" formControlName="emailTo" class="form-control m-input has-danger" />
                    <span class="m-input-icon__icon m-input-icon__icon--left">
                        <span>
                            <i class="la la-envelope"></i>
                        </span>
                    </span>
                </div>
                <div *ngIf="sendPayerInvoiceForm.controls['emailTo'].errors && isSubmited">
                    <small
                        [hidden]="!sendPayerInvoiceForm.controls['emailTo'].errors.required"
                        class="form-control-feedback m--font-danger"
                        i18n="@@5466938849361920"
                    >
                        E-pošta je obvezen podatek.
                    </small>
                    <small
                        [hidden]="!sendPayerInvoiceForm.controls['emailTo'].errors.email"
                        class="form-control-feedback m--font-danger"
                        i18n="@@acc.widget.send.popup.notemail"
                    >
                        Napačna oblika epošte.
                    </small>
                </div>

                <div class="m-input-icon m-input-icon--left mb-2">
                    <input type="text" formControlName="phoneTo" class="form-control m-input has-danger" />
                    <span class="m-input-icon__icon m-input-icon__icon--left">
                        <span>
                            <i class="la la-phone"></i>
                        </span>
                    </span>
                </div>
                <div *ngIf="sendPayerInvoiceForm.controls['phoneTo'].errors && isSubmited">
                    <small
                        [hidden]="!sendPayerInvoiceForm.controls['phoneTo'].errors.required"
                        class="form-control-feedback m--font-danger"
                        i18n="@@5919721981476864"
                    >
                        Mobilni telefon je obvezen podatek.
                    </small>
                </div>
            </form>
        </div>
    </div>
</div>
<div class="modal-footer mf-custom-footer">
    <button
        (click)="activeModal.close(undefined)"
        type="button"
        style="width: 150px"
        class="ml-auto btn m-btn--pill btn-warning m-btn m-btn--custom"
        i18n="@@cancel"
    >
        Prekliči
    </button>
    <button
        (click)="onSubmitSendInvoice(); isSubmited = true"
        type="submit"
        style="width: 150px"
        class="mr-auto btn m-btn--pill btn-accent m-btn"
        i18n="@@confirm"
    >
        Pošlji
    </button>
</div>
