// import { FcEvent } from './../fcEvent.model';
import * as _ from 'lodash';
import { EventActions } from '../../enums/eventActions.enum';
import { Serializable } from '../deserialize.service';
import { VisitNew } from './visitNew.model';

export class VisitReturn implements Serializable<VisitReturn> {
    action: EventActions;
    visitNew: VisitNew;

    deserialize(input) {
        if (_.isNil(input)) {
            return this;
        }
        this.action = input.action;
        this.visitNew = new VisitNew().deserialize(input.visitNew);

        return this;
    }
}
