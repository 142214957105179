<form [formGroup]="gfForm" (ngSubmit)="onSubmit()" novalidate autocomplete="off">
    <div class="row mf-wr-wrapper">
        <div class="col-md col-xl">
            <!-- PRVI STOLPEC  -->
            <pf-p-wr-subcontractor [subcontracor]="subcontractor"></pf-p-wr-subcontractor>
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">Vrsta specialnosti napotnega zdravnika</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body d-flex">
                    <span style="line-height: 32px" class="pr-2">
                        <i class="la la-i-cursor m--font-inverse-light"></i>
                    </span>
                    <input
                        formControlName="doctorSpeciality"
                        tabindex="3"
                        type="text"
                        autocomplete="off"
                        class="form-control form-control-sm m-input"
                    />
                </div>
            </div>
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">Kraj in dne</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body d-flex">
                    <div class="d-flex w-100">
                        <span style="line-height: 32px" class="pr-2">
                            <i class="la la-map-marker m--font-inverse-light"></i>
                        </span>
                        <input
                            formControlName="referralPlace"
                            tabindex="3"
                            type="text"
                            autocomplete="off"
                            placeholder="Kraj"
                            class="form-control form-control-sm m-input"
                        />
                    </div>
                    <div class="d-flex w-100">
                        <span style="line-height: 32px" class="pr-2">
                            <i class="la la-calendar m--font-inverse-light"></i>
                        </span>
                        <input
                            [options]="calendarOptions"
                            (selected)="onDateChange($event)"
                            formControlName="referralDateString"
                            tabindex="3"
                            type="text"
                            autocomplete="off"
                            placeholder="Dne"
                            daterangepicker
                            class="form-control form-control-sm m-input"
                        />
                    </div>
                </div>
            </div>
        </div>
        <!-- DRUGI STOLPEC -->
        <div class="col-md col-xl">
            <pf-p-wr-customer [customer]="customer"></pf-p-wr-customer>
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">Razlog obravnave</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body">
                    <div class="m-radio-list">
                        <label class="m-radio m-radio--state-brand">
                            <input type="radio" formControlName="reasonOfTreatment" value="2" />
                            Bolezen
                            <span></span>
                        </label>
                        <label class="m-radio m-radio--state-brand">
                            <input type="radio" formControlName="reasonOfTreatment" value="1" />
                            Poškodba
                            <span></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <!-- VNOSNA POLJA -->
        <div class="col-md col-xl">
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">Delovna diagnoza</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body">
                    <editor [init]="tmceSettings" formControlName="referralText1"></editor>
                </div>
            </div>
        </div>
    </div>
</form>
