import * as moment from 'moment';
import { Serializable } from '../deserialize.service';

export class WhiteReferralApi implements Serializable<WhiteReferralApi> {
    //TIP NAPOTNICE (BELA/HALO DOKTOR)  WHITE, HELLODOC
    public referralType: string;
    // VELJAVNOST
    public validity: string;
    public validityMonth: string;

    // OBSEG POOBLASTILA
    public authorization: string;
    // RAZLOG OBRAVNAVE
    public reasonOfTreatment: string;

    // PREDNOSTNI KRITERIJI
    public priorityCriteria: string;

    // ŠT. ELEKTRONSKE NAPOTNICE
    public referralNumber: string;

    // STOPNJA NUJNOSTI
    public priorityLevel: string;

    // ŠIFRA IZVAJALCA
    public performerId: string;

    // RDP
    public rdpCode: string;
    public rdpDescription: string;

    // MKB
    public mkbCode: string;
    public mkbDescription: string;

    public doctorSpeciality: string;
    public referralPlace: string;
    public referralDate: moment.Moment | Date;
    public orderDate: moment.Moment;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public services: any;

    public referralText1: string;
    public referralText2: string;
    public referralText3: string;
    public referralText4: string;
    public referralText5: string;
    public referralText6: string;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        this.validity = input.validity;
        this.validityMonth = input.validityMonth;
        this.authorization = input.authorization;

        this.referralType = input.referralType;
        this.reasonOfTreatment = input.reasonOfTreatment;
        this.priorityCriteria = input.priorityCriteria;
        this.referralNumber = input.referralNumber;
        this.priorityLevel = input.priorityLevel;
        this.performerId = input.performerId;
        this.rdpCode = input.rdpCode;
        this.rdpDescription = input.rdpDescription;
        this.mkbCode = input.mkbCode;
        this.mkbDescription = input.mkbDescription;
        this.doctorSpeciality = input.doctorSpeciality;
        this.referralPlace = input.referralPlace;
        this.referralText1 = input.referralText1;
        this.referralText2 = input.referralText2;
        this.referralText3 = input.referralText3;
        this.referralText4 = input.referralText4;
        this.referralText5 = input.referralText5;
        this.referralText6 = input.referralText6;

        if (input.referralDate) {
            this.referralDate = moment(input.referralDate);
        }
        return this;
    }
}
