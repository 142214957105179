export enum OrderStatus {
    DIAGNOSTICS = 'DIAGNOSTICS',
    MODELING_SENT = 'MODELING_SENT',
    MODELING_IN_PROGRESS = 'MODELING_IN_PROGRESS',
    MODELING_FINISHED = 'MODELING_FINISHED',
    PRODUCTION = 'PRODUCTION',
    PACKING = 'PACKING',
    TAKEOVER = 'TAKEOVER',
    DONE = 'DONE',
    CANCELLED = 'CANCELLED',
}
