import { Customer } from './../customer.model';

export class CustomerGuardian extends Customer {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        super.deserialize(input);
        return Object.assign(this, input);
    }
}
