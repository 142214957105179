import * as moment from 'moment';
import { Asset } from '../asset.model';
import { Serializable } from '../deserialize.service';
import { Subcontractor } from '../subcontractor.model';

export class AvailabiliyRequestData implements Serializable<AvailabiliyRequestData> {
    subcontractor: Subcontractor;
    from: moment.Moment;
    to: moment.Moment;
    asset: Asset;
    openForExternal: boolean;
    openForExternalCustomer: boolean;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    bsids: any;
    id: string;
    reacurring: number;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    partners: any;
    virtualReadiness: boolean;
    virtualOrdered: boolean;
    services: number[];
    // connected: boolean;
    // directBooking: boolean;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        this.from = input.from;
        this.to = input.to;
        if (input.subcontractor) {
            this.subcontractor = new Subcontractor().deserialize(input.subcontractor);
        }
        if (input.asset) {
            this.asset = new Asset().deserialize(input.asset);
        }
        this.openForExternal = input.openForExternal;
        this.openForExternalCustomer = input.openForExternalCustomer;
        this.id = input.id;
        this.bsids = input.bsids;
        this.reacurring = input.reacurring;
        this.partners = input.partners;
        this.virtualReadiness = input.virtualReadiness;
        this.virtualOrdered = input.virtualOrdered;
        this.services = input.services;
        return this;
    }
}
