import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { environment } from './../../../../../environments/environment';
import { HelperService } from './../../../../_shared/services/helper.service';
import { HttpClientService } from './../../../../_shared/services/http-client.service';
import { OrderData } from './_model/orderData';
import { OrderDataHistory } from './_model/orderDataHistory';
import { OrderDeliveryInfo } from './_model/orderDeliveryInfo';
import { OrderStatus } from './_model/orderStatus.enum';

@Injectable({
    providedIn: 'root',
})
export class OimTrackingService {
    // @Controller("/booking/eav2/oim/api")
    constructor(
        private httpClient: HttpClientService,
        private helper: HelperService,
    ) {}
    // -----------------------------------------------------------------------------------------------------
    // -------------------------------------------------------- DUMMY CALLS---------------------------------
    // --------------------------------------------------------------------------------------------------------
    public tryChangeOrderStatus(orderData: OrderData): Observable<boolean> {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let allow: any = throwError('missing_data');
        switch (orderData?.workOrderStatus) {
            case OrderStatus.DIAGNOSTICS:
                //DIAGNOSTICS -> MODELING_SENT
                if (
                    orderData?.deliveryInfo &&
                    orderData?.measurement &&
                    orderData?.documents?.documents?.some(docu => docu.documentSource === 'CONTRACT')
                )
                    allow = of(true);
                break;
            case OrderStatus.MODELING_FINISHED:
                //MODELING_FINISHED -> PRODUCTION
                if (orderData?.deliveryInfo && orderData?.documents?.documents?.some(docu => docu.documentSource === 'MODEL')) allow = of(true);
                break;
            case OrderStatus.PRODUCTION:
                //PRODUCTION -> PACKING
                if (
                    orderData?.qualityAssurances?.qualityAssurances?.some(
                        el =>
                            (el?.upperMaterialOK &&
                                el?.middleMaterialOK &&
                                el?.lowerMaterialOK &&
                                el?.lengthShapeOK &&
                                el?.edgesOK &&
                                el?.properInstructionsGivenOK &&
                                el?.correctionsMadeOK) ||
                            false,
                    )
                )
                    allow = of(true);
                break;
            case OrderStatus.PACKING:
                //PACKING -> TAKEOVER
                allow = of(true);
                break;
            case OrderStatus.TAKEOVER:
                //TAKEOVER -> DONE
                allow = of(true);
                break;
        }
        return allow;
    }
    public getQrCodeUrl(orderId: string): string {
        return `${
            environment.RELEASE_STAGE === 'production' ? 'https://app.eambulanta.si' : 'https://test-app.eambulanta.si'
        }/oim/kanban?orderId=${orderId}`;
    }
    // -----------------------------------------------------------------------------------------------------
    // -------------------------------------------------------- POST/PUT CALLS---------------------------------
    // --------------------------------------------------------------------------------------------------------
    public createOrderCopy(contractorId: number, orderId: string) {
        return this.httpClient.post(this.helper.getOimApi() + `/contractors/${contractorId}/workorder/${orderId}/copy`, {});
    }
    public changeNumberOfPairs(contractorId: number, orderId: string, numberOfPairs: number) {
        return this.httpClient.put(
            this.helper.getOimApi() + `/contractors/${contractorId}/workorders/${orderId}/quantity/${numberOfPairs}`,
            {},
            {
                responseType: 'text',
            },
        );
    }
    public submitControlPaperOnWorkOrder(contractorId: number, orderId: string, quallityData) {
        return this.httpClient.post(this.helper.getOimApi() + `/contractors/${contractorId}/workorders/${orderId}/qualityAssurance`, quallityData, {
            responseType: 'text',
        });
    }
    public changeOrderStatus(contractorId: number, orderId: string, status: OrderStatus) {
        return this.httpClient.put(this.helper.getOimApi() + `/contractors/${contractorId}/workorders/${orderId}/status/${status}`);
    }
    public submitSurveyOnOrder(data) {
        return this.httpClient.post(this.helper.getAssistanceUrl(3) + `/api/surveys`, data, { responseType: 'text' });
    }
    public addDeliveryInfoOnOrder(contractorId: number, orderId: string, data: OrderDeliveryInfo) {
        return this.httpClient.post(this.helper.getOimApi() + `/contractors/${contractorId}/workorders/${orderId}/deliveryInfo`, data);
    }
    public addMeasurmentsOnOrder(contractorId: number, orderId: string, data) {
        return this.httpClient.post(this.helper.getOimApi() + `/contractors/${contractorId}/workorders/${orderId}/measurement`, data);
    }
    public addCommentOnOrder(contractorId: number, orderId: string, commentData) {
        return this.httpClient.post(this.helper.getOimApi() + `/contractors/${contractorId}/workorders/${orderId}/comments`, commentData, {
            responseType: 'text',
        });
    }
    public editCommentOnOrder(contractorId: number, orderId: string, commentId: number, commentData) {
        return this.httpClient.put(
            this.helper.getOimApi() + `/contractors/${contractorId}/workorders/${orderId}/comments/${commentId}`,
            commentData,
            {
                responseType: 'text',
            },
        );
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public createWorkOrder(contractorId: number, customerId: number, visitId?): Observable<any> {
        let apiUrl = this.helper.getOimApi() + `/contractors/${contractorId}/customers/${customerId}/workorder`;
        if (visitId) apiUrl += `?visitId=${visitId}`;
        return this.httpClient.post(
            apiUrl,
            {},
            {
                responseType: 'text',
            },
        );
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addDocumentOnWorkOrder(contractorId: number, workOrderId: string, maskedId: any, type): Observable<any> {
        return this.httpClient.post(
            this.helper.getOimApi() + `/contractors/${contractorId}/workorders/${workOrderId}/documents/${maskedId}/type/${type}`,
            {},
            {
                responseType: 'text',
            },
        );
    }

    // -----------------------------------------------------------------------------------------------------
    // --------------------------------------------------------GET CALLS------------------------------------
    // -----------------------------------------------------------------------------------------------------
    public getSingleOrder(contractorId: number, orderId: string): Observable<OrderData> {
        return this.httpClient.get(this.helper.getOimApi() + `/contractors/${contractorId}/workorders/${orderId}`);
    }
    public getOrdersForContractor(contractorId: number, onlyActive: boolean, from?: string, to?: string): Observable<OrderData[]> {
        let dataUrl: string = this.helper.getOimApi() + `/contractors/${contractorId}/workorders?active=${onlyActive}`;
        if (from && to) {
            dataUrl += `&from=${from}&to=${to}`;
        }
        return this.httpClient.get(dataUrl);
    }
    public getOrdersForVisit(contractorId: number, visitId: number): Observable<OrderData> {
        // @Get("/contractors/{contractorId}/visits/{visitId}/workorder")
        return this.httpClient.get(this.helper.getOimApi() + `/contractors/${contractorId}/visits/${visitId}/workorder`);
    }
    public getOrdersForCustomer(contractorId: number, customerId: number): Observable<OrderData[]> {
        // @Get("/contractors/{contractorId}/customers/{customerId}/workorders")
        return this.httpClient.get(this.helper.getOimApi() + `/contractors/${contractorId}/customers/${customerId}/workorders`);
    }
    public getOrderHistory(contractorId: number, orderId: string): Observable<OrderDataHistory[]> {
        // @Get("/contractors/{contractorId}/workorders/{id}/log")
        return this.httpClient.get(this.helper.getOimApi() + `/contractors/${contractorId}/workorders/${orderId}/log`);
    }

    public getWorkOrdersForReservation(contractorId: number, prereservationid: string): Observable<OrderData> {
        return this.httpClient.get(this.helper.getOimApi() + `/contractors/${contractorId}/reservations/${prereservationid}/workorder`);
    }
    // -----------------------------------------------------------------------------------------------------
    // --------------------------------------------------------DELETE CALLS---------------------------------
    // -----------------------------------------------------------------------------------------------------
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteCommentOnOrder(contractorId: number, orderId: string, commentId: number): Observable<any> {
        return this.httpClient.delete(this.helper.getOimApi() + `/contractors/${contractorId}/workorders/${orderId}/comments/${commentId}`, {
            responseType: 'text',
        });
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteDocumentOnWorkOrder(contractorId: number, orderId: string, documentId: any): Observable<any> {
        return this.httpClient.delete(this.helper.getOimApi() + `/contractors/${contractorId}/workorders/${orderId}/documents/${documentId}`, {
            responseType: 'text',
        });
    }
}
