import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TelephonyService } from './../../../pages/home/telephony/telephony.service';

@Component({
    selector: 'asn-telephony-status',
    templateUrl: './asn-telephony-status.component.html',
    styleUrls: ['./asn-telephony-status.component.scss'],
})
export class AsnTelephonyStatusComponent implements OnInit {
    public isActiveCall = false;
    private onDestroy$: Subject<void> = new Subject<void>();

    constructor(private telephony: TelephonyService) {}

    ngOnInit() {
        this.telephony.onOutgoingAnswered.pipe(takeUntil(this.onDestroy$)).subscribe(() => (this.isActiveCall = true));
        this.telephony.oncCallEndRingtone.pipe(takeUntil(this.onDestroy$)).subscribe(() => (this.isActiveCall = true));
        this.telephony.onCallEnded.pipe(takeUntil(this.onDestroy$)).subscribe(() => (this.isActiveCall = false));
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
