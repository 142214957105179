import { Injectable } from '@angular/core';
import { ActiveToast, ToastrService } from 'ngx-toastr';
import { ErrorNotifyService } from './errorNotify.service';

// const bugsnagClient = bugsnag({ apiKey: '4c8b0c632c20c7d5d987858bb90936a3', appVersion: environment.VERSION });

@Injectable()
export class MfToastService {
    constructor(
        private toast: ToastrService,
        private erorNotify: ErrorNotifyService,
    ) {}

    public error(text: string): void {
        this.toast.error(text);
    }

    public errorAndSend(text: string): void {
        this.toast.error(text);
        this.erorNotify.send(text, { severity: 'error' });
    }

    public warning(text: string): void {
        this.toast.warning(text);
    }

    public warningAndSend(text: string): void {
        this.toast.warning(text);
        this.erorNotify.send(text, { severity: 'warning' });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public info(text: string): ActiveToast<any> {
        return this.toast.info(text);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public hd(text: string, title?: string): ActiveToast<any> {
        return this.toast.info(text, title ? title : 'Halo Doktor', { closeButton: true, progressBar: true });
        // return this.toast.info(text, undefined, { closeButton: true, disableTimeOut: true });
    }

    public infoAndSend(text: string): void {
        this.toast.info(text);
        this.erorNotify.send(text, { severity: 'info' });
    }

    public success(text: string): void {
        this.toast.success(text);
    }

    public successAndSend(text: string): void {
        this.toast.success(text);
        this.erorNotify.send(text, { severity: 'info' });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public clear(id: number): any {
        return this.toast.clear(id);
    }
}
