<ng-container *ngIf="userGuiSettings?.haloDoctor?.isActive !== false">
    <span ngbTooltip="Aktiven - sprejemam klice" class="m-menu__link-badge">
        <span class="m-badge m-badge--success"></span>
    </span>
</ng-container>

<ng-container *ngIf="userGuiSettings?.haloDoctor?.isActive === false">
    <span ngbTooltip="Zaseden - ne sprejemam klicev" class="m-menu__link-badge">
        <span class="m-badge m-badge--warning"></span>
    </span>
</ng-container>
