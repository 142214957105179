import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceFile } from './../../../../_shared/model/inbox/deviceFile.model';
import { DeviceFileRequest } from './../../../../_shared/model/inbox/deviceFileRequest.model';
import { DeviceFileResponse } from './../../../../_shared/model/inbox/deviceFileResponse.model';
import { TelemedRead } from './../../../../_shared/model/inbox/telemedRead.model';
import { TelemedUnread } from './../../../../_shared/model/inbox/telemedUnread.model';
import { HelperService } from './../../../../_shared/services/helper.service';
import { HttpClientService } from './../../../../_shared/services/http-client.service';
import { StorageService } from './../../../../_shared/services/storage.service';

@Injectable({
    providedIn: 'root',
})
export class InboxService {
    constructor(
        private httpClient: HttpClientService,
        private storage: StorageService,
        private helper: HelperService,
    ) {}

    //getIteroFiles
    public getIteroFiles(): Observable<DeviceFile[]> {
        return this.httpClient.get(this.helper.getIteroUrl() + '/files');
    }

    //getIteroFiles
    public getIteroFileData(data: DeviceFileRequest): Observable<DeviceFileResponse> {
        return this.httpClient.post(this.helper.getIteroUrl() + '/file', data);
        // return this.httpClient.getAttachmentPost(this.helper.getIteroUrl() + '/file', data);
    }

    public ignoreIteroFileData(data: DeviceFileRequest): Observable<DeviceFileResponse> {
        return this.httpClient.post(this.helper.getIteroUrl() + '/file/ignore', data);
        // return this.httpClient.getAttachmentPost(this.helper.getIteroUrl() + '/file', data);
    }

    public getTelemedicineUnread(cid: number): Observable<TelemedUnread[]> {
        return this.httpClient.get(this.helper.getTelemedUrl() + `/contractors/${cid}/chat/unread`).pipe(map(el => el.unreads));
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public setTelemedicineRead(cid: number, data: TelemedRead): Observable<any> {
        return this.httpClient.post(this.helper.getTelemedUrl() + `/contractors/${cid}/chat/read`, data);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public setSurveysRead(contractorId: number, surveyId: any): Observable<any> {
        // /contractors/{contractorid}/surveys/{surveyResultId}/seen")
        return this.httpClient.post(
            this.helper.getErpRootUrl() + `api/contractors/${contractorId}/surveys/${surveyId}/seen`,
            {},
            {
                responseType: 'text',
            },
        );
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public setTriagesRead(contractorId: number, triageId: any): Observable<any> {
        // /contractors/{contractorid}/etriages/{triageId}/seen")
        return this.httpClient.post(
            this.helper.getErpRootUrl() + `api/contractors/${contractorId}/etriages/${triageId}/seen`,
            {},
            {
                responseType: 'text',
            },
        );
    }
}
