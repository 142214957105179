import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Asset } from './../../../../../_shared/model/asset.model';
import { Slot } from './../../../../../_shared/model/slot.model';
import { Subcontractor } from './../../../../../_shared/model/subcontractor.model';
import { AvailabilityCalendarFilter } from './../../../../../_shared/model/userGuiSettings/availabilityCalendarFilter';
import { AvailabilityStateModel } from './availability-state.model';
import { SetFilter, UpdateFilterParameter } from './availability.actions';
const AVAILABILITYSTATE_TOKEN: StateToken<AvailabilityStateModel> = new StateToken('availabilityState');

const DEFAULT_STATE: AvailabilityStateModel = {
    filter: {
        showResources: true,
        showPlanning: false,
        showOnlyAvailable: false,
        showExternalAvailable: true,
        showExternalCustomerAvailable: true,
        showInternal: true,
        showWeekend: false,
        showHolidays: true,
        from: '06:00',
        to: '19:00',
        timeFrequncy: 15,
        selectedSubcontractors: [],
        selectedAssets: [],
        viewDateRange: undefined,
    },
};

@State<AvailabilityStateModel>({
    name: AVAILABILITYSTATE_TOKEN,
    defaults: DEFAULT_STATE,
    children: [], //ce bo kdaj prov prislo
})
@Injectable()
export class AvailabilityState {
    // static getFilter() {
    //     return createSelector([AvailabilityState], (state: AvailabilityStateModel) => state.filter);
    // }

    @Selector()
    public static getFilter(state: AvailabilityStateModel): AvailabilityCalendarFilter {
        return state.filter;
    }

    @Selector()
    public static getFilterSubcontracors(state: AvailabilityStateModel): Subcontractor[] {
        return state.filter.selectedSubcontractors;
    }

    @Selector()
    public static getFilterAssets(state: AvailabilityStateModel): Asset[] {
        return state.filter.selectedAssets;
    }

    @Selector()
    public static getFilterShowWeekend(state: AvailabilityStateModel): boolean {
        return state.filter.showWeekend;
    }

    @Selector()
    public static getFilterViewDateRange(state: AvailabilityStateModel): Slot {
        return state.filter.viewDateRange;
    }

    @Action(SetFilter)
    public SetFilter({ setState }: StateContext<AvailabilityStateModel>, { filter }: SetFilter) {
        setState(
            patch({
                filter: filter,
            }),
        );
    }

    @Action(UpdateFilterParameter)
    public UpdateNoteParameter(ctx: StateContext<AvailabilityStateModel>, { name, value }: UpdateFilterParameter) {
        ctx.setState(
            patch<AvailabilityStateModel>({
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                filter: patch({ [name]: val => (val = value) }),
            }),
        );
    }
}
