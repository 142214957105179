import { Injectable } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { HelperService } from '../../../../_shared/services';
import { HttpClientService } from '../../../../_shared/services/http-client.service';

@Injectable()
export class IndexService {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    token: any;

    constructor(
        private httpClient: HttpClientService,
        private helper: HelperService,
        private permissionsService: NgxPermissionsService,
    ) {}

    // findReservations()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getReservationList(cid: number, subcontractorId: number, fromdate: string, to: string): Observable<any> {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const promise: Promise<any> = this.permissionsService.hasPermission('index_termsViewAll');
        return from(promise).pipe(
            mergeMap(hasPermission => {
                let url = '';
                if (hasPermission) {
                    url = this.helper.getApiUrl() + '/contractors/' + cid + '/reservations?from=' + fromdate + '&to=' + to;
                } else {
                    url =
                        this.helper.getApiUrl() +
                        '/contractors/' +
                        cid +
                        '/subcontractors/' +
                        subcontractorId +
                        '/reservations?from=' +
                        fromdate +
                        '&to=' +
                        to;
                }

                return this.httpClient.get(url);
            }),
        );
    }
}
