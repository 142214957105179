import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BCReservationStatus, BusinessCaseResponse, Comment, Document, WQInfo } from '../../../../_shared/model';
import { SecondaryContact } from '../../../../_shared/model/case/secondary-contact.model';
import { WaitingQueueItem } from '../../../../_shared/model/schedule/WaitingQueueItem.model';
import { HelperService } from '../../../../_shared/services';
import { HttpClientService } from '../../../../_shared/services/http-client.service';

@Injectable({
    providedIn: 'root',
})
export class CaseService {
    constructor(
        private httpClient: HttpClientService,
        private helper: HelperService,
    ) {}

    public getCasesForContractor(contractorid: number, bsid?: number, caseId?: string): Observable<BusinessCaseResponse[]> {
        let url = this.helper.getApiUrl() + `/contractors/${contractorid}/cases`;
        if (bsid && caseId) {
            url = this.helper.getApiUrl() + `/contractors/${contractorid}/bs/${bsid}/cases/${this.helper.replaceSlahsForDolar(caseId)}`;
        }
        return this.httpClient.get(url).pipe(map(el => el.map((el2: BusinessCaseResponse) => new BusinessCaseResponse().deserialize(el2))));
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getCasesForContractorPaging(contractorid: number, pagingData?: any) {
        let dataUrl = this.helper.getApiUrl(2) + `/contractors/${contractorid}/cases-paging`;
        if (!_.isNil(pagingData)) {
            let first = true;
            Object.entries(pagingData).forEach(el => {
                if (!_.isNil(el[1]) && _.get(el, '[1]', null)?.length !== 0 && _.get(el, '[0]') != 'total') {
                    if (el[0] == 'offset') {
                        el[1] = pagingData.offset * pagingData.limit;
                    }
                    if (first) {
                        dataUrl += `?${el[0]}=${el[1]}`;
                        first = false;
                    } else {
                        dataUrl += `&${el[0]}=${el[1]}`;
                    }
                }
            });
        }
        return this.httpClient.get(dataUrl);
    }

    //getCaseReservationStatuses()
    public getCaseReservationStatuses(bsid: number, caseid: string): Observable<BCReservationStatus[]> {
        return this.httpClient.get(this.helper.getAssistanceUrl() + `/bs/${bsid}/cases/${this.helper.replaceSlahsForDolar(caseid)}/statuses`);
        // .pipe(map(el => el.map(el2 => new HistoryLog().deserialize(el2))));
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getCaseCoverage(bsid: number, caseId: string): Observable<any> {
        return this.httpClient.get(this.helper.getAccountingUrl(2) + `/bs/${bsid}/cases/${this.helper.replaceSlahsForDolar(caseId)}/coveragePrices`);
    }

    //addCaseContractorComment()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addCaseContractorComment(contractorid: number, bsid: number, caseid: string, data: any): Observable<any> {
        return this.httpClient.post(
            this.helper.getApiUrl() + `/contractors/${contractorid}/bs/${bsid}/cases/${this.helper.replaceSlahsForDolar(caseid)}/comments`,
            data,
        );
    }

    //getCaseContractorComments()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getCaseContractorComments(contractorid: number, bsid: number, caseid: any): Observable<Comment[]> {
        return this.httpClient
            .get(this.helper.getApiUrl() + `/contractors/${contractorid}/bs/${bsid}/cases/${this.helper.replaceSlahsForDolar(caseid)}/comments`)
            .pipe(map(el => el.comments));
    }

    //addCaseContractorDocument
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addCaseContractorDocument(contractorid: number, bsid: number, caseid: any, documentId: string): Observable<any> {
        return this.httpClient.post(
            this.helper.getApiUrl() +
                `/contractors/${contractorid}/bs/${bsid}/cases/${this.helper.replaceSlahsForDolar(caseid)}/documents/${documentId}`,
        );
    }

    //getCaseContractorDocuments()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getCaseContractorDocuments(contractorid: number, bsid: number, caseid: any): Observable<Document[]> {
        return this.httpClient
            .get(this.helper.getApiUrl(2) + `/contractors/${contractorid}/bs/${bsid}/cases/${this.helper.replaceSlahsForDolar(caseid)}/documents`)
            .pipe(
                map(el => {
                    if (_.isNil(el.documents)) {
                        return [];
                    }
                    return el.documents.map(el => new Document().deserialize(el));
                }),
            );
        // .pipe(map(el => el.comments));
    }

    //findWaitingQueueItemForPrereservation
    public findWaitingQueueItemForPrereservation(contractorId: number, data: WQInfo): Observable<WaitingQueueItem> {
        return this.httpClient.post(this.helper.getApiUrl() + `/contractors/${contractorId}/waitingqueueinfo`, data);
    }

    //getCasesForContractor()
    public getCaseForContractor(contractorid: number, bsid: number, caseid: string): Observable<BusinessCaseResponse> {
        return this.httpClient
            .get(this.helper.getApiUrl() + `/contractors/${contractorid}/bs/${bsid}/cases/${this.helper.replaceSlahsForDolar(caseid)}/`)
            .pipe(map(el => new BusinessCaseResponse().deserialize(el[0])));
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public changeConcludeCase(contractorid: number, bsid: number, caseid: string, concludeData: string): Observable<any> {
        //concludeData: 'null' --> aktiviraj avtorizacijo,
        //concludeData:   datum ('2021-07-02') -> zaključi avtorizacijo
        let dataUrl =
            this.helper.getErpRootUrl('bsapi') +
            `/contractors/${contractorid}/bs/${bsid}/cases/${this.helper.replaceSlahsForDolar(caseid)}/concludecase`;
        if (concludeData) dataUrl += `?dateConcluded=${concludeData}`;
        return this.httpClient.put(dataUrl);
    }

    public getCaseSecondaryContact(bsid: number, caseid: string): Observable<SecondaryContact> {
        return this.httpClient.get(this.helper.getAssistanceUrl(3) + `/v3/api/bs/${bsid}/cases/${caseid}/secondary-contact`);
    }
}
