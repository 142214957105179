<div>
    <p class="text-center">
        <ng-container *ngIf="additionalText">
            {{ additionalText }}
            <br />
        </ng-container>
        Na spodaj prikazani sliki kliknite na črko "
        <strong class="text-dark">{{ selectChar }}</strong>
        "
    </p>

    <div class="captcha-img-placeholder">
        <div *ngIf="!captchaImage" class="m-loader m-loader--brand"></div>
        <img *ngIf="captchaImage" [src]="captchaImage" (click)="captchaSolver($event)" />
    </div>
</div>
