<div class="m-content">
    <div>
        <pf-p-comn-input-text
            *ngFor="let set of inputSetsObj"
            [data]="set"
            [settings]="{ height: 350 }"
            (onChange)="this.comm.dirty$.next(true)"
        ></pf-p-comn-input-text>
    </div>
</div>
