import { Serializable } from '../deserialize.service';

export class ContractorUserSettings implements Serializable<ContractorUserSettings> {
    showNewTaskReceivedNotification = false;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        this.showNewTaskReceivedNotification = input.showNewTaskReceivedNotification;
        return this;
    }
}
