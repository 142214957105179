import { Pipe, PipeTransform } from '@angular/core';
import { VisitExitType } from '../enums/visitExitType.enum';

@Pipe({
    name: 'visitExitPipe',
})
export class VisitExitPipe implements PipeTransform {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(type: any): any {
        switch (type) {
            case VisitExitType.HD_REFERRAL:
                return 'Sekundarij';
            case VisitExitType.PHYSICAL_EXAM:
                return 'Fizični pregled';
            case VisitExitType.PUBLIC_HEALTH:
                return 'Javna zdravstvena mreža';
            case VisitExitType.SELF_HELP:
                return 'Samopomoč';
            case VisitExitType.TECHNICAL_ISSUE:
                return 'Neuspeli klic/Drugo';
            case VisitExitType.UNANSWERED_CALL:
                return 'Neodgovorjeni klic';
            case VisitExitType.LIFE_STYLE:
                return 'Življenjski slog';
            default:
                return type;
        }
    }
}
