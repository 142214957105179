import * as _ from 'lodash';
import { Serializable } from '../deserialize.service';
import { Payer } from './payer.model';
import { Recipient } from './recipient.model';

export class InvoicePartner implements Serializable<InvoicePartner> {
    id: number;
    active: boolean;
    name: string;
    addressName: string;
    addressNumber: string;
    contractorId: string;
    postName: string;
    description: string;
    postNumber: string;
    taxId: string;
    recipients: Recipient[];
    postBox: number;
    paymentDeadlineDays: number;
    phone: string;
    email: string;
    deserialize(input) {
        if (_.isNil(input)) {
            return undefined;
        }

        this.id = input.id;
        this.name = input.name;
        this.active = input.active;
        this.addressName = input.addressName;
        this.addressNumber = input.addressNumber;
        this.contractorId = input.contractorId;
        this.postName = input.postName;
        this.description = input.description;
        this.postNumber = input.postNumber;
        this.taxId = input.taxId;
        this.recipients = input.recipients;
        this.postBox = input.postBox;
        this.paymentDeadlineDays = input.paymentDeadlineDays;
        this.phone = input.phone;
        this.email = input.email;
        return this;
    }

    public deserializeFromPayer(input: Payer): InvoicePartner {
        const addressArray: string[] = input.address.split(' ');
        let addressNumber = '';
        if (addressArray.length > 0) {
            addressNumber = addressArray.slice(-1)[0];
            addressArray.pop();
        }

        this.id = input.id;
        this.name = input.name;
        this.description = input.surname;
        this.addressName = addressArray.join(' ');
        this.addressNumber = addressNumber;
        this.postName = input.city;
        this.postNumber = input.post;
        this.taxId = input.taxNo;
        this.recipients = input.recipients;
        this.postBox = input.postBox;
        this.paymentDeadlineDays = input.paymentDeadlineDays;
        this.phone = input.phone;
        this.email = input.email;
        return this;
    }
}
