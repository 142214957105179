import { Serializable } from '../deserialize.service';
import { AppModulesType } from './../../enums/appModulesType.enum';

export class MenuItem implements Serializable<MenuItem> {
    link: string;
    title: string;
    onlyTest: boolean;
    id: string;
    icon: string;
    rules: string;
    submenu?: MenuItem[];
    statusIcon?: string;
    routerActiveExact: boolean;
    module: AppModulesType;
    action: string;
    displayValue?: string;
    tabMenuId: string;

    deserialize(input) {
        this.link = input.link;
        this.title = input.title;
        this.onlyTest = input.onlyTest;
        this.id = input.id;
        this.icon = input.icon;
        this.rules = input.rules;
        this.submenu = input.submenu;
        this.statusIcon = input.statusIcon;
        this.routerActiveExact = input.routerActiveExact;
        this.module = input.module;
        this.action = input.action;
        this.displayValue = input.displayValue;
        this.tabMenuId = input.tabMenuId;
        return this;
    }
}
