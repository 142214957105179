import { BusinessSubject } from '../businessSubject.model';
import { Serializable } from '../deserialize.service';

export class BusinessSubjectConnection implements Serializable<BusinessSubjectConnection> {
    bs: BusinessSubject;
    connected: boolean;
    directBooking: boolean;
    directDraft: boolean;
    draftAllowed: boolean;
    sendContractorNotifications: boolean;
    directBookingAndWQ: boolean;
    exclusiveAvailability: boolean;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        this.connected = input.connected;
        this.directBooking = input.directBooking;
        this.directDraft = input.directDraft;
        this.draftAllowed = input.draftAllowed;
        this.sendContractorNotifications = input.sendContractorNotifications;
        this.directBookingAndWQ = input.directBookingAndWQ;
        this.exclusiveAvailability = input.exclusiveAvailability;

        if (input.bs) {
            this.bs = new BusinessSubject().deserialize(input.bs);
        }
        return this;
    }
}
