import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { EditorModule } from '@tinymce/tinymce-angular';
import { Daterangepicker } from 'ng2-daterangepicker';
import { BasicComponentsModule } from './../../../basic-components/basic-components.module';
import { PfPBaseComponent } from './papers/pf-p-base/pf-p-base.component';
import { PfPFthComponent } from './papers/pf-p-fth/pf-p-fth.component';
import { PfPGenericReferralComponent } from './papers/pf-p-genericReferral/pf-p-genericReferral.component';
import { PfPMdpsCertificateComponent } from './papers/pf-p-mdps-certificate/pf-p-mdps-certificate.component';
import { PfPMdpsSurveyComponent } from './papers/pf-p-mdps-survey/pf-p-mdps-survey.component';
import { PfPMfmReportComponent } from './papers/pf-p-mfm-report/pf-p-mfm-report.component';
import { PfPMFMSoapSurveyComponent } from './papers/pf-p-mfm-soapSurvey/pf-p-mfm-hdSurvey.component';
import { PfPOrderFormDiagComponent } from './papers/pf-p-orderFormDiag/pf-p-orderFormDiag.component';
import { PfPOrderFormLabComponent } from './papers/pf-p-orderFormLab/pf-p-orderFormLab.component';
import { PfPSoapSurveyComponent } from './papers/pf-p-soapSurvey/pf-p-hdSurvey.component';
import { PfPSurveyComponent } from './papers/pf-p-survey/pf-p-survey.component';
import { PfPTermConfComponent } from './papers/pf-p-termConf/pf-p-termConf.component';
import { PfPVisitConfComponent } from './papers/pf-p-visitConf/pf-p-visitConf.component';
import { PfPWhitereferralComponent } from './papers/pf-p-whitereferral/pf-p-whitereferral.component';
import { PfPWrCustomerComponent } from './papers/pf-p-whitereferral/pf-p-wr-customer/pf-p-wr-customer.component';
import { PfPWrSubcontractorComponent } from './papers/pf-p-whitereferral/pf-p-wr-subcontractor/pf-p-wr-subcontractor.component';
import { PfPComnInputTextComponent } from './papers/_common/pf-p-comn-input-text/pf-p-comn-input-text.component';
import { PortfolioListItemComponent } from './portfolio-list/portfolio-list-item/portfolio-list-item.component';
import { PortfolioListComponent } from './portfolio-list/portfolio-list.component';
import { PfComnActionsComponent } from './_common/pf-comn-actions/pf-comn-actions.component';
import { PfComnDetailsComponent } from './_common/pf-comn-details/pf-comn-details.component';
import { PfComnHistoryListComponent } from './_common/pf-comn-history-list/pf-comn-history-list.component';
import { PfComnPopupComponent } from './_common/pf-comn-popup/pf-comn-popup.component';
import { PfHostDirective } from './_common/pf-comn-popup/pf-host.directive';
import { PfComnTemplateComponent } from './_common/pf-comn-template/pf-comn-template.component';
import { PfComnVersionsListComponent } from './_common/pf-comn-versions-list/pf-comn-versions-list.component';

@NgModule({
    imports: [
        CommonModule,
        NgSelectModule,
        // LayoutModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        Daterangepicker,
        EditorModule,
        BasicComponentsModule,
    ],
    declarations: [
        PfHostDirective,
        PfPSurveyComponent,
        PfPMdpsSurveyComponent,
        PfComnActionsComponent,
        PfComnPopupComponent,
        PfPFthComponent,
        PfPComnInputTextComponent,
        PfPWhitereferralComponent,
        PfPGenericReferralComponent,
        PfPWrCustomerComponent,
        PfPWrSubcontractorComponent,
        PfComnHistoryListComponent,
        PfComnVersionsListComponent,
        PfPVisitConfComponent,
        PfComnTemplateComponent,
        PfPBaseComponent,
        PfPMFMSoapSurveyComponent,
        PfPSoapSurveyComponent,
        PfPOrderFormLabComponent,
        PfPOrderFormDiagComponent,
        PfPTermConfComponent,
        PortfolioListComponent,
        PortfolioListItemComponent,
        // PaperNamePipe,
        PfComnDetailsComponent,
        PfPMdpsCertificateComponent,
        PfPMfmReportComponent,
    ],
    exports: [PortfolioListComponent, PortfolioListItemComponent],
})
export class PortfolioPapersModule {}
