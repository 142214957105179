import { Pipe, PipeTransform } from '@angular/core';
import { CommunicationType } from '../enums/communicationType.enum';

@Pipe({
    name: 'commTypeDescriptionPipe',
})
export class CommTypeDescriptionPipe implements PipeTransform {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(type: any): any {
        switch (type) {
            case CommunicationType.REMINDER:
                return 'Opomnik';
            case CommunicationType.SURVEY:
                return 'Anketa';
            case CommunicationType.INVOICE_ACCESS:
                return 'Dostop do računa';
            // case CommunicationType.DIAGNOSIS_ACCESS:
            //     return 'Dostop do diagnoze';
            case CommunicationType.DOCUMENTATION_ACCESS:
                return 'Dostop do dokumentacije';
            case CommunicationType.RESERVATION_NOTICE:
                return 'Rezervacija termina';
            case CommunicationType.RESERVATION_CHANGE_NOTICE:
                return 'Sprememba termina';
            case CommunicationType.RESERVATION_REMINDER:
                return 'Opomnik na termin';
            case CommunicationType.RESERVATION_CANCEL:
                return 'Preklic rezervacije';
            case CommunicationType.SLOT_RESERVATION:
                return 'Rezervacije terminov';
            case CommunicationType.NEW_SUBCONTRACTOR_REQUEST:
                return 'Zahteva za novega podizvajalca';
            case CommunicationType.ACCESS_CODE:
                return 'Koda za dostop';
            case CommunicationType.PWD_RENEWAL:
                return 'Obnova gesla';
            case CommunicationType.PROMOTION_COUPON:
                return 'Promocijska koda';
            // case CommunicationType.PASSWORD_RENEWAL:
            //     return 'Obnova gesla';
            case CommunicationType.GDPR_PERMISSION:
                return 'GDPR privolitve';
            case CommunicationType.TELEMEDICINE:
                return 'Telemedicina';
            case CommunicationType.COVID19:
                return 'Covid vprašalnik';
            case CommunicationType.UNANSWERED_CALL:
                return 'Neodgovorjen klic';
            case CommunicationType.INCOMING_CALL:
                return 'Dohodni klic';
            case CommunicationType.OUTGOING_CALL:
                return 'Odhodni klic';
            case CommunicationType.HELLO_DOC_REGISTER:
                return 'HD registracija';
            case CommunicationType.HD_CUSTOMER_UNREAD_CHAT:
                return 'HD neprebrana sporočila';
            case CommunicationType.CUSTOMER_SHARED_DATA:
                return 'Deljeni podatki s stranko';
            case CommunicationType.PATIENT_BOOKING:
                return 'Rezervacija preko widgeta';
            case CommunicationType.SUBCONTRACTOR_RESERVATION_NOTICE:
                return 'Rezervacija termina';
            case CommunicationType.SUBCONTRACTOR_RESERVATION_REMINDER:
                return 'Opomnik na termin';
            default:
                return type;
        }
    }
}
