import { Serializable } from '../deserialize.service';
import { PrintPOSItem } from './printPOSItem.model';
import { PrintPOSItemsFooter } from './printPOSItemsFooter.model';
import { PrintPOSItemsHeader } from './printPOSItemsHeader.model';

export class PrintPOSInvoiceItems implements Serializable<PrintPOSInvoiceItems> {
    public header: PrintPOSItemsHeader;
    public footer: PrintPOSItemsFooter;
    public items: PrintPOSItem[];

    // public payer: string;
    // public description: string;
    // public quantity: number;
    // //price per item
    // public pricePI: number;
    // public priceSum: number;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        if (input.header) {
            this.header = new PrintPOSItemsHeader().deserialize(input.header);
        }

        if (input.items) {
            this.items = input.items.map((el: PrintPOSItem) => new PrintPOSItem().deserialize(el));
        }
        if (input.footer) {
            this.footer = new PrintPOSItemsFooter().deserialize(input.footer);
        }

        // this.payer = input.payer;
        // this.description = input.description;
        // this.quantity = input.quantity;
        // this.pricePI = input.pricePI;
        // this.priceSum = input.priceSum;
        return this;
    }
}
