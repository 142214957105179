<div class="m-portlet">
    <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
                <h3 class="m-portlet__head-text">Naziv zdravnika</h3>
            </div>
        </div>
    </div>
    <div class="m-portlet__body mf-portlet-custome__body">
        <div *ngIf="selectedSubcontractor" class="m-list-search">
            <div class="m-list-search__results">
                <div class="m-list-search__result-item mf-customer">
                    <span class="m-list-search__result-item-icon">
                        <i container="body" ngbTooltip="Podatki o zdravniku" class="la la-user m--font-inverse-light" i18n-ngbTooltip></i>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <strong>{{ selectedSubcontractor?.name }}</strong>
                        <span *ngIf="selectedSubcontractor?.address">
                            <br />
                            {{ selectedSubcontractor?.address }},
                        </span>
                        <span *ngIf="selectedSubcontractor?.post">
                            <br />
                            {{ selectedSubcontractor?.post }}
                        </span>
                    </span>
                </div>
                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <i container="body" ngbTooltip="Telefon zdravnika" class="la la-phone m--font-inverse-light" i18n-ngbTooltip></i>
                    </span>
                    <span class="m-list-search__result-item-text">
                        {{ selectedSubcontractor?.phone }}
                    </span>
                </div>
                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <i container="body" ngbTooltip="ID zdravnika" class="la la-info m--font-inverse-light" i18n-ngbTooltip></i>
                    </span>
                    <span class="m-list-search__result-item-text">
                        {{ selectedSubcontractor?.id }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
