import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import { TextMaskModule } from 'angular2-text-mask';
import { Daterangepicker } from 'ng2-daterangepicker';
import { BasicComponentsModule } from './../../../../basic-components/basic-components.module';
import { PortfolioPapersModule } from './../../portfolio-papers/portfolio-papers.module';
import { TrtWidListComponent } from './trt-wid-list/trt-wid-list.component';
import { TrtWidMDetailsComponent } from './trt-wid-main-popup/trt-wid-m-details/trt-wid-m-details.component';
import { TrtWidMDocumentsComponent } from './trt-wid-main-popup/trt-wid-m-documents/trt-wid-m-documents.component';
import { TrtWidMNoteIHeaderComponent } from './trt-wid-main-popup/trt-wid-m-note-item/trt-wid-m-note-i-header/trt-wid-m-note-i-header.component';
import { TrtWidMNoteItemComponent } from './trt-wid-main-popup/trt-wid-m-note-item/trt-wid-m-note-item.component';
import { TrtWidMTermBaseComponent } from './trt-wid-main-popup/trt-wid-m-term-gen/trt-wid-m-term-base/trt-wid-m-term-base.component';
import { TrtWidMTermGenComponent } from './trt-wid-main-popup/trt-wid-m-term-gen/trt-wid-m-term-gen.component';
import { TrtWidMTermBadgeComponent } from './trt-wid-main-popup/trt-wid-m-term-item/trt-wid-m-term-badge/trt-wid-m-term-badge.component';
import { TrtWidMTermHeadComponent } from './trt-wid-main-popup/trt-wid-m-term-item/trt-wid-m-term-head/trt-wid-m-term-head.component';
import { TrtWidMTermItemComponent } from './trt-wid-main-popup/trt-wid-m-term-item/trt-wid-m-term-item.component';
import { TrtWidMainPopupComponent } from './trt-wid-main-popup/trt-wid-main-popup.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        Daterangepicker,
        TextMaskModule,
        NgxsFormPluginModule,
        BasicComponentsModule,
        PortfolioPapersModule,
        NgSelectModule,
        // ComponentModule,
    ],
    exports: [TrtWidListComponent],
    declarations: [
        TrtWidMainPopupComponent,
        TrtWidMDetailsComponent,
        TrtWidMTermGenComponent,
        TrtWidMTermItemComponent,
        TrtWidMTermBaseComponent,
        TrtWidMTermBadgeComponent,
        TrtWidMTermHeadComponent,
        TrtWidListComponent,
        TrtWidMNoteItemComponent,
        TrtWidMNoteIHeaderComponent,
        TrtWidMDocumentsComponent,
    ],
})
export class TrtWidgetsModule {}
