import * as moment from 'moment';
import { Customer } from './../customer.model';
import { Serializable } from './../deserialize.service';
export class Treatment implements Serializable<Treatment> {
    id?: number;
    description?: string;
    customer?: Customer;
    created?: moment.Moment;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        return Object.assign(this, input);
    }
}
