import { Serializable } from '../deserialize.service';
import { FcEvent } from '../fcEvent.model';
import { Service } from './../service.model';

export class CalendarEvent extends FcEvent implements Serializable<CalendarEvent> {
    title: string;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    start: any;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    end: any;
    resourceId: number;
    resourceIds: number[];
    backgroundColor: string;
    textColor: string;
    className: string;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    id: string;
    allDay: boolean;
    rendering: string;
    editable: boolean;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any, subcontractor?: any[], servicesForColor?: Service[]) {
        if (subcontractor) {
            super.deserialize(input, subcontractor, servicesForColor);
        } else {
            this.title = input.title;
            this.resourceId = input.resourceId;
            this.resourceIds = input.resourceIds;
            this.start = input.start;
            this.end = input.end;
            this.backgroundColor = input.backgroundColor;
            this.textColor = input.textColor;
            this.className = input.className;
            this.data = input.data;
            this.id = input.id;
            this.allDay = input.allDay;
            this.rendering = input.rendering;
            this.editable = input.editable;
        }
        return this;
    }
}
