import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AnimationDefinitions, ObjectState, StatefullValue } from '@meddev/fe-shared';
import FileSaver from 'file-saver';
import { ToastrService } from 'ngx-toastr';
import { EMPTY, from, Observable, of, Subject } from 'rxjs';
import { catchError, filter, first, map, shareReplay, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { Helpers } from '../../../helpers';
import { HelperService } from '../../../_shared/services';
import { MediaViewParameters } from '../../components/media-view-popup/model/media-view-parameters-model';
import { OimTrackingService } from '../../pages/home/oim-tracking/oim-tracking.service';
import { OimOrderService } from '../../pages/home/oim-tracking/_services/oim-order.service';
import { DocumentsService } from '../../pages/home/_services';
import { Document, DocumentViewerDocument } from './../../../_shared/model/document.model';
import { BasicDocumentViewerSettings } from './model/basic-document-viewer-settings';

@Component({
    selector: 'app-basic-document-viewer',
    templateUrl: './basic-document-viewer.component.html',
    styleUrls: ['./basic-document-viewer.component.scss'],
    animations: [AnimationDefinitions.ExpandCollapseHeight],
})
export class BasicDocumentViewerComponent implements OnInit {
    public ObjectState = ObjectState;
    @Input() settings: BasicDocumentViewerSettings;
    @Output() embeddedPreviewState = new EventEmitter<boolean>(false);
    public selectedFilesForUpload: FileList;
    public isVisibleInput = false;
    public percentDone = 0;
    private onDestroy$ = new Subject<void>();
    public documentsForm: FormGroup;
    public documents$: Observable<DocumentViewerDocument[]>;
    public embeddedPreviewDocument$ = new Subject<StatefullValue<MediaViewParameters>>();
    public isEmbeddedPreviewOpen = false;

    constructor(
        private fb: FormBuilder,
        private docviewRest: DocumentsService,
        private helper: HelperService,
        private oimRest: OimTrackingService,
        private mfToast: ToastrService,
        private oimOrderService: OimOrderService,
    ) {}

    ngOnInit(): void {
        if (this.settings) {
            this.documents$ = this.settings.documentList$.pipe(shareReplay({ bufferSize: 1, refCount: true }));
        }
        this.init();
    }

    public toggleDropdown() {
        this.isVisibleInput = !this.isVisibleInput;
        this.selectedFilesForUpload = undefined;
        this.documentsForm.reset();
    }

    public init(): void {
        this.percentDone = 0;
        this.documentsForm = this.fb.group({
            uploadFile: [''],
            comment: [''],
        });
    }
    public selectFile($event): void {
        this.selectedFilesForUpload = $event.target.files;
    }

    public onSubmitAddDocument(): void {
        this.uploadFile(this.selectedFilesForUpload);
    }

    private uploadFile(files: FileList): void {
        of(files)
            .pipe(
                filter(files => {
                    if (!files || files.length == 0) {
                        this.mfToast.warning('Nobena datoteka ni izbrana.');
                        return false;
                    }
                    return true;
                }),
                map(files => {
                    const file: File = files[0];
                    return { file };
                }),
                switchMap(result => {
                    return this.docviewRest.uploadFile(result.file, result.file.name).pipe(
                        // TODO Ignored with eslint-interactive on 2023-11-10
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        tap((event: any) => {
                            if (event.type == HttpEventType.UploadProgress) {
                                this.percentDone = Math.round((100 * event.loaded) / event.total);
                                console.log(`File is ${this.percentDone}% loaded.`);
                            }
                        }),
                        // TODO Ignored with eslint-interactive on 2023-11-10
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        filter((event: any) => event.type !== HttpEventType.UploadProgress),
                        // TODO Ignored with eslint-interactive on 2023-11-10
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        tap((event: any) => {
                            if (event instanceof HttpResponse) {
                                console.log('File is completely loaded.');
                            }
                        }),
                        // TODO Ignored with eslint-interactive on 2023-11-10
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        filter((event: any) => event.type == HttpEventType.Response),
                        catchError(err => {
                            if (err.status === 413) {
                                this.mfToast.error('Datoteka je prevelika.');
                            } else if (this.helper.checkStructError(err, 'VIRUS')) {
                                this.mfToast.error('Datoteka mogoče vsebuje zlonamerno kodo, nalaganje ni mogoče.');
                            } else {
                                this.mfToast.error('Težava pri nalaganju datoteke.');
                            }
                            this.percentDone = 0;
                            return EMPTY;
                        }),
                        tap(() => this.init()),
                        // TODO Ignored with eslint-interactive on 2023-11-10
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        switchMap((event: any) => {
                            return this.settings.postFileUpload$(event.body);
                        }),
                    );
                }),
            )
            .subscribe();
    }

    /*
        funkcije vezane direktno na dokument (preview, download,delete)
       */

    public openMediaViewDialog(doc: Document) {
        this.docviewRest
            .openDocument(this.settings.contractorId, doc.documentId)
            .pipe(
                takeUntil(this.onDestroy$),
                tap(() => {
                    Helpers.setLoading(true);
                }),
                map(res => {
                    if (res.type !== 0) {
                        return new Blob([res.body], { type: res.body.type });
                    }
                }),
                tap(() => Helpers.setLoading(false)),
                filter(res => res != null),
                switchMap(res => {
                    return this.helper.openDocumentViewer(res, res.type, doc.description, undefined, true).pipe(takeUntil(this.onDestroy$));
                }),
                withLatestFrom(this.documents$),
                map(([el, documentList]) => {
                    //index elementa
                    const documents = documentList;
                    let index = 0;
                    documents.forEach((el, ind) => {
                        if (JSON.stringify(el) === JSON.stringify(doc)) {
                            index = ind;
                        }
                    });
                    //index +1/-1
                    index = el === 'next' ? index + 1 : index - 1;
                    index = index === documents.length ? 0 : index;
                    index = index < 0 ? documents.length - 1 : index;
                    //daj nov element v openMediaViewDialog funkcijo
                    return documents[index];
                }),
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                catchError(err => {
                    return EMPTY;
                }),
            )
            .subscribe(doc => this.openMediaViewDialog(doc));
    }
    public removeDocumetFromlist(event: Document): void {
        from(this.helper.displayAlert('Brisanje dokumenta.', 'Izbrišem izbrani dokument?'))
            .pipe(
                first(),
                filter(res => res.value),
                switchMap(() => this.settings.postDeleteFile$(event.documentId)),
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                catchError(err => {
                    this.mfToast.error('Napaka pri brisanju dokumenta.');
                    return EMPTY;
                }),
            )
            .subscribe(() => {
                this.mfToast.success('Uspešno zbrisan dokument.');
            });
    }
    public downloadDocument(doc: Document): void {
        this.docviewRest
            .openDocument(this.settings.contractorId, doc.documentId)
            .pipe(
                takeUntil(this.onDestroy$),
                map(res => {
                    if (res.type !== 0) {
                        return new Blob([res.body], { type: res.body.type });
                    }
                }),
            )
            .subscribe(res => {
                if (res) {
                    FileSaver.saveAs(res, doc.description);
                }
            });
    }

    public onEmbeddedPreviewOpen(doc: Document): void {
        this.isEmbeddedPreviewOpen = true;
        this.embeddedPreviewState.next(true);

        this.docviewRest
            .openDocument(this.settings.contractorId, doc.documentId)
            .pipe(
                takeUntil(this.onDestroy$),
                tap(() => {
                    Helpers.setLoading(true);
                }),
                map(res => {
                    if (res.type !== 0) {
                        return new Blob([res.body], { type: res.body.type });
                    }
                }),
                tap(() => Helpers.setLoading(false)),
                filter(res => res != null),
            )
            .subscribe(res => {
                this.embeddedPreviewDocument$.next({
                    state: ObjectState.VALID,
                    value: {
                        data: res,
                        type: res.type,
                        filename: doc.description,
                        print: undefined,
                        signature: undefined,
                        navigationButtons: true,
                    },
                } as StatefullValue<MediaViewParameters>);
            });
    }

    public onEmbeddedPreviewClose(): void {
        this.isEmbeddedPreviewOpen = false;
        this.embeddedPreviewState.next(false);
        this.embeddedPreviewDocument$.next(null);
    }
}
