import { Notification } from '../enums/notification.enum';
import { Serializable } from './deserialize.service';

export class NotificationData implements Serializable<NotificationData> {
    //
    bsId?: number;
    caseId?: string;
    triageId?: string;
    caseInternalId?: string;
    contractorName?: string;
    newFrom?: number;
    type?: Notification;
    appUserName?: string;
    comment?: string;
    notificationId?: number;

    deserialize(input) {
        this.bsId = parseInt(input.bsId);
        this.caseId = input.caseId;
        this.caseInternalId = input.caseInternalId;
        this.contractorName = input.contractorName;
        this.newFrom = input.newFrom;
        this.type = input.type;
        this.appUserName = input.appUserName;
        this.comment = input.comment;
        this.notificationId = input.notificationId;
        return this;
    }
}
