<div class="m-portlet">
    <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
                <h3 class="m-portlet__head-text" i18n="app.visit.portfolio.comn.details.title.text">Podrobnosti</h3>
            </div>
        </div>
    </div>
    <div class="m-portlet__body">
        <div [formGroup]="parent">
            <section class="mb-2">
                <div class="font-weight-bold">Trajanje obravnave</div>
                <!-- zaradi autofocus -->
                <input style="display: none" />
                <div formGroupName="timeSpan" class="d-flex align-items-center">
                    <div class="m-input-icon m-input-icon--left">
                        <input
                            [options]="fromInput"
                            (selected)="selectedFromDate($event)"
                            type="text"
                            name="daterangeInput"
                            daterangepicker
                            formControlName="from"
                            autocomplete="off"
                            class="form-control m-input"
                            [ngClass]="{ 'border-danger border': formControlsTimeSpan['from']?.errors }"
                        />
                        <span class="m-input-icon__icon m-input-icon__icon--left">
                            <span>
                                <i ngbTooltip="Pričetek" class="la la-calendar"></i>
                            </span>
                        </span>
                    </div>
                    <div class="px-2">-</div>
                    <div class="m-input-icon m-input-icon--left">
                        <input
                            [options]="toInput"
                            (selected)="selectedToDate($event)"
                            type="text"
                            name="daterangeInput"
                            daterangepicker
                            formControlName="to"
                            autocomplete="off"
                            class="form-control m-input"
                        />
                        <span class="m-input-icon__icon m-input-icon__icon--left">
                            <span>
                                <i ngbTooltip="Konec" class="la la-calendar"></i>
                            </span>
                        </span>
                    </div>
                </div>
            </section>

            <section *ngIf="formControls?.subcontractor">
                <div class="font-weight-bold">Izvajalec</div>
                <ng-select
                    [items]="allSubcontractors"
                    bindLabel="name"
                    tabindex="2"
                    formControlName="subcontractor"
                    [ngClass]="{ 'mf-ng-select-danger-border': formControls?.subcontractor?.errors }"
                >
                    <ng-template ng-optgroup-tmp let-item="item">
                        {{ item.name }}
                    </ng-template>
                </ng-select>
            </section>
        </div>
    </div>
</div>
