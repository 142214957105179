import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { BsType } from '../../../../../../_shared/enums/business.enum';
import { PaperType } from '../../../../../../_shared/enums/PaperType.enum';
import { PaperHistoryElement, PortfolioPaper, Result, ResultSet } from '../../../../../../_shared/model';
import { HelperService, MfToastService } from '../../../../../../_shared/services';
import { ResultService } from '../../../_services';
import { PfPBaseComponent } from '../pf-p-base/pf-p-base.component';
import { PfPCommnComunicationService } from '../_common/pf-p-comn-communication.service';
import { Case } from './../../../../../../_shared/model/schedule/case.model';
import { User } from './../../../../../../_shared/model/user.model';
import { BaseState } from './../../../../../../_shared/state/base/base.state';

@Component({
    selector: 'pf-p-fth',
    templateUrl: './pf-p-fth.component.html',
    styles: [],
})
export class PfPFthComponent extends PfPBaseComponent implements OnInit {
    @Input()
    isNew = false;

    @Input()
    paper: PortfolioPaper;

    public inputSetsObj: ResultSet[] = [];
    private onDestroy$ = new Subject<void>();
    private case: Case;

    public selectedContractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);
    public loggedUser: User = this.store.selectSnapshot(BaseState.LoggedUser);

    constructor(
        // private storage: StorageService,
        private rest: ResultService,
        private toast: MfToastService,
        public helper: HelperService,
        public comm: PfPCommnComunicationService,
        public store: Store,
    ) {
        super(helper);
    }

    ngOnInit() {
        if (this.isNew) {
            this.createReport();
        } else {
            this.loadData(this.paper.visitId, this.paper.id);
        }

        this.communications();
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        this.comm.dirty$.next(false);
        this.onDestroy$.next();
    }

    private loadData(visitId: number, paperId: number, setDirty: boolean = false): void {
        this.rest
            .getSurveyPaper(visitId, paperId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(res => {
                if (res && res.surveys && res.surveys.length > 0) {
                    if (res?.to || res?.from) {
                        (<FormGroup>this.detailsForm.controls.timeSpan).patchValue({
                            from: this.helper.apiDateTostringDate(res.from),
                            to: this.helper.apiDateTostringDate(res.to),
                        });
                    }
                    this.inputSetsObj = res.surveys;
                    if (setDirty) {
                        this.comm.dirty$.next(true);
                    } else {
                        this.comm.dirty$.next(false);
                    }
                }
            });
    }

    private communications(): void {
        this.comm.onCopy.pipe(takeUntil(this.onDestroy$)).subscribe((data: PaperHistoryElement) => {
            this.copyFromHistroy(data);
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onSave.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.saveFTH().subscribe();
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onPreview.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.openPreview(this.paper, false);
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onPrint.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.openPreview(this.paper, true);
        });

        this.comm.onSignDocument.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
            this.comm.saveSignShareAndShow(
                this.paper,
                data,
                () => this.saveFTH(),
                casee => (this.case = casee),
                false,
                false,
                this.bsType === BsType.PREVENTIVNI,
            );
        });
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.comm.onTemplateSaveFTH.pipe(takeUntil(this.onDestroy$)).subscribe((data: any) => {
            if (!data.name) {
                this.saveTemplate(data).subscribe(result => {
                    this.comm.onTemplateSaveFTH.next({ id: result, name: data });
                });
            }
        });
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.comm.onTemplateSelect.pipe(takeUntil(this.onDestroy$)).subscribe((data: any) => {
            super.selectTemplate(data, 'fth', this.inputSetsObj);
            this.comm.dirty$.next(true);
        });
        this.comm.resetValues.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
            this.inputSetsObj = [];
            this.createReport();
        });
    }

    private copyFromHistroy(data: PaperHistoryElement): void {
        this.loadData(data.visitId, data.paperId, true);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public saveTemplate(name: string): Observable<any> {
        return this.rest.saveDocumentTemplate(
            this.selectedContractorId,
            name,
            new Result().deserialize({
                surveys: this.inputSetsObj,
                surveyType: PaperType.FTH_POROCILO,
            }),
        );
    }

    public openSignedPreview(paper: PortfolioPaper): void {
        this.comm
            .openSignedPreviewAppend(paper, this.case, 'Fizioterapevtsko porocilo')
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
                () => {
                    this.toast.success('Dokument dodan k avtorizaciji');
                },
                data => {
                    if (data !== 'close-no-error') {
                        this.toast.error('Napaka pri dodajanju dokumenta k avtorizaciji');
                    }
                },
            );
    }

    public openPreview(paper: PortfolioPaper, print: boolean = false): void {
        this.rest
            .getSurveyPaperPreview(this.paper.visitId, paper.id)
            .pipe(
                takeUntil(this.onDestroy$),
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                map((res: any) => {
                    if (res.type !== 0) {
                        return new Blob([res.body], {
                            type: 'application/pdf',
                        });
                    }
                }),
            )
            .subscribe(
                res => {
                    if (res) {
                        this.helper.openDocumentViewer(res, res.type, 'Izvid', print);
                    }
                },
                () => {
                    this.toast.errorAndSend('Napaka pri predogledu poročila.');
                },
            );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private saveFTH(): Observable<any> {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const detailsFormValue: any = this.detailsForm.value;

        if (this.isNew) {
            return this.rest
                .addSurvey(
                    this.paper.visitId,
                    new Result().deserialize({
                        surveys: this.inputSetsObj,
                        surveyType: PaperType.FTH_POROCILO,
                        from: this.helper.stringDateToApiDate(detailsFormValue?.timeSpan?.from),
                        to: this.helper.stringDateToApiDate(detailsFormValue?.timeSpan?.to),
                    }),
                )
                .pipe(
                    takeUntil(this.onDestroy$),
                    tap(
                        res => {
                            this.paper = new PortfolioPaper().deserialize({
                                id: res,
                                visitId: this.paper.visitId,
                                paperType: PaperType.FTH_POROCILO,
                            });
                            this.comm.onPaperChanged.emit(this.paper);

                            this.toast.success('Spremembe so shranjene.');
                            this.isNew = false;
                            this.comm.dirty$.next(false);
                        },
                        () => this.toast.errorAndSend('Napaka pri dodajanju novega sklopa.'),
                    ),
                );
        } else {
            //update
            return this.rest
                .updateSurveyPaper(
                    this.paper.visitId,
                    this.paper.id,
                    new Result().deserialize({
                        surveys: this.inputSetsObj,
                        surveyType: PaperType.FTH_POROCILO,
                        from: this.helper.stringDateToApiDate(detailsFormValue?.timeSpan?.from),
                        to: this.helper.stringDateToApiDate(detailsFormValue?.timeSpan?.to),
                    }),
                )
                .pipe(
                    takeUntil(this.onDestroy$),
                    tap(
                        () => {
                            this.toast.success('Spremembe so shranjene.');
                            this.comm.dirty$.next(false);
                        },
                        () => this.toast.errorAndSend('Napaka pri urejanju sklopa.'),
                    ),
                );
        }
    }

    private createReport(): void {
        this.inputSetsObj.push(
            new ResultSet().deserialize({
                title: 'Anamneza',
                surveyId: '-1',
                createdBy: this.loggedUser.name,
                sortId: 1,
            }),
        );
        this.inputSetsObj.push(
            new ResultSet().deserialize({
                title: 'Pregled',
                surveyId: '-1',
                createdBy: this.loggedUser.name,
                sortId: 2,
            }),
        );
        this.inputSetsObj.push(
            new ResultSet().deserialize({
                title: 'Opis uporabljenih metod',
                surveyId: '-1',
                createdBy: this.loggedUser.name,
                sortId: 3,
            }),
        );

        this.inputSetsObj.push(
            new ResultSet().deserialize({
                title: 'Končno stanje',
                surveyId: '-1',
                createdBy: this.loggedUser.name,
                sortId: 4,
            }),
        );
        this.inputSetsObj.push(
            new ResultSet().deserialize({
                title: 'Priporočila',
                surveyId: '-1',
                createdBy: this.loggedUser.name,
                sortId: 5,
            }),
        );

        this.inputSetsObj.push(
            new ResultSet().deserialize({
                title: 'Število in datum obravnav',
                surveyId: '-1',
                createdBy: this.loggedUser.name,
                sortId: 6,
            }),
        );

        this.inputSetsObj.push(
            new ResultSet().deserialize({
                title: 'Komentar',
                surveyId: '-1',
                createdBy: this.loggedUser.name,
                sortId: 7,
            }),
        );
    }
}
