import { KeyValue } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import moment from 'moment';
import { EMPTY, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DATE_CONST } from '../../../../../../../_shared/constants/date-constants.const';
import { PaperType } from '../../../../../../../_shared/enums/PaperType.enum';
import { PortfolioPaper, Visit } from '../../../../../../../_shared/model';
import { Reservation } from '../../../../../../../_shared/model/reservation.model';
import { HelperService, MfToastService, StorageService } from '../../../../../../../_shared/services';
import { CommonService, ResultService } from '../../../../_services';
import { MdpsCertificateBase } from './mdps-certificate-base';

export interface ICertificateDriverModel {
    certificateNumber?: string;
    customerFullName: string;
    customerAddress?: string;
    customerBirthDate: string;
    customerDocumentNumber?: string;
    contractorCertificateNumber?: string;
    contractorCertificateIssuedDate?: string;
    contractorCertificateIssuerFullName?: string;
    selectedCategories: string;
    status1?: string;
    status2aText?: string;
    status2aLimitations?: string;
    status2bText?: string;
    status2cText?: string;
    status3Text?: string;
    status4Text?: string;
    status5?: string;
    dateOfSignature?: string;
    placeOfSignature?: string;
    commissionChairman?: string;
    contractorFullName?: string;
    subContractorFullName?: string;
}

export class CertificateDriversModel extends MdpsCertificateBase implements ICertificateDriverModel {
    certificateNumber?: string;
    customerFullName: string;
    customerAddress?: string;
    customerBirthDate: string;
    customerDocumentNumber?: string;
    contractorCertificateNumber?: string;
    contractorCertificateIssuedDate?: string;
    contractorCertificateIssuerFullName?: string;
    selectedCategories: string;
    status1?: string;
    status2aText?: string;
    status2aLimitations?: string;
    status2bText?: string;
    status2cText?: string;
    status3Text?: string;
    status4Text?: string;
    status5?: string;
    dateOfSignature?: string;
    placeOfSignature?: string;
    commissionChairman?: string;
    contractorFullName?: string;
    subContractorFullName?: string;

    public firstGroupCategories = ['A', 'A1', 'A2', 'AM', 'B', 'B1', 'BE', 'F', 'G'];
    public secondGroupCategories = ['C', 'CE', 'C1', 'C1E', 'DE', 'D1', 'D', 'D1E'];
    public resultOneOptions = this.getResultOneOptions();
    public resultThreeOptions = this.getResultThreeOptions();
    public resultFiveOptions = this.getResultFiveOptions();
    public selectedCategoriesArray: string[] = [];
    public selectedCategories2aArray: string[] = [];
    public selectedCategories2bArray: string[] = [];
    public selectedCategories2cArray: string[] = [];
    public selectedCategories5Array: string[] = [];

    private commonRest: CommonService;
    private storage: StorageService;

    constructor(
        paper: PortfolioPaper,
        fb: FormBuilder,
        onDestroy$: Subject<void>,
        resultService: ResultService,
        helper: HelperService,
        toast: MfToastService,
        visit: Visit,
        reservation: Reservation,
        commonRest: CommonService,
        storage: StorageService,
    ) {
        super(paper, fb, onDestroy$, resultService, helper, toast, visit, reservation);
        this.commonRest = commonRest;
        this.storage = storage;

        this.commonRest.getContractorBasicInfo(this.storage.getSelectedContractor().id).subscribe(res => {
            this.form.controls['contractorCertificateNumber'].setValue(res.rizddzId);
            this.form.controls['placeOfSignature'].setValue(res.postName);
        });
    }

    public createForm(): FormGroup {
        return this.fb.group({
            certificateNumber: this.fb.control({ value: null, disabled: false }),
            customerFullName: this.fb.control({ value: null, disabled: false }),
            customerAddress: this.fb.control({ value: null, disabled: false }),

            customerBirthDate: this.fb.control({ value: null, disabled: false }),
            customerDocumentNumber: this.fb.control({ value: null, disabled: false }),
            contractorCertificateNumber: this.fb.control({ value: null, disabled: false }),
            contractorCertificateIssuedDate: this.fb.control({ value: null, disabled: false }),
            contractorCertificateIssuerFullName: this.fb.control({ value: null, disabled: false }),
            status1: this.fb.control({ value: null, disabled: false }),
            status2a: this.fb.control({ value: null, disabled: false }),
            status2aText: this.fb.control({ value: null, disabled: false }),
            status2aLimitations: this.fb.control({ value: null, disabled: false }),
            status2b: this.fb.control({ value: null, disabled: false }),
            status2bText: this.fb.control({ value: null, disabled: false }),
            status2c: this.fb.control({ value: null, disabled: false }),
            status2cText: this.fb.control({ value: null, disabled: false }),
            status3: this.fb.control({ value: null, disabled: false }),
            status3Text: this.fb.control({ value: null, disabled: false }),
            status4Text: this.fb.control({ value: null, disabled: false }),
            dateOfSignature: this.fb.control({ value: moment().format(DATE_CONST.format.date.view), disabled: false }),
            placeOfSignature: this.fb.control({ value: null, disabled: false }),
            commissionChairman: this.fb.control({ value: null, disabled: false }),
            contractorFullName: this.fb.control({ value: null, disabled: false }),
            subContractorFullName: this.fb.control({ value: null, disabled: false }),
        });
    }

    public getPaperData(paperId: number): void {
        this.resultService
            .getDriverCertificateData(this.visit.contractor.id, this.visit.visitId, paperId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(res => {
                this.applyDataToForm(res);
            });
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public saveDocument$(): Observable<any> {
        if (!this.form.valid) {
            return EMPTY;
        }
        const dto = this.getDataModelFromForm();

        if (this.paper.paperType === PaperType.DRIVER_CERTIFICATE) {
            return this.resultService.saveDriverCertificate(this.visit.contractor.id, this.visit.visitId, dto);
        } else if (this.paper.paperType === PaperType.DRIVER_CERTIFICATE_SPECIAL) {
            return this.resultService.saveDriverCertificateSpecial(this.visit.contractor.id, this.visit.visitId, dto);
        } else {
            throw Error();
        }
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateDocument$(): Observable<any> {
        const paperId = this.paper.id ? this.paper.id : this.paper.paperId;
        const dto = this.getDataModelFromForm();

        if (this.paper.paperType === PaperType.DRIVER_CERTIFICATE) {
            return this.resultService.updateDriverCertificateData(this.visit.contractor.id, this.visit.visitId, paperId, dto);
        } else if (this.paper.paperType === PaperType.DRIVER_CERTIFICATE_SPECIAL) {
            return this.resultService.updateDriverCertificateSpecialData(this.visit.contractor.id, this.visit.visitId, paperId, dto);
        } else {
            throw Error();
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected getPaperPreview$(): Observable<any> {
        return this.resultService.getPortfolioPaper(this.visit.contractor.id, this.paper.id ? this.paper.id : this.paper.paperId);
    }

    public selectCategoryOption(selectedItem: string): void {
        this.proccessCategoryOption(selectedItem, this.selectedCategoriesArray);
    }
    public selectCategory2aOption(selectedItem: string): void {
        this.proccessCategoryOption(selectedItem, this.selectedCategories2aArray);
        console.warn(this.selectedCategories2aArray);
    }
    public selectCategory2bOption(selectedItem: string): void {
        this.proccessCategoryOption(selectedItem, this.selectedCategories2bArray);
    }
    public selectCategory2cOption(selectedItem: string): void {
        this.proccessCategoryOption(selectedItem, this.selectedCategories2cArray);
    }

    private proccessCategoryOption(selectedItem: string, targetArray: string[]): void {
        const matchIndex = targetArray.indexOf(selectedItem);
        if (matchIndex !== -1) {
            targetArray.splice(matchIndex, 1);
        } else {
            targetArray.push(selectedItem);
        }
    }

    public selectOptionOne(item: KeyValue<string, string>): void {
        const value = this.form.controls['status1']?.value as KeyValue<string, string>;
        if (value?.key !== item?.key) {
            this.form.controls['status1'].setValue(item);
        } else {
            this.form.controls['status1'].setValue(null);
        }
    }

    public selectOptionThree(item: KeyValue<string, string>): void {
        const value = this.form.controls['status3']?.value as KeyValue<string, string>;
        if (value?.key !== item?.key) {
            this.form.controls['status3'].setValue(item);
        } else {
            this.form.controls['status3'].setValue(null);
        }
    }
    public selectOptionFive(item: KeyValue<string, string>): void {
        this.proccessCategoryOption(item.key, this.selectedCategories5Array);
    }

    public onDateChange(date: any): void {
        this.form.controls['dateOfSignature'].setValue(date.start.format(DATE_CONST.format.date.view));
    }

    private getResultOneOptions(): Array<KeyValue<string, string>> {
        const itemsMap = new Array<KeyValue<string, string>>();
        itemsMap.push({
            key: 'a',
            value: 'Zdravstveni pregled kandidata za voznika',
        });
        itemsMap.push({
            key: 'b',
            value: 'Zdravstveni pregled za podaljšanje veljavnosti vozniškega dovoljenja',
        });
        itemsMap.push({
            key: 'c',
            value: 'Kontrolni zdravstveni pregled',
        });
        return itemsMap;
    }

    private getResultThreeOptions(): Array<KeyValue<string, string>> {
        const itemsMap = new Array<KeyValue<string, string>>();
        itemsMap.push({
            key: 'a',
            value: 'ni potreben',
        });
        itemsMap.push({
            key: 'b',
            value: 'se opravi najpozneje čez:',
        });
        return itemsMap;
    }

    private getResultFiveOptions(): Array<KeyValue<string, string>> {
        const itemsMap = new Array<KeyValue<string, string>>();
        itemsMap.push({
            key: '5a',
            value: 'udeležbo voznika v edukacijski delavnici',
        });
        itemsMap.push({
            key: '5b',
            value: 'udeležbo voznika v psihosocialni delavnici',
        });
        itemsMap.push({
            key: '5c',
            value: 'udeležbo voznika v standardiziranem programu zdravljenja odvisnosti',
        });
        return itemsMap;
    }

    private applyDataToForm(model: ICertificateDriverModel): void {
        if (model?.customerDocumentNumber?.length) {
            this.form.controls['customerDocumentNumber'].setValue(model?.customerDocumentNumber);
        }

        if (model?.contractorCertificateNumber?.length) {
            this.form.controls['contractorCertificateNumber'].setValue(model?.contractorCertificateNumber);
        }

        if (model?.selectedCategories?.length) {
            this.selectedCategoriesArray = model?.selectedCategories.split(',').map(status => status.replace(/'/g, '').trim());
        }

        if (model?.status1?.length) {
            switch (model?.status1) {
                case '1a':
                    this.form.controls['status1'].setValue(this.resultOneOptions.find(item => item.key === 'a'));
                    break;
                case '1b':
                    this.form.controls['status1'].setValue(this.resultOneOptions.find(item => item.key === 'b'));
                    break;
                case '1c':
                    this.form.controls['status1'].setValue(this.resultOneOptions.find(item => item.key === 'c'));
                    break;
            }
        }

        if (model?.status2aText?.length) {
            this.selectedCategories2aArray = model?.status2aText.split(',').map(status => status.trim());
        }
        if (model?.status2aLimitations?.length) {
            this.form.controls['status2aLimitations'].setValue(model?.status2aLimitations);
        }
        if (model?.status2bText?.length) {
            this.selectedCategories2bArray = model?.status2bText.split(',').map(status => status.trim());
        }
        if (model?.status2cText?.length) {
            this.selectedCategories2cArray = model?.status2cText.split(',').map(status => status.trim());
        }

        if (model?.status3Text?.length) {
            this.form.controls['status3'].setValue(this.resultThreeOptions.find(item => item.key === 'b'));
            this.form.controls['status3Text'].setValue(model?.status3Text);
        } else {
            this.form.controls['status3'].setValue(this.resultThreeOptions.find(item => item.key === 'a'));
        }

        if (model?.status4Text?.length) {
            this.form.controls['status4Text'].setValue(model?.status4Text);
        }

        if (model?.status5?.length) {
            this.selectedCategories5Array = model?.status5.split(', ').map(status => status.trim());
        }

        if (model?.placeOfSignature?.length) {
            this.form.controls['placeOfSignature'].setValue(model?.placeOfSignature);
        }

        if (model?.dateOfSignature?.length) {
            this.form.controls['dateOfSignature'].setValue(moment(model?.dateOfSignature).format(DATE_CONST.format.date.view));
            this.calendarOptions = { ...this.calendarOptions, startDate: moment(model?.dateOfSignature) };
        }

        if (model?.commissionChairman?.length) {
            this.form.controls['commissionChairman'].setValue(model?.commissionChairman);
        }

        if (model?.contractorFullName?.length) {
            this.form.controls['contractorFullName'].setValue(model?.contractorFullName);
        }

        if (model?.subContractorFullName?.length) {
            this.form.controls['subContractorFullName'].setValue(model?.subContractorFullName);
        }
    }

    private getDataModelFromForm(): ICertificateDriverModel {
        const model = {
            certificateNumber: null,
            customerFullName: this.visit.customer.fullName,
            customerAddress: `${this.visit.customer.address ? this.visit.customer.address : ''}, ${
                this.visit.customer.post != null ? this.visit.customer.post : ''
            }, ${this.visit.customer.city != null ? this.visit.customer.city : ''}`,
            customerBirthDate: moment(this.visit.customer.birthDate).format(DATE_CONST.format.date.api),
            customerDocumentNumber: this.form.controls['customerDocumentNumber']?.value || '',
            contractorCertificateNumber: this.form.controls['contractorCertificateNumber']?.value || '',
            contractorCertificateIssuedDate: moment().format(DATE_CONST.format.date.api),
            contractorCertificateIssuerFullName: this.visit.contractor.subcontractors[0].name,
            selectedCategories: this.selectedCategoriesArray.map(item => `'${item}'`).join(','),
            status1: null,
            status2aText: '',
            status2aLimitations: '',
            status2bText: '',
            status2cText: '',
            status3Text: '',
            status4Text: '',
            status5: '',
            dateOfSignature:
                moment(this.form.controls['dateOfSignature']?.value, DATE_CONST.format.date.view).format(DATE_CONST.format.date.api) ||
                moment().format(DATE_CONST.format.date.api),
            placeOfSignature: this.form.controls['placeOfSignature']?.value || '',
            commissionChairman: this.form.controls['commissionChairman']?.value || '',
        } as ICertificateDriverModel;

        const selectedKeyOptionOne = (this.form.controls['status1']?.value as KeyValue<string, string>)?.key;
        switch (selectedKeyOptionOne) {
            case 'a':
                model.status1 = '1a';
                break;
            case 'b':
                model.status1 = '1b';
                break;
            case 'c':
                model.status1 = '1c';
                break;
        }

        model.status2aText = this.selectedCategories2aArray.join(', ');
        model.status2aLimitations = this.form.controls['status2aLimitations']?.value || '';
        model.status2bText = this.selectedCategories2bArray.join(', ');
        model.status2cText = this.selectedCategories2cArray.join(', ');

        const selectedKeyOptionThree = (this.form.controls['status3']?.value as KeyValue<string, string>)?.key;
        switch (selectedKeyOptionThree) {
            case 'a':
                model.status3Text = '';
                break;
            case 'b':
                model.status3Text = this.form.controls['status3Text']?.value ? this.form.controls['status3Text']?.value : '/';
                break;
        }

        model.status4Text = this.form.controls['status4Text']?.value || '';
        model.status5 = this.selectedCategories5Array.join(', ');

        if (this.paper.paperType !== PaperType.DRIVER_CERTIFICATE_SPECIAL) {
            model.contractorFullName = this.visit.contractor.name;
            model.subContractorFullName = this.visit.contractor.subcontractors[0].name;
        }

        return model;
    }
}
