import * as moment from 'moment';
import { Serializable } from './deserialize.service';

export class UserSettings implements Serializable<UserSettings> {
    selectedCalendarView: string; //month, agendaWeek, agendaDay, listMonth
    calendarFrom: moment.Moment;

    deserialize(input) {
        if (!input) {
            return this;
        }
        this.selectedCalendarView = input.selectedCalendarView;
        this.calendarFrom = input.calendarFrom;
        return this;
    }
}
