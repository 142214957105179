<div
    id="m_login"
    style="background-image: url(./assets/app/media/img/bg/bg-3.jpg)"
    appunwraptag=""
    class="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-2"
>
    <div class="m-grid__item m-grid__item--fluid m-login__wrapper">
        <div class="m-login__container">
            <div class="m-login__logo">
                <a href="#">
                    <img src="./assets/app/media/img/logos/logo.svg" width="200" />
                </a>
            </div>
            <div *ngIf="isTest" role="alert" class="m-alert m-alert--outline alert alert-danger alert-dismissible fade show">
                <button type="button" data-dismiss="alert" aria-label="Close" class="close"></button>
                <ng-container i18n="@@8dbae8c7-fdb4-59eb-b07c-84bbc6dc04f9">
                    Do aplikacije dostopate preko
                    <strong>testnega okolja.</strong>
                    Prosimo vas, da uporabite produkcijsko različico na naslovu:
                </ng-container>
                <a href="https://app.eambulanta.si" class="m--font-danger">
                    <strong>app.eambulanta.si</strong>
                </a>
            </div>
            <div [hidden]="showPasswordSent">
                <div class="m-login__signin">
                    <form #f="ngForm" (ngSubmit)="f.form.valid && signin()" class="m-login__form m-form">
                        <ng-template #alertSignin></ng-template>

                        <div class="form-group m-form__group">
                            <input
                                #email="ngModel"
                                [(ngModel)]="model.email"
                                type="text"
                                placeholder="Uporabniško ime"
                                name="email"
                                autocomplete="off"
                                autocorrect="off"
                                autocapitalize="none"
                                class="form-control m-input"
                                i18n-placeholder="@@bb356c7d-3559-55c2-b4ae-6014dbd63514"
                            />
                        </div>
                        <div class="form-group m-form__group">
                            <input
                                #password="ngModel"
                                [(ngModel)]="model.password"
                                type="password"
                                placeholder="Geslo"
                                name="password"
                                autocomplete="on"
                                class="form-control m-input m-login__form-input--last"
                            />
                        </div>
                        <div class="row m-login__form-sub">
                            <div class="col m--align-right m-login__form-right">
                                <a href="javascript:" id="m_login_forget_password" class="m-link" i18n="@@9a3a15804c098a250ee099fcec0737c70c2f536b">
                                    Pozabljeno geslo ?
                                </a>
                            </div>
                        </div>

                        <div *ngIf="isCaptcha" class="row m-login__form-sub text-center justify-content-center mt-4">
                            <app-captcha
                                #loginCaptcha
                                *ngIf="!isCaptchaSolved"
                                (captchaSolved)="captchaLoginSolver($event)"
                                additionalText="Zahtevano je bilo dodatno preverjanje uporabnika."
                            ></app-captcha>
                            <i *ngIf="isCaptchaSolved" class="la-4x la la-check-circle text-success"></i>
                        </div>

                        <div class="m-login__form-action">
                            <button
                                id="m_login_signin_submit"
                                class="btn btn-brand m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary"
                                i18n="@@3577a42460b087ba9da9e70fcf826c5b90a310b8"
                            >
                                Prijava
                            </button>
                        </div>
                    </form>
                </div>
                <div class="m-login__signup">
                    <div class="m-login__head">
                        <h3 class="m-login__title" i18n="@@001d9386-692d-5903-8c5a-b1d7a52e9506">Sign Up</h3>
                        <div class="m-login__desc" i18n="@@da46cf3a-b7e2-5a22-9d9d-d1ba8318c007">Enter your details to create your account:</div>
                    </div>
                    <form action="" class="m-login__form m-form">
                        <div class="form-group m-form__group">
                            <input type="text" placeholder="Fullname" name="fullname" class="form-control m-input" />
                        </div>
                        <div class="form-group m-form__group">
                            <input type="text" placeholder="Email" name="email" autocomplete="off" class="form-control m-input" />
                        </div>
                        <div class="form-group m-form__group">
                            <input type="password" placeholder="Password" name="password" autocomplete="on" class="form-control m-input" />
                        </div>
                        <div class="form-group m-form__group">
                            <input
                                type="password"
                                placeholder="Confirm Password"
                                name="rpassword"
                                autocomplete="on"
                                class="form-control m-input m-login__form-input--last"
                            />
                        </div>
                        <div class="row form-group m-form__group m-login__form-sub">
                            <div class="col m--align-left">
                                <label class="m-checkbox m-checkbox--focus">
                                    <input type="checkbox" name="agree" />
                                    I Agree the
                                    <a href="#" class="m-link m-link--focus">terms and conditions</a>
                                    .
                                    <span></span>
                                </label>
                                <span class="m-form__help"></span>
                            </div>
                        </div>
                        <div class="m-login__form-action">
                            <button
                                id="m_login_signup_submit"
                                class="btn btn-focus m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn"
                                i18n="@@eb46e87c-4648-54d1-8424-e66e62155598"
                            >
                                Sign Up
                            </button>
                            &nbsp;&nbsp;
                            <button
                                id="m_login_signup_cancel"
                                class="btn btn-outline-focus m-btn m-btn--pill m-btn--custom m-login__btn"
                                i18n="@@f354472e-256d-5f40-a968-49244699d9d5"
                            >
                                Cancel
                            </button>
                        </div>
                    </form>
                </div>
                <div class="m-login__forget-password">
                    <div class="m-login__head">
                        <h3 class="m-login__title" i18n="@@5c57258af9db92e7b610ad699f4fd6495eb4278a">Pozabljeno geslo ?</h3>
                        <div class="m-login__desc" i18n="@@a1a37386ab85b728ea43a150f0412880f9415221">Vpišite vaše uporabniško ime:</div>
                    </div>
                    <form #f="ngForm" (ngSubmit)="f.form.valid && forgotPass()" class="m-login__form m-form">
                        <div class="form-group m-form__group">
                            <input
                                [(ngModel)]="model.email"
                                type="text"
                                placeholder="Uporabniško ime"
                                name="emailusername"
                                id="m_email"
                                autocomplete="on"
                                class="form-control m-input"
                                i18n-placeholder="@@694d8bdc-6018-5fd1-af2f-d149c488589e"
                            />
                        </div>
                        <div class="form-group m-form__group mt-3 text-center">
                            <app-captcha
                                #passwordResetCaptcha
                                *ngIf="!isPasswordResetCaptchaSolved"
                                (captchaSolved)="captchaPasswordResetSolver($event)"
                            ></app-captcha>
                            <i *ngIf="isPasswordResetCaptchaSolved" class="la-4x la la-check-circle text-success"></i>
                        </div>

                        <div class="m-login__form-action">
                            <button
                                id="m_login_forget_password_submit"
                                class="btn btn-brand m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primaryr"
                                i18n="@@36ed63993b696e58311ff590d179a3749672534d"
                            >
                                Pošlji
                            </button>
                            &nbsp;&nbsp;
                            <button
                                id="m_login_forget_password_cancel"
                                class="btn btn-outline-brand m-btn m-btn--pill m-btn--custom m-login__btn"
                                i18n="@@5bc6fc92033155bd0dc9aa3179f4bf8e9d0a8cb3"
                            >
                                Prekliči
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div *ngIf="showPasswordSent" class="m-login__sent-password my-5">
                <p style="font-size: 1.5em" class="mb-3" i18n="@@5843269325946880">
                    Če med uporabniki obstaja račun z navedenim elektronskim naslovom, bo nanj poslana povezava za ponastavitev gesla.
                </p>
                <button
                    (click)="backToSingIn()"
                    class="btn btn-brand m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary mx-auto d-flex p-3 mt-5"
                    i18n="@@8566939456634880"
                >
                    Nazaj na prijavo
                </button>
            </div>
        </div>

        <div class="text-center m--font-primary"><mds-app-info></mds-app-info></div>
    </div>
</div>
<input
    #username="ngModel"
    [(ngModel)]="model.username"
    data-lpignore="true"
    type="text"
    placeholder="nameuser"
    name="nameuser"
    autocomplete="off"
    autocorrect="off"
    autocapitalize="none"
    class="mf-h-input"
/>
