import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { Customer } from '../../../../_shared/model';
import { BaseState } from './../../../../_shared/state/base/base.state';
import { CustomerService } from './../../../pages/home/_services/customer.service';

@Component({
    selector: 'app-customer-multiple-new-user',
    templateUrl: './customer-multiple-new-user.component.html',
    styleUrls: ['./customer-multiple-new-user.component.scss'],
})
export class CustomerMultipleNewUserComponent implements OnInit {
    @Input()
    customerNew: Customer;

    @Input()
    customersOld: Customer[];

    @Input()
    existingCustomer: boolean;

    @Input()
    editingType: 'EXISTING_CUSTOMER' | 'LINKING_CUSTOMERS' = 'EXISTING_CUSTOMER';

    constructor(
        public activeModal: NgbActiveModal,
        private customerRest: CustomerService,
        private store: Store,
    ) {}
    public isManualSearchEnabled = false;
    public allCustomers: Customer[] = [];
    public allCustomersOldCopy: Customer[];
    private contractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);
    private onDestroy$ = new Subject<void>();
    ngOnInit() {
        if (!this.customersOld?.length) this.customersOld = [];
        this.allCustomersOldCopy = _.cloneDeep(this.customersOld);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy() {
        this.onDestroy$.next();
    }

    public saveNewCustomer() {
        this.activeModal.dismiss();
    }
    public getCustomers() {
        this.customerRest
            .getAllCustomers(this.contractorId)
            .pipe(
                takeUntil(this.onDestroy$),
                map((customersData: Customer[]) => {
                    return customersData?.filter(cust => cust?.id != this.customerNew?.id);
                }),
            )
            .subscribe(customers => {
                this.allCustomers = customers;
                if (this.editingType === 'LINKING_CUSTOMERS') {
                    this.allCustomers = this.allCustomers.filter(cust => !cust.dataCarrier);
                }
            });
    }
    public toggleSearch() {
        this.isManualSearchEnabled = !this.isManualSearchEnabled;
        if (this.isManualSearchEnabled) {
            this.customersOld = [];
        } else {
            this.customersOld = [...(this.allCustomersOldCopy || [])];
        }
        if (!this.allCustomers?.length && this.isManualSearchEnabled) {
            this.getCustomers();
        }
    }
    public addCustomerFromSearch(customer: Customer) {
        if (customer !== undefined) {
            this.customersOld = [];
            this.customersOld.push(customer);
        } else {
            this.customersOld = [];
        }
    }

    public selectCustomer(c: Customer): void {
        this.activeModal.dismiss(c);
    }
}
