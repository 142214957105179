import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Consent } from '../model/consent.model';
import { CustomerSharedList } from './../../public/common/model/customer-shared-list.model';
import { HelperService } from './helper.service';

@Injectable()
export class PublicService {
    constructor(
        private http: HttpClient,
        private helper: HelperService,
    ) {}

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public sendAccessCode(documentid: string, phonenumber: string): Observable<any> {
        return this.http.post(this.helper.getAccessUrl() + '/code/' + documentid + '/phone/' + phonenumber, {});
    }
    //printDiagnose()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getResultDocument(documentid: string, pwd: string): Observable<any> {
        return this.http.get(this.helper.getPrintServerUrl() + '/visits/survey/document/' + documentid + '/' + pwd, {
            responseType: 'blob',
        });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getPaymentConfirmation(documentid: string, pwd: string): Observable<any> {
        return this.http.get(this.helper.getAccountingUrl(2) + '/paymentConfirmations/document/' + documentid + '/' + pwd, {
            responseType: 'blob',
        });
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getPublicCouponPayment(documentid: string, pwd: string): Observable<any> {
        return this.http.get(this.helper.getAccountingUrl(2) + '/couponPaymentConfirmations/document/' + documentid + '/' + pwd, {
            responseType: 'blob',
        });
    }
    // previewInvoice()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getInvoiceDocument(documentid: string, pwd: string): Observable<any> {
        return this.http.get(this.helper.getAccountingUrl(2) + '/invoices/document/' + documentid + '/' + pwd, {
            responseType: 'blob',
        });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getSharedDocuments(prereservationId: string, pwd): Observable<any> {
        return this.http.get(this.helper.getVisitUrl(2) + '/sharedDocuments/' + prereservationId + '/' + pwd);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getSharedDocument(prereservationId: string, pwd, paperId): Observable<any> {
        return this.http.get(this.helper.getVisitUrl(2) + '/sharedDocuments/' + prereservationId + '/' + pwd + '/paper/' + paperId, {
            responseType: 'blob',
        });
    }

    //forgottenPwd() //public access
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public forgottenPwd(data: any): Observable<any> {
        return this.http.post(this.helper.getApiUrl() + '/pwd/forgotten', data);
    }

    //renewPwd() //public access
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public renewPwd(data: any): Observable<any> {
        return this.http.post(this.helper.getApiUrl() + '/pwd/renew', data);
    }

    //surveyHook() //public access
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public sendSurveyResults(guid: string, data: any): Observable<any> {
        return this.http.post(this.helper.getSurveysUrl() + '/erp?guid=' + guid, data);
    }

    //printDiagnose()
    public getCustomerConsent(token: string): Observable<Consent> {
        return this.http.get(this.helper.getApiUrl(2) + '/consent/' + token).pipe(map(res => new Consent().deserialize(res)));
    }

    //updateCustomerConsentWithToken()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateCustomerConsentWithToken(token: string, data: Consent): Observable<any> {
        return this.http.post(this.helper.getApiUrl() + '/consent/' + token, data);
    }

    public solveUserCaptcha(username: string, guid: string, x: number, y: number): Observable<boolean> {
        return this.http.get(this.helper.getRootUrl() + `captcha/users/${username}/validate/${guid}/${x}/${y}`) as Observable<boolean>;
    }

    //customerData

    // /guid/{guid}/pwd/{pwd}/papers/{maskedid}

    //     @Get("/guid/{guid}/pwd/{pwd}")
    // fun getSharedData(guid: String, pwd: String): HttpResponse<*> {
    public getPublicCustomerLogin(guid: string, pwd: string): Observable<CustomerSharedList> {
        return this.http.get<CustomerSharedList>(this.helper.getCustomerDataUrl() + `/guid/${guid}/pwd/${pwd}`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getCustomerPortfolio(guid: string, pwd: string, maskedid: string): Observable<any> {
        return this.http.get(this.helper.getCustomerDataUrl() + `/guid/${guid}/pwd/${pwd}/papers/${maskedid}`, {
            responseType: 'blob',
            // observe: 'response',
        });
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getPublicCustomerCoupon(guid: string, pwd: string, maskedid: string): Observable<any> {
        return this.http.get(this.helper.getCustomerDataUrl() + `/guid/${guid}/pwd/${pwd}/couponpayments/${maskedid}`, {
            responseType: 'blob',
        });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getPublicCustomerGroupInvoice(guid: string, pwd: string, maskedid: string): Observable<any> {
        return this.http.get(this.helper.getCustomerDataUrl() + `/guid/${guid}/pwd/${pwd}/groupinvoices/${maskedid}`, {
            responseType: 'blob',
        });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getCustomerDocument(guid: string, pwd: string, maskedid: string): Observable<any> {
        return this.http.get(this.helper.getCustomerDataUrl() + `/guid/${guid}/pwd/${pwd}/documents/${maskedid}`, {
            responseType: 'blob',
            // observe: 'response',
        });
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getCustomerWorkOrderDocument(guid: string, pwd: string, maskedid: string): Observable<any> {
        return this.http.get(this.helper.getCustomerDataUrl() + `/guid/${guid}/pwd/${pwd}/workorderdocuments/${maskedid}`, {
            responseType: 'blob',
        });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getCustomerInvoice(guid: string, pwd: string, maskedid: string): Observable<any> {
        return this.http.get(this.helper.getCustomerDataUrl() + `/guid/${guid}/pwd/${pwd}/invoices/${maskedid}`, {
            responseType: 'blob',
            // observe: 'response',
        });
    }
    public checkPublicCredentials(guid: string, pwd: string) {
        return this.http.get(this.helper.getApiUrl(2) + `/documentlinkcheck/uuid/${guid}/pwd/${pwd}`);
    }

    // @Controller("/booking/eav2/erp/customerdata")
    // @Secured(SecurityRule.IS_AUTHENTICATED)
    // @Post(uris = [
    //     "/contractors/{contractorid}/customers/{customerid}/papers/{paperid}/share",
    //     "/contractors/{contractorid}/customers/{customerid}/documents/{maskeddocumentid}/share",
    //     "/contractors/{contractorid}/customers/{customerid}/invoices/{invoiceid}/share"
    // ])
    // fun shareData(
    //     contractorid: Long,
    //     customerid: Long,
    //     paperid: Long?,
    //     maskeddocumentid: String?,
    //     invoiceid: Long?,
    //     @Body receiver: NotificationReceiver?,
    //     authentication: Authentication
    // ): HttpResponse<*> {
    // @Put(uris = [
    //     "/contractors/{contractorid}/customers/{customerid}/papers/{paperid}/unshare",
    //     "/contractors/{contractorid}/customers/{customerid}/documents/{maskeddocumentid}/unshare",
    //     "/contractors/{contractorid}/customers/{customerid}/invoices/{invoiceid}/unshare"
    // ])
    // fun unshareData(
    //     contractorid: Long,
    //     customerid: Long,
    //     paperid: Long?,
    //     maskeddocumentid: String?,
    //     invoiceid: Long?,
    //     authentication: Authentication
    // ): HttpResponse<*> {

    // @Controller("/booking/eav2/erp/customerdata")
    // @Secured(SecurityRule.IS_ANONYMOUS)
    // @Get("/guid/{guid}/pwd/{pwd}")
    // fun getSharedData(guid: String, pwd: String): HttpResponse<*> {
    // @Get("/guid/{guid}/pwd/{pwd}/invoices/{maskedid}")
    // fun getSharedInvoice(guid: String, pwd: String, maskedid: String): HttpResponse<*> {
    // @Get("/guid/{guid}/pwd/{pwd}/papers/{maskedid}")
    // fun getSharedPaper(guid: String, pwd: String, maskedid: String): HttpResponse<*> {
    // @Get("/guid/{guid}/pwd/{pwd}/documents/{maskedid}")
    // fun getSharedDocument(guid: String, pwd: String, maskedid: String): HttpResponse<*> {
}
