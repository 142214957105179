import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { CustomerSharedList } from './../common/model/customer-shared-list.model';
import { PublicCommon } from './public.actions';
import { PublicStateModel } from './public.model';

const PUBLICSTATE_TOKEN: StateToken<PublicStateModel> = new StateToken('publicstate');

const DEFAULT_STATE: PublicStateModel = {
    auth: undefined,
    customerSharedList: undefined,
};

@State<PublicStateModel>({
    name: PUBLICSTATE_TOKEN,
    defaults: DEFAULT_STATE,
    children: [], //ce bo kdaj prov prislo
})
@Injectable()
export class PublicState {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    @Selector()
    public static customerSharedList(state: PublicStateModel): CustomerSharedList {
        return state.customerSharedList;
    }

    @Selector()
    public static auth(state: PublicStateModel): CustomerSharedList {
        return state.auth;
    }

    @Action(PublicCommon.SetAuthData)
    public SetAuthData(ctx: StateContext<PublicStateModel>, { auth }: PublicCommon.SetAuthData) {
        ctx.setState(
            patch<PublicStateModel>({
                auth: auth,
            }),
        );
    }

    @Action(PublicCommon.SetCustomerSharedList)
    public SetCustomerSharedList(ctx: StateContext<PublicStateModel>, { data }: PublicCommon.SetCustomerSharedList) {
        ctx.setState(
            patch<PublicStateModel>({
                customerSharedList: data,
            }),
        );
    }
}
