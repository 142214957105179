import { Component, Input, OnInit } from '@angular/core';
import { PaperType } from './../../../../../_shared/enums/PaperType.enum';
import { PortfolioPaper } from './../../../../../_shared/model/portfolio/portfolioPaper.model';

@Component({
    selector: 'documentation-overview-element',
    templateUrl: './documentation-overview-element.component.html',
    styleUrls: ['./documentation-overview-element.component.scss'],
})
export class DocumentationOverviewElementComponent implements OnInit {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}
    @Input() paper: PortfolioPaper;
    public paperTypes: typeof PaperType = PaperType;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit(): void {}
}
