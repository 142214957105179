import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import _ from 'lodash';
import { BsType } from '../../../../../../_shared/enums/business.enum';
import { HelperService } from './../../../../../../_shared/services/helper.service';
import { PfType } from './../../_common/enum/pf-type.enum';

@Component({
    selector: 'visit-pf-p-base',
    template: '',
    styles: [],
})
export class PfPBaseComponent implements OnInit {
    @Input()
    detailsForm: FormGroup;

    @Input()
    pfType: PfType;

    public inputSetsObj;
    public selectedDiagnoses;
    public bsType: BsType;
    constructor(public helper: HelperService) {}

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit() {}

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public selectTemplate(data: any, type: string, currentData: any) {
        const surveys = _.get(data, 'template.surveys', []);
        const diagnoses = _.get(data, 'template.diagnoses', []);
        let pristine = true;
        //dodan združen opis
        if (type === 'survey' && surveys[0].title !== 'Združen opis') {
            surveys.unshift({
                sortId: '1',
                title: 'Združen opis',
            });
        }
        //check if pristine
        currentData.forEach(resultData => {
            if (resultData.content) {
                pristine = false;
            }
        });
        if (pristine) {
            if (type === 'fth') {
                this.inputSetsObj = surveys;
            } else if (type === 'survey') {
                this.inputSetsObj = surveys;
                this.selectedDiagnoses = diagnoses;
            }
        } else {
            this.helper.displayAlert('Uporaba predloge', 'Izgubili boste trenutno vpisane podatke. Želite nadaljevati?').then(result => {
                if (result.value) {
                    if (type === 'fth') {
                        this.inputSetsObj = surveys;
                    } else if (type === 'survey') {
                        this.inputSetsObj = surveys;
                        this.selectedDiagnoses = diagnoses;
                    }
                }
            });
        }
    }
}
