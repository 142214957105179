import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Document } from '../../../../_shared/model';
import { HelperService, StorageService } from '../../../../_shared/services';
import { HttpClientService } from '../../../../_shared/services/http-client.service';
import { SendInvoiceNotificationInfo } from './../../../../_shared/model/accounting/invoice/sendInvoiceNotificationInfo.model';

@Injectable({
    providedIn: 'root',
})
export class DocumentsService {
    constructor(
        private http: HttpClient,
        private httpClient: HttpClientService,
        private storage: StorageService,
        private helper: HelperService,
    ) {}

    // addContractorDocument()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addFileToContractor(constracorId: number, documentId: string): Observable<any> {
        // /contractors/{contractorId}/documents/{documentId}
        return this.httpClient.post(this.helper.getApiUrl() + '/contractors/' + constracorId + '/documents/' + documentId);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public uploadFile(file: File, comment: string): Observable<any> {
        return this.httpClient.uploadFile(
            this.helper.getDocumentUrl() + '/upload/contractors/' + this.storage.getSelectedContractor().id,
            file,
            comment,
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addFileToVR(reservationId: string, documentId: string, visitId?: number): Observable<any> {
        if (visitId) {
            return this.addFileToVisit(visitId, documentId);
        } else {
            return this.addFileToReservation(reservationId, documentId);
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addFileToVisit(visitId: number, documentId: string): Observable<any> {
        return this.httpClient.post(this.helper.getVisitUrl() + '/visits/' + visitId + '/documents/' + documentId);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addFileToReservation(reservationId: string, documentId: string): Observable<any> {
        return this.httpClient.post(this.helper.getApiUrl() + '/reservations/' + reservationId + '/documents/' + documentId);
    }

    public getDocumentsVR(reservationId: string, visitId?: number): Observable<Document[]> {
        if (visitId) {
            return this.getDocumentsForVisit(visitId);
        } else {
            return this.getDocumentsForReservation(reservationId);
        }
    }

    public getDocumentsForReservation(reservationId: string): Observable<Document[]> {
        return this.httpClient.get(this.helper.getApiUrl() + '/reservations/' + reservationId + '/documents').pipe(
            map(val => {
                return val.documents;
            }),
        );
    }

    public getDocumentsForVisit(visitId: number): Observable<Document[]> {
        return this.httpClient.get(this.helper.getVisitUrl(2) + '/visits/' + visitId + '/documents').pipe(
            map(val => {
                return val.documents;
            }),
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteDocumentVR(prereservationid: string, documentId: string, visitId?: number): Observable<any> {
        if (visitId) {
            return this.deleteDocumentFromVisit(visitId, documentId);
        } else {
            return this.deleteDocumentFromReseration(prereservationid, documentId);
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteDocumentFromReseration(prereservationid: string, documentId: string): Observable<any> {
        return this.httpClient.delete(this.helper.getApiUrl() + '/reservations/' + prereservationid + '/documents/' + documentId);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteDocumentFromVisit(visitId: number, documentId: string): Observable<any> {
        return this.httpClient.delete(this.helper.getVisitUrl() + '/visits/' + visitId + '/documents/' + documentId);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteDocumentFromTelemedChat(contractorId: number, prereservationId: string, documentId: string): Observable<any> {
        return this.httpClient.put(
            this.helper.getTelemedUrl() + `/contractors/${contractorId}/prereservation/${prereservationId}/chat/document/${documentId}/hide`,
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public openDocument(contractorId: number, documentId: string): Observable<any> {
        return this.httpClient.getAttachment(this.helper.getDocumentUrl() + '/contractors/' + contractorId + '/documents/' + documentId);
    }

    // #########CUSTOMER###########

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public printGdprConsent(contractorId: number, customerId: number): Observable<any> {
        return this.httpClient.getAttachment(this.helper.getPrintServerUrl() + '/gdpr/contractors/' + contractorId + '/customers/' + customerId);
    }

    //getCustomerDocuments()
    public getCustomerDocuments(contractorId: number, customerId: number): Observable<Document[]> {
        return this.httpClient.get(this.helper.getApiUrl(2) + '/contractors/' + contractorId + '/customers/' + customerId + '/documents').pipe(
            map(val => {
                return val.documents;
            }),
        );
    }
    // addCustomerDocument()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addFileToCustomer(contractorId: number, customerId: number, documentId: string): Observable<any> {
        return this.httpClient.post(
            this.helper.getApiUrl() + '/contractors/' + contractorId + '/customers/' + customerId + '/documents/' + documentId,
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteCustomerDocument(contractorId: number, customerId: number, documentId: string): Observable<any> {
        return this.httpClient.delete(
            this.helper.getApiUrl() + '/contractors/' + contractorId + '/customers/' + customerId + '/documents/' + documentId,
        );
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getDocumentThumbnails(contractorId: number, documentId: string, height: number, width: number): Observable<any> {
        if (width && !height) {
            return this.httpClient.getAttachment(
                this.helper.getDocumentUrl() + '/contractors/' + contractorId + '/documents/' + documentId + `/thumbnail?width=${width}`,
            );
        } else if (height && !width) {
            return this.httpClient.getAttachment(
                this.helper.getDocumentUrl() + '/contractors/' + contractorId + '/documents/' + documentId + `/thumbnail?height=${height}`,
            );
        } else {
            return this.httpClient.getAttachment(
                this.helper.getDocumentUrl() +
                    '/contractors/' +
                    contractorId +
                    '/documents/' +
                    documentId +
                    `/thumbnail?width=${width}&height=${height}`,
            );
        }
    }

    // /contractors/{contractorid}/notes/{notesid}/documents/{documentid}
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addFileToTreatmenNote(contractorId: number, notesid: number, documentId: string): Observable<any> {
        return this.httpClient.post(this.helper.getTreatmentUrl(3) + `/contractors/${contractorId}/notes/${notesid}/documents/${documentId}`);
    }

    //SHARE
    // /booking/eav2/erp/customerdata
    // "/contractors/{contractorid}/customers/{customerid}/papers/{paperid}/share",
    // "/contractors/{contractorid}/customers/{customerid}/documents/{maskeddocumentid}/share",
    // "/contractors/{contractorid}/customers/{customerid}/invoices/{invoiceid}/share"

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public sharePaper(contractorId: number, customerid: number, paperid: number, receiver: SendInvoiceNotificationInfo): Observable<any> {
        return this.httpClient.post(
            this.helper.getCustomerDataUrl() + `/contractors/${contractorId}/customers/${customerid}/papers/${paperid}/share`,
            receiver,
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public shareDocument(contractorId: number, customerid: number, maskeddocumentid: string, receiver: SendInvoiceNotificationInfo): Observable<any> {
        return this.httpClient.post(
            this.helper.getCustomerDataUrl() + `/contractors/${contractorId}/customers/${customerid}/documents/${maskeddocumentid}/share`,
            receiver,
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public shareInvoices(contractorId: number, customerid: number, invoiceid: number, receiver: SendInvoiceNotificationInfo): Observable<any> {
        return this.httpClient.post(
            this.helper.getCustomerDataUrl() + `/contractors/${contractorId}/customers/${customerid}/invoices/${invoiceid}/share`,
            receiver,
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public shareGroupInvoices(contractorId: number, customerid: number, invoiceid: number, receiver: SendInvoiceNotificationInfo): Observable<any> {
        return this.httpClient.post(
            this.helper.getCustomerDataUrl() + `/contractors/${contractorId}/customers/${customerid}/groupinvoices/${invoiceid}/share`,
            receiver,
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public unShareGroupInvoices(contractorId: number, customerid: number, invoiceid: number): Observable<any> {
        return this.httpClient.put(
            this.helper.getCustomerDataUrl() + `/contractors/${contractorId}/customers/${customerid}/groupinvoices/${invoiceid}/unshare`,
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public unsharePaper(contractorId: number, customerid: number, paperid: number): Observable<any> {
        return this.httpClient.put(
            this.helper.getCustomerDataUrl() + `/contractors/${contractorId}/customers/${customerid}/papers/${paperid}/unshare`,
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public unshareDocument(contractorId: number, customerid: number, maskeddocumentid: string): Observable<any> {
        return this.httpClient.put(
            this.helper.getCustomerDataUrl() + `/contractors/${contractorId}/customers/${customerid}/documents/${maskeddocumentid}/unshare`,
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public unshareInvoices(contractorId: number, customerid: number, invoiceid: number): Observable<any> {
        return this.httpClient.put(
            this.helper.getCustomerDataUrl() + `/contractors/${contractorId}/customers/${customerid}/invoices/${invoiceid}/unshare`,
        );
    }

    // @Put(uris = [
    //     "/contractors/{contractorid}/customers/{customerid}/papers/{paperid}/unshare",
    //     "/contractors/{contractorid}/customers/{customerid}/documents/{maskeddocumentid}/unshare",
    //     "/contractors/{contractorid}/customers/{customerid}/invoices/{invoiceid}/unshare"
    // ])
    // fun unshareData(
    //     contractorid: Long,
    //     customerid: Long,
    //     paperid: Long?,
    //     maskeddocumentid: String?,
    //     invoiceid: Long?,
    //     authentication: Authentication
    // ): HttpResponse<*> {
}
