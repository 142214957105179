import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'assetTypePipe' })
export class AssetTypePipe implements PipeTransform {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(value: any) {
        switch (value) {
            case 'ROOM':
                return 'Prostor';
            case 'DEVICE':
                return 'Naprava';
        }
    }
}
