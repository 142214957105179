import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { BsType } from '../../../../../../_shared/enums/business.enum';
import { PaperType } from '../../../../../../_shared/enums/PaperType.enum';
import { PaperHistoryElement, PortfolioPaper, Result, ResultSet } from '../../../../../../_shared/model';
import { HelperService, MfToastService } from '../../../../../../_shared/services';
import { ResultService } from '../../../_services';
import { PfPCommnComunicationService } from '../_common/pf-p-comn-communication.service';
import { Case } from './../../../../../../_shared/model/schedule/case.model';
import { User } from './../../../../../../_shared/model/user.model';
import { Visit } from './../../../../../../_shared/model/visit.model';
import { BaseState } from './../../../../../../_shared/state/base/base.state';

@Component({
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'pf-p-visitConf',
    templateUrl: './pf-p-visitConf.component.html',
    styles: [],
})
export class PfPVisitConfComponent implements OnInit {
    @Input()
    isNew = false;

    @Input()
    paper: PortfolioPaper;

    @Input()
    visit: Visit;

    public userText: string;
    public inputSetsObj: ResultSet[] = [];
    public term$ = new Subject<string>();
    public selectedContractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);
    public loggedUser: User = this.store.selectSnapshot(BaseState.LoggedUser);
    private onDestroy$ = new Subject<void>();
    private case: Case;
    public bsType: BsType;

    constructor(
        public activeModal: NgbActiveModal,
        // private storage: StorageService,
        private rest: ResultService,
        private helper: HelperService,
        public comm: PfPCommnComunicationService,
        private toast: MfToastService,
        public store: Store,
    ) {}

    ngOnInit() {
        if (this.isNew) {
            this.createReport();
        } else {
            this.loadData(this.paper.visitId, this.paper.id);
        }

        this.communications();
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        this.comm.dirty$.next(false);
        this.onDestroy$.next();
    }

    public openSignedPreview(paper: PortfolioPaper): void {
        this.comm.openSignedPreviewAppend(paper, this.case, 'Potrdilo o obisku').subscribe(
            () => {
                this.toast.success('Dokument dodan k avtorizaciji');
            },
            data => {
                if (data !== 'close-no-error') {
                    this.toast.error('Napaka pri dodajanju dokumenta k avtorizaciji');
                }
            },
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private saveSurvey(): Observable<any> {
        if (this.isNew) {
            return this.rest
                .addSurvey(
                    this.paper.visitId,
                    new Result().deserialize({
                        surveys: this.inputSetsObj,
                        surveyType: PaperType.CONFIRM_VISIT,
                    }),
                )
                .pipe(
                    takeUntil(this.onDestroy$),
                    tap(
                        res => {
                            this.paper = new PortfolioPaper().deserialize({
                                id: res,
                                visitId: this.paper.visitId,
                                paperType: PaperType.CONFIRM_VISIT,
                            });
                            this.comm.onPaperChanged.emit(this.paper);
                            this.toast.success('Spremembe so shranjene.');
                            this.isNew = false;
                            this.comm.dirty$.next(false);
                        },
                        () => {
                            this.toast.errorAndSend('Napaka pri dodajanju novega sklopa.');
                        },
                    ),
                );
            // .subscribe(
            //     (res) => {
            //         this.paper = new PortfolioPaper().deserialize({
            //             id: res,
            //             visitId: this.visit.visitId,
            //             paperType: PaperType.CONFIRM_VISIT,
            //         });
            //         this.comm.onPaperChanged.emit(this.paper);
            //         this.toast.success('Spremembe so shranjene.');
            //         this.isNew = false;
            //     },
            //     () => {
            //         this.toast.errorAndSend('Napaka pri dodajanju novega sklopa.');
            //     },
            // );
        } else {
            //update

            return this.rest
                .updateSurveyPaper(
                    this.paper.visitId,
                    this.paper.id,
                    new Result().deserialize({
                        surveys: this.inputSetsObj,
                        surveyType: PaperType.CONFIRM_VISIT,
                    }),
                )
                .pipe(
                    takeUntil(this.onDestroy$),
                    tap(
                        () => {
                            this.toast.success('Spremembe so shranjene.');
                            this.comm.dirty$.next(false);
                        },
                        () => this.toast.errorAndSend('Napaka pri urejanju sklopa.'),
                    ),
                );
            // .subscribe(
            //     () => {
            //         this.toast.success('Spremembe so shranjene.');
            //     },
            //     () => {
            //         this.toast.errorAndSend('Napaka pri urejanju sklopa.');
            //     },
            // );
        }
    }

    private createReport(): void {
        //TODO  ob ${moment(date).format('HH:mm')},
        const date = this.visit.visitEndDate ? moment(this.visit.visitEndDate) : moment();
        const content = `<p> Potrjujemo, da je ${this.visit.customer.name} ${this.visit.customer.surname}, dne 
            ${date.format('DD. MM. YYYY')} ob ${moment(date).format('HH:mm')}, 
            koristil/a zdravstveno storitev v naši ustanovi. </p> <br> <p> S prijaznimi pozdravi, </p> <br> <p> ${_.get(
                this.visit,
                'contractor.subcontractors[0].name',
                '',
            )} </p>`;
        // console.log(this.visit);

        this.inputSetsObj.push(
            new ResultSet().deserialize({
                title: 'Potrdilo',
                surveyId: '-1',
                createdBy: this.loggedUser.name,
                sortId: 1,
                content: content,
            }),
        );
    }

    private communications(): void {
        this.comm.onCopy.pipe(takeUntil(this.onDestroy$)).subscribe((data: PaperHistoryElement) => {
            this.copyFromHistroy(data);
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onSave.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.saveSurvey().subscribe();
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onPreview.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.openPreview(this.paper, false);
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onPrint.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.openPreview(this.paper, true);
        });

        this.comm.onSignDocument.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
            this.comm.saveSignShareAndShow(
                this.paper,
                data,
                () => this.saveSurvey(),
                casee => (this.case = casee),
                false,
                false,
                this.bsType === BsType.PREVENTIVNI,
            );
        });
    }

    private copyFromHistroy(data: PaperHistoryElement): void {
        this.comm.dirty$.next(true);
        this.loadData(data.visitId, data.paperId);
    }

    public openPreview(paper: PortfolioPaper, print: boolean = false): void {
        this.rest
            .getSurveyPaperPreview(this.visit.visitId, paper.id)
            .pipe(
                takeUntil(this.onDestroy$),
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                map((res: any) => {
                    if (res.type !== 0) {
                        return new Blob([res.body], {
                            type: 'application/pdf',
                        });
                    }
                    // Helpers.setLoading(false);
                }),
            )
            .subscribe(
                res => {
                    if (res) {
                        this.helper.openDocumentViewer(res, res.type, 'Izvid', print);
                    }
                },
                () => {
                    this.toast.errorAndSend('Napaka pri predogledu potrdila.');
                },
            );
    }

    private loadData(visitId: number, paperId: number): void {
        this.rest
            .getSurveyPaper(visitId, paperId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(res => {
                if (res && res.surveys && res.surveys.length > 0) {
                    this.inputSetsObj = res.surveys;
                }
            });
    }
}
