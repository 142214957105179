import { Directive, EventEmitter, Injectable, Output } from '@angular/core';

@Directive()
@Injectable({
    providedIn: 'root',
})
export class QuickSidebarService {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private data: any = {
        isOpen: false,
        value: undefined,
    };

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix, @typescript-eslint/no-explicit-any
    @Output() onChange: EventEmitter<any> = new EventEmitter();
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix, @typescript-eslint/no-explicit-any
    @Output() onChangeSeenNotifications: EventEmitter<any> = new EventEmitter();

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toggle(value?: any) {
        this.data.isOpen = !this.data.isOpen;
        this.data.value = value;
        this.onChange.emit(this.data);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public seenNotificationChange(value?: any): void {
        this.onChangeSeenNotifications.emit(value);
    }
}
