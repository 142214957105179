import { EventEmitter, Injectable } from '@angular/core';
import AircallPhone from 'aircall-everywhere';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HelperService } from './../../../../_shared/services/helper.service';
import { HttpClientService } from './../../../../_shared/services/http-client.service';
import { TphCallEnded } from './model/tph-call-ended.model';

@Injectable({
    providedIn: 'root',
})
export class TelephonyService {
    public showWindow: EventEmitter<string> = new EventEmitter();
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public hiddeWindow: EventEmitter<any> = new EventEmitter();
    public initTelephony: EventEmitter<boolean> = new EventEmitter();
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onIncomingCall: EventEmitter<any> = new EventEmitter();
    public onOutgoingCall: EventEmitter<TphCallEnded> = new EventEmitter();
    public onOutgoingAnswered: EventEmitter<TphCallEnded> = new EventEmitter();
    public onCallEnded: EventEmitter<TphCallEnded> = new EventEmitter();
    public oncCallEndRingtone: EventEmitter<TphCallEnded> = new EventEmitter();
    // public destructorTelephony: EventEmitter<boolean> = new EventEmitter();

    // BehaviorSubject

    private phone: AircallPhone;
    constructor(
        private httpClient: HttpClientService,
        private helper: HelperService,
    ) {}

    public show(phoneNumber?: string): void {
        this.showWindow.next(phoneNumber);
    }

    public hidde(): void {
        this.hiddeWindow.next(true);
    }

    public init(test: boolean = true): void {
        this.initTelephony.next(test);
    }

    // public setCustomer(customer:Customer): void {
    //     this.showWindow.next(true);
    // }

    public initPhone(): void {
        this.phone = new AircallPhone({
            domToLoadPhone: '#mf-phone',
        });
        this.phone.on('call_ended', callInfos => {
            this.onCallEnded.next(new TphCallEnded().deserialize(callInfos));
        });

        this.phone.on('call_end_ringtone', callInfos => {
            this.oncCallEndRingtone.next(callInfos);
        });

        this.phone.on('outgoing_call', callInfos => {
            this.onOutgoingCall.next(callInfos);
        });

        this.phone.on('incoming_call', callInfos => {
            this.onIncomingCall.next(callInfos);
            this.show();
        });

        this.phone.on('outgoing_answered', callInfos => {
            this.onOutgoingAnswered.next(callInfos);
        });
    }

    public isLoggedIn(): boolean {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return this.phone.isLoggedIn(response => {
            return true;
        });
    }

    public callNumber(number: string): void {
        const payload = {
            phone_number: number,
        };

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.phone.send('dial_number', payload);
    }

    public isUserRegistered(cid: number, email: string): Observable<boolean> {
        return this.httpClient.get(this.helper.getAirCallUrl() + `/contractors/${cid}/aircall/users/`).pipe(
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            map((data: any) => {
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return (<[]>data.users).some((el: any) => el.email == email);
            }),
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public registerNewUser(cid: number, acUser: any): Observable<any> {
        return this.httpClient.post(this.helper.getAirCallUrl() + `/contractors/${cid}/aircall/users/`, acUser);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public saveCall(cid: number, callid: number): Observable<any> {
        return this.httpClient.post(this.helper.getAirCallUrl() + `/contractors/${cid}/calls/${callid}`);
    }
}
