import { Injectable } from '@angular/core';
import 'rxjs';
import { Observable } from 'rxjs';
import { PreReservation } from '../../../../_shared/model/preReservation.model';
import { HelperService } from '../../../../_shared/services';
import { HttpClientService } from '../../../../_shared/services/http-client.service';
import { Event } from './../../../../_shared/model/event.model';

@Injectable()
export class TermsService {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    token: any;

    constructor(
        private httpClient: HttpClientService,
        private helper: HelperService,
    ) {}

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getAllSubcontractors(cid: number): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl() + '/contractors/' + cid + '/subcontractors');
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public cancelReservation(prereservation: PreReservation): Observable<any> {
        return this.httpClient.post(this.helper.getApiUrl() + '/reservation/delete', prereservation);
    }
    // getReservationByPrereservationId()
    public getReservation(contractorId: number, prereservationId: string): Observable<Event> {
        return this.httpClient.get(this.helper.getApiUrl() + '/contractors/' + contractorId + '/reservations/' + prereservationId);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public customerNoShow(prereservation: string, notShow: boolean): Observable<any> {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any = {
            noShow: notShow,
        };
        return this.httpClient.put(this.helper.getApiUrl() + '/reservations/' + prereservation, data);
    }

    public getReservationsDocumentationData(
        contractorId: number,
        filterData: { from: string; to: string; telemed?: boolean; subcontractorIds?: number[] },
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Observable<any[]> {
        return this.httpClient.post(this.helper.getApiUrl(2) + `/contractors/${contractorId}/reservationswithdocumentation`, filterData);
    }
    public changeDocumentationStatus(contractorId: number, prereservationid: string, status: 'MISSING' | 'IN_PROGRESS' | 'DONE') {
        return this.httpClient.put(
            this.helper.getApiUrl(2) + `/contractors/${contractorId}/reservations/${prereservationid}/documentationStatus/${status}`,
            {},
        );
    }
    public resetDocumentationStatus(contractorId: number, prereservationid: string) {
        return this.httpClient.delete(
            this.helper.getApiUrl(2) + `/contractors/${contractorId}/reservations/${prereservationid}/documentationStatus`,
            {},
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateCovidSurveys(reservationId: string, contractorId: number, customerId: number, surveysData): Observable<any> {
        // http://localhost:9090/booking/erp/api/reservations/6b397f42-2cab-4411-a70e-951a0562f78f/contractors/54/customers/1262/covid/update
        return this.httpClient.put(
            this.helper.getApiUrl() + `/reservations/${reservationId}/contractors/${contractorId}/customers/${customerId}/covid/update`,
            surveysData,
        );
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getAllCovidSurveys(reservationId: string, contractorId: number): Observable<any> {
        // http://localhost:9090/booking/erp/api/reservations/79a6b20a-af2b-4d89-ba77-e7c5ba6ef101/contractors/54/customers/1262/covid
        return this.httpClient.get(this.helper.getApiUrl(2) + `/reservations/${reservationId}/contractors/${contractorId}/covid`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public sendCovidSurveyToCustomer(reservationId: string, contractorId: number, customerId: number): Observable<any> {
        // http://localhost:9090/booking/erp/api/reservations/79a6b20a-af2b-4d89-ba77-e7c5ba6ef101/contractors/54/customers/1262/covid/send
        return this.httpClient.post(
            this.helper.getApiUrl() + `/reservations/${reservationId}/contractors/${contractorId}/customers/${customerId}/covid/send`,
        );
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public confirmCovidSurveysByCustomer(guid: string): Observable<any> {
        //http://localhost:9090/booking/erp/api/covid/045f174d-6814-40f8-bc01-099857d240c3
        return this.httpClient.post(this.helper.getApiUrl() + `/covid/${guid}`);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getCovidDataFromGuid(guid: string): Observable<any> {
        //localhost:9090/booking/erp/api/covid/045f174d-6814-40f8-bc01-099857d240c3
        return this.httpClient.get(this.helper.getApiUrl(2) + `/covid/${guid}`);
    }
}
