import { Serializable } from './deserialize.service';

export class ResultSet implements Serializable<ResultSet> {
    surveyId: string;
    createdBy: string;
    createdDate: Date;
    content: string;
    title: string;
    sortId: string;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    placeholder: any;

    deserialize(input) {
        this.surveyId = input.surveyId;
        this.createdBy = input.createdBy;
        this.createdDate = input.createdDate;
        this.content = input.content;
        this.title = input.title;
        this.sortId = input.sortId;
        this.placeholder = input.placeholder;
        return this;
    }
}
// public class Comment {
//     public String id;
//     public Date commentAdded;
//     public String comment;
