import { Injectable } from '@angular/core';
import { ReservationType } from '../enums/schedule/reservationType.enum';

@Injectable({
    providedIn: 'root',
})
export class HelperMedicalService {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public mtaReservationTypes: any[] = [
        {
            type: ReservationType.HDP,
        },
        {
            type: ReservationType.HDD,
        },
        {
            type: ReservationType.SAVAP,
        },
        {
            type: ReservationType.SAVAD,
        },
        {
            type: ReservationType.MDFTP,
        },
        {
            type: ReservationType.MDFTD,
        },
        {
            type: ReservationType.LAIFD,
        },
        {
            type: ReservationType.LAIFP,
        },
        {
            type: ReservationType.WIDGET_VOUCHER,
        },
        {
            type: ReservationType.ASISTENT,
        },
    ];

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    public isMTA(type: ReservationType): boolean {
        return this.mtaReservationTypes.map(el => el.type).includes(type);
    }

    public appName(type: ReservationType): string {
        switch (type) {
            case ReservationType.MDFTD:
            case ReservationType.MDFTP:
                return 'Medifit App';
            case ReservationType.HDD:
            case ReservationType.HDP:
                return 'Halo Doktor';
            case ReservationType.SAVAD:
            case ReservationType.SAVAP:
                return 'Zdravnik na daljavo';
            case ReservationType.LAIFD:
            case ReservationType.LAIFP:
                return 'Laif App';
        }
    }

    stripTinyMceTags(htmlContent: string): string {
        // Stripping TinyMce <p> and <br> tags and removing empty lines
        // This is for the purpose of saving cleaner structured data in the database
        return htmlContent?.replace(/>\n+</g, '><')?.replace(/<\/?p>|<br\s*\/?>/gi, '') ?? null;
    }
}
