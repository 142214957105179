import { Serializable } from '../../deserialize.service';

export class SendInvoiceNotificationInfo implements Serializable<SendInvoiceNotificationInfo> {
    receiver: string;
    phone: string;
    email: string;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        this.receiver = input.receiver;
        this.phone = input.phone;
        this.email = input.email;
        return this;
    }
}
