import { Serializable } from '../deserialize.service';

export class PrintPOSFooterMessage implements Serializable<PrintPOSFooterMessage> {
    public value: string;
    public footerNote: string;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        this.value = input.value;
        this.footerNote = input.footerNote;

        return this;
    }
}
