import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { from, Observable } from 'rxjs';
import { PfPFthComponent } from '../../../theme/pages/home/portfolio-papers/papers/pf-p-fth/pf-p-fth.component';
import { PfPGenericReferralComponent } from '../../../theme/pages/home/portfolio-papers/papers/pf-p-genericReferral/pf-p-genericReferral.component';
import { PfPMdpsCertificateComponent } from '../../../theme/pages/home/portfolio-papers/papers/pf-p-mdps-certificate/pf-p-mdps-certificate.component';
import { PfPMdpsSurveyComponent } from '../../../theme/pages/home/portfolio-papers/papers/pf-p-mdps-survey/pf-p-mdps-survey.component';
import { PfPMfmReportComponent } from '../../../theme/pages/home/portfolio-papers/papers/pf-p-mfm-report/pf-p-mfm-report.component';
import { PfPMFMSoapSurveyComponent } from '../../../theme/pages/home/portfolio-papers/papers/pf-p-mfm-soapSurvey/pf-p-mfm-hdSurvey.component';
import { PfPOrderFormDiagComponent } from '../../../theme/pages/home/portfolio-papers/papers/pf-p-orderFormDiag/pf-p-orderFormDiag.component';
import { PfPOrderFormLabComponent } from '../../../theme/pages/home/portfolio-papers/papers/pf-p-orderFormLab/pf-p-orderFormLab.component';
import { PfPSoapSurveyComponent } from '../../../theme/pages/home/portfolio-papers/papers/pf-p-soapSurvey/pf-p-hdSurvey.component';
import { PfPSurveyComponent } from '../../../theme/pages/home/portfolio-papers/papers/pf-p-survey/pf-p-survey.component';
import { PfPTermConfComponent } from '../../../theme/pages/home/portfolio-papers/papers/pf-p-termConf/pf-p-termConf.component';
import { PfPVisitConfComponent } from '../../../theme/pages/home/portfolio-papers/papers/pf-p-visitConf/pf-p-visitConf.component';
import { PfPWhitereferralComponent } from '../../../theme/pages/home/portfolio-papers/papers/pf-p-whitereferral/pf-p-whitereferral.component';
import { PfType } from '../../../theme/pages/home/portfolio-papers/_common/enum/pf-type.enum';
import { PfComnPopupComponent } from '../../../theme/pages/home/portfolio-papers/_common/pf-comn-popup/pf-comn-popup.component';
import { Event } from '../../../_shared/model/event.model';
import { PaperType } from '../../enums/PaperType.enum';
import { PaperNamePipe } from '../../pipes/paper-name.pipe';
import { Customer } from '../customer.model';
import { Case } from '../schedule/case.model';
import { PortfolioPaper } from './portfolioPaper.model';

export class PopupPaper {
    private modalService: NgbModal;
    private paperNamePipe: PaperNamePipe;

    constructor(modalService: NgbModal, paperNamePipe: PaperNamePipe) {
        this.modalService = modalService;
        this.paperNamePipe = paperNamePipe;
    }

    public show(type: PaperType, data: PopupPaperData, paper?: PortfolioPaper): Observable<any> {
        const isNew: boolean = _.isNil(paper);

        const modalPortfolioPopup = this.modalService.open(PfComnPopupComponent, {
            backdrop: 'static',
            keyboard: false,
            windowClass: 'mf-transparent-popup mf-gray-background-popup mf-screen-height-popup mf-popup-with__title',
            size: 'lg',
        });
        if (data.prereservationId) {
            modalPortfolioPopup.componentInstance.id = data.prereservationId;
        }
        if (data.termData) {
            modalPortfolioPopup.componentInstance.termData = data.termData;
        }
        if (data.sourceVisit) {
            modalPortfolioPopup.componentInstance.sourceVisit = data.sourceVisit;
        }

        modalPortfolioPopup.componentInstance.isNew = isNew;
        modalPortfolioPopup.componentInstance.paper = isNew
            ? new PortfolioPaper().deserialize({
                  paperType: type,
                  visitId: data.termData?.visitId,
                  prereservationId: data.prereservationId,
              })
            : paper;
        modalPortfolioPopup.componentInstance.title = this.paperNamePipe.transform(type);
        modalPortfolioPopup.componentInstance.case = data.case;
        modalPortfolioPopup.componentInstance.event = data.termData;
        modalPortfolioPopup.componentInstance.customer = data.customer;
        modalPortfolioPopup.componentInstance.pfType = data.pfType;

        switch (type as PaperType) {
            case PaperType.IZVID:
            case PaperType.SURVEY_CUSTOMER:
                modalPortfolioPopup.componentInstance.selectedType = PfPSurveyComponent;
                break;
            case PaperType.FTH_POROCILO:
                modalPortfolioPopup.componentInstance.selectedType = PfPFthComponent;
                break;
            case PaperType.WHITE_REFERRAL:
                modalPortfolioPopup.componentInstance.selectedType = PfPWhitereferralComponent;
                break;
            case PaperType.CONFIRM_VISIT:
                modalPortfolioPopup.componentInstance.selectedType = PfPVisitConfComponent;
                break;
            case PaperType.CONFIRM_RESERVATION:
                modalPortfolioPopup.componentInstance.selectedType = PfPTermConfComponent;
                break;
            case PaperType.HELLODOC_REFERRAL:
                modalPortfolioPopup.componentInstance.selectedType = PfPGenericReferralComponent;
                break;
            case PaperType.HELLODOC_IZVID:
                modalPortfolioPopup.componentInstance.selectedType = PfPSoapSurveyComponent;
                break;
            case PaperType.MFMEDICAL_IZVID:
                modalPortfolioPopup.componentInstance.selectedType = PfPMFMSoapSurveyComponent;
                break;
            case PaperType.MEDIFIT_MEDICAL_REPORT:
                modalPortfolioPopup.componentInstance.selectedType = PfPMfmReportComponent;
                break;
            case PaperType.HELLODOC_ORDERFORM_LAB:
                modalPortfolioPopup.componentInstance.selectedType = PfPOrderFormLabComponent;
                break;
            case PaperType.HELLODOC_ORDERFORM_DIAG:
                modalPortfolioPopup.componentInstance.selectedType = PfPOrderFormDiagComponent;
                break;
            case PaperType.WORK_CERTIFICATE:
            case PaperType.DRIVER_CERTIFICATE:
            case PaperType.DRIVER_CERTIFICATE_SPECIAL:
            case PaperType.WEAPON_CERTIFICATE:
                modalPortfolioPopup.componentInstance.selectedType = PfPMdpsCertificateComponent;
                break;
            case PaperType.NAPREDNI_IZVID:
                modalPortfolioPopup.componentInstance.selectedType = PfPMdpsSurveyComponent;
                break;
            default:
                break;
        }

        return from(modalPortfolioPopup.result);
    }
}

export interface PopupPaperData {
    prereservationId?: string;
    termData?: Event;
    sourceVisit?: any;
    case?: Case;
    customer?: Customer;
    pfType?: PfType;
}
