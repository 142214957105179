import * as _ from 'lodash';
import { Serializable } from './deserialize.service';

export class Service implements Serializable<Service> {
    id: number;
    name: string;
    description: string;
    length: number;
    price: number;
    internalId: string;
    tax: number;
    isDirectReservation: boolean;
    isCoupon: boolean;
    directBooking: boolean;
    medicalService: boolean;
    color: string;
    telemed: boolean;
    phoneCall: boolean;
    origin: string;
    active: boolean;
    type: string;
    forms: string;
    deserialize(input) {
        if (_.isNil(input)) {
            return undefined;
        }
        if (input.id) {
            this.id = parseInt(input.id);
        }
        this.name = input.name;
        this.description = input.description;
        this.length = input.length;
        this.price = input.price;
        this.internalId = input.internalId;
        this.tax = input.tax;
        this.isDirectReservation = input.isDirectReservation;
        this.isCoupon = input.isCoupon;
        this.directBooking = input.directBooking;
        this.medicalService = input.medicalService;
        this.color = input.color;
        this.telemed = input.telemed;
        this.phoneCall = input.phoneCall;
        this.origin = input.origin;
        this.active = input.active;
        this.type = input.type;
        this.forms = input.forms;
        return this;
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(obj?: any) {
        this.id = (obj && obj.id) || null;
        this.name = (obj && obj.name) || null;
        this.description = (obj && obj.description) || [];
        this.length = (obj && obj.length) || [];
        this.price = (obj && obj.price) || [];
        this.internalId = (obj && obj.internalId) || [];
        this.tax = (obj && obj.tax) || [];
        this.isDirectReservation = (obj && obj.isDirectReservation) || false;
        this.isCoupon = (obj && obj.isCoupon) || false;
        this.directBooking = (obj && obj.directBooking) || false;
        this.medicalService = (obj && obj.medicalService) || false;
        this.color = (obj && obj.color) || '#ffffff';
        this.telemed = (obj && obj.telemed) || false;
        this.forms = (obj && obj.forms) || null;
    }
}
