import { ExaminationType } from '../../../../../../../../_shared/enums/examination/examination-type.enum';
import { ReservationExaminationStatus } from '../../../../../../../../_shared/enums/examination/reservation-examination-status.enum';
import {
    ExaminationResponseDto,
    ReservationExaminationResponseDto,
} from '../../../../../../../../_shared/model/examinations/examination-response-dto';
import { ExaminationViewItem } from './examination-view-item';

export class AssignedExaminationsManager {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    public mapToViewItems(contractorExaminations: ExaminationResponseDto[], examinationsForReservation: ReservationExaminationResponseDto[]) {
        const items = contractorExaminations?.map(resItem => {
            const match = examinationsForReservation.find(cItem => cItem.examination.id === resItem.id);
            return {
                id: resItem.id,
                name: resItem.name,
                canEdit: match?.status == null || match?.status === ReservationExaminationStatus.EMPTY,
                checked: match != null,
            } as ExaminationViewItem;
        });
        return items;
    }

    public getDiffItemsToCreate(examinationsForReservation: ReservationExaminationResponseDto[], selectedItems: ExaminationViewItem[]): number[] {
        return selectedItems
            .filter(selectedItem => !examinationsForReservation.some(examinationForReservation => examinationForReservation.id === selectedItem.id))
            .map(item => item.id);
    }

    public getDiffItemsToDelete(examinationsForReservation: ReservationExaminationResponseDto[], selectedItems: ExaminationViewItem[]): number[] {
        return examinationsForReservation
            .filter(
                examinationForReservation =>
                    !selectedItems.some(selectedItem => {
                        return (
                            selectedItem.id === examinationForReservation.id ||
                            examinationForReservation.examination.type === ExaminationType.EXTERNAL_LABIS
                        );
                    }),
            )
            .map(item => item.examination.id);
    }
}
