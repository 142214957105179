import { Serializable } from './../deserialize.service';
import { PrereservationId } from './../schedule/prereservationId.model';

export class ConfirmReservationsRequest implements Serializable<ConfirmReservationsRequest> {
    prereservations: PrereservationId[];
    appUserId: number;
    appUserName: string;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        return Object.assign(this, input);
    }
}
