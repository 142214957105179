import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { VisitService } from '../../theme/pages/home/_services';
import { AppModulesType } from '../enums/appModulesType.enum';
import { OriginUrl } from '../enums/originUrl.enum';
import { ReservationType } from '../enums/schedule/reservationType.enum';
import { Case, Event } from '../model';
import { ActiveModulesService } from './activeModules.service';
import { HelperMedicalService } from './helper-medical.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class HelperNavigateService {
    constructor(
        private route: Router,
        private storage: StorageService,
        private visitRest: VisitService,
        private am: ActiveModulesService,
        private mta: HelperMedicalService,
    ) {}

    public navigateToReservaton(prereservationId: string, isHD?: boolean, telemedOrPhone?): void {
        if (isHD && telemedOrPhone?.reservationType !== ReservationType.EA && (telemedOrPhone?.telemed || telemedOrPhone?.phoneCall)) {
            this.route.navigate([`calendar/selected/hd/${prereservationId}`]);
        } else {
            this.route.navigate([`calendar/selected/${prereservationId}`]);
        }
    }

    public navigateToVisit(aggregateId: string, isHD?: boolean, telemedOrPhone?): void {
        this.visitRest.getVisit(this.storage.getSelectedContractor().id, aggregateId).subscribe(res => {
            this.storage.setSelectedTerm(res);
            if (!res.prereservationId) {
                this.route.navigate([`calendar/selected/${res.visitId}/visit`]);
            } else {
                if (isHD && telemedOrPhone.reservationType !== ReservationType.EA && (telemedOrPhone.telemed || telemedOrPhone.phoneCall)) {
                    this.route.navigate([`calendar/selected/hd/${res.prereservationId}`]);
                } else {
                    this.route.navigate([`calendar/selected/${res.prereservationId}`]);
                }
            }
        });
    }

    public navigateToVisitPrereservation(prereservationId: string): void {
        this.route.navigate([`calendar/selected/${prereservationId}`]);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public navigateToVisitOrReservation(dataIn: any, forceReload?: boolean): void {
        const data = new Event().deserialize(dataIn);
        if (!data.case) {
            data.case = new Case().deserialize({
                caseId: dataIn.caseId,
                bsid: dataIn.bsid,
            });
        }

        //Forsira reload vseh komponent v urlju in posledično novi api klici s pravimi podatki
        if (forceReload) {
            this.route.routeReuseStrategy.shouldReuseRoute = function () {
                return false;
            };
        }

        this.storage.setSelectedTerm(data);
        if (data.prereservationId) {
            if (
                this.am.isAM(AppModulesType.HELLO_DOCTOR) &&
                (this.mta.isMTA(data?.reservationType) ||
                    data?.originUrl == OriginUrl.G100_01 ||
                    data?.originUrl == OriginUrl.G100_03 ||
                    data?.originUrl == OriginUrl.LAIF) &&
                (data?.telemed || data?.phoneCall || data?.service?.telemed || data?.service?.phoneCall)
            ) {
                this.route.navigate([`calendar/selected/hd/${data.prereservationId}`]);
            } else {
                this.route.navigate([`calendar/selected/${data.prereservationId}`]);
            }
        } else {
            this.route.navigate([`calendar/selected/${data.visitId}/visit`]);
        }
    }

    public getCaseUrl(): string {
        if (this.am.isAM(AppModulesType.INBOX)) {
            return 'inbox/case/i/';
        }
        return 'case/';
    }

    public openCustomer(customerId: number): void {
        if (customerId) {
            this.route.navigate(['/customers/customers/view', customerId]);
        }
    }
}
