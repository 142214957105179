import { Serializable } from '../deserialize.service';

export class PrintPOSItemsHeader implements Serializable<PrintPOSItemsHeader> {
    public id: string;
    public payer: string;
    public description: string;
    public quantity: number;
    //price per item
    public pricePI: number;
    public priceSum: number;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        this.id = input.id;
        this.payer = input.payer;
        this.description = input.description;
        this.quantity = input.quantity;
        this.pricePI = input.pricePI;
        this.priceSum = input.priceSum;
        return this;
    }
}
