import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'app-incomingCall-HD-popup',
    templateUrl: './incomingCall-HD-popup.component.html',
    styles: [],
})
export class IncomingCallHDPopupComponent implements OnInit {
    constructor(public activeModal: NgbActiveModal) {}
    public customer;
    public reservationType;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit() {}

    public acceptCall() {
        this.activeModal.dismiss({ action: 'ANSWER' });
    }

    public declineCall() {
        this.activeModal.dismiss({ action: 'DECLINE' });
    }
}
