import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'customerNamePipe',
})
export class CustomerNamePipe implements PipeTransform {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(name: any): any {
        switch (name) {
            case null:
                return 'Ime ni na voljo';
            default:
                return name;
        }
    }
}
