<form [formGroup]="gfForm" (ngSubmit)="onSubmit()" novalidate autocomplete="off">
    <div class="row mf-wr-wrapper">
        <div class="col-md col-xl">
            <!-- PRVI STOLPEC  -->
            <!-- ZDRAVNIK -->
            <pf-p-wr-subcontractor *ngIf="subcontractor" [subcontracor]="subcontractor"></pf-p-wr-subcontractor>
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">Naziv napotne organizacije:</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body d-flex pl-1">
                    <img src="https://www.medifit.si/wp-content/uploads/2018/10/medifit-logo.png" height="35px" />
                </div>
            </div>
            <input type="button" style="display: none" />
            <div class="m-portlet">
                <!-- KRAJ IN DNE -->
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">Kraj in dne</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body d-flex">
                    <div class="d-flex w-100">
                        <span style="line-height: 32px" class="pr-2">
                            <i class="la la-map-marker m--font-inverse-light"></i>
                        </span>
                        <input
                            formControlName="referralPlace"
                            tabindex="3"
                            type="text"
                            autocomplete="off"
                            placeholder="Kraj"
                            class="form-control form-control-sm m-input"
                        />
                    </div>
                    <div class="d-flex w-100">
                        <span style="line-height: 32px" class="pr-2">
                            <i class="la la-calendar m--font-inverse-light"></i>
                        </span>
                        <input
                            [options]="calendarOptions"
                            (selected)="onDateChange($event, 'referralDate')"
                            formControlName="referralDateString"
                            tabindex="3"
                            type="text"
                            autocomplete="off"
                            placeholder="Dne"
                            daterangepicker
                            class="form-control form-control-sm m-input"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md col-xl">
            <!-- DRUGI STOLPEC -->
            <!-- STRANKA -->
            <pf-p-wr-customer [customer]="customer" [title]="'Podatki stranke'"></pf-p-wr-customer>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <!-- PREISKAVE -->
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">Laboratorijske preiskave</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body">
                    <div class="">
                        <div class="form-group m-form__group">
                            <ng-select
                                [items]="allServices"
                                [multiple]="true"
                                [compareWith]="compareFunction"
                                groupBy="description"
                                formControlName="services"
                                bindLabel="name"
                                placeholder="Izberi preiskave"
                            ></ng-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">Opis</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body">
                    <editor [init]="tmceSettings" formControlName="referralText2"></editor>
                </div>
            </div>
        </div>
    </div>
</form>
