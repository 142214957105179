import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import _ from 'lodash';
import * as moment from 'moment';
import { NgxPermissionsService } from 'ngx-permissions';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { AppModulesType } from '../../../../_shared/enums/appModulesType.enum';
import { Customer } from '../../../../_shared/model';
import { MfToastService, StorageService } from '../../../../_shared/services';
import { ActiveModulesService } from '../../../../_shared/services/activeModules.service';
import { CommonService, CustomerService } from '../../../pages/home/_services';
import { CustomerMultipleNewUserComponent } from '../customer-multiple-new-user/customer-multiple-new-user.component';
import { CustomerGuardian } from './../../../../_shared/model/customer/customer-guardian.model';
import { HelperService } from './../../../../_shared/services';
import { BaseState } from './../../../../_shared/state/base/base.state';

@Component({
    selector: 'cust-customer-details',
    templateUrl: './customer-details.component.html',
    styleUrls: ['./customer-details.component.scss'],
})
export class CustomerDetailsComponent implements OnInit {
    @Input()
    customerForEdit: Customer;

    @Input()
    set setCustomer(customerForEdit: Customer) {
        this.customerForEdit = customerForEdit;
        this.init();
    }

    @Input()
    isNewCustomer = false;

    @Input()
    isReadOnly = false;

    @Output()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onSaveCustomer: EventEmitter<boolean>;

    @Output()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onChangeInput: EventEmitter<boolean> = new EventEmitter();

    public customerDetailsForm: FormGroup;
    public customer2: Customer;
    public customerGuardian: CustomerGuardian;
    public isPermissionsLoaded = false;
    public isSubmited = false;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public dateOfBirthMask: any = [/[0-3]/, /\d/, '.', /[0-1]/, /\d/, '.', /[1-2]/, /\d/, /\d/, /\d/];
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public zipCodes: any = [];
    private contractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);
    public isMdpsModuleActive = false;
    // private contractorId:number  = this.store.
    // public selectedPost = this.zipCodes;
    // public selectedCity = this.selectedPost;

    // public dateOfBirthMask: any = [/[0-3]/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];

    constructor(
        private storage: StorageService,
        private commonRest: CommonService,
        private fb: FormBuilder,
        private rest: CustomerService,
        public toastr: MfToastService,
        private permissionsService: NgxPermissionsService,
        private datepipe: DatePipe,
        private mfToast: MfToastService,
        private modalService: NgbModal,
        private helper: HelperService,
        private store: Store,
        private am: ActiveModulesService,
        private activeModal: NgbActiveModal,
    ) {
        this.onSaveCustomer = new EventEmitter();
    }
    ngOnInit() {
        this.isMdpsModuleActive = this.am.isAM(AppModulesType.OCCUPATIONAL_MEDICINE);

        if (!this.isReadOnly) {
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.commonRest.getZipCodes().subscribe((res: any[]) => {
                this.zipCodes = res;
            });
        }
        this.init();
    }

    private init(): void {
        if (!this.isNewCustomer) {
            if (this.customerForEdit) {
                this.customer2 = this.customerForEdit;
            } else {
                this.customer2 = this.storage.getSelectedCustomer();
            }

            if (!this.customer2) {
                return;
            }
            if (this.customer2?.id) {
                this.rest
                    .getCustomerGuardians(this.contractorId, this.customer2.id)
                    .pipe(tap((cg: CustomerGuardian[] = []) => (this.customerGuardian = cg.shift())))
                    .subscribe(() => {
                        this.createForm();
                    });
            } else {
                this.createForm();
            }
        } else {
            this.createForm();
        }
    }

    test(): void {
        this.customerDetailsForm;
    }

    public createForm(): void {
        if (this.isNewCustomer) {
            this.customer2 = new Customer().deserialize({});
            this.customer2.sendMail = true;
            this.customer2.sendSms = true;
        }

        this.permissionsService.hasPermission('customer_details_canEdit').then(isEditable => {
            // ce ni editable pomeni da nima pravice, ce pa je editebla pa lahko spreminjamo
            if (isEditable) {
                isEditable = !this.isReadOnly;
            }

            this.isPermissionsLoaded = true;
            this.customerDetailsForm = this.fb.group({
                name: this.fb.control({ value: this.customer2.name, disabled: !isEditable }, Validators.required),
                surname: this.fb.control({ value: this.customer2.surname, disabled: !isEditable }, Validators.required),
                birthDate: this.fb.control(
                    {
                        value: this.datepipe.transform(this.customer2.birthDate, 'dd.MM.yyyy'),
                        disabled: !isEditable,
                    },
                    [Validators.pattern(/^\d{2}\.\d{2}\.\d{4}$|^$/)],
                ),
                address: this.fb.control({ value: this.customer2.address, disabled: !isEditable }),
                post: this.fb.control({ value: this.customer2.post, disabled: !isEditable }),
                city: this.fb.control({ value: this.customer2.city, disabled: !isEditable }),
                email: this.fb.control({ value: this.customer2.email, disabled: !isEditable }),
                phone: this.fb.control({ value: this.customer2.phone, disabled: !isEditable }, [Validators.required, Validators.minLength(9)]),
                sendMail: this.fb.control({ value: this.customer2.sendMail, disabled: !isEditable }),
                sendSms: this.fb.control({ value: this.customer2.sendSms, disabled: !isEditable }),
                customerId: this.fb.group(
                    {
                        kzz: this.fb.control({ value: this.customer2.kzz, disabled: !isEditable }, [
                            Validators.maxLength(9),
                            Validators.minLength(9),
                            Validators.pattern('^[0-9]*$'),
                        ]),
                        emso: this.fb.control({ value: this.customer2.emso, disabled: !isEditable }, [
                            Validators.maxLength(13),
                            Validators.minLength(13),
                            Validators.pattern('^[0-9]*$'),
                        ]),
                        taxNo: this.fb.control({ value: this.customer2.taxNo, disabled: !isEditable }, [
                            Validators.maxLength(8),
                            Validators.minLength(8),
                            Validators.pattern('^[0-9]*$'),
                        ]),
                        jobTitle: this.fb.control({ value: this.customer2.jobTitle, disabled: !isEditable }, []),
                        jobCode: this.fb.control({ value: this.customer2.jobCode, disabled: !isEditable }, [
                            Validators.maxLength(4),
                            Validators.minLength(4),
                            Validators.pattern('^[0-9]*$'),
                        ]),
                    },
                    // ,
                    // {
                    //     validator: AtLeastOneFieldValidator
                    // }
                ),
                idCustomer: this.fb.control({ value: this.customer2.id, disabled: true }),
                gender: this.fb.control({ value: this.customer2.gender, disabled: !isEditable }),
                customerAdministrator: this.fb.group({
                    isActive: this.fb.control({ value: this.customerGuardian != undefined, disabled: !isEditable }),
                    name: this.fb.control(
                        { value: this.customerGuardian?.name, disabled: !isEditable },
                        this.conditionallyRequiredValidator,
                        // Validators.required,
                    ),
                    surname: this.fb.control(
                        { value: this.customerGuardian?.surname, disabled: !isEditable },
                        this.conditionallyRequiredValidator,
                        // Validators.required,
                    ),
                    email: this.fb.control({ value: this.customerGuardian?.email, disabled: !isEditable }),
                    phone: this.fb.control({ value: this.customerGuardian?.phone, disabled: !isEditable }, [
                        this.conditionallyRequiredValidator,
                        // Validators.required,
                        Validators.minLength(9),
                    ]),
                }),
            });

            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            this.customerDetailsForm.valueChanges.subscribe(res => {
                this.onChangeInput.next(true);
            });
            const customerGuardianForm = this.customerDetailsForm.get('customerAdministrator');
            customerGuardianForm.get('isActive').valueChanges.subscribe(() => {
                customerGuardianForm.get('name').updateValueAndValidity();
                customerGuardianForm.get('surname').updateValueAndValidity();
                customerGuardianForm.get('phone').updateValueAndValidity();
            });
        });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onSubmitNewCustomer(forceSave: boolean): Observable<any> {
        this.isSubmited = true;
        if (!this.customerDetailsForm.valid) {
            return EMPTY;
        }
        const formModel = this.customerDetailsForm.value;
        formModel.name = formModel?.name
            ?.trim()
            ?.split(' ')
            ?.map(name => {
                return (name[0]?.toUpperCase() ? name[0]?.toUpperCase() : '') + name?.substring(1);
            })
            .join(' ');
        formModel.surname = formModel?.surname
            ?.trim()
            ?.split(' ')
            ?.map(name => {
                return (name[0]?.toUpperCase() ? name[0]?.toUpperCase() : '') + name?.substring(1);
            })
            .join(' ');
        formModel.emso = formModel.customerId.emso;
        formModel.kzz = formModel.customerId.kzz;
        formModel.taxNo = formModel.customerId.taxNo;
        formModel.jobTitle = formModel.customerId.jobTitle;
        formModel.jobCode = formModel.customerId.jobCode;

        if (formModel.birthDate) {
            formModel.birthDate = moment(formModel.birthDate, 'DD.MM.YYYY').toDate();
        }
        const customer = new Customer().deserialize(formModel);
        const guardian = new CustomerGuardian().deserialize(formModel?.customerAdministrator);

        return this.rest.createCustomer(customer, forceSave).pipe(
            map(
                res => {
                    if (res?.customers?.length > 0) {
                        this.multipleNewUser(customer, res.customers);
                        return undefined;
                    } else {
                        this.toastr.success('Stranka je uspešno dodana.');
                        formModel.id = +res.aggregateId;
                        this.customerDetailsForm.markAsPristine();
                        return formModel;
                    }
                    // this.activeModal.dismiss(new Customer().deserialize(formModel));
                },
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                error => {
                    this.mfToast.errorAndSend('Napaka pri dodajanju nove stranke.');
                },
            ),
            filter(res => {
                return res != undefined;
            }),
            mergeMap(c => {
                if (!formModel?.customerAdministrator?.isActive) {
                    return of(c);
                }
                return this.rest.createCustomer(guardian, true).pipe(
                    mergeMap(g => {
                        guardian.id = g?.aggregateId;
                        return this.rest.appCustomerGuardian(this.contractorId, c?.id, guardian).pipe(
                            map(() => {
                                return c;
                            }),
                        );
                    }),
                );
            }),
        );
    }

    public fillCity(e) {
        if (e.city) {
            this.customerDetailsForm.controls.city.setValue(e.city);
        } else {
            //če je mesto iz šifranta, pusti pri miru, če ni pa reset field
            const findCity = this.zipCodes.find(el => el.city == this.customerDetailsForm.value.city);
            if (findCity) {
                this.customerDetailsForm.controls.city.setValue(e.city);
            }
        }
    }
    public fillPost(e) {
        if (e.zipCode) {
            this.customerDetailsForm.controls.post.setValue(e.zipCode);
        } else {
            //če je pošta iz šifranta, pusti pri miru, če ni pa reset field
            const findPost = this.zipCodes.find(el => el.zipCode == this.customerDetailsForm.value.post);
            if (findPost) {
                this.customerDetailsForm.controls.post.setValue(e.zipCode);
            }
        }
    }
    private multipleNewUser(customerNew: Customer, customers: Customer[]): void {
        this.toastr.info('V sistemu je vnešena stranka s podobnimi podatki.');
        const modalMultipleCustomers = this.modalService.open(CustomerMultipleNewUserComponent, {
            backdropClass: 'mf-second-modal-backdrop',
            size: 'lg',
            windowClass: 'mf-transparent-popup',
        });

        modalMultipleCustomers.result.then(
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
            result => {},
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            newCustomer => {
                if (newCustomer) {
                    this.toastr.info('V sistemu je vnešena stranka s podobnimi podatki, uporabite iskalnik strank.');
                    this.activeModal.dismiss();
                } else {
                    this.onSaveCustomer.emit();
                }
            },
        );
        modalMultipleCustomers.componentInstance.customerNew = customerNew;
        modalMultipleCustomers.componentInstance.customersOld = customers;
        modalMultipleCustomers.componentInstance.existingCustomer = true;
    }

    // backdrop: 'static',
    // keyboard: false,
    // size: 'lg',
    // windowClass: 'mf-transparent-popup'

    public trimValues(data) {
        data.email != undefined ? (data.email = data.email.trim()) : undefined;
        data.emso != undefined ? (data.emso = data.emso.trim()) : undefined;
        data.phone != undefined ? (data.phone = data.phone.trim()) : undefined;
        data.name = data?.name
            ?.trim()
            ?.split(' ')
            ?.map(name => {
                return (name[0]?.toUpperCase() ? name[0]?.toUpperCase() : '') + name?.substring(1);
            })
            .join(' ');
        data.surname = data?.surname
            ?.trim()
            ?.split(' ')
            ?.map(name => {
                return (name[0]?.toUpperCase() ? name[0]?.toUpperCase() : '') + name?.substring(1);
            })
            .join(' ');
        for (const value in data.customerId) {
            if (data.customerId[value]) {
                data.customerId[value] = data.customerId[value].trim();
            }
        }
        return data;
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onSubmitUpdateCustomerDetails(): Observable<any> {
        this.isSubmited = true;
        if (!this.customerDetailsForm.valid) {
            return EMPTY;
        }
        //dodam 12 ur zardi ceasovnega pasu (je dodal 1 h in se je sprmenil dan...)
        if (this.customerDetailsForm.value.birthDate) {
            this.customerDetailsForm.value.birthDate = moment(this.customerDetailsForm.value.birthDate, 'DD.MM.YYYY').add(12, 'h').toDate();
        }
        const updateSubContractor = this.customerDetailsForm.value;
        // updateSubContractor.emso != undefined ? _.get(updateSubContractor, 'customerId.emso').trim() : undefined;
        // updateSubContractor.kzz != undefined ? _.get(updateSubContractor, 'customerId.kzz').trim() : undefined;
        // updateSubContractor.taxNo != undefined ? _.get(updateSubContractor, 'customerId.taxNo').trim() : undefined;

        updateSubContractor.emso = updateSubContractor?.customerId?.emso?.trim();
        updateSubContractor.kzz = updateSubContractor?.customerId?.kzz?.trim();
        updateSubContractor.taxNo = updateSubContractor?.customerId?.taxNo?.trim();
        updateSubContractor.jobTitle = updateSubContractor?.customerId?.jobTitle?.trim();
        updateSubContractor.jobCode = updateSubContractor?.customerId?.jobCode?.trim();

        updateSubContractor.gender != undefined ? _.get(updateSubContractor, 'gender') : undefined;
        if (updateSubContractor.phone) {
            updateSubContractor.phone = updateSubContractor.phone.trim();
        }
        if (updateSubContractor.email) {
            updateSubContractor.email = updateSubContractor.email.trim();
        }

        // if (!updateSubContractor.createdFrom) {
        //     updateSubContractor.createdFrom = CusomerCreatedFromType.EAMBULANTA;
        // }
        // this.customerForEdit;
        // this.customerGuardian;
        return this.rest
            .updateCustomer(
                this.storage.getSelectedContractor().id,
                this.customer2.id,
                new Customer().deserialize(this.trimValues(this.customerDetailsForm.value)),
            )
            .pipe(
                catchError(err => {
                    this.mfToast.error(this.helper.parseMFError(err));
                    return throwError(err);
                }),
                map(
                    () => {
                        this.storage.setSelectedCustomer(new Customer().deserialize(updateSubContractor));
                        this.toastr.success('Urejanje uspešno.');
                        const updatedCustomer = new Customer().deserialize(updateSubContractor);
                        updatedCustomer.id = this.customer2.id;
                        this.customerDetailsForm.markAsPristine();
                        return updatedCustomer;
                    },
                    // TODO Ignored with eslint-interactive on 2023-11-10
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    error => {
                        this.mfToast.errorAndSend('Napaka pri urejanju stranke.');
                    },
                ),
                mergeMap((res: Customer) => {
                    if (!this.customerGuardian && updateSubContractor?.customerAdministrator?.isActive) {
                        //ADD new customerguardian
                        const guardian = new CustomerGuardian().deserialize(updateSubContractor?.customerAdministrator);
                        return this.rest.createCustomer(guardian, true).pipe(
                            mergeMap(g => {
                                guardian.id = g?.aggregateId;
                                this.customerGuardian = guardian;
                                return this.rest.appCustomerGuardian(this.contractorId, res?.id, guardian);
                            }),
                        );
                    } else if (this.customerGuardian && updateSubContractor?.customerAdministrator?.isActive) {
                        //UPDATE customerguardian
                        const guardian = new CustomerGuardian().deserialize(updateSubContractor?.customerAdministrator);
                        return this.rest.updateCustomer(this.contractorId, this.customerGuardian.id, this.trimValues(guardian)).pipe(map(() => res));
                    } else if (this.customerGuardian && !updateSubContractor?.customerAdministrator?.isActive) {
                        //DELETE connection
                        return this.rest
                            .deleteCustomerGuardians(this.contractorId, this.customer2.id, this.customerGuardian.id)
                            .pipe(mergeMap(() => this.rest.deactivateCustomer(this.contractorId, this.customerGuardian.id)));
                    }
                    return of(res);
                }),
            );
    }

    get formData() {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return <any>this.customerDetailsForm.controls;
    }

    private conditionallyRequiredValidator(formControl: AbstractControl) {
        if (!formControl.parent) {
            return null;
        }

        if (formControl?.parent?.get('isActive').value) {
            return Validators.required(formControl);
        }
        return null;
    }
}
