import { Injectable } from '@angular/core';
import { SetFormDisabled, UpdateFormValue } from '@ngxs/form-plugin';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { Service } from '../../model';
import { updateManyItems } from '../operators/update-many-items';
import { ReservationService } from './../../../theme/pages/home/_services/reservation.service';
import { ResultService } from './../../../theme/pages/home/_services/result.service';
import { TermsService } from './../../../theme/pages/home/_services/terms.service';
import { TreatmentService } from './../../../theme/pages/home/_services/treatment.service';
import { tags } from './../../constants/customTags.const';
import { DATE_CONST } from './../../constants/date-constants.const';
import { RecurringType } from './../../enums/recurringType.enum';
import { AvailabilityStatus } from './../../enums/treatment/availabilityStatus.enum';
import { PortfolioPaper } from './../../model/portfolio/portfolioPaper.model';
import { PreReservation } from './../../model/preReservation.model';
import { Reservation } from './../../model/reservation.model';
import { ReservationRequest } from './../../model/reservationRequest.model';
import { EventUpdate } from './../../model/schedule/eventUpdate.model';
import { PrereservationId } from './../../model/schedule/prereservationId.model';
import { Slot } from './../../model/slot.model';
import { ConfirmReservationsRequest } from './../../model/treatment/confirmReservationsRequest.model';
import { CreatePrereservationRequest } from './../../model/treatment/createPrereservationRequest.model';
import { CreateTreatmentPrereservationsRequest } from './../../model/treatment/createTreatmentPrereservationsRequest.model';
import { GeneratedTreatmentPrereservation } from './../../model/treatment/generatedTreatmentPrereservation.model';
import { TreatmentGuiList } from './../../model/treatment/treatment-gui-list.model';
import { TreatmentNotesRequest } from './../../model/treatment/treatment-notes-request.model';
import { TreatmentNote } from './../../model/treatment/treatment-notes.model.';
import { TreatmentGUI } from './../../model/treatment/treatmentGUI.model';
import { TreatmentReservationRequest } from './../../model/treatment/treatmentReservationRequest';
import { User } from './../../model/user.model';
import { HelperService } from './../../services/helper.service';
import { BaseState } from './../base/base.state';
import { TreatmentStateModel } from './treatment-state.model';
import { ClearTreatmentState, FormItemActions, Notes, Term, TreatmentActions } from './treatment.actions';

const TREATMENTSTATE_TOKEN: StateToken<TreatmentStateModel> = new StateToken('treatmentstate');

const DEFAULT_STATE: TreatmentStateModel = {
    baseTerm: undefined,
    newTerms: undefined,
    allTerms: undefined,
    treatmentData: new TreatmentGUI().deserialize({
        id: undefined,
        customer: undefined,
        description: '',
    }),
    treatmentform: undefined,
    allNotes: undefined,
    portfolioPapers: undefined,
    itemForm: {
        model: {
            items: [],
        },
    },
    generatorForm: undefined,
    // x: {
    //     i: [],
    // },
};

// interface ItemFormModel {
//     model?: {
//         test: string;
//         items: {
//             internalId: string;
//         }[];
//     };
// }

@State<TreatmentStateModel>({
    name: TREATMENTSTATE_TOKEN,
    defaults: DEFAULT_STATE,
    children: [], //ce bo kdaj prov prislo
})
@Injectable({
    providedIn: 'root',
})
export class TreatmentState {
    // private selectedContractor: Contractor = this.store.selectSnapshot(BaseState.activeContractor);

    constructor(
        private helper: HelperService,
        private reservationRest: ReservationService,
        private treatmentRest: TreatmentService,
        private store: Store,
        private toast: ToastrService,
        private termRest: TermsService,
        private resultRest: ResultService,
    ) {}

    @Selector()
    public static baseTerm(state: TreatmentStateModel): ReservationRequest {
        return state.baseTerm;
    }

    @Selector()
    public static treatmentData(state: TreatmentStateModel): TreatmentGUI {
        return state.treatmentData;
    }

    @Selector()
    public static allTerms(state: TreatmentStateModel): TreatmentReservationRequest[] {
        return state.allTerms;
    }

    @Selector()
    public static allNotes(state: TreatmentStateModel): TreatmentNote[] {
        return state.allNotes;
    }

    @Selector()
    public static allTermsAndNotes(state: TreatmentStateModel): TreatmentGuiList[] {
        const result: TreatmentGuiList[] = [];
        state?.allNotes?.map(el => {
            result.push({
                date: el.eventDate,
                note: el,
            });
        });

        state?.allTerms?.forEach(el => {
            result.push({
                date: el.selectedDate,
                term: el,
            });
        });

        result.sort((a: TreatmentGuiList, b: TreatmentGuiList) => {
            let result = 1;
            if (moment(a?.date).isSameOrBefore(moment(b?.date))) {
                result = -1;
            }
            return result;
        });

        return result;
    }

    @Selector()
    public static portfolioPapers(state: TreatmentStateModel): PortfolioPaper[] {
        return state.portfolioPapers;
    }

    @Action(FormItemActions.UpdateFormItem)
    public UpdateFormItem(ctx: StateContext<TreatmentStateModel>, { internalId, value }: FormItemActions.UpdateFormItem) {
        const items = ctx.getState().itemForm.model.items;
        const index = items.findIndex(el => el.internalId == internalId);

        this.store.dispatch(
            new UpdateFormValue({
                path: 'treatmentstate.itemForm',
                value: value,
                propertyPath: `items.${index}`,
            }),
        );
    }

    @Action(Term.CheckTermAvailability)
    public CheckTermAvailability(ctx: StateContext<TreatmentStateModel>, { termId }: Term.CheckTermAvailability) {
        // const term: TreatmentReservationRequest = ctx.getState().allTerms.find((el) => el.internalId == termId);

        const term: TreatmentReservationRequest = (<TreatmentReservationRequest[]>ctx.getState().itemForm.model.items).find(
            el => el.internalId == termId,
        );
        // const index = items.findIndex((el) => el.internalId == termId);
        const slot: Slot = this.prepareSlotData(term);
        const contractorId: number = this.store.selectSnapshot(BaseState.activeContractor).id;

        const getData: CreateTreatmentPrereservationsRequest = new CreateTreatmentPrereservationsRequest().deserialize({
            from: slot.start,
            to: slot.end,
            recurringType: RecurringType.SAMEDAY,
            service: term.selectedService,
            asset: term.selectedAsset,
            draft: false,
            numOfPrereservations: 3,
            excludePrereservationId: term.prereservationId,
            includeStartDate: true,
        });
        return this.treatmentRest.getTreatmentPrereservations(contractorId, term.selectedSubcontractor.id, getData).pipe(
            map((res: GeneratedTreatmentPrereservation[]) => {
                if (res.length > 0) {
                    return res;
                }
            }),
            tap((res: GeneratedTreatmentPrereservation[]) => {
                const from: moment.Moment = moment(term.selectedDate.format('YYYY-MM-DD') + ' ' + term.selectedTime);

                const to: moment.Moment = moment(term.selectedDate.format('YYYY-MM-DD') + ' ' + term.selectedTimeEnd);

                if (from.isSame(moment(res[0].from)) && to.isSame(moment(res[0].to))) {
                    ctx.setState(
                        patch<TreatmentStateModel>({
                            // TODO Ignored with eslint-interactive on 2023-11-10
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            itemForm: patch(<any>{
                                model: patch({
                                    items: updateItem(
                                        (el: TreatmentReservationRequest) => el.internalId == termId,
                                        // TODO Ignored with eslint-interactive on 2023-11-10
                                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                        patch({ availabilityStatus: val => AvailabilityStatus.FREE }),
                                    ),
                                }),
                            }),
                        }),
                    );
                } else {
                    //prikažimo predloge

                    ctx.dispatch(
                        new FormItemActions.UpdateFormItem(termId, {
                            availabilityStatus: AvailabilityStatus.OCCUPIED,
                            freeSlots: res.map((el: GeneratedTreatmentPrereservation) =>
                                new Slot().deserialize({
                                    start: el.from,
                                    end: el.to,
                                }),
                            ),
                        }),
                    );
                }
            }),
        );
    }

    @Action(ClearTreatmentState)
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line no-empty-pattern
    public ClearState(ctx: StateContext<TreatmentStateModel>, {}: ClearTreatmentState) {
        ctx.patchState(DEFAULT_STATE);
    }

    @Action(Term.AddBaseTerm)
    public AddBaseTerm(ctx: StateContext<TreatmentStateModel>, { baseTerm, isDefault }: Term.AddBaseTerm) {
        const result: TreatmentReservationRequest[] = [];
        if (isDefault) {
            const data: TreatmentReservationRequest = new TreatmentReservationRequest().deserialize(baseTerm);
            data.termTypeObj = tags.TERM_TYPE.obj.RESERVATION;
            data.availabilityStatus = AvailabilityStatus.FREE;
            data.internalId = this.helper.uuid(4);
            result.push(data);
        }
        ctx.patchState({ baseTerm: baseTerm, allTerms: result });
    }

    @Action(Term.UpdateBaseTerm)
    public UpdateBaseTerm(ctx: StateContext<TreatmentStateModel>, { baseTerm }: Term.UpdateBaseTerm) {
        ctx.patchState({ baseTerm: baseTerm });
    }

    @Action(Term.GenerateNewTerms)
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line no-empty-pattern
    public GenerateNewTerms(ctx: StateContext<TreatmentStateModel>, {}: Term.GenerateNewTerms) {
        //TODO
        if (ctx.getState().generatorForm?.status == 'INVALID') {
            this.toast.warning('Polja niso pravilno vnešena');
            return of([]);
        }
        const contractorId: number = this.store.selectSnapshot(BaseState.activeContractor).id;
        // const baseTerm: ReservationRequest = ctx.getState().baseTerm;
        const treatment: TreatmentGUI = ctx.getState().treatmentData;
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const generatorForm: any = ctx.getState().generatorForm.model;
        // const allTerms: TreatmentReservationRequest[] = ctx.getState().allTerms;
        if (!generatorForm.selectedSubcontractor) {
            return of();
        }
        const slot: Slot = this.prepareSlotData(generatorForm);

        const getData: CreateTreatmentPrereservationsRequest = new CreateTreatmentPrereservationsRequest().deserialize({
            includeStartDate: true,
            from: slot.start,
            to: slot.end,
            recurringType: generatorForm.recurringType,
            service: generatorForm.selectedService,
            asset: generatorForm.selectedAsset,
            draft: generatorForm.reservationMode == tags.TERM_TYPE.obj.DRAFT.reservationType ? true : false,
            numOfPrereservations: generatorForm.recurringFrequency,
        });
        return this.treatmentRest.getTreatmentPrereservations(contractorId, generatorForm.selectedSubcontractor.id, getData).pipe(
            tap((res: GeneratedTreatmentPrereservation[]) => {
                const genTerms: TreatmentReservationRequest[] = res.map(el => {
                    const tmp: TreatmentReservationRequest = new TreatmentReservationRequest().deserialize(generatorForm);
                    tmp.internalId = this.helper.uuid(4);
                    tmp.termTypeObj = tags.TERM_TYPE.table.find(el => el.id == generatorForm.termType);
                    tmp.selectedAsset = el.asset;
                    tmp.selectedService = new Service().deserialize({
                        ...el.service,
                        type: generatorForm?.selectedService?.type,
                    });
                    tmp.selectedDate = moment(el.from);
                    tmp.selectedTime = moment(el.from).format('HH:mm');
                    tmp.selectedTimeEnd = moment(el.to).format('HH:mm');
                    tmp.availabilityStatus = AvailabilityStatus.FREE;
                    tmp.termTypeObj = tags.TERM_TYPE.table.find(el => el.id == generatorForm.reservationMode);
                    tmp.includeStartDate = !treatment?.isTreatmentCreated;
                    tmp.prereservationId = undefined;
                    // tmp.reservationType = baseTerm.reservationType;
                    return tmp;
                });
                //TODO
                // generate new item form

                ctx.setState(
                    patch<TreatmentStateModel>({
                        allTerms: append(genTerms),
                    }),
                );
            }),
        );
    }

    @Action(Term.AddNewTerm)
    public AddNewTerm(ctx: StateContext<TreatmentStateModel>, { newTerm }: Term.AddNewTerm) {
        const baseTerm: ReservationRequest = ctx.getState().baseTerm;
        const treatment: TreatmentGUI = ctx.getState().treatmentData;
        const slot: Slot = this.prepareSlotDataNewTerm(baseTerm, moment(`${newTerm.startDate} ${newTerm.startTime}`, DATE_CONST.format.full));
        const newTermData: TreatmentReservationRequest = new TreatmentReservationRequest().deserialize(baseTerm);
        newTermData.internalId = this.helper.uuid(4);
        newTermData.selectedDate = moment(slot.start);
        newTermData.selectedTime = moment(slot.start).format('HH:mm');
        newTermData.selectedTimeEnd = moment(slot.end).format('HH:mm');
        newTermData.includeStartDate = !treatment?.isTreatmentCreated;
        newTermData.termTypeObj = tags.TERM_TYPE.obj.RESERVATION;
        newTermData.prereservationId = undefined;
        newTermData.availabilityStatus = AvailabilityStatus.FREE;
        newTermData.selectedService = newTerm.service;
        newTermData.selectedAsset = newTerm.asset;
        newTermData.selectedSubcontractor = newTerm.subcontractor;

        if (newTerm.customer) {
            ctx.dispatch([
                new TreatmentActions.SetTreatment(
                    new TreatmentGUI().deserialize({
                        customer: newTerm.customer,
                        description: '',
                    }),
                ),
            ]);
            // ctx.setState(
            //     patch<TreatmentStateModel>({
            //         treatmentData: patch<TreatmentGUI>({
            //             customer: newTerm.customer,
            //             description: '',
            //         }),
            //     }),
            // );
        }
        ctx.setState(
            patch<TreatmentStateModel>({
                // allTerms: genTerms,
                allTerms: append([newTermData]),
            }),
        );
    }

    @Action(TreatmentActions.CreateNewTreatment)
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line no-empty-pattern
    public CreateNewTreatment(ctx: StateContext<TreatmentStateModel>, {}: TreatmentActions.CreateNewTreatment) {
        const treatment: TreatmentGUI = ctx.getState().treatmentform.model;
        delete treatment.id;
        const allTerms: TreatmentReservationRequest[] = ctx.getState().allTerms;
        const contractorId: number = this.store.selectSnapshot(BaseState.activeContractor).id;
        const subcontractorId: number = this.store.selectSnapshot(BaseState.LoggedUser).subcontractorId;
        //TODO
        //validate
        // if (allTerms.some((el) => el.isFormValid == false)) {
        //     this.toast.warning('Vsi termini niso pravilno vnešeni');
        //     return of([]);
        // }

        return this.treatmentRest.createTreatment(contractorId, subcontractorId, treatment).pipe(
            tap(res => {
                treatment.isTreatmentCreated = true;
                treatment.id = res;
                ctx.setState(
                    patch<TreatmentStateModel>({
                        // treatmentData: treatment,
                        treatmentData: patch<TreatmentGUI>({
                            id: res,
                            isTreatmentCreated: true,
                        }),
                    }),
                );

                this.store.dispatch([new SetFormDisabled('treatmentstate.treatmentform'), new Term.SetAllDetails(false)]);
            }),
            mergeMap(() => {
                return this.treatmentRest
                    .makePrereservations(
                        contractorId,
                        allTerms.map(el => {
                            const slot: Slot = this.prepareSlotData(el);
                            return new CreatePrereservationRequest().deserialize({
                                from: slot.start,
                                to: slot.end,
                                contractor: { id: contractorId, subcontractors: [{ id: el.selectedSubcontractor.id }] },
                                service: el.selectedService,
                                asset: el.selectedAsset,
                                customer: treatment.customer,
                                itemId: el.internalId,
                                availabilityStatus: AvailabilityStatus.BOOKED,
                            });
                        }),
                    )
                    .pipe(
                        tap((prereservations: PrereservationId[]) => {
                            // TODO Ignored with eslint-interactive on 2023-11-10
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            allTerms.map((term, index) => {
                                ctx.dispatch([
                                    new Term.UpdateTermParameter('availabilityStatus', AvailabilityStatus.BOOKED, term.internalId),
                                    new Term.UpdateTermParameter(
                                        'prereservationId',
                                        prereservations.find(el => el.itemId == term.internalId).prereservationId,
                                        term.internalId,
                                    ),
                                ]);
                            });
                        }),
                    );
            }),
            mergeMap((prereservations: PrereservationId[]) => {
                const treatmentId: number = ctx.getState().treatmentData.id;
                const user: User = this.store.selectSnapshot(BaseState.LoggedUser);

                const confirmRequest: ConfirmReservationsRequest = new ConfirmReservationsRequest().deserialize({
                    appUserId: user.id,
                    appUserName: user.name,
                    prereservations: prereservations,
                });

                return this.treatmentRest.confirmPrereservations(contractorId, treatmentId, confirmRequest);
            }),
        );
    }

    private preparePreReservationRequest(
        allTerms: TreatmentReservationRequest[],
        cid: number,
        treatment: TreatmentGUI,
    ): CreatePrereservationRequest[] {
        const tmp: CreatePrereservationRequest[] = allTerms.map(term => {
            const slot: Slot = this.prepareSlotData(term);
            const prereq = new CreatePrereservationRequest().deserialize({
                from: slot.start,
                to: slot.end,
                contractor: { id: cid, subcontractors: [{ id: term.selectedSubcontractor.id }] },
                service: term.selectedService,
                asset: term.selectedAsset,
                customer: treatment.customer,
                forceUpdate: true,
                itemId: term.internalId,
            });
            if (term?.selectedService?.type == 'GROUP') {
                prereq.serviceGroup = term.selectedService;
                delete prereq.service;
            }

            return prereq;
        });
        return tmp;
    }

    @Action(Term.CreateNewReservations)
    public CreateNewReservations(ctx: StateContext<TreatmentStateModel>, { allTerms }: Term.CreateNewReservations) {
        if (ctx.getState().itemForm?.status == '"INVALID"') {
            this.toast.warning('Termin ni pravilno vnešen');
            return of([]);
        }

        const treatment: TreatmentGUI = ctx.getState().treatmentform.model;
        const contractorId: number = this.store.selectSnapshot(BaseState.activeContractor).id;
        const items: TreatmentReservationRequest[] = [];
        allTerms.forEach(internalId => {
            items.push((<TreatmentReservationRequest[]>ctx.getState().itemForm.model.items).find(el => el.internalId == internalId));
        });

        // const subcontractorId: number = this.store.selectSnapshot(BaseState.loggedUser).subcontractorId;
        //validate
        //TODO
        // if (!allTerms.every((el) => el.isFormValid)) {
        //     this.toast.warning('Termin ni pravilno vnešen');
        //     return of([]);
        // }

        return this.treatmentRest.makePrereservations(contractorId, this.preparePreReservationRequest(items, contractorId, treatment)).pipe(
            tap((prereservations: PrereservationId[]) => {
                prereservations.map((prere: PrereservationId) => {
                    ctx.dispatch([
                        new Term.UpdateTermParameter('availabilityStatus', AvailabilityStatus.BOOKED, prere.itemId),
                        new Term.UpdateTermParameter('prereservationId', prere.prereservationId, prere.itemId),
                    ]);
                });
            }),
            mergeMap((prereservations: PrereservationId[]) => {
                const treatmentId: number = ctx.getState().treatmentData.id;
                const user: User = this.store.selectSnapshot(BaseState.LoggedUser);

                const confirmRequest: ConfirmReservationsRequest = new ConfirmReservationsRequest().deserialize({
                    appUserId: user.id,
                    appUserName: user.name,
                    prereservations: prereservations,
                });

                return this.treatmentRest.confirmPrereservations(contractorId, treatmentId, confirmRequest).pipe(
                    tap(() => {
                        this.store.dispatch([new SetFormDisabled('treatmentstate.treatmentform'), new Term.SetAllDetails(false)]);
                    }),
                );
            }),
        );
    }

    @Action(Term.UpdateReservations)
    public UpdateReservations(ctx: StateContext<TreatmentStateModel>, { internalIds }: Term.UpdateReservations) {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const treatment: TreatmentGUI = ctx.getState().treatmentform.model;
        // const contractorId: number = this.store.selectSnapshot(BaseState.activeContractor).id;
        // const subcontractorId: number = this.store.selectSnapshot(BaseState.loggedUser).subcontractorId;
        //validate
        //TODO

        if (ctx.getState().itemForm?.status == '"INVALID"') {
            this.toast.warning('Termin ni pravilno vnešen');
            return of([]);
        }
        const items: TreatmentReservationRequest[] = [];

        internalIds.forEach(internalId => {
            items.push((<TreatmentReservationRequest[]>ctx.getState().itemForm.model.items).find(el => el.internalId == internalId));
        });

        const apiCalls: Observable<TreatmentReservationRequest>[] = [];

        items.map(item => {
            const from: Date = moment(this.helper.stringDateToApiDate(item.selectedDateView) + ' ' + item.selectedTime, 'YYYY-MM-DD HH:mm').toDate();
            const to: Date = moment(this.helper.stringDateToApiDate(item.selectedDateView) + ' ' + item.selectedTimeEnd, 'YYYY-MM-DD HH:mm').toDate();

            const data: EventUpdate = new EventUpdate().deserialize({
                dateFrom: from,
                dateTo: to,
                subcontractorId: item.selectedSubcontractor.id,
                // customer: treatment.customer,
                serviceId: item.selectedService.id,
                assetId: item.selectedAsset.id,
                // customTagId: item.customTagId,
                forceUpdate: true,
            });
            apiCalls.push(this.reservationRest.updateReservation(item.prereservationId, data));
        });

        return forkJoin(apiCalls).pipe(
            tap(() => {
                internalIds.map(internalId => {
                    ctx.dispatch(
                        new FormItemActions.UpdateFormItem(internalId, {
                            availabilityStatus: AvailabilityStatus.BOOKED,
                        }),
                    );
                });
            }),
        );
    }

    @Action(TreatmentActions.UpdateReservationsTreatment)
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line no-empty-pattern
    public UpdateReservationsTreatment(ctx: StateContext<TreatmentStateModel>, {}: TreatmentActions.UpdateReservationsTreatment) {
        const terms = (<TreatmentReservationRequest[]>ctx.getState().itemForm.model.items).filter(el => el.itemType == 'TERM');
        terms;
        const onlyNewTerms: TreatmentReservationRequest[] = terms.filter(
            el => el.availabilityStatus == AvailabilityStatus.FREE && el.prereservationId == undefined,
        );

        const onlyUpdateTerms: TreatmentReservationRequest[] = terms.filter(
            el => el.availabilityStatus == AvailabilityStatus.FREE && el.prereservationId != undefined,
        );
        ctx.dispatch([
            new Term.CreateNewReservations(onlyNewTerms?.map(el => el.internalId)),
            new Term.UpdateReservations(onlyUpdateTerms?.map(el => el.internalId)),
        ]);
    }

    @Action(TreatmentActions.UpdateNotesTreatment)
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line no-empty-pattern
    public UpdateNotesTreatment(ctx: StateContext<TreatmentStateModel>, {}: TreatmentActions.UpdateNotesTreatment) {
        const notes = (<TreatmentNote[]>ctx.getState().itemForm.model.items).filter(el => el.itemType == 'NOTE');
        // const onlyUpdateNotes: TreatmentNote[] = ctx.getState().allNotes; //.filter((el) => el.id != undefined);
        ctx.dispatch([new Notes.UpdateNotes(notes)]);
    }

    @Action(TreatmentActions.UpdateTreatment)
    public UpdateTreatment(ctx: StateContext<TreatmentStateModel>, { treatment }: TreatmentActions.UpdateTreatment) {
        ctx.patchState({ treatmentData: treatment });
    }

    @Action(TreatmentActions.SetTreatment)
    public SetTreatment(ctx: StateContext<TreatmentStateModel>, { treatment }: TreatmentActions.SetTreatment) {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const formData: any = _.cloneDeep(treatment);
        formData.smsNotify = _.get(treatment, 'customer.sendSms');
        formData.emailNotify = _.get(treatment, 'customer.sendMail');
        ctx.patchState({ treatmentData: treatment, treatmentform: { model: formData } });
    }

    @Action(TreatmentActions.LoadTreatmentPortfolio)
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line no-empty-pattern
    public LoadTreatmentPortfolio(ctx: StateContext<TreatmentStateModel>, {}: TreatmentActions.LoadTreatmentPortfolio) {
        const terms: TreatmentReservationRequest[] = ctx.getState().allTerms;

        // treatment.isTreatmentCreated = true;
        const contractorId: number = this.store.selectSnapshot(BaseState.activeContractor).id;
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const apiCalls: Observable<any>[] = [];
        terms.map(el => {
            if (el?.visitId) {
                apiCalls.push(this.resultRest.getVisitPortfolio(contractorId, el.visitId));
            }
            apiCalls.push(this.resultRest.getReservationPortfolio(contractorId, el.prereservationId));
        });

        return forkJoin(apiCalls).pipe(
            tap((data: PortfolioPaper[][]) => {
                const flatten: PortfolioPaper[] = _.flatten(data);
                ctx.setState(
                    patch<TreatmentStateModel>({
                        portfolioPapers: flatten,
                    }),
                );
            }),
        );
    }

    @Action(TreatmentActions.LoadTreatment)
    public LoadTreatment(ctx: StateContext<TreatmentStateModel>, { treatment }: TreatmentActions.LoadTreatment) {
        treatment.isTreatmentCreated = true;
        const contractorId: number = this.store.selectSnapshot(BaseState.activeContractor).id;

        return forkJoin({
            reservation: this.treatmentRest.getTreatmentReservations(contractorId, treatment.id).pipe(
                map((res: Reservation[]) => {
                    return res.map((el: Reservation) => {
                        return new TreatmentReservationRequest().deserialize({
                            selectedSubcontractor: el.contractor.subcontractors[0],
                            internalId: this.helper.uuid(4),
                            selectedAsset: el.asset,
                            selectedService: el.service,
                            customTagId: el.customTagId,
                            selectedDate: moment(el.reservationFrom),
                            selectedTime: moment(el.reservationFrom).format('HH:mm'),
                            selectedTimeEnd: moment(el.reservationTo).format('HH:mm'),
                            availabilityStatus: AvailabilityStatus.BOOKED,
                            prereservationId: el.prereservationId,
                            aggregateId: el.aggregateId,
                            visitId: el.visitId,
                        });
                    });
                }),
            ),
            notes: this.treatmentRest.getTreatmenNotes(contractorId, treatment.id).pipe(
                map(notes => {
                    return notes.map(el => {
                        el.internalId = this.helper.uuid(4);
                        return el;
                    });
                }),
            ),
        }).pipe(
            tap(({ reservation, notes }: { reservation: TreatmentReservationRequest[]; notes: TreatmentNote[] }) => {
                const lastTerm: ReservationRequest = _.last(reservation);

                ctx.dispatch([
                    new Term.AddBaseTerm(lastTerm),
                    new TreatmentActions.SetTreatment(treatment),
                    new Term.SetAllTerms(reservation),
                    new SetFormDisabled('treatmentstate.treatmentform'),
                    new Notes.SetAllNotes(notes),
                ]);
            }),
        );
    }

    @Action(Term.DeleteTermReservation)
    public DeleteTermReservation(ctx: StateContext<TreatmentStateModel>, { term }: Term.DeleteTermReservation) {
        this.helper.displayAlert('Brisanje rezervacije', 'Ali ste prepričani, da želite izbrisati rezervacijo?').then(result => {
            if (result.value) {
                this.termRest.cancelReservation(new PreReservation().deserialize(term)).subscribe(
                    () => {
                        this.toast.success('Termin je bil izbrisan.');
                        ctx.setState(
                            patch<TreatmentStateModel>({
                                allTerms: removeItem(el => el.internalId == term.internalId),
                            }),
                        );
                    },
                    () => {
                        this.toast.error('Napaka pri  brisanju termina.');
                    },
                );
            }
        });
    }

    @Action(Term.RemoveTerm)
    public RemoveTerm(ctx: StateContext<TreatmentStateModel>, { internalId }: Term.RemoveTerm) {
        ctx.setState(
            patch<TreatmentStateModel>({
                allTerms: removeItem(el => el.internalId == internalId),
            }),
        );
    }

    @Action(Term.UpdateTerm)
    public UpdateTerm(ctx: StateContext<TreatmentStateModel>, { term }: Term.UpdateTerm) {
        ctx.setState(
            patch<TreatmentStateModel>({
                allTerms: updateItem(el => el.internalId == term.internalId, term),
            }),
        );
    }

    @Action(Term.SetAllTerms)
    public SetAllTerms(ctx: StateContext<TreatmentStateModel>, { terms }: Term.SetAllTerms) {
        ctx.setState(
            patch<TreatmentStateModel>({
                allTerms: terms,
            }),
        );
    }

    @Action(Term.UpdateAllTerms)
    public UpdateAllTerms(ctx: StateContext<TreatmentStateModel>, { term }: Term.UpdateAllTerms) {
        const allTermsCopy: TreatmentReservationRequest[] = _.cloneDeep(ctx.getState().allTerms);
        allTermsCopy.map(el => {
            // _.set(el, 'selectedAsset', term.selectedAsset);
            el.selectedAsset = term.selectedAsset;
            el.selectedService = term.selectedService;
            el.customTagId = term.customTagId;
            el.selectedSubcontractor = term.selectedSubcontractor;
            el.selectedTimeEnd = term.selectedTimeEnd;
            el.internalId = this.helper.uuid(4);
        });
        ctx.setState(
            patch<TreatmentStateModel>({
                baseTerm: patch({
                    selectedAsset: term.selectedAsset,
                    selectedService: term.selectedService,
                    customTagId: term.customTagId,
                    selectedSubcontractor: term.selectedSubcontractor,
                }),
                allTerms: allTermsCopy,
            }),
        );
    }

    @Action(Term.UpdateTermParameter)
    public UpdateTermParameter(ctx: StateContext<TreatmentStateModel>, { name, value, termId }: Term.UpdateTermParameter) {
        ctx.setState(
            patch<TreatmentStateModel>({
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                allTerms: updateItem(el => el.internalId == termId, patch({ [name]: val => (val = value) })),
            }),
        );
    }

    @Action(Term.ToggleDetails)
    public ToggleDetails(ctx: StateContext<TreatmentStateModel>, { termId }: Term.ToggleDetails) {
        ctx.setState(
            patch<TreatmentStateModel>({
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                itemForm: patch(<any>{
                    model: patch({
                        items: updateItem((el: TreatmentReservationRequest) => el.internalId == termId, patch({ isDetailsOpend: val => !val })),
                    }),
                }),
            }),
        );
    }

    @Action(Term.SetDetailsOpen)
    public SetDetailsOpen(ctx: StateContext<TreatmentStateModel>, { termId, isOpen }: Term.SetDetailsOpen) {
        ctx.setState(
            patch<TreatmentStateModel>({
                allTerms: updateItem(el => el.internalId == termId, patch({ isDetailsOpend: isOpen })),
            }),
        );
    }

    @Action(Term.SetAllDetails)
    public SetAllDetails(ctx: StateContext<TreatmentStateModel>, { isOpen }: Term.SetAllDetails) {
        ctx.setState(
            patch<TreatmentStateModel>({
                allTerms: updateManyItems(() => true, patch({ isDetailsOpend: isOpen })),
            }),
        );
    }

    @Action(Term.SetFormValidation)
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public SetFormValidation(ctx: StateContext<TreatmentStateModel>, { isValid, termId }: Term.SetFormValidation) {
        //TODO
        // ctx.setState(
        //     patch<TreatmentStateModel>({
        //         allTerms: updateItem((el) => el.internalId == termId, patch({ isFormValid: isValid })),
        //     }),
        // );
    }

    @Action(Term.InitTermGenerator)
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line no-empty-pattern
    public InitTermGenerator(ctx: StateContext<TreatmentStateModel>, {}: Term.InitTermGenerator) {
        ctx.patchState({
            generatorForm: {
                model: {
                    customTagId: '',
                    recurringFrequency: '1',
                    recurringType: RecurringType.WEEKLY,
                    selectedAsset: '',
                    selectedDate: '',
                    selectedDateView: '',
                    selectedService: '',
                    selectedSubcontractor: undefined,
                    selectedTime: '',
                    selectedTimeEnd: '',
                    termType: '',
                },
            },
        });
        //TODO
        // ctx.setState(
        //     patch<TreatmentStateModel>({
        //         allTerms: updateItem((el) => el.internalId == termId, patch({ isFormValid: isValid })),
        //     }),
        // );
    }

    private prepareSlotDataNewTerm(baseTerm: TreatmentReservationRequest | ReservationRequest, selectedDate: moment.Moment): Slot {
        if (baseTerm?.selectedService?.length) {
            return new Slot().deserialize({
                start: selectedDate.toDate(),
                end: selectedDate.add(baseTerm.selectedService.length, 'minutes').toDate(),
            });
        }
        return new Slot().deserialize({
            start: selectedDate.toDate(),
            end: selectedDate.add(1, 'h').toDate(),
        });
    }
    private prepareSlotData(formModel: TreatmentReservationRequest | ReservationRequest): Slot {
        let startDate, endDate;
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line prefer-const
        startDate = moment(formModel.selectedDate, 'DD.MM.YYYY')
            .hours(moment(formModel.selectedTime, 'HH:mm').hours())
            .minutes(moment(formModel.selectedTime, 'HH:mm').minutes());

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line prefer-const
        endDate = moment(formModel.selectedDate, 'DD.MM.YYYY')
            .hours(moment(formModel.selectedTimeEnd, 'HH:mm').hours())
            .minutes(moment(formModel.selectedTimeEnd, 'HH:mm').minutes());

        return new Slot().deserialize({
            start: startDate.toDate(),
            end: endDate.toDate(),
        });
    }

    @Action(Notes.SetAllNotes)
    public SetAllNotes(ctx: StateContext<TreatmentStateModel>, { notes }: Notes.SetAllNotes) {
        ctx.setState(
            patch<TreatmentStateModel>({
                allNotes: notes,
            }),
        );
    }

    // @Action(Notes.ToggleDetails)
    // publicNotesToggleDetails(ctx: StateContext<TreatmentStateModel>, { noteId }: Notes.ToggleDetails) {
    //     ctx.setState(
    //         patch<TreatmentStateModel>({
    //             allNotes: updateItem((el) => el.id == noteId, patch({ isDetailsOpend: (val) => !val })),
    //         }),
    //     );
    // }

    @Action(Notes.RemoveNote)
    public RemoveNote(ctx: StateContext<TreatmentStateModel>, { note }: Notes.RemoveNote) {
        const contractorId: number = this.store.selectSnapshot(BaseState.activeContractor).id;
        const treatment: TreatmentGUI = ctx.getState().treatmentData;

        this.treatmentRest.removeTreatmenNotes(contractorId, treatment.id, note.id).subscribe(
            () => {
                ctx.setState(
                    patch<TreatmentStateModel>({
                        allNotes: removeItem(el => el.id == note.id),
                    }),
                );
            },
            () => this.toast.error('Napaka pri brisanju dogodka.'),
        );
    }

    @Action(Notes.UpdateNotes)
    public UpdateNotes(ctx: StateContext<TreatmentStateModel>, { notes }: Notes.UpdateNotes) {
        // const treatment: TreatmentGUI = ctx.getState().treatmentform.model;

        if (ctx.getState().itemForm?.status == '"INVALID"') {
            this.toast.warning('Polja ni pravilno vnešena');
            return of([]);
        }

        const treatment: TreatmentGUI = ctx.getState().treatmentData;
        const contractorId: number = this.store.selectSnapshot(BaseState.activeContractor).id;

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const apiCalls: Observable<any>[] = [];

        notes.map((el: TreatmentNote) => {
            el.eventDate = moment(el.eventDate, DATE_CONST.format.date.view);
            const data: TreatmentNotesRequest = {
                title: el.title,
                eventDate: el.eventDate.toDate(),
                content: el.content,
                id: el.id,
            };
            // el.isDetailsOpend = true;

            // el.eventDate = moment(el.eventDate, DATE_CONST.format.date.view).toDate();
            apiCalls.push(
                this.treatmentRest.updateTreatmenNotes(contractorId, treatment.id, data).pipe(
                    tap((id: number) => {
                        el.id = id;
                        el.isChange = false;
                        el.availabilityStatus = AvailabilityStatus.BOOKED;
                        // el.eventDate = data.eventDate

                        ctx.setState(
                            patch<TreatmentStateModel>({
                                allNotes: updateItem(el2 => el2.internalId == el.internalId, el),
                            }),
                        );
                    }),
                ),
            );
            return el;
        });

        return forkJoin(apiCalls);
    }

    @Action(Notes.UpdateNote)
    public UpdateNote(ctx: StateContext<TreatmentStateModel>, { note }: Notes.UpdateNote) {
        ctx.setState(
            patch<TreatmentStateModel>({
                allNotes: updateItem(el => el.internalId == note.internalId, note),
            }),
        );
    }

    @Action(Notes.UpdateNoteParameter)
    public UpdateNoteParameter(ctx: StateContext<TreatmentStateModel>, { name, value, internalId }: Notes.UpdateNoteParameter) {
        ctx.setState(
            patch<TreatmentStateModel>({
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                allNotes: updateItem(el => el.internalId == internalId, patch({ [name]: val => (val = value) })),
            }),
        );
    }

    @Action(Notes.AddNewNote)
    public AddNewNote(ctx: StateContext<TreatmentStateModel>): Observable<number> {
        const treatment: TreatmentGUI = ctx.getState().treatmentData;
        const contractorId: number = this.store.selectSnapshot(BaseState.activeContractor).id;
        const data: TreatmentNotesRequest = {
            title: '',
            eventDate: moment().toDate(),
            content: '',
        };

        const newNote: TreatmentNote = {
            title: data.title,
            eventDate: moment(data.eventDate),
            content: data.content,
            isDetailsOpend: true,
            internalId: this.helper.uuid(),
            documents: [],
            itemType: 'NOTE',
        };

        return this.treatmentRest.updateTreatmenNotes(contractorId, treatment.id, data).pipe(
            tap((id: number) => {
                newNote.id = id;
                ctx.setState(
                    patch<TreatmentStateModel>({
                        allNotes: append([newNote]),
                    }),
                );
            }),
        );
    }
}
