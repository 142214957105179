import { Serializable } from './../deserialize.service';

export class GroupTag implements Serializable<GroupTag> {
    id: number;
    name: string;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        this.id = input.id;
        this.name = input.name;
        return this;
    }
}
