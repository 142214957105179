export enum DocumentSource {
    CUSTOMER = 'CUSTOMER',
    VISIT = 'VISIT',
    RESERVATION = 'RESERVATION',
    TRIAGE = 'TRIAGE',
    CASE = 'CASE',
    //OIM
    MODEL = 'MODEL',
    CONTRACT = 'CONTRACT',
    MODEL_WRONG = 'MODEL_WRONG',
    WORKORDER = 'WORKORDER',
    NETWORK = 'NETWORK',
}
