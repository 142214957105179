<div *ngIf="isTelemedicineModuleActive" class="telemedicine">
    <div
        [ngClass]="{
            'telemedicine-large-modal': windowSize !== 'small',
            'telemedicine-small-modal': windowSize === 'small',
            'telemedicine-small-modal--bottom-right': windowPosition === 'bottom-right',
            'telemedicine-small-modal--bottom-left': windowPosition === 'bottom-left',
            'telemedicine-small-modal--top-left': windowPosition === 'top-left',
            'telemedicine-small-modal--top-right': windowPosition === 'top-right'
        }"
    >
        <ng-container *ngIf="callState >= callStates.CALL_STARTING || callState === callStates.DEVICE_TEST">
            <telemedicine-video></telemedicine-video>
        </ng-container>
    </div>
</div>
