import { Treatment } from './treatment.model';

export class TreatmentGUI extends Treatment {
    isTreatmentCreated?: boolean;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        super.deserialize(input);
        return Object.assign(this, input);
    }

    // public getTreatment(): Treatment {
    //     return super.deserialize(this);
    // }
}
