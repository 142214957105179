import * as _ from 'lodash';
import * as moment from 'moment';
import { tags } from '../constants/customTags.const';
import { ReservationMode } from '../enums/reservationMode.enum';
import { Serializable } from './deserialize.service';
import { Event } from './event.model';
import { HolidayEvent } from './schedule/holidayEvent.model';
import { Service } from './service.model';

export class FcEvent implements Serializable<FcEvent> {
    allDay: boolean;
    backgroundColor: string;
    borderColor: string;
    className: string;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    color: any;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constraint: any;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    durationEditable: boolean;
    editable: boolean;
    end: moment.Moment;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    id: any;
    overlap: boolean;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rendering: any;
    resourceEditable: boolean;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resourceId: any;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    resourceIds: any[];
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    source: any;
    start: moment.Moment;
    startEditable: boolean;
    textColor: string;
    title: string;
    url: string;
    telemed: boolean;
    display: string;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any, subcontractor?: any[], servicesForColor?: Service[]) {
        if (_.isNil(input)) {
            return this;
        }
        // this.data = input.data;
        if (input.data instanceof HolidayEvent) {
            this.data = input.data;
        } else {
            this.data = new Event().deserialize(input.data);
        }
        this.display = input.display;
        this.resourceIds = input.resourceIds;
        this.allDay = input.allDay;
        this.backgroundColor = input.backgroundColor;
        this.borderColor = input.borderColor;
        this.className = input.className;
        this.color = input.color;
        this.constraint = input.constraint;
        if (input.durationEditable) {
            this.durationEditable = input.durationEditable;
        }
        this.editable = input.editable;
        if (input.end instanceof moment) {
            this.end = input.end.toDate();
        } else {
            if (input.end) {
                this.end = moment(input.end);
            } else {
                this.end = moment(_.get(this.data, 'end', undefined));
            }
        }
        this.id = input.id;
        this.overlap = input.overlap;
        this.rendering = input.rendering;
        if (input.resourceEditable) {
            this.resourceEditable = input.resourceEditable;
        }
        this.source = input.source;
        if (input.start instanceof moment) {
            this.start = input.start.toDate();
        } else {
            if (input.start) {
                this.start = moment(input.start);
            } else {
                this.start = moment(_.get(this.data, 'start', undefined));
            }
        }

        if (input.startEditable) {
            this.startEditable = input.startEditable;
        }
        this.textColor = input.textColor;
        this.title = input.title;
        if (input.url) {
            this.url = input.url;
        }

        //set title
        if (this.data.customer) {
            this.title = _.get(this.data, 'customer.name', '') + ' ' + _.get(this.data, 'customer.surname', '');
        } else if (input.title) {
            this.title = input.title;
        } else if (this.data.title) {
            this.title = this.data.title;
        } else {
            this.title = 'BREZ STRANKE';
        }

        if (input.resourceId) {
            this.resourceId = input.resourceId;
        } else {
            this.resourceId = _.get(this.data, 'contractor.subcontractors[0].id', undefined);
        }
        if (servicesForColor) {
            if (input.serviceGroup) {
                this.getStyle(undefined, servicesForColor, input.serviceGroup);
            } else {
                this.getStyle(undefined, servicesForColor, undefined);
            }
        } else {
            this.getStyle(subcontractor, undefined, undefined);
        }
        // this.editable = input.editable;
        this.telemed = input.telemed;

        return this;
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private getStyle(subcontractor?: any[], services?: Service[], group?): void {
        //preveri če urejaš skupino
        if (group) {
            const foundGroup = services.find(el => {
                return el.id === group.id;
            });
            if (foundGroup) {
                this.backgroundColor = _.get(foundGroup, 'color', '');
            }
        } else if (services) {
            const foundService = services.find(el => {
                return el.id === _.get(this.data, 'service.id', -1);
            });

            if (foundService) {
                this.backgroundColor = _.get(foundService, 'color', '');
                // this.textColor = '#000000';
            }
        } else {
            if (subcontractor) {
                const foundSubc = subcontractor.find(el => {
                    return el.id === this.data.contractor.subcontractors[0].id;
                });
                if (foundSubc) {
                    this.backgroundColor = foundSubc.bgColor;
                    this.textColor = foundSubc.fgColor;
                }
            }
        }

        if (this.data.customTagId) {
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const tag: any = tags.CUSTOM_TERMS_TAGS.find(el => el.id === this.data.customTagId);
            if (tag) {
                this.className = this.className + ' ' + tag.classColor;
            }
        }
        if (this.data.reservationMode === ReservationMode.DRAFT) {
            this.className = this.className + ' mf-draft-event';
        }
    }
}
