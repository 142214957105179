import { Serializable } from '../deserialize.service';

export class PrintPOSItemsFooter implements Serializable<PrintPOSItemsFooter> {
    public sum: number;
    public toPay: number;
    public discountSum: number;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        this.sum = input.sum;
        this.toPay = input.toPay;
        this.discountSum = input.discountSum;

        return this;
    }
}
