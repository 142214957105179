import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MomentModule } from 'ngx-moment';
import { BasicComponentsModule } from '../../basic-components/basic-components.module';
import { PaperNamePipe } from './../../../_shared/pipes/paper-name.pipe';
import { ComponentModule } from './../../components/component.module';
import { TelmParseUsernamePipe } from './pipes/telm-parse-username.pipe';
import { TelemedicineComponent } from './telemedicine/telemedicine.component';
import { TelmChatPortletComponent } from './telm-chat/telm-chat-portlet/telm-chat-portlet.component';
import { TelmChatComponent } from './telm-chat/telm-chat.component';
import { TermCardComponent } from './term-card/term-card.component';
import { VideoComponent } from './video/video.component';

@NgModule({
    declarations: [VideoComponent, TelemedicineComponent, TermCardComponent, TelmChatComponent, TelmChatPortletComponent, TelmParseUsernamePipe],
    imports: [
        CommonModule,
        NgbModule,
        MomentModule.forRoot({
            relativeTimeThresholdOptions: {
                m: 60,
                h: 24,
                d: 28,
                M: 12,
            },
        }),
        FileUploadModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentModule,
        BasicComponentsModule,
    ],
    exports: [TelemedicineComponent, TermCardComponent, TelmChatComponent, TelmChatPortletComponent],
    providers: [PaperNamePipe],
    // bootstrap: [TelmChatComponent],
    //entryComponents: [TelemedicineComponent],
})
export class TelemedicineModule {}
