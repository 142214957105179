import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Customer } from '../../../../../../../_shared/model';
import { BaseState } from './../../../../../../../_shared/state/base/base.state';
import { CustomerService } from './../../../../_services/customer.service';

@Component({
    selector: 'pf-p-wr-customer',
    templateUrl: './pf-p-wr-customer.component.html',
    styles: [],
})
export class PfPWrCustomerComponent implements OnInit {
    public selectedCustomer: Customer;
    private onDestroy$ = new Subject<void>();
    public selectedContractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);

    constructor(
        private customerRest: CustomerService,
        private store: Store,
    ) {}
    @Input()
    customer: Customer;

    @Input()
    title = 'Zavarovana oseba';

    ngOnInit() {
        this.customerRest
            .getCustomer(this.selectedContractorId, this.customer?.id)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(customer => {
                this.selectedCustomer = customer ? customer : this.customer;
            });
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
