export const DATE_CONST: {
    format: { date: { view: string; api: string; ng: string }; time: { timeValue: string }; full: string };
} = {
    format: {
        date: {
            view: 'DD.MM.YYYY',
            api: 'YYYY-MM-DD',
            ng: 'dd.MM.yyyy',
        },
        time: {
            timeValue: 'HH:mm',
        },
        full: 'DD.MM.YYYY HH:mm',
    },
};
