import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// other
import { map } from 'rxjs/operators';
// models
import { BusinessSubject, Comment, TriageResponse } from '../../../../_shared/model';
import { HelperService } from '../../../../_shared/services';
// services
import { HttpClientService } from '../../../../_shared/services/http-client.service';

@Injectable({
    providedIn: 'root',
})
export class TriageService {
    constructor(
        private helper: HelperService,
        private httpClient: HttpClientService,
    ) {}

    public getBusinessSubjectTriage(contrId: string | number): Observable<TriageResponse[]> {
        return this.httpClient.get(this.helper.getTriageUrl(3) + `/contractors/${contrId}/triages`).pipe(
            map(el =>
                el.map(el2 => {
                    el2.business = this.helper.getBusiness(new BusinessSubject().deserialize({ id: el2.bsid }));
                    return new TriageResponse().deserialize(el2);
                }),
            ),
        );
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public uploadFile(file: File, comment: string, bsid: number): Observable<any> {
        return this.httpClient.uploadFile(this.helper.getTriageUrl() + `/contractors/upload/bs/${bsid}/`, file, comment);
    }

    public getTriageWithId(contrId: number, triageId: number): Observable<TriageResponse> {
        return this.httpClient.get(this.helper.getTriageUrl() + `/contractors/${contrId}/triage/${triageId}`);
    }

    // "/contractors/{contractorid}/triage/{triageId}/documents/{documentId}" POST (edited)
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addTriageComments(contractorId: number, triageId: number, data: Comment): Observable<any> {
        return this.httpClient.post(this.helper.getTriageUrl() + `/contractors/${contractorId}/triage/${triageId}/comments`, data, {
            responseType: 'text',
        });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public connectTriageToService(contractorId: number, triageId: number, data: any) {
        return this.httpClient.put(this.helper.getTriageUrl() + `/contractors/${contractorId}/triage/${triageId}/service`, data);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public saveFile(contractorId: number, file: File, comment: string): Observable<any> {
        return this.httpClient.uploadFile(this.helper.getDocumentUrl() + '/upload/contractors/' + contractorId, file, comment);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addFile(contractorId: number, triageId: number, documentId: string): Observable<any> {
        return this.httpClient.post(this.helper.getTriageUrl() + `/contractors/${contractorId}/triage/${triageId}/documents/${documentId}`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getUnreadTriagesCount(contractorId: number): Observable<any> {
        return this.httpClient.get(this.helper.getErpRootUrl() + `api/contractors/${contractorId}/etriages/count`);
    }
}
//triaže za posameznega kontraktorja
// "erp/triageapi/contractors/{contractorid}/triage"

// "/contractors/{contractorid}/triage/{triageId}/documents/{documentId}"

// "/contractors/{contractorid}/triage/{triageId}/comments"
