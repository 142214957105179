import { Injectable } from '@angular/core';
// other
import * as moment from 'moment';
import { Observable } from 'rxjs';
// rxjs
import { map } from 'rxjs/operators';
// models
import { Customer, CustomerHistory } from '../../../../_shared/model';
import { Consent } from '../../../../_shared/model/consent.model';
import { Reservation } from '../../../../_shared/model/reservation.model';
import { HelperService, StorageService } from '../../../../_shared/services';
// services
import { HttpClientService } from '../../../../_shared/services/http-client.service';
import { CustomerGuardian } from './../../../../_shared/model/customer/customer-guardian.model';

@Injectable()
export class CustomerService {
    constructor(
        private httpClient: HttpClientService,
        private storage: StorageService,
        private helper: HelperService,
    ) {}

    public findSimilarCustomersCustomer(contractorId: number, customerid: number): Observable<Customer[]> {
        return this.httpClient.get(this.helper.getApiUrl() + `/contractors/${contractorId}/customers/${customerid}/similar`).pipe(
            map(el => {
                if (el.customers) {
                    return el.customers;
                }
                return undefined;
            }),
        );
    }

    //createCustomer()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public createCustomer(data: any, force?: boolean): Observable<any> {
        let forceString = '';

        if (force) {
            forceString = '?force=true';
        }
        return this.httpClient.post(
            this.helper.getApiUrl() + '/contractors/' + this.storage.getSelectedContractor().id + '/customers' + forceString,
            data,
        );
    }
    //getCustomers
    public getAllCustomers(contractorId: number): Observable<Customer[]> {
        return this.httpClient.get(this.helper.getApiUrl(2) + '/contractors/' + contractorId + '/customers');
    }
    //getCustomerInfo
    public getCustomer(contractorId: number, customerId: number): Observable<Customer> {
        return this.httpClient.get(this.helper.getApiUrl(2) + '/contractors/' + contractorId + '/customers/' + customerId);
    }
    //updateCustomer()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    public updateCustomer(contractorId: number, customerId: number, data: Customer, gdprConsent: boolean = false): Observable<any> {
        const url = `/contractors/${contractorId}/customers/${customerId}`;

        // HF-3.8.4; MD-2978
        // if (gdprConsent) {
        //     url += `?gdprConsent=true`;
        // }
        return this.httpClient.put(this.helper.getApiUrl() + url, data);
    }
    //getCustomerVisitsHistory()
    public getCustomerHistory(contractorId: number, customerId: number, from?: moment.Moment, to?: moment.Moment): Observable<CustomerHistory[]> {
        let url = '';
        if (!from || !to) {
            url = this.helper.getVisitUrl(2) + `/contractors/${contractorId}/customers/${customerId}/visits`;
        } else {
            if (from.toJSON() == null && to.toJSON() == null) {
                url = this.helper.getVisitUrl(2) + `/contractors/${contractorId}/customers/${customerId}/visits`;
            } else {
                url =
                    this.helper.getVisitUrl(2) +
                    `/contractors/${contractorId}/customers/${customerId}/visits?from=${from.format('YYYY-MM-DD')}&to=${to.format('YYYY-MM-DD')}`;
            }
        }
        return this.httpClient.get(url).pipe(map(res => res.visits));
    }

    public getCustomerReservations(contractorId: number, customerId: number, from: moment.Moment, to: moment.Moment): Observable<Reservation[]> {
        if ((from === null && to === null) || (from.toJSON() == null && to.toJSON() == null)) {
            return this.httpClient.get(this.helper.getApiUrl() + '/contractors/' + contractorId + '/customers/' + customerId + '/reservations');
        } else {
            return this.httpClient.get(
                this.helper.getApiUrl() +
                    '/contractors/' +
                    contractorId +
                    '/customers/' +
                    customerId +
                    '/reservations?from=' +
                    from.format('YYYY-MM-DD') +
                    '&to=' +
                    to.format('YYYY-MM-DD'),
            );
        }
    }

    public getCustomerConsent(contractorId: number, customerId: number): Observable<Consent> {
        return this.httpClient.get(this.helper.getApiUrl() + '/contractors/' + contractorId + '/customers/' + customerId + '/consent');
    }

    public updateCustomerConsent(contractorId: number, customerId: number, consent: Consent): Observable<Consent> {
        return this.httpClient.put(this.helper.getApiUrl() + '/contractors/' + contractorId + '/customers/' + customerId + '/consent', consent);
    }

    public sendConsentMail(contractorId: number, customerId: number): Observable<Consent> {
        return this.httpClient.post(this.helper.getApiUrl() + '/contractors/' + contractorId + '/customers/' + customerId + '/consent/mail');
    }

    //deactivateCustomer()
    public deactivateCustomer(contractorId: number, customerId: number): Observable<Consent> {
        return this.httpClient.delete(this.helper.getApiUrl() + `/contractors/${contractorId}/customers/${customerId}`);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getCustomerSurveys(contractorId: number): Observable<any> {
        return this.httpClient.get(this.helper.getSurveysUrl() + '/contractors/' + contractorId);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getSingleCustomerSurveys(contractorId: number, customerId: number): Observable<any> {
        return this.httpClient.get(this.helper.getSurveysUrl() + '/contractors/' + contractorId + '/customers/' + customerId);
    }

    //guardian
    //http://localhost:9190/booking/eav2/erp/api/contractors/54/customers/1225/guardians/5022'
    public appCustomerGuardian(contractorId: number, customerId: number, guardian: CustomerGuardian): Observable<CustomerGuardian[]> {
        return this.httpClient.post(
            this.helper.getApiUrl(2) + `/contractors/${contractorId}/customers/${customerId}/guardians/${guardian?.id}`,
            guardian,
        );
    }

    public getCustomerGuardians(contractorId: number, customerId: number): Observable<CustomerGuardian[]> {
        return this.httpClient.get(this.helper.getApiUrl(2) + `/contractors/${contractorId}/customers/${customerId}/guardians`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteCustomerGuardians(contractorId: number, customerId: number, guardianId: number): Observable<any> {
        return this.httpClient.delete(this.helper.getApiUrl(2) + `/contractors/${contractorId}/customers/${customerId}/guardians/${guardianId}`);
    }
    // http://10.230.33.13:9190/booking/eav2/erp/apibooking/eav2/erp/api/contractors/54/gdprinfo?marketing=true
    // http://localhost:9190/booking/eav2/erp/api/contractors/54/gdprinfo?marketing=true

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public getCustomerGDPRListFiltered(contractorId: number, filter?: string): Observable<Customer[]> {
        // možna filtra sta še informing in/ali survey

        const url = `/contractors/${contractorId}/gdprinfo?marketing=true`;
        return this.httpClient.get(this.helper.getApiUrl(2) + url);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public connectUserToSuperUser(contractorId: number, carrierId: number, customerId?: number, carrier?: boolean): Observable<any> {
        let url = this.helper.getCustomerDataUrl() + `/contractors/${contractorId}/joinedcustomers/${carrierId}`;
        if (customerId) url += `/${customerId}`;
        if (carrier) url += `?carrier=true`;
        return this.httpClient.post(url);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public removeSuperUserConnection(contractorId: number, customerId: number): Observable<any> {
        return this.httpClient.delete(this.helper.getCustomerDataUrl() + `/contractors/${contractorId}/joinedcustomers/${customerId}`);
    }
    public getCustomersForGroup(contractorId: number, groupId: number): Observable<Customer[]> {
        return this.httpClient.get(this.helper.getCustomerDataUrl() + `/contractors/${contractorId}/joingroups/${groupId}/customers`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getAllChatsForCustomer(contractorId: number, customerId: number): Observable<any[]> {
        return this.httpClient.get(this.helper.getErpRootUrl() + `api/contractors/${contractorId}/customers/${customerId}/telemed/chat`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deletePortfolioPaperFromCustomer(contractorid: number, paperId: number): Observable<any> {
        return this.httpClient.delete(this.helper.getVisitUrl() + `/contractors/${contractorid}/portfolio/${paperId}`);
    }
}
