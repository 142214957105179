import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { ResultSet } from './../../../../../../../_shared/model/resultSet.model';
import { HelperService } from './../../../../../../../_shared/services/helper.service';

@Component({
    selector: 'pf-p-comn-input-text',
    templateUrl: './pf-p-comn-input-text.component.html',
    styleUrls: ['./pf-p-comn-input-text.component.scss'],
})
export class PfPComnInputTextComponent implements OnInit {
    @Input() public data: ResultSet;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() public settings: any;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() public onDelete: EventEmitter<boolean>;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix, @typescript-eslint/no-explicit-any
    @Output() public onSave: EventEmitter<any>;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix, @typescript-eslint/no-explicit-any
    @Output() public onChange: EventEmitter<any>;

    // public dateChanged: boolean = false;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public dataModel: any;
    public term$ = new Subject<string>();
    //https://www.tinymce.com/docs-3x//reference/buttons/
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public tmceSettings: any = {
        base_url: '/tinymce',
        suffix: '.min',
        menubar: false,
        branding: false,
        statusbar: true,
        resize: true,
        toolbar: 'bold | italic | link | fullscreen',
        plugins: 'paste, link, fullscreen',
        paste_as_text: true,
        contextmenu: false,
        // visualchars_default_state: true,
        setup: editor => {
            editor.on('keyup change', () => {
                const content = editor.getContent();
                this.term$.next(content);
                this.onChange.emit();
                // console.info(content);
            });
            editor.on('FullscreenStateChanged', function (e) {
                if (e.state) {
                    $('.modal-dialog').attr('style', 'transform: none !important');
                } else {
                    $('.modal-dialog').attr('style', 'transform: translate(0,0)');
                }
            });
        },
    };
    public icon: string;
    constructor(private helper: HelperService) {
        this.onDelete = new EventEmitter();
        this.onSave = new EventEmitter();
        this.onChange = new EventEmitter();
    }

    ngOnInit() {
        if (this.settings?.placeholder) {
            _.set(this.tmceSettings, 'placeholder', this.settings.placeholder);
        }
        if (this.settings) {
            this.tmceSettings = Object.assign(this.settings, this.tmceSettings);
        }
        if (this.data.title === 'Anamneza') {
            this.icon = 'la-street-view';
        } else if (this.data.title === 'Diagnoza') {
            this.icon = 'la-medkit';
        } else {
            this.icon = 'la-sticky-note';
        }
    }

    public deleteField(): void {
        this.helper.displayAlert('Brisanje sklopa.', 'Izbrišem izbrani sklop?').then(result => {
            if (result.value) {
                this.onDelete.emit(true);
            }
        });
    }

    public saveField(): void {
        // this.dateChanged = false;
        this.onSave.emit({ set: this.data });
    }

    // public dataChanged(x): void {
    //     this.dateChanged = true;
    //     // this.helper.disabledNavigation = true;
    // }
}
