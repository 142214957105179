import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { PaperType } from '../../../../../../_shared/enums/PaperType.enum';
import { Event, PaperHistoryElement, PortfolioPaper } from '../../../../../../_shared/model';
import { HelperService, MfToastService, StorageService } from '../../../../../../_shared/services';
import { ResultService } from '../../../_services';
import { PfPCommnComunicationService } from '../../papers/_common/pf-p-comn-communication.service';
import { Customer } from './../../../../../../_shared/model/customer.model';
import { BaseState } from './../../../../../../_shared/state/base/base.state';

@Component({
    selector: 'pf-comn-history-list',
    templateUrl: './pf-comn-history-list.component.html',
    styleUrls: ['./pf-comn-history-list.component.scss'],
})
export class PfComnHistoryListComponent implements OnInit {
    @Input()
    paper: PortfolioPaper;

    // @Input()
    // id: any;

    @Input()
    customer: Customer;

    @Input()
    event: Event;

    @Input()
    G100 = false;

    @Input()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sourceVisit: any;

    // @Output()
    // public onCopy: EventEmitter<PaperHistoryElement> = new EventEmitter();

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public allVisits: Observable<any[]>;
    //pagenacija
    public currentPage = 1;
    public selectedDay = true;
    public itemsPerPage = 3;
    public numberOfElements = 0;
    public selectedContractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);

    private onDestroy$ = new Subject<void>();

    constructor(
        private rest: ResultService,
        private storage: StorageService,
        private helper: HelperService,
        private toast: MfToastService,
        private comm: PfPCommnComunicationService,
        public store: Store,
    ) {}

    ngOnInit() {
        // let customerId = this.storage.getSelectedTerms(this.id)?.customer?.id;
        // if (this.customer) {
        //     customerId = this.customer.id;
        // }
        this.allVisits = this.rest.getCustomerPapersHistory(this.storage.getSelectedContractor()?.id, this.customer.id, this.paper?.paperType).pipe(
            map((array: PaperHistoryElement[]) =>
                array.filter(el => {
                    if (this.G100 && this.sourceVisit?.visitId) {
                        return el.visitId === this.sourceVisit?.visitId && el.paperId !== this.paper.id;
                    } else {
                        return el.paperId !== this.paper.id;
                    }
                }),
            ),
            tap((res: PaperHistoryElement[]) => (this.numberOfElements = res.length)),
        );
    }

    //     moment()
    //     .add(-3, 'year')
    //     .format('YYYY-MM-DD'),
    // moment()
    //     .add(1, 'week')
    //     .format('YYYY-MM-DD')

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    public resultsPreview(paperHistory: PaperHistoryElement, print: boolean): void {
        switch (this.paper.paperType) {
            case PaperType.IZVID:
            case PaperType.FTH_POROCILO:
            case PaperType.CONFIRM_VISIT:
                this.surveyPreview(paperHistory, print);
                break;
            case PaperType.SURVEY_CUSTOMER:
                this.surveyPreview(paperHistory, print, 'customer');
                break;
            case PaperType.CONFIRM_RESERVATION:
                this.surveyPreview(paperHistory, print, 'reservation');
                break;
            case PaperType.WHITE_REFERRAL:
                this.paperPreview(paperHistory, print);
                break;
            case PaperType.HELLODOC_REFERRAL:
                this.paperPreview(paperHistory, print);
                break;
            case PaperType.HELLODOC_IZVID:
                this.surveyPreview(paperHistory, print);
                break;
            case PaperType.MFMEDICAL_IZVID:
                this.surveyPreview(paperHistory, print);
                break;
            case PaperType.MEDIFIT_MEDICAL_REPORT:
                this.surveyPreview(paperHistory, print);
                break;
            default:
                break;
        }
    }

    private paperPreview(paperHistory: PaperHistoryElement, print: boolean = false): void {
        this.rest
            .printPortfolioPaper(this.storage.getSelectedContractor().id, paperHistory.paperId)
            .pipe(
                takeUntil(this.onDestroy$),
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                map((res: any) => {
                    if (res.type !== 0) {
                        return new Blob([res.body], {
                            type: 'application/pdf',
                        });
                    }
                }),
            )
            .subscribe(
                res => {
                    if (res) {
                        this.helper.openDocumentViewer(res, res.type, 'Izvid', print);
                    }
                },
                () => {
                    this.toast.errorAndSend('Napaka pri predogledu poročila.');
                },
            );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private surveyPreview(paperHistory: PaperHistoryElement | any, print: boolean, type: string = 'visit'): void {
        let rest;

        if (type == 'visit') {
            rest = this.rest.getSurveyPaperPreview(paperHistory.visitId, paperHistory.paperId);
        } else if (type == 'reservation') {
            rest = this.rest.getSurveyPaperPreviewReservation(paperHistory.prereservationId, paperHistory.paperId);
        } else {
            rest = this.rest.getPortfolioPaper(this.selectedContractorId, paperHistory.paperId);
        }
        rest.pipe(
            takeUntil(this.onDestroy$),
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            map((res: any) => {
                if (res.type !== 0) {
                    return new Blob([res.body], {
                        type: 'application/pdf',
                    });
                }
                // Helpers.setLoading(false);
            }),
        ).subscribe(
            res => {
                if (res) {
                    this.helper.openDocumentViewer(res, res.type, 'Izvid', print);
                }
            },
            () => {
                this.toast.errorAndSend('Napaka pri predogledu poročila.');
            },
        );
    }

    public copyData(paperHistory: PaperHistoryElement): void {
        this.helper.displayAlert('Kopiranje.', 'Izbrisala se bo vsebina poročila, nadaljujem?').then(result => {
            if (result.value) {
                this.comm.onCopy.emit(paperHistory);
            }
        });
    }
}
