<ng-template #inactive_module>Modul ni aktiven.</ng-template>
<div *ngIf="isDocumentationModuleActive; else inactive_module" class="m-content">
    <div class="row">
        <div class="col-xl-12 col-lg-12">
            <div class="m-portlet mb-4">
                <div class="m-portlet__body">
                    <div class="row">
                        <div class="col-lg-6">
                            <input
                                [(ngModel)]="searchString"
                                (keyup)="updateFilter()"
                                type="text"
                                autofocus
                                placeholder="Iskanje ...."
                                class="form-control m-input"
                                i18n-placeholder="@@c3695314fd46a1f136890678c3607a238e327624"
                            />
                        </div>
                        <div class="col-lg-3">
                            <ng-select
                                [(ngModel)]="searchSubcontractorsString"
                                [items]="allSubcontractors"
                                [clearable]="true"
                                (change)="updateFilter('Subcontractors')"
                                bindLabel="name"
                                placeholder="Podizvajalci"
                            ></ng-select>
                        </div>

                        <div class="col-lg-3">
                            <div class="m-input-icon m-input-icon--left">
                                <input
                                    [options]="options"
                                    (selected)="selectedDate($event, daterange)"
                                    type="text"
                                    name="daterangeInput"
                                    daterangepicker
                                    class="form-control m-input"
                                />
                                <span class="m-input-icon__icon m-input-icon__icon--left">
                                    <span>
                                        <i class="la la-calendar"></i>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">Seznam vseh terminov</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body">
                    <ngx-datatable
                        [messages]="helper.ngxDataTableTransletedMessages"
                        [rows]="allReservationsData"
                        [columnMode]="'flex'"
                        [limit]="15"
                        [rowHeight]="70"
                        [footerHeight]="50"
                        [rowClass]="getRowClass"
                        [selectionType]="'single'"
                        (select)="onSelect($event)"
                        (activate)="onActivate($event)"
                        class="material striped"
                    >
                        <ngx-datatable-column [canAutoResize]="false" [width]="180" name="Datum" prop="reservationFrom">
                            <ng-template ngx-datatable-cell-template let-value="value">
                                {{ value | date: 'dd.MM.yyyy' }} ob {{ value | date: 'HH:mm' }}
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="2" name="Stranka" prop="customerName">
                            <ng-template ngx-datatable-cell-template let-value="value">
                                {{ value }}
                            </ng-template>
                        </ngx-datatable-column>
                        <ng-container *ngIf="isHDModuleActive">
                            <ngx-datatable-column [flexGrow]="1" [maxWidth]="80" [sortable]="false" name="Storitev" prop="serviceName">
                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                    <span ngbTooltip="{{ value }}">
                                        <i class="la" [ngClass]="getIconClass(row)"></i>
                                    </span>
                                </ng-template>
                            </ngx-datatable-column>
                        </ng-container>
                        <ng-container *ngIf="!isHDModuleActive">
                            <ngx-datatable-column [flexGrow]="2" name="Storitev" prop="serviceName">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                    <span [disableTooltip]="value?.length < 25" ngbTooltip="{{ value }}">
                                        {{ value }}
                                    </span>
                                </ng-template>
                            </ngx-datatable-column>
                        </ng-container>
                        <ngx-datatable-column [flexGrow]="2" name="Podizvajalec" prop="subcontractorName">
                            <ng-template ngx-datatable-cell-template let-value="value">
                                <b>{{ value }}</b>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="1" [maxWidth]="80" name="Naročnik" prop="bsId">
                            <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                <common-business-subject-icon
                                    *ngIf="value || (!value && !row?.reservationType)"
                                    [onlyIcon]="true"
                                    [setBsId]="value || -999"
                                ></common-business-subject-icon>
                                <common-hd-app-icon
                                    *ngIf="!value && row?.reservationType"
                                    [height]="20"
                                    [onlyIcon]="true"
                                    [setApp]="row?.reservationType"
                                ></common-hd-app-icon>
                            </ng-template>
                        </ngx-datatable-column>
                        <ngx-datatable-column [flexGrow]="3" [cellClass]="'documents-overflow'" name="Dokumentacija" prop="papers" class="p-1">
                            <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                <div *ngIf="value?.length; else noDcoumentation" style="overflow-x: auto" class="d-flex paper-container">
                                    <documentation-overview-element
                                        *ngFor="let paper of value"
                                        [paper]="paper"
                                        (click)="popupPaper($event, paper.paperType, paper, row); $event.stopPropagation()"
                                        class="d-flex"
                                    ></documentation-overview-element>
                                </div>
                                <ng-template #noDcoumentation>Ni dokumentacije</ng-template>
                            </ng-template>
                        </ngx-datatable-column>
                        <ng-container *ngIf="isHDModuleActive">
                            <ngx-datatable-column [flexGrow]="2" name="Izhod" prop="hdConcludeStatus">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                    <span *ngIf="value; else noExit">
                                        <b>{{ value | visitExitPipe }}</b>
                                    </span>
                                    <ng-template #noExit>/</ng-template>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column [flexGrow]="3" name="Komentar" prop="hdConcludeComment">
                                <ng-template ngx-datatable-cell-template let-value="value">
                                    <span *ngIf="value">
                                        {{ value }}
                                    </span>
                                </ng-template>
                            </ngx-datatable-column>
                            <ngx-datatable-column [flexGrow]="1" [maxWidth]="70" [sortable]="false" (click)="$event.stopPropagation()" name="Status">
                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                    <div class="d-flex align-items-center">
                                        <ng-container *ngIf="isAbleToChangeStatus; else readOnlyStatus">
                                            <div ngbDropdown container="body">
                                                <button
                                                    (click)="$event.stopPropagation()"
                                                    container="body"
                                                    ngbTooltip="{{
                                                        getDocumentationStatus(row) === 'DONE'
                                                            ? 'Izpolnjen'
                                                            : getDocumentationStatus(row) === 'MISSING'
                                                            ? 'Manjkajoč'
                                                            : 'Neizpolnjen'
                                                    }} pogoj "
                                                    style="border: none"
                                                    ngbDropdownToggle
                                                    class="btn btn-outline-primary btn-sm"
                                                >
                                                    <span
                                                        class="m-badge"
                                                        [ngClass]="{
                                                            'm-badge--success': getDocumentationStatus(row) === 'DONE',
                                                            'm-badge--warning': getDocumentationStatus(row) === 'IN_PROGRESS',
                                                            'm-badge--danger': getDocumentationStatus(row) === 'MISSING'
                                                        }"
                                                    ></span>
                                                </button>
                                                <div ngbDropdownMenu>
                                                    <button (click)="changeTermStatus(row, 'DONE')" ngbDropdownItem>
                                                        <span class="m-badge m-badge--success mr-1"></span>
                                                        Izpolnjen
                                                    </button>
                                                    <button (click)="changeTermStatus(row, 'MISSING')" ngbDropdownItem>
                                                        <span class="m-badge m-badge--danger mr-1"></span>
                                                        Manjkajoč
                                                    </button>
                                                    <button (click)="changeTermStatus(row, 'IN_PROGRESS')" ngbDropdownItem>
                                                        <span class="m-badge m-badge--warning mr-1"></span>
                                                        Neizpolnjen
                                                    </button>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-template #readOnlyStatus>
                                            <span
                                                ngbTooltip="{{
                                                    getDocumentationStatus(row) === 'DONE'
                                                        ? 'Izpolnjen'
                                                        : getDocumentationStatus(row) === 'MISSING'
                                                        ? 'Manjkajoč'
                                                        : 'Neizpolnjen'
                                                }} pogoj "
                                                class="m-badge"
                                                [ngClass]="{
                                                    'm-badge--success': getDocumentationStatus(row) === 'DONE',
                                                    'm-badge--warning': getDocumentationStatus(row) === 'IN_PROGRESS',
                                                    'm-badge--danger': getDocumentationStatus(row) === 'MISSING'
                                                }"
                                            ></span>
                                        </ng-template>
                                        <div
                                            *ngIf="row.documentationStatus"
                                            container="body"
                                            ngbTooltip="Status je bil ročno spremenjen"
                                            class="ml-1"
                                        >
                                            <b>!</b>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngx-datatable-column>
                        </ng-container>
                        <ng-container *ngIf="!isHDModuleActive">
                            <ngx-datatable-column [width]="80" name="Račun" prop="invoiceExists">
                                <ng-template ngx-datatable-cell-template let-value="value" let-row="row">
                                    <span
                                        ngbTooltip="
                                            {{ value ? 'Račun kreiran' : 'Manjkajoč račun' }}
                                        "
                                        class="la"
                                        [ngClass]="{
                                            'la-check text-success': value,
                                            'la-times text-danger': !value
                                        }"
                                    ></span>
                                </ng-template>
                            </ngx-datatable-column>
                        </ng-container>
                    </ngx-datatable>
                </div>
            </div>
        </div>
    </div>
</div>
