import { NgModule } from '@angular/core';
import { CanLeaveComponent } from './can-leave.component';
import { CanLeaveService } from './can-leave.service';

@NgModule({
    imports: [],
    declarations: [CanLeaveComponent],
    providers: [CanLeaveService],
    exports: [CanLeaveComponent],
})
export class CanLeaveModule {}
