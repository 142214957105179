import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DocumentSoucePipe } from './../../_shared/pipes/document-souce.pipe';
import { ModuleNamePipe } from './../../_shared/pipes/module-name.pipe';
import { PaperNamePipe } from './../../_shared/pipes/paper-name.pipe';
import { ServiceTypePipe } from './../../_shared/pipes/service-type.pipe';
import { TphCallNumberDirective } from './directives/tph-call-number.directive';
import { DocumentListElementComponent } from './document-viewer/document-list-element/document-list-element.component';
import { DocumentViewerComponent } from './document-viewer/document-viewer.component';
import { OnboardingWizardComponent } from './onboarding-wizard/onboarding-wizard.component';

@NgModule({
    imports: [CommonModule, NgbModule, FormsModule, ReactiveFormsModule, RouterModule],
    declarations: [
        TphCallNumberDirective,
        DocumentViewerComponent,
        DocumentListElementComponent,
        DocumentSoucePipe,
        PaperNamePipe,
        ServiceTypePipe,
        OnboardingWizardComponent,
        ModuleNamePipe,
    ],
    exports: [
        TphCallNumberDirective,
        DocumentViewerComponent,
        DocumentListElementComponent,
        DocumentSoucePipe,
        PaperNamePipe,
        ServiceTypePipe,
        OnboardingWizardComponent,
        ModuleNamePipe,
    ],
})
export class BasicComponentsModule {}
