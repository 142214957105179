import * as _ from 'lodash';
import { Serializable } from '../deserialize.service';

export class Resource implements Serializable<Resource> {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    id: any;
    eventColor: string;
    eventBackgroundColor: string;
    eventBorderColor: string;
    eventTextColor: string;
    eventClassName: string;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    businessHours: any;
    children: string;
    parentId = false;
    parent = false;

    deserialize(input) {
        if (_.isNil(input)) {
            return this;
        }
        this.id = input.id;
        this.eventColor = input.eventColor;
        this.eventBackgroundColor = input.eventBackgroundColor;
        this.eventBorderColor = input.eventBorderColor;
        this.eventTextColor = input.eventTextColor;
        this.eventClassName = input.eventClassName;
        this.businessHours = input.businessHours;
        this.children = input.children;
        this.parentId = input.parentId;
        this.parent = input.parent;

        return this;
    }
}
