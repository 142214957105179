export enum PaymentType {
    GROUP_INVOICE = 'GROUP_INVOICE',
    CASH = 'CASH',
    DEBIT_CARD = 'DEBIT_CARD',
    CREDIT_CARD = 'CREDIT_CARD',
    VOUCHER = 'VOUCHER',
    TRR = 'TRR',
    SELECT_BOX = 'SELECT_BOX',
    ZVEZDAR = 'ZVEZDAR',
    HISA_DARIL = 'HISA_DARIL',
    MAESTRO = 'CARD_MAESTRO',
    MASTERCARD = 'CARD_MASTERCARD',
    VISA = 'CARD_VISA',
    DINERS = 'CARD_DINERS',
    ONLINE_PAYMENT = 'ONLINE_PAYMENT',
    PREAUTHORIZE = 'PREAUTHORIZE',
    BON21 = 'BON21',
    ZZZS = 'ZZZS',
}
