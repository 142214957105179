import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'labScientificNotation',
})
export class LabScientificNotationPipe implements PipeTransform {
    transform(value: string): string {
        const regex = /^(\d+)E(\d+)\/([A-Za-z])$/;
        const match = value?.match(regex);

        if (match) {
            const base = match[1];
            const exponent = match[2];
            const unit = match[3];
            return `${base}<sup>${exponent}</sup>/${unit}`;
        } else {
            return value;
        }
    }
}
