import { Serializable } from './deserialize.service';

export class BusinessSubject implements Serializable<BusinessSubject> {
    id: number;
    name: string;

    deserialize(input) {
        this.id = input.id;
        if (input.comentatorName) {
            this.name = input.comentatorName;
        } else {
            this.name = input.name;
        }
        return this;
    }
}
