import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Reservation } from './../../../../_shared/model/reservation.model';
import { PrereservationId } from './../../../../_shared/model/schedule/prereservationId.model';
import { ConfirmReservationsRequest } from './../../../../_shared/model/treatment/confirmReservationsRequest.model';
import { CreatePrereservationRequest } from './../../../../_shared/model/treatment/createPrereservationRequest.model';
import { CreateTreatmentPrereservationsRequest } from './../../../../_shared/model/treatment/createTreatmentPrereservationsRequest.model';
import { GeneratedTreatmentPrereservation } from './../../../../_shared/model/treatment/generatedTreatmentPrereservation.model';
import { TreatmentNotesRequest } from './../../../../_shared/model/treatment/treatment-notes-request.model';
import { TreatmentNote } from './../../../../_shared/model/treatment/treatment-notes.model.';
import { Treatment } from './../../../../_shared/model/treatment/treatment.model';
import { HelperService } from './../../../../_shared/services/helper.service';
import { HttpClientService } from './../../../../_shared/services/http-client.service';

@Injectable({
    providedIn: 'root',
})
export class TreatmentService {
    constructor(
        private httpClient: HttpClientService,
        private helper: HelperService,
    ) {}

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public validatePrereservations(prereservations: CreatePrereservationRequest[]): Observable<any> {
        return this.httpClient.post(this.helper.getTreatmentUrl() + '/validate/prereservation', prereservations);
    }

    public getTreatmentPrereservations(
        contractorid: number,
        subcontractorid: number,
        prereservations: CreateTreatmentPrereservationsRequest,
    ): Observable<GeneratedTreatmentPrereservation[]> {
        return this.httpClient.post(
            this.helper.getTreatmentUrl() + `/contractors/${contractorid}/subcontractors/${subcontractorid}/prereservations`,
            prereservations,
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public createTreatment(contractorid: number, subcontractorid: number, treatment: Treatment): Observable<any> {
        let url = `/contractors/${contractorid}`;
        if (subcontractorid) {
            url += `/subcontractors/${subcontractorid}`;
        }
        return this.httpClient.post(this.helper.getTreatmentUrl() + url, treatment);
    }

    public getAllTreatmentsCustomer(contractorid: number, customerid: number): Observable<Treatment[]> {
        return this.httpClient.get(this.helper.getTreatmentUrl() + `/contractors/${contractorid}/customer/${customerid}`);
    }

    public getAllTreatments(contractorid: number): Observable<Treatment[]> {
        return this.httpClient.get(this.helper.getTreatmentUrl() + `/contractors/${contractorid}`);
    }

    public makePrereservations(contractorid: number, prepres: CreatePrereservationRequest[]): Observable<PrereservationId[]> {
        return this.httpClient.post(this.helper.getTreatmentUrl() + `/contractors/${contractorid}/makePrereservations`, prepres);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public confirmPrereservations(contractorid: number, treatmentid: number, confprere: ConfirmReservationsRequest): Observable<any> {
        return this.httpClient.post(
            this.helper.getTreatmentUrl() + `/contractors/${contractorid}/treatments/${treatmentid}/confirmPrereservations`,
            confprere,
        );
    }

    //getTreatmentReservations
    public getTreatmentReservations(contractorid: number, treatmentid: number): Observable<Reservation[]> {
        return this.httpClient.get(this.helper.getTreatmentUrl() + `/contractors/${contractorid}/treatments/${treatmentid}/reservations`);
    }

    public getTreatmentDetails(contractorid: number, treatmentid: number): Observable<Treatment> {
        return this.httpClient.get(this.helper.getTreatmentUrl() + `/contractors/${contractorid}/treatments/${treatmentid}`);
    }

    public getTreatmenNotes(contractorid: number, treatmentid: number): Observable<TreatmentNote[]> {
        return this.httpClient.get(this.helper.getTreatmentUrl(3) + `/contractors/${contractorid}/treatments/${treatmentid}/notes`).pipe(
            map((notes: TreatmentNote[]) => {
                return notes.map(el => {
                    el.eventDate = moment(el.eventDate);
                    el.created = moment(el.created);
                    el.internalId = this.helper.uuid();
                    return el;
                });
            }),
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateTreatmenNotes(contractorid: number, treatmentid: number, data: TreatmentNotesRequest): Observable<any> {
        return this.httpClient.post(this.helper.getTreatmentUrl(3) + `/contractors/${contractorid}/treatments/${treatmentid}/notes`, data);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public removeTreatmenNotes(contractorid: number, treatmentid: number, noteId: number): Observable<any> {
        return this.httpClient.delete(this.helper.getTreatmentUrl(3) + `/contractors/${contractorid}/treatments/${treatmentid}/notes/${noteId}`, {
            responseType: 'text',
        });
    }
}
