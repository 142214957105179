import { Injectable } from '@angular/core';
import 'rxjs';
import { Observable } from 'rxjs';
import { BusinessSubjectConnection, PriceList, PriceListPut, Service } from '../../../../_shared/model';
import { GenericModuleSettings } from '../../../../_shared/model/app/genericModuleSettings';
import { SchedulerRules } from '../../../../_shared/model/schedulerRules.model';
import { WorkingIntervals } from '../../../../_shared/model/workingIntervals.model';
import { HelperService } from '../../../../_shared/services';
import { HttpClientService } from '../../../../_shared/services/http-client.service';
import { Module } from './../../../../_shared/model/app/module.model';
import { Settings } from './../../../../_shared/model/app/settings.model';
import { ContractorBasicInfo } from './../../../../_shared/model/contractorBasicInfo.model';
import { ServiceGroup } from './../../../../_shared/model/serviceGroup.model';
import { ServiceGroupModel } from './../../../../_shared/model/settings/serviceGroup.model';
import { ServiceGroupRequestModel } from './../../../../_shared/model/settings/serviceGroupRequest.model';
import { WidgetLocation } from './../../../../_shared/model/settings/widget-location.model';
import { WidgetRequest } from './../../../../_shared/model/settings/widget-request.model';
import { Widget } from './../../../../_shared/model/settings/widget.model';

@Injectable()
export class SettingsService {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    token: any;

    constructor(
        private httpClient: HttpClientService,
        private helper: HelperService,
    ) {}

    public getContractorServiceGroups(contractorId: number): Observable<ServiceGroup[]> {
        // @Get("/contractors/{contractorid}/services/groups")
        return this.httpClient.get(this.helper.getApiUrl(2) + `/contractors/${contractorId}/services/groups`);
    }

    public addContractorServiceGroup(contractorId: number, data: ServiceGroupModel) {
        // @Post("/contractors/{contractorid}/services/groups")
        return this.httpClient.post(this.helper.getApiUrl(2) + `/contractors/${contractorId}/services/groups`, data);
    }
    public getServiceGroupData(contractorId: number, groupId: number) {
        // @Get("/contractors/{contractorid}/services/groups/{groupid}/details")
        return this.httpClient.get(this.helper.getApiUrl(2) + `/contractors/${contractorId}/services/groups/${groupId}/details`);
    }
    public editServicesInGroup(contractorId: number, groupId: number, data: ServiceGroupRequestModel) {
        // @Post("/contractors/{contractorid}/services/groups/{groupid}/details")
        return this.httpClient.post(this.helper.getApiUrl(2) + `/contractors/${contractorId}/services/groups/${groupId}/details`, data);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteServiceInGroup(contractorId: number, groupId: number, data: any): Observable<any> {
        // @Delete("/contractors/{contractorid}/services/groups/{groupid}/details")
        // return this.httpClient.delete(
        //     this.helper.getApiUrl(2) + `/contractors/${contractorId}/services/groups/${groupId}/details`,
        //     data,
        // );

        return this.httpClient.post(
            this.helper.getApiUrl(2) + '/contractors/' + contractorId + '/services/groups/' + groupId + '/details/delete',
            data,
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteServiceGroup(contractorId: number, groupId: number): Observable<any> {
        // @Delete("/contractors/{contractorid}/services/groups/{groupid}")
        return this.httpClient.delete(this.helper.getApiUrl(2) + `/contractors/${contractorId}/services/groups/${groupId}`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateServiceActiveStatus(contractorid: number, serviceId: number, status: boolean): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl(2) + `/contractors/${contractorid}/services/${serviceId}/active/${status}`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public exportAllServices(contractorid: number): Observable<any> {
        return this.httpClient.getAttachment(this.helper.getApiUrl(2) + `/contractors/${contractorid}/services/excel`);
    }

    //sendNewSubcontractorRequest()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public sendNewSubcontractorRequest(contractorid: number, newSubcontractor: any): Observable<any> {
        return this.httpClient.post(this.helper.getApiUrl() + '/contractors/' + contractorid + '/subcontractors/new', newSubcontractor);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public createUserWithExistingEaAccount(contractorid: number, newUser: any): Observable<any> {
        return this.httpClient.post(this.helper.getApiUrl() + '/contractors/' + contractorid + '/contractorUserLinkRequest', newUser);
    }

    // .delete(
    //     'http://10.50.45.69:9090/booking/erp/api' +
    //         '/contractors/' +
    //         this.storage.getSelectedContractor().id +
    //         '/assets/' +
    //         data.value.id,
    //     data.value,
    // )

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public createNewAsset(contractorid: number, data: any): Observable<any> {
        return this.httpClient.post(this.helper.getApiUrl() + '/contractors/' + contractorid + '/assets', data);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateAsset(contractorid: number, assetId: number, data: any): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl() + '/contractors/' + contractorid + '/assets/' + assetId, data);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deleteAsset(contractorid: number, assetId: number, data: any): Observable<any> {
        return this.httpClient.delete(this.helper.getApiUrl() + '/contractors/' + contractorid + '/assets/' + assetId, data);
    }

    //defineSlotRules()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public defineSlotRules(contractorid: number, subcontractorid: number, schedulerRules: SchedulerRules): Observable<any> {
        // const data = {
        //     title: title,
        //     content: content
        // };
        return this.httpClient.post(
            this.helper.getApiUrl() + '/slotrules/contractors/' + contractorid + '/subcontractors/' + subcontractorid,
            schedulerRules,
        );
    }
    // getSlotRules()
    public getSlotRules(contractorid: number, subcontractorid: number): Observable<SchedulerRules> {
        return this.httpClient.get(this.helper.getApiUrl() + '/slotrules/contractors/' + contractorid + '/subcontractors/' + subcontractorid);
    }

    public getWorkingHours(
        contractorid: number,
        subcontractorid: number,
        from: string,
        to: string,
        isTemplate?: boolean,
    ): Observable<SchedulerRules> {
        const url = `/workinghours/contractors/${contractorid}/subcontractors/${subcontractorid}?from=${from}&to=${to}${
            isTemplate ? '&template=true' : ''
        }`;

        return this.httpClient.get(this.helper.getApiUrl() + url);
    }

    public getAllWorkingHours(contractorid: number, from: string, to: string): Observable<SchedulerRules> {
        return this.httpClient.get(this.helper.getApiUrl() + '/workinghours/contractors/' + contractorid + '?from=' + from + '&to=' + to);
    }
    // http://localhost:9090/booking/erp/api/workinghours/contractors/54?from=2019-06-01&to=2019-07-30
    //updateWorkingHours()
    public updateWorkingHours(
        contractorid: number,
        subcontractorid: number,
        data: SchedulerRules,
        force: boolean = false,
        isTemplate: boolean = false,
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Observable<any> {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dataPut: any = data;
        dataPut.force = force;

        return this.httpClient.put(
            this.helper.getApiUrl() +
                `/workinghours/contractors/${contractorid}/subcontractors/${subcontractorid}${isTemplate ? '?template=true' : ''}`,
            dataPut,
        );
    }

    public deleteWorkingHours(
        contractorid: number,
        subcontractorid: number,
        timeSlotId: string,
        from?: string,
        at?: string,
    ): Observable<SchedulerRules> {
        let url = `/workinghours/contractors/${contractorid}/subcontractors/${subcontractorid}/timeslots/${timeSlotId}`;
        if (from) {
            url = url + `?from=${from}`;
        } else if (at) {
            url = url + `?at=${at}`;
        }
        return this.httpClient.delete(this.helper.getApiUrl() + url);
    }

    public editWorkingHours(
        contractorid: number,
        subcontractorid: number,
        timeSlotId: string,
        data: WorkingIntervals,
        from?: string,
        diff?: number,
    ): Observable<SchedulerRules> {
        let url = `/workinghours/contractors/${contractorid}/subcontractors/${subcontractorid}/timeslots/${timeSlotId}`;
        if (from) {
            url = url + `?from=${from}&diff=${diff}`;
        } else {
            url = url + `?diff=${diff}`;
        }
        return this.httpClient.put(this.helper.getApiUrl() + url, data);
    }

    //updateContractor()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateContractor(contractorid: number, data: ContractorBasicInfo): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl() + '/contractors/' + contractorid, data);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getSubContractorsServicesSetup(contractorid): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl() + `/services/manage/${contractorid}/type/MEDIFIT`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateSubContractorsServicesSetup(contractorid, data: any): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl() + '/services/manage/' + contractorid, data);
    }

    //updateContractorService()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateContractorService(contractorId: number, serviceId: number, data: Service, checkPartnersPriceList: boolean = false): Observable<any> {
        const url =
            this.helper.getApiUrl() + '/contractors/' + contractorId + '/services/' + serviceId + `?checkPartnersPriceList=${checkPartnersPriceList}`;
        return this.httpClient.put(url, data);
    }

    //addContractorService()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addContractorService(contractorId: number, data: Service): Observable<any> {
        return this.httpClient.post(this.helper.getApiUrl() + '/contractors/' + contractorId + '/services', data);
    }

    //removeContractorService()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public removeContractorService(contractorId: number, serviceId: number, checkPartnersPriceList: boolean = false): Observable<any> {
        const url =
            this.helper.getApiUrl() + '/contractors/' + contractorId + '/services/' + serviceId + `?checkPartnersPriceList=${checkPartnersPriceList}`;
        return this.httpClient.delete(url);
    }
    //getBusinessSubjects()
    public getBusinessSubjects(contractorid: number): Observable<BusinessSubjectConnection[]> {
        return this.httpClient.get(this.helper.getApiUrl(2) + `/contractors/${contractorid}/bs`);
    }

    //updateBusinessSubjectsContractorSetup()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateBusinessSubjectsContractorSetup(contractorid: number, bsid: number, data: BusinessSubjectConnection): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl() + `/contractors/${contractorid}/bs/${bsid}`, data);
    }

    //getContractorPriceLists()
    public getContractorPriceLists(contractorid: number, bsid: number): Observable<PriceList[]> {
        return this.httpClient.get(this.helper.getApiUrl(2) + `/contractors/${contractorid}/bs/${bsid}/price-lists`);
    }

    //updateContractorPriceLists()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateContractorPriceLists(contractorid: number, bsid: number, data: PriceListPut): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl() + `/contractors/${contractorid}/bs/${bsid}/price-lists`, data);
    }

    //getContractorPriceListForId()
    public getContractorPriceListForId(contractorid: number, bsid: number, plistid: number): Observable<PriceList> {
        return this.httpClient.get(this.helper.getApiUrl() + `/contractors/${contractorid}/bs/${bsid}/price-lists/${plistid}`);
    }

    //getContractorActivePricelist()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getContractorActivePriceList(contractorid: number, bsid: number): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl() + `/contractors/${contractorid}/bs/${bsid}/price-lists/active`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getSendingContent(cid: number): Observable<any> {
        return this.httpClient.get(this.helper.getCommunicationUrl() + `/contractors/${cid}/templates`);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public setSendingContent(cid: number, data: any): Observable<any> {
        return this.httpClient.put(this.helper.getCommunicationUrl() + `/contractors/${cid}/templates`, data);
    }
    // /contractors/{contractorid}/notifications/rules"
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getSendingRules(cid: number): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl() + `/contractors/${cid}/notifications/rules`);
    }
    // ("/contractors/{contractorid}/notifications/rules"
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public setSendingRules(cid: number, data: any): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl() + `/contractors/${cid}/notifications/rules`, data);
    }
    // /booking/erp/communicationapi/templates
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getDefaultRules(): Observable<any> {
        return this.httpClient.get(this.helper.getCommunicationUrl() + '/templates');
    }

    //WIDGETS

    public getWidgetsList(contractorId: number): Observable<Widget[]> {
        return this.httpClient.get(this.helper.getWidgetUrl() + `/contractors/${contractorId}/widgets`);
    }

    public getWidget(contractorId: number, wid: string): Observable<Widget> {
        return this.httpClient.get(this.helper.getWidgetUrl() + `/contractors/${contractorId}/widgets/${wid}`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateWidget(contractorId: number, data: WidgetRequest, wid: string): Observable<any> {
        return this.httpClient.put(this.helper.getWidgetUrl() + `/contractors/${contractorId}/widgets/${wid}`, data, {
            responseType: 'text',
        });
    }

    public deleteWidget(contractorId: number, wid: string): Observable<Widget> {
        return this.httpClient.delete(this.helper.getWidgetUrl() + `/contractors/${contractorId}/widgets/${wid}`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public addWidget(contractorId: number, data: WidgetRequest): Observable<any> {
        return this.httpClient.post(this.helper.getWidgetUrl() + `/contractors/${contractorId}/widgets`, data, {
            responseType: 'text',
        });
    }

    public getContractorGroup(contractorId: number): Observable<WidgetLocation[]> {
        return this.httpClient.get(this.helper.getWidgetUrl() + `/contractors/${contractorId}/groupcontractors`);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deletePriceList(bsid: number, contractorid: number, id: number): Observable<any> {
        //delete only if priceList not confirmed AND not active
        return this.httpClient.delete(this.helper.getRootUrl(3, 'eav2/erp/bsapi') + `/bs/${bsid}/contractors/${contractorid}/price-lists/${id}`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getBSServiceExportExcel(contractorId: number, bsId: number, priceListId?: number): Observable<any> {
        return this.httpClient.getAttachment(
            this.helper.getErpRootUrl('bsapi') +
                `/contractors/${contractorId}/bs/${bsId}/services/excel${priceListId ? `?priceListId=${priceListId}` : ''}`,
        );
    }

    public getContractorModules(contractorId: number): Observable<Module<GenericModuleSettings>[]> {
        return this.httpClient.get(this.helper.getRootUrl(3, `eav2/settings/api/contractors/${contractorId}/modules`));
    }

    public getModules(): Observable<Module<GenericModuleSettings>[]> {
        return this.httpClient.get(this.helper.getRootUrl(3, `eav2/settings/api/modules`));
    }

    public activateContractorModules(contractorId: number, moduleId: number): Observable<Module<GenericModuleSettings>[]> {
        return this.httpClient.post(this.helper.getRootUrl(3, `eav2/settings/api/contractors/${contractorId}/modules/${moduleId}`));
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public deactivateContractorModules(contractorId: number, moduleId: number): Observable<any> {
        return this.httpClient.delete(this.helper.getRootUrl(3, `eav2/settings/api/contractors/${contractorId}/modules/${moduleId}`));
    }

    public getContractorSettings(contractorId: number): Observable<Settings> {
        return this.httpClient.get(this.helper.getRootUrl(3, `eav2/settings/api/contractors/${contractorId}/settings`));
    }

    public setContractorSettingsBillingPlan(contractorId: number, billingPlan: string): Observable<Settings> {
        return this.httpClient.post(this.helper.getRootUrl(3, `eav2/settings/api/contractors/${contractorId}/billingplan/${billingPlan}`));
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getContractorWidgetPricing(contractorId: any, month: number, year: number): Observable<any> {
        return this.httpClient.get(this.helper.getWidgetUrl() + `/contractors/${contractorId}/year/${year}/month/${month}/widget-pricing`);
    }

    public setContractorSmsTransliteration(contractorId: number): Observable<number> {
        return this.httpClient.post(this.helper.getRootUrl(3, `eav2/settings/api/contractors/${contractorId}/smsTransliteration`));
    }
}
