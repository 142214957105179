import * as moment from 'moment';
import { Asset } from './../asset.model';
import { Contractor } from './../contractor.model';
import { Serializable } from './../deserialize.service';
import { Service } from './../service.model';

export class CreatePrereservationRequest implements Serializable<CreatePrereservationRequest> {
    from: moment.Moment;
    to: moment.Moment;
    contractor: Contractor;
    service: Service;
    asset: Asset;
    itemId: string;
    forceUpdate: boolean;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        return Object.assign(this, input);
    }
}
