import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Daterangepicker } from 'ng2-daterangepicker';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { HomeComponent } from '../home.component';
import { BasicComponentsModule } from './../../../basic-components/basic-components.module';
import { ComponentModule } from './../../../components/component.module';
import { LayoutModule } from './../../../layouts/layout.module';
import { DocumentationOverviewElementComponent } from './documentation-overview-element/documentation-overview-element.component';
import { DocumentationOverviewComponent } from './documentation-overview.component';

const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        children: [
            {
                path: '',
                component: DocumentationOverviewComponent,
            },
        ],
    },
];

@NgModule({
    declarations: [DocumentationOverviewComponent, DocumentationOverviewElementComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        CommonModule,
        LayoutModule,
        NgbModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        BasicComponentsModule,
        Daterangepicker,
        ComponentModule,
        NgxDatatableModule,
        NgScrollbarModule,
    ],
})
export class DocumentationOverviewModule {}
