<span>
    <img
        [style.max-width.px]="showLogo ? width : '100%'"
        [ngbTooltip]="app?.name"
        [disableTooltip]="!onlyIcon"
        src="{{ showLogo ? app?.logoPath : app?.iconPath }}"
        alt="{{ app?.name }}"
        height="{{ app?.height ? app?.height : height }}"
    />
    <span *ngIf="!onlyIcon" class="pl-1">{{ app?.name }}</span>
</span>
