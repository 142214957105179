<div class="telephony">
    <div [hidden]="!isWindowShown" cdkDragBoundary=".m-page" cdkDrag class="telephony-large-modal">
        <header class="mf-header">
            <div class="mf-actions w-100 my-1 d-flex">
                <div style="max-width: 343px" class="w-100 my-1 pr-2">
                    <div *ngIf="customer" class="text-white p-1">
                        <ng-container *ngFor="let item of customer">
                            <a
                                (click)="$event.preventDefault(); openCustomer(item)"
                                href="#"
                                class="m-badge m-badge--success m-badge--wide mr-1 mb-1"
                            >
                                {{ item?.name }} {{ item?.surname }}
                            </a>
                        </ng-container>
                    </div>
                </div>
                <a
                    (click)="$event.preventDefault(); closeWindow()"
                    href="#"
                    ngbTooltip="Zapri"
                    class="btn m-btn m-btn--hover-danger m-btn--icon m-btn--icon-only m-btn--pill"
                >
                    <i class="la la-times"></i>
                </a>
            </div>
        </header>
        <div class="mf-phone-wrapper">
            <div id="mf-phone" class="border-0"></div>
            <section *ngIf="isUnregistered" class="">
                <tph-new-user (onRegister)="onRegister()"></tph-new-user>
            </section>
        </div>
    </div>
</div>
