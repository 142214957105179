import { Pipe, PipeTransform } from '@angular/core';
import { PaymentType } from '../enums/accounting/paymentType.enum';

@Pipe({
    name: 'typePaymentPipe',
})
export class TypePaymentPipe implements PipeTransform {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(type: any): any {
        switch (type) {
            case PaymentType.CASH:
                return 'Gotovina';
            case PaymentType.CREDIT_CARD:
                return 'Ostale kartice';
            case PaymentType.DEBIT_CARD:
                return 'Kartica';
            case PaymentType.VOUCHER:
                return 'Vrednostni bon';
            case PaymentType.TRR:
                return 'Nakazilo na TRR';
            case PaymentType.ZVEZDAR:
                return 'Zvezdar';
            case PaymentType.HISA_DARIL:
                return 'Hiša daril';
            case PaymentType.SELECT_BOX:
                return 'Select box';
            case PaymentType.DINERS:
                return 'Diners';
            case PaymentType.MAESTRO:
                return 'Maestro';
            case PaymentType.MASTERCARD:
                return 'Mastercard';
            case PaymentType.VISA:
                return 'Visa';
            case PaymentType.ONLINE_PAYMENT:
                return 'Spletno plačilo';
            case PaymentType.BON21:
                return 'Bon 21';
            case PaymentType.ZZZS:
                return 'ZZZS';
            default:
                return 'Manjka prevod';
        }
    }
}
