import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import _ from 'lodash';
import { map, take, tap } from 'rxjs/operators';
import { AppModulesType } from '../../enums/appModulesType.enum';
import { CalendarUserGuiSettings } from '../../model';
import { GenericModuleSettings } from '../../model/app/genericModuleSettings';
import { WizardListElement } from '../../model/wizardListElement.model';
import { CommonService } from './../../../theme/pages/home/_services/common.service';
import { ConfigGUIService } from './../../../theme/pages/home/_services/configGUI.service';
import { Module } from './../../model/app/module.model';
import { Asset } from './../../model/asset.model';
import { Contractor } from './../../model/contractor.model';
import { Service } from './../../model/service.model';
import { Subcontractor } from './../../model/subcontractor.model';
import { User } from './../../model/user.model';
import { CalendarFilterSettings } from './../../model/userGuiSettings/calendarFilterSettings.model';
import { ContractorSettings } from './../../model/userGuiSettings/contractorSettings.model';
import { GuiUserContractorSettings } from './../../model/userGuiSettings/guiUserContractorSettings.model';
import { HelperService } from './../../services/helper.service';
import {
    AddServices,
    ChangeWizardTodoStatus,
    ClearServiceCache,
    ClearState,
    GetAndLoadAssets,
    GetAndLoadServices,
    GetAndLoadSubcontractors,
    GetAndLoadWizardSettings,
    SetActiveContractor,
    SetActiveModules,
    SetCalendarFilter,
    SetCalendarSubcontractors,
    SetContractorSettings,
    SetDefaultUserSettings,
    SetLoggedUser,
    SetUserSettings,
} from './base.actions';
import { BaseStateModel } from './base.model';

const BASESTATE_TOKEN: StateToken<BaseStateModel> = new StateToken('basestate');

const DEFAULT_STATE: BaseStateModel = {
    services: [],
    activeContractor: undefined,
    userGuiSettings: undefined,
    allSubcontractors: [],
    assets: [],
    loggedUser: undefined,
    contractorSettings: undefined,
    onBoardingWizardSettings: undefined,
    activeModules: undefined,
};

@State<BaseStateModel>({
    name: BASESTATE_TOKEN,
    defaults: DEFAULT_STATE,
    children: [], //ce bo kdaj prov prislo
})
@Injectable()
export class BaseState {
    constructor(
        private commonRest: CommonService,
        private configGUIRest: ConfigGUIService,
        private helper: HelperService,
        private store: Store,
    ) {}

    static getServices() {
        return createSelector([BaseState], (state: BaseStateModel) => state.services);
    }

    static getAllSubcontractors() {
        return createSelector([BaseState], (state: BaseStateModel) => state.allSubcontractors);
    }

    static getAssets() {
        return createSelector([BaseState], (state: BaseStateModel) => state.assets);
    }
    //@Selectors
    @Selector()
    public static services(state: BaseStateModel): Service[] {
        return state.services;
    }

    @Selector()
    static GetUserSettings(state: BaseStateModel): GuiUserContractorSettings {
        return state.userGuiSettings;
    }

    @Selector()
    static GetContractorSettings(state: BaseStateModel): ContractorSettings {
        return state.contractorSettings;
    }

    @Selector()
    static getState(state: BaseStateModel): BaseStateModel {
        return state;
    }
    @Selector()
    public static activeContractor(state: BaseStateModel): Contractor {
        return state.activeContractor;
    }

    @Selector()
    public static activeContractorId(state: BaseStateModel): number {
        return state.activeContractor.id;
    }

    @Selector()
    public static LoggedUser(state: BaseStateModel): User {
        return state.loggedUser;
    }

    @Selector()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static GetOnboardingWizardSettings(state: BaseStateModel): WizardListElement | any {
        return state.onBoardingWizardSettings;
    }

    @Selector()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static GetActiveModules(state: BaseStateModel): Module<any>[] {
        return state.activeModules;
    }

    static GetActiveModule(id: AppModulesType) {
        return createSelector([BaseState], (state: BaseStateModel) => {
            return state.activeModules.find(el => el.name == id);
        });
    }

    //@Actions
    @Action(ClearState)
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line no-empty-pattern
    public ClearState(ctx: StateContext<BaseStateModel>, {}: ClearState) {
        ctx.patchState(DEFAULT_STATE);
    }

    @Action(ClearServiceCache)
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line no-empty-pattern
    public ClearServiceCache(ctx: StateContext<BaseStateModel>, {}: ClearServiceCache) {
        ctx.patchState({
            services: [],
        });
    }

    @Action(AddServices)
    public AddServices({ setState }: StateContext<BaseStateModel>, { services }: AddServices) {
        setState(
            patch({
                services: services,
            }),
        );
    }

    @Action(GetAndLoadServices)
    public GetAndLoadServices({ getState, patchState }: StateContext<BaseStateModel>) {
        const storeServices: Service[] = getState().services;

        if (storeServices.length > 0) {
            return;
        }
        return this.commonRest.getServicesForContractor(getState().activeContractor.id).pipe(
            tap(res => {
                patchState({
                    services: res,
                });
            }),
        );
    }

    @Action(SetActiveContractor)
    public SetActiveContractor(ctx: StateContext<BaseStateModel>, { contractor }: SetActiveContractor) {
        ctx.patchState({
            activeContractor: contractor,
        });
    }

    @Action(SetLoggedUser)
    public SetLoggedUser(ctx: StateContext<BaseStateModel>, { user }: SetLoggedUser) {
        ctx.patchState({
            loggedUser: user,
        });
    }

    @Action(GetAndLoadSubcontractors)
    public GetAndLoadSubcontractors({ getState, patchState }: StateContext<BaseStateModel>) {
        const allSubcontractors: Subcontractor[] = getState().allSubcontractors;

        if (allSubcontractors.length > 0) {
            return;
        }
        return this.commonRest.getAllSubcontractors(getState().activeContractor.id).pipe(
            tap(res => {
                patchState({
                    allSubcontractors: res,
                });
            }),
        );
    }
    @Action(SetCalendarFilter)
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public SetCalendarFilter({ patchState, getState }: StateContext<BaseStateModel>, { filter, timeFrequency }: any) {
        const state = getState();
        const newSettings = _.cloneDeep(state.userGuiSettings);
        newSettings.calendar.filter = new CalendarFilterSettings().deserialize(filter);
        if (timeFrequency) {
            newSettings.calendar.timeFrequency = timeFrequency;
            // this.configGUIRest.updateUserGuiCalendarFrequency(state.activeContractor.id, timeFrequency);
        }
        this.configGUIRest.updateUserContractorGuiSettings(state.activeContractor.id, newSettings);
        patchState({
            userGuiSettings: newSettings,
        });
    }

    @Action(GetAndLoadAssets)
    public GetAndLoadAssets({ getState, patchState }: StateContext<BaseStateModel>) {
        const assets: Asset[] = getState().assets;
        if (assets.length > 0) {
            return;
        }
        return this.commonRest.getAllAssets(getState().activeContractor.id).pipe(
            tap(res => {
                patchState({
                    assets: res,
                });
            }),
        );
    }

    @Action(SetUserSettings)
    public SetUserSettings({ setState }: StateContext<BaseStateModel>, { userGuiSettings }: SetUserSettings) {
        setState(
            patch({
                userGuiSettings: userGuiSettings,
            }),
        );
    }

    @Action(SetContractorSettings)
    public SetContractorSettings({ setState }: StateContext<BaseStateModel>, { contractorSettings }: SetContractorSettings) {
        setState(
            patch({
                contractorSettings: contractorSettings,
            }),
        );
    }

    @Action(SetDefaultUserSettings)
    public SetDefaultUserSettings({ setState }: StateContext<BaseStateModel>, { settings }: SetDefaultUserSettings) {
        // set default userGuisettings for calendar
        if (_.isNil(settings)) {
            settings = {};
        }
        settings.calendar = this.helper.getDefaultCalendarFilterSettings();
        setState(
            patch({
                userGuiSettings: settings,
            }),
        );
    }
    @Action(SetCalendarSubcontractors)
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public SetCalendarSubcontractors(ctx: StateContext<BaseStateModel>, { newSelectedSubcontractors }: any) {
        ctx.setState(
            patch<BaseStateModel>({
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                userGuiSettings: patch<GuiUserContractorSettings | any>({
                    calendar: patch<CalendarUserGuiSettings>({
                        subconstractors: newSelectedSubcontractors,
                    }),
                }),
            }),
        );
    }
    @Action(GetAndLoadWizardSettings)
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line no-empty-pattern
    public GetAndLoadWizardSettings({ getState, patchState }: StateContext<BaseStateModel>, {}: GetAndLoadWizardSettings) {
        return this.commonRest.getWizardTodoList(getState().activeContractor.id).pipe(
            map(data => {
                return data.filter((wizardElement: WizardListElement) => {
                    return (
                        wizardElement?.modules?.every((module: AppModulesType) => this.isAM(module)) &&
                        wizardElement?.views?.every((view: string) => this.isAV(view))
                    );
                });
            }),
            tap((wizardSettings: WizardListElement[]) => {
                patchState({
                    onBoardingWizardSettings: wizardSettings,
                });
            }),
        );
    }
    @Action(ChangeWizardTodoStatus)
    public ChangeWizardTodoStatus({ getState, patchState }: StateContext<BaseStateModel>, { settings }: ChangeWizardTodoStatus) {
        return this.commonRest.changeTodoListStatus(settings.completed, getState().activeContractor.id, settings.id).pipe(
            take(1),
            tap(() => {
                const wizardDataFromState = getState().onBoardingWizardSettings;
                const onBoardingWizardSttngs = _.cloneDeep(wizardDataFromState);
                const findIndex = onBoardingWizardSttngs.findIndex(wizard => wizard?.id === settings.id);
                if (findIndex != -1) onBoardingWizardSttngs[findIndex].completed = settings.completed;
                patchState({
                    onBoardingWizardSettings: onBoardingWizardSttngs,
                });
            }),
        );
    }

    @Action(SetActiveModules)
    public SetActiveModules(ctx: StateContext<BaseStateModel>, { modules }: SetActiveModules) {
        modules.map(m => {
            try {
                m.settings = JSON.parse(<string>m.settings);
            } catch (error) {
                console.log(error);
            }
        });
        ctx.setState(
            patch<BaseStateModel>({
                activeModules: modules,
            }),
        );
    }

    public isAM(module: AppModulesType): boolean {
        return this.store
            .selectSnapshot(BaseState.GetActiveModules)
            ?.map((el: Module<GenericModuleSettings>) => el.name)
            .includes(module);
    }

    public isAV(view: string): boolean {
        const contractorSettings: ContractorSettings = this.store.selectSnapshot(BaseState.GetContractorSettings);
        return !_.get(contractorSettings, 'menu.hidden', []).includes(view);
    }
}
