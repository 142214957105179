import { Serializable } from '../deserialize.service';

export class OpenDocumentRequest implements Serializable<OpenDocumentRequest> {
    document: Blob;

    deserialize(input) {
        this.document = input.document;
        return this;
    }
}
