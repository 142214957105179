export enum BsId {
    GENERALI = 1,
    TRIGLAV = 2,
    VZAJEMNA = 7,
}

export enum BsType {
    HIS = 'HIS',
    PREVENTIVNI = 'PREVENTIVNI',
    NETWORK = 'NETWORK',
    INSURANCE = 'INSURANCE',
    BUSINESS = 'BUSINESS',
    BW = 'BW',
}

// TODO Ignored with eslint-interactive on 2023-11-10
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getBusiness(data: any): any {
    if (!data.bsid) {
        switch (data.bsName) {
            case 'Generali':
                return { id: 1 };
            case 'Merkur':
                return { id: 2 };
            case 'Sava':
                return { id: 9 };
            case 'Vox Medica':
                return { id: 12 };
            default:
                return { id: 8 };
        }
    } else {
        return {
            id: data.bsid,
            name: data.bsName,
        };
    }
}
