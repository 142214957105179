import { Serializable } from './../../../../../_shared/model/deserialize.service';
import { Tooth } from './tooth.model';

export class Teeth implements Serializable<Teeth> {
    t11: Tooth;
    t12: Tooth;
    t13: Tooth;
    t14: Tooth;
    t15: Tooth;
    t16: Tooth;
    t17: Tooth;
    t18: Tooth;

    t21: Tooth;
    t22: Tooth;
    t23: Tooth;
    t24: Tooth;
    t25: Tooth;
    t26: Tooth;
    t27: Tooth;
    t28: Tooth;

    t31: Tooth;
    t32: Tooth;
    t33: Tooth;
    t34: Tooth;
    t35: Tooth;
    t36: Tooth;
    t37: Tooth;
    t38: Tooth;

    t41: Tooth;
    t42: Tooth;
    t43: Tooth;
    t44: Tooth;
    t45: Tooth;
    t46: Tooth;
    t47: Tooth;
    t48: Tooth;
    //Mlecni
    t51: Tooth;
    t52: Tooth;
    t53: Tooth;
    t54: Tooth;
    t55: Tooth;

    t61: Tooth;
    t62: Tooth;
    t63: Tooth;
    t64: Tooth;
    t65: Tooth;

    t71: Tooth;
    t72: Tooth;
    t73: Tooth;
    t74: Tooth;
    t75: Tooth;

    t81: Tooth;
    t82: Tooth;
    t83: Tooth;
    t84: Tooth;
    t85: Tooth;
    deserialize(input) {
        Object.keys(input).forEach(key => (this[key] = input[key]));
        return this;
    }
}
