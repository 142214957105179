import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AvgPerformance } from '../../../../_shared/model';
import { HelperService } from '../../../../_shared/services';
import { HttpClientService } from '../../../../_shared/services/http-client.service';
import { HdCall } from './../../../../_shared/model/reports/hd-call.model';
import { HdCallSurvey } from './../reports/_model/hd-call-survey.model';

@Injectable({
    providedIn: 'root',
})
export class ReportsService {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    token: any;

    constructor(
        private http: HttpClient,
        private httpClient: HttpClientService,
        private helper: HelperService,
    ) {}

    // getVisit()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getCustomerStats(cid: number, from: string, to: string): Observable<any> {
        return this.httpClient.get(this.helper.getStatsUrl() + '/contractors/' + cid + '/stats?from=' + from + '&to=' + to);
    }

    //allStatsNew
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getAllStats(cid: number, data: any): Observable<any> {
        return this.httpClient.post(this.helper.getStatsUrl() + '/contractors/' + `${cid}/` + 'reservationVisitStats', data);
    }

    //Promet
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getIncomeStats(cid: number, data: any): Observable<any> {
        return this.httpClient.post(this.helper.getStatsUrl() + '/contractors/' + `${cid}/` + 'visitStats', data);
    }
    //poslani SMS+EMAIL
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getCommunicationStats(cid: number, data: any): Observable<any> {
        return this.httpClient.post(this.helper.getStatsUrl() + '/contractors/' + `${cid}/` + 'communicationStats', data);
    }
    //Rezervacije
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getReservationsStats(cid: number, data: any): Observable<any> {
        return this.httpClient.post(this.helper.getStatsUrl() + '/contractors/' + cid + '/reservationStatuses', data);
    }
    //Termini
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getVisitStats(cid: number, data: any): Observable<any> {
        return this.httpClient.post(this.helper.getStatsUrl() + '/contractors/' + `${cid}/` + 'visitDailyStats', data);
    }
    //nove stranke
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getNewCustomers(cid: number, data: any): Observable<any> {
        return this.httpClient.post(this.helper.getStatsUrl() + '/contractors/' + `${cid}/` + 'userStats', data);
    }

    // getServicesPerformance()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getServicesPerformance(cid: number, from: string, to: string): Observable<any> {
        return this.httpClient.get(this.helper.getStatsUrl() + '/contractors/' + cid + '/services/performance?from=' + from + '&to=' + to);
    }

    // getSubcontractorsPerformance()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getSubcontractorsPerformance(cid: number, from: string, to: string): Observable<any> {
        return this.httpClient.get(this.helper.getStatsUrl() + '/contractors/' + cid + '/subcontractors/performance?from=' + from + '&to=' + to);
    }

    // getSubcontractorsPerformance()
    public getAvgPerformanceContr(contractorid: number, from: string, to: string): Observable<AvgPerformance> {
        return this.httpClient
            .get(this.helper.getStatsUrl() + `/avg/contractors/${contractorid}?from=${from}&to=${to}`)
            .pipe(map(el => new AvgPerformance().deserialize(el)));
    }

    // getSubcontractorsPerformance()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getAvgPerformanceSubcontr(contractorid: number, subcontractorid: number, from: string, to: string): Observable<any> {
        return this.httpClient.get(
            this.helper.getStatsUrl() + `/avg/contractors/${contractorid}/subcontractors/${subcontractorid}?from=${from}&to=${to}`,
        );
    }

    //getDailyAvailabilityReport
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getDailyAvailabilityReport(contractorId: number, subcontractor: number[], from: string, to: string): Observable<any> {
        return this.httpClient.getAttachmentPost(
            `${this.helper.getApiUrl()}/contractors/${contractorId}/availability/report?from=${from}&to=${to}`,
            subcontractor,
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getHdCallSurveyReport(contractorId: number, data: any): Observable<HdCallSurvey> {
        return this.httpClient.post(`${this.helper.getStatsUrl()}/contractors/${contractorId}/HdCallSurveyReport`, data);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getHdOrdersStats(contractorId: number, data: any): Observable<any> {
        return this.httpClient.post(`${this.helper.getStatsUrl()}/contractors/${contractorId}/haloDoktorOrderFormStats`, data);
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getSurveysZip(contractorId: number, from: string, to: string): Observable<any> {
        return this.httpClient.getAttachment(this.helper.getPrintServerUrl() + `/contractors/${contractorId}/hd/surveys/zip/?from=${from}&to=${to}`);
    }

    public getHDCalls(from: string, to: string): Observable<HdCall[]> {
        //2000-01-01
        return this.httpClient.get(this.helper.getStatsUrl() + `/hdcallstat/${from}/${to}`);
    }
}
