import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BsType } from '../../../../../../_shared/enums/business.enum';
import { PfPCommnComunicationService } from '../_common/pf-p-comn-communication.service';
import { PaperType } from './../../../../../../_shared/enums/PaperType.enum';
import { Contractor } from './../../../../../../_shared/model/contractor.model';
import { Customer } from './../../../../../../_shared/model/customer.model';
import { GenericReferralApi } from './../../../../../../_shared/model/portfolio/genericReferralApi.model';
import { PaperHistoryElement } from './../../../../../../_shared/model/portfolio/paperHistoryElement.model';
import { PortfolioPaper } from './../../../../../../_shared/model/portfolio/portfolioPaper.model';
import { Case } from './../../../../../../_shared/model/schedule/case.model';
import { Subcontractor } from './../../../../../../_shared/model/subcontractor.model';
import { HelperService } from './../../../../../../_shared/services/helper.service';
import { MfToastService } from './../../../../../../_shared/services/mfToast.service';
import { BaseState } from './../../../../../../_shared/state/base/base.state';
import { CommonService } from './../../../_services/common.service';
import { ResultService } from './../../../_services/result.service';
import { PfPBaseComponent } from './../pf-p-base/pf-p-base.component';

@Component({
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'pf-p-genericReferral',
    templateUrl: './pf-p-genericReferral.component.html',
    styles: [],
})
export class PfPGenericReferralComponent extends PfPBaseComponent implements OnInit {
    @Input()
    isNew = false;

    @Input()
    paper: PortfolioPaper;

    @Input()
    customer: Customer;

    @Input()
    subcontractor: Subcontractor;

    // @Input()
    // id: any;

    // @Input()
    // visit: Visit;

    public gfForm: FormGroup;
    private case: Case;
    private onDestroy$ = new Subject<void>();
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public tmceSettings: any = {
        menubar: false,
        statusbar: true,
        resize: true,
        toolbar: 'bold | italic | ',
        plugins: 'paste',
        height: '170',
    };
    public selectedContractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);
    public selectedContractor: Contractor = this.store.selectSnapshot(BaseState.activeContractor);
    // public loggedUser: User = this.store.selectSnapshot(BaseState.LoggedUser);
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public calendarOptions: any = {
        autoUpdateInput: false,
        singleDatePicker: true,
        autoApply: true,
        locale: {
            format: 'DD.MM.YYYY',
            cancelLabel: 'Prekliči',
            applyLabel: 'Potrdi',
            customRangeLabel: 'Izberi ročno',
            daysOfWeek: ['Ne', 'Po', 'To', 'Sr', 'Če', 'Pe', 'So'],
            monthNames: ['Januar', 'Februar', 'Marec', 'April', 'Maj', 'Junij', 'Julij', 'Avgust', 'September', 'Oktober', 'November', 'December'],
            firstDay: 1,
        },
        alwaysShowCalendars: false,
    };

    constructor(
        private fb: FormBuilder,
        private comm: PfPCommnComunicationService,
        private rest: ResultService,
        // private storage: StorageService,
        private toast: MfToastService,
        public helper: HelperService,
        private datePipe: DatePipe,
        private commonRest: CommonService,
        public store: Store,
    ) {
        super(helper);
    }

    ngOnInit() {
        this.createForm();
        this.communications();
        if (!this.isNew) {
            this.loadData(this.paper?.id);
        } else {
            this.setDefaultPlace();
        }
        this.loadIzvidContent();
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        this.comm.dirty$.next(false);
        this.onDestroy$.next();
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public onSubmit(): void {}

    public createForm(): void {
        this.gfForm = this.fb.group({
            reasonOfTreatment: new FormControl(''),
            referralPlace: new FormControl(this.selectedContractor),
            referralDateString: new FormControl(moment().format('DD.MM.YYYY')),
            doctorSpeciality: new FormControl(''),
            referralText1: new FormControl(),
        });
        this.gfForm?.valueChanges?.subscribe(() => {
            if (this.gfForm?.touched) {
                this.comm.dirty$.next(true);
            }
        });
    }
    public setDefaultPlace() {
        this.commonRest.getContractorBasicInfo(this.selectedContractorId).subscribe(contractorData => {
            this.gfForm.controls.referralPlace.setValue(contractorData.postName ? contractorData.postName : '');
        });
    }

    public communications(): void {
        this.comm.onCopy.pipe(takeUntil(this.onDestroy$)).subscribe((data: PaperHistoryElement) => {
            this.copyFromHistroy(data);
        });

        this.comm.onSignDocument.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
            this.comm.saveSignShareAndShow(
                this.paper,
                data,
                () => this.saveSurvey(),
                casee => (this.case = casee),
                false,
                false,
                this.bsType === BsType.PREVENTIVNI,
            );
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onSave.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.saveSurvey().subscribe();
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onPreview.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.openPreview(this.paper, false);
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onPrint.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.openPreview(this.paper, true);
        });
    }

    private copyFromHistroy(data: PaperHistoryElement): void {
        this.comm.dirty$.next(true);
        this.loadData(data.paperId);
    }

    public loadData(paperId: number): void {
        this.rest
            .getWhiteReferral(this.selectedContractorId, paperId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((res: GenericReferralApi) => {
                this.gfForm.patchValue(res);
                this.gfForm.controls.referralDateString.setValue(this.datePipe.transform(<Date>res.referralDate, 'dd.MM.yyyy'));
            });
    }

    public loadIzvidContent(): void {
        //če je NOVA napotnica, napolni polje diagnoza iz HD izvida
        this.rest
            .getCustomerPapersHistory(this.selectedContractorId, this.customer.id, PaperType.MEDIFIT_MEDICAL_REPORT)
            .pipe(
                map(res => {
                    return res.filter(el => el.visitId == this.paper.visitId);
                }),
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                switchMap((survey: any) => {
                    return this.rest.getSurveyPaper(survey[0].visitId, survey[0].paperId).pipe(takeUntil(this.onDestroy$));
                }),
            )
            .subscribe(res => {
                //patch Form
                if (this.isNew) {
                    let allDiagnoses = '';
                    res?.diagnoses?.forEach(diagn => {
                        allDiagnoses += `<p> ${diagn.name} (${diagn.code ? diagn.code : 'ročni vnos'}) </p>`;
                    });
                    this.gfForm.controls.referralText1.setValue(allDiagnoses);
                }
            });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private saveSurvey(): Observable<any> {
        if (this.isNew) {
            //create
            return this.rest.newWhiteReferral(this.selectedContractorId, this.paper.visitId, this.prepareSaveObj()).pipe(
                takeUntil(this.onDestroy$),
                tap(
                    (id: string) => {
                        this.paper = new PortfolioPaper().deserialize({
                            id: id,
                            visitId: this.paper.visitId,
                            paperType: PaperType.HELLODOC_REFERRAL,
                        });
                        this.comm.onPaperChanged.emit(this.paper);

                        this.toast.success('Spremembe so shranjene.');
                        this.isNew = false;
                        this.comm.dirty$.next(false);
                    },
                    () => this.toast.errorAndSend('Napaka pri dodajanju novega sklopa.'),
                ),
            );
        } else {
            //update
            return this.rest.updateWhiteReferral(this.selectedContractorId, this.paper.visitId, this.paper.id, this.prepareSaveObj()).pipe(
                takeUntil(this.onDestroy$),
                tap(
                    () => {
                        this.toast.success('Spremembe so shranjene.');
                        this.comm.dirty$.next(false);
                    },
                    () => this.toast.errorAndSend('Napaka pri urejanju sklopa.'),
                ),
            );
        }
    }

    public openPreview(paper: PortfolioPaper, print: boolean = false): void {
        this.rest
            .printPortfolioPaper(this.selectedContractorId, paper.id)
            .pipe(
                takeUntil(this.onDestroy$),
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                map((res: any) => {
                    if (res.type !== 0) {
                        return new Blob([res.body], {
                            type: 'application/pdf',
                        });
                    }
                }),
            )
            .subscribe(
                res => {
                    if (res) {
                        this.helper.openDocumentViewer(res, res.type, 'Izvid', print);
                    }
                },
                () => {
                    this.toast.errorAndSend('Napaka pri predogledu poročila.');
                },
            );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private prepareSaveObj(): any {
        // const detailsFormValue: any = this.detailsForm.value;
        //TODO

        const formData = this.gfForm.value;
        const data = new GenericReferralApi().deserialize(formData);
        data.referralDate = moment(formData.referralDateString, 'DD.MM.YYYY');
        //TYPE
        data.referralType = 'HELLODOC';
        return data;
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onDateChange(date: any): void {
        this.gfForm.controls.referralDateString.setValue(date.start.format('DD.MM.YYYY'));
    }
}
