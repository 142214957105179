<div
    id="m_quick_sidebar"
    appunwraptag=""
    style="overflow-y: hidden"
    class="m-quick-sidebar m-quick-sidebar--skin-light p-0 pb-2"
    [ngClass]="{ 'm-quick-sidebar--on': isOpen }"
>
    <div class="mf-quick-sidebar-title text-center">
        <span (click)="markAllAsRead()" id="notificationMarkAsRead" placement="right" ngbTooltip="Označi vsa kot prebrana" class="float-left">
            <i class="la la-check-circle-o"></i>
        </span>
        <span i18n="@@layouts.quicksidebar.notifications.title.caption">Obvestila</span>
        <span (click)="closeSidebar()" id="m_quick_sidebar_close" class="mf-quick-sidebar-title_close">
            <i class="la la-close"></i>
        </span>
    </div>
    <div class="m-quick-sidebar__content h-100">
        <ng-scrollbar [sensorDebounce]="1" [visibility]="'hover'" [appearance]="'compact'">
            <div class="tab-content">
                <div [class.active]="isOpen" id="m_quick_sidebar_tabs_messenger" class="tab-pane active">
                    <div *ngIf="notificationsListSlice?.length < 1" class="ml-2" i18n="@@layouts.quicksidebar.notifications.no-notifications.caption">
                        Ni obvestil!
                    </div>
                    <div *ngFor="let notification of notificationsListSlice; index as i" class="timelineItem mt-3 ml-3 mr-3">
                        <app-header-notification-element
                            [index]="i"
                            [notification]="notification"
                            (messageClick)="messageRead($event)"
                            (onMessageNavigate)="closeSidebar(true)"
                        ></app-header-notification-element>
                    </div>
                    <div *ngIf="notificationsListSlice?.length > 1" class="mf-quick-sidebar-loadmore d-flex w-100 p-2">
                        <a (click)="loadNext()" href="#" class="ml-auto mr-auto btn btn-outline-brand m-btn btn-sm m-btn--icon m-btn--pill">
                            <span>
                                <i class="la la-arrow-down"></i>
                                <span i18n="@@layouts.quicksidebar.notifications.loadmore.button.caption">Naloži več obvestil</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </ng-scrollbar>
    </div>
</div>
<div *ngIf="isOpen" (click)="closeSidebar()" class="m-quick-sidebar-overlay"></div>
