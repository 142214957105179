import { CommonModule, DatePipe, DecimalPipe, KeyValuePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppBasicsModule } from '@meddev/fe-shared';
import { NgbActiveModal, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { TextMaskModule } from 'angular2-text-mask';
import { QrCodeModule } from 'ng-qrcode';
import { Daterangepicker } from 'ng2-daterangepicker';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { TokenInterceptor } from '../../token.interceptor';
import { DisableControlDirective } from '../../_directives/disable-control.directive';
import { UppercaseDirective } from '../../_directives/uppercase.directive';
import { CommTypeDescriptionPipe } from '../../_shared/pipes/comm-type-description.pipe';
import { CustomTagPipe } from '../../_shared/pipes/custom-tag.pipe';
import { CustomerNamePipe } from '../../_shared/pipes/customer-name.pipe';
import { FilterArrayByIdPipe } from '../../_shared/pipes/filter-array-by-id.pipe';
import { SafePipe } from '../../_shared/pipes/safe.pipe';
import { SecondaryContactPipe } from '../../_shared/pipes/secondary-contact.pipe';
import { TypePaymentPipe } from '../../_shared/pipes/type-payment.pipe';
import { UserTagPipe } from '../../_shared/pipes/user-tag.pipe';
import { MfToastService } from '../../_shared/services';
import { NgbDateMomentParserFormatter } from '../../_shared/services/NgbDateMomentParserFormatter.class';
import { SharedModule } from '../../_shared/shared.module';
import { BasicDocumentViewerComponent } from '../basic-components/basic-document-viewer/basic-document-viewer.component';
import { NoDblClickDirective } from '../basic-components/directives/no-dbl-click.directive';
import { LaifSpecialistsComponent } from '../pages/home/terms/visit/selected-hd-term/laif-specialists/laif-specialists.component';
import { SendHdTermsToPatientAppComponent } from '../pages/home/terms/visit/selected-hd-term/send-hd-terms-to-patient-app/send-hd-terms-to-patient-app.component';
import { SendTermsStepsComponent } from '../pages/home/terms/visit/selected-hd-term/send-hd-terms-to-patient-app/send-terms-steps/send-terms-steps.component';
import { CaseLogTypesPipe } from '../pages/home/terms/_pipes/case-log-types.pipe';
import { CustomerService, NotificationService, ReservationService, SettingsService, TermsService, VisitService } from '../pages/home/_services';
import { CanLeaveModule } from './../../auth/can-leave/can-leave.module';
import { AssetTypePipe } from './../../_shared/pipes/asset-type.pipe';
import { CaseStatusPipe } from './../../_shared/pipes/case-status.pipe';
import { CommCurrStatusPipe } from './../../_shared/pipes/comm-curr-status.pipe';
import { CommunicationStringPipe } from './../../_shared/pipes/comm-strings.pipe';
import { OimStatusPipe } from './../../_shared/pipes/oim-status.pipe';
import { OriginUrlPipe } from './../../_shared/pipes/origin-url.pipe';
import { ReservationStatusStringPipe } from './../../_shared/pipes/reservation-status-string.pipe';
import { VisitExitPipe } from './../../_shared/pipes/visit-exit.pipe';
import { BasicComponentsModule } from './../basic-components/basic-components.module';
import { TrtWidgetsModule } from './../pages/home/treatment/trt-widgets/trt-widgets.module';
import { AccWidInvActionsComponent } from './acc-widgets/acc-wid-invoice-popup/acc-wid-inv-actions/acc-wid-inv-actions.component';
import { AccWidInvoicePopupComponent } from './acc-widgets/acc-wid-invoice-popup/acc-wid-invoice-popup.component';
import { AccWidCmnExectionPeriodComponent } from './acc-widgets/common/acc-wid-cmn-exection-period/acc-wid-cmn-exection-period.component';
import { AccWidCmnInvoiceItemsComponent } from './acc-widgets/common/acc-wid-cmn-invoice-items/acc-wid-cmn-invoice-items.component';
import { AccWidCmnInvoiceNoteComponent } from './acc-widgets/common/acc-wid-cmn-invoice-note/acc-wid-cmn-invoice-note.component';
import { AccWidCmnPayerNewPopupComponent } from './acc-widgets/common/acc-wid-cmn-payer-new-popup/acc-wid-cmn-payer-new-popup.component';
import { AccWidCmnPayerComponent } from './acc-widgets/common/acc-wid-cmn-payer/acc-wid-cmn-payer.component';
import { AccPyrRecGroupPipe } from './acc-widgets/common/acc-wid-cmn-payer/acc-wid-cmn-pyr-address/acc-pyr-rec-group.pipe';
import { AccWidCmnPyrAddressComponent } from './acc-widgets/common/acc-wid-cmn-payer/acc-wid-cmn-pyr-address/acc-wid-cmn-pyr-address.component';
import { AccWidPyrGroupPipe } from './acc-widgets/common/acc-wid-cmn-payer/acc-wid-pyr-group.pipe';
import { AccWidCmnRcpFormComponent } from './acc-widgets/common/acc-wid-cmn-recipient-new-popup/acc-wid-cmn-rcp-form/acc-wid-cmn-rcp-form.component';
import { AccWidCmnRecipientNewPopupComponent } from './acc-widgets/common/acc-wid-cmn-recipient-new-popup/acc-wid-cmn-recipient-new-popup.component';
import { AccWidCmnStatusBadgeComponent } from './acc-widgets/common/acc-wid-cmn-status-badge/acc-wid-cmn-status-badge.component';
import { BusinessSubjectIconComponent } from './business-subject-icon/business-subject-icon.component';
import { WaquCommentsComponent } from './case/case-comments/waqu-comments.component';
import { CaseDetailsComponent } from './case/case-details/case-details.component';
import { WaquDocumentsComponent } from './case/case-documents/waqu-documents.component';
import { ComFilterComponent } from './com-filter/com-filter.component';
import { CommentListElementComponent } from './comment-list-element/comment-list-element.component';
import { CommunicationDetailsPopupComponent } from './communication-details-popup/communication-details-popup.component';
import { AddNewCustomerComponent } from './customer/add-new-customer/add-new-customer.component';
import { CustomerCriticalDataElementComponent } from './customer/customer-critical-data/customer-critical-data-element/customer-critical-data-element.component';
import { CustomerCriticalDataComponent } from './customer/customer-critical-data/customer-critical-data.component';
import { CustomerDetailsComponent } from './customer/customer-details/customer-details.component';
import { CustomerAdministratorComponent } from './customer/customer-details/customer-guardian/customer-administrator.component';
import { CustomerFormComponent } from './customer/customer-form/customer-form.component';
import { CustomerMultipleNewUserComponent } from './customer/customer-multiple-new-user/customer-multiple-new-user.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { EditTextPopupComponent } from './edit-text-popup/edit-text-popup.component';
import { ExaminationsStatusListComponent } from './examinations/examinations-status-list/examinations-status-list.component';
import { CmpDdFilterPipe } from './form/cmp-dropdown/cmp-dd-filter.pipe';
import { CmpDropdownComponent } from './form/cmp-dropdown/cmp-dropdown.component';
import { CmpStatNamePipe } from './form/cmp-stat/cmp-stat-name.pipe';
import { CmpStatComponent } from './form/cmp-stat/cmp-stat.component';
import { HdAppIconComponent } from './hd-app-icon/hd-app-icon.component';
import { HeaderNotificationsElementComponent } from './header-notifications/header-notifications-element/header-notifications-element.component';
import { HeaderNotificationsComponent } from './header-notifications/header-notifications.component';
import { HelperWizardPopupComponent, PORTAL_DATA } from './helper-wizard-popup/helper-wizard-popup.component';
import { IncomingCallHDPopupComponent } from './incomingCall-HD-popup/incomingCall-HD-popup.component';
import { MediaViewPopupComponent } from './media-view-popup/media-view-popup.component';
import { MergeDocumentsComponent } from './merge-documents/merge-documents.component';
import { ProgressIndicatorComponent } from './progress-indicator/progress-indicator.component';
import { RenderStarsComponent } from './render-stars/render-stars.component';
import { SendPopupComponent } from './send-popup/send-popup.component';
import { EditGroupPopupComponent } from './services-groups/edit-group-popup/edit-group-popup.component';
import { ServicesGroupsPopupComponent } from './services-groups/services-groups-popup/services-groups-popup.component';
import { ServicesGroupsComponent } from './services-groups/services-groups.component';
import { TaskListElementComponent } from './task-list/task-list-element/task-list-element.component';
import { TaskListComponent } from './task-list/task-list.component';
import { TasksAddPopupComponent } from './tasks-add-popup/tasks-add-popup.component';
import { TermFormCustomerDetailsComponent } from './term-form/term-form-customer-details/term-form-customer-details.component';
import { TermFormComponent } from './term-form/term-form.component';
import { TermPopupComponent } from './term-popup/term-popup.component';
import { AvailabilityReportPopupComponent } from './term/availability-report-popup/availability-report-popup.component';
import { SendTermsElementComponent } from './term/send-terms-to-patient-app/send-terms-element/send-terms-element.component';
import { SendTermsToPatientAppComponent } from './term/send-terms-to-patient-app/send-terms-to-patient-app.component';
import { TimeListDetailsComponent } from './time-list/time-list-details/time-list-details.component';
import { TimeListElementComponent } from './time-list/time-list-element/time-list-element.component';
import { TimeListComponent } from './time-list/time-list.component';
import { ComAppVersionComponent } from './utils/com-app-version/com-app-version.component';
import { JumpToPopupComponent } from './utils/jump-to-popup/jump-to-popup.component';
import { SubcontractorIconComponent } from './utils/subcontractor-icon/subcontractor-icon.component';
import { VisitCaseComponent } from './visit-case/visit-case.component';
import { VisitHDExitPopupComponent } from './visitHD-exit-popup/visitHD-exit-popup.component';

export const DefaultIntl = {
    /** A label for the up second button (used by screen readers).  */
    upSecondLabel: 'Add a second',

    /** A label for the down second button (used by screen readers).  */
    downSecondLabel: 'Minus a second',

    /** A label for the up minute button (used by screen readers).  */
    upMinuteLabel: 'Add a minute',

    /** A label for the down minute button (used by screen readers).  */
    downMinuteLabel: 'Minus a minute',

    /** A label for the up hour button (used by screen readers).  */
    upHourLabel: 'Add a hour',

    /** A label for the down hour button (used by screen readers).  */
    downHourLabel: 'Minus a hour',

    /** A label for the previous month button (used by screen readers). */
    prevMonthLabel: 'Previous month',

    /** A label for the next month button (used by screen readers). */
    nextMonthLabel: 'Next month',

    /** A label for the previous year button (used by screen readers). */
    prevYearLabel: 'Previous year',

    /** A label for the next year button (used by screen readers). */
    nextYearLabel: 'Next year',

    /** A label for the previous multi-year button (used by screen readers). */
    prevMultiYearLabel: 'Previous 21 years',

    /** A label for the next multi-year button (used by screen readers). */
    nextMultiYearLabel: 'Next 21 years',

    /** A label for the 'switch to month view' button (used by screen readers). */
    switchToMonthViewLabel: 'Change to month view',

    /** A label for the 'switch to year view' button (used by screen readers). */
    switchToMultiYearViewLabel: 'Choose month and year',

    /** A label for the cancel button */
    cancelBtnLabel: 'Prekliči',

    /** A label for the set button */
    setBtnLabel: 'Izberi',

    /** A label for the range 'from' in picker info */
    rangeFromLabel: 'From',

    /** A label for the range 'to' in picker info */
    rangeToLabel: 'To',

    /** A label for the hour12 button (AM) */
    hour12AMLabel: 'AM',

    /** A label for the hour12 button (PM) */
    hour12PMLabel: 'PM',
};

@NgModule({
    declarations: [
        TimeListComponent,
        TimeListDetailsComponent,
        TimeListElementComponent,
        HeaderNotificationsComponent,
        HeaderNotificationsElementComponent,
        TaskListComponent,
        TaskListElementComponent,
        // DocumentViewerComponent,
        AddNewCustomerComponent,
        CommentListElementComponent,
        // DocumentListElementComponent,
        // SendEmailPopupComponent,
        TasksAddPopupComponent,
        MediaViewPopupComponent,
        DisableControlDirective,
        TermFormComponent,
        TermPopupComponent,
        CustomerFormComponent,
        CustomerDetailsComponent,
        CustomerCriticalDataComponent,
        CustomerCriticalDataElementComponent,
        UppercaseDirective,
        CommunicationDetailsPopupComponent,
        SafePipe,
        SecondaryContactPipe,
        CustomerMultipleNewUserComponent,
        BusinessSubjectIconComponent,
        HdAppIconComponent,
        RenderStarsComponent,
        WaquDocumentsComponent,
        WaquCommentsComponent,
        // PaperNamePipe,
        VisitExitPipe,
        VisitCaseComponent,
        SendTermsToPatientAppComponent,
        SendHdTermsToPatientAppComponent,
        LaifSpecialistsComponent,
        SendTermsElementComponent,
        JumpToPopupComponent,
        ComAppVersionComponent,
        ServicesGroupsComponent,
        EditGroupPopupComponent,
        AssetTypePipe,
        // ServiceTypePipe,
        CaseDetailsComponent,
        AvailabilityReportPopupComponent,
        TypePaymentPipe,
        OriginUrlPipe,
        CustomerNamePipe,
        CustomTagPipe,
        EditTextPopupComponent,
        VisitHDExitPopupComponent,
        // DocumentSoucePipe,
        SubcontractorIconComponent,
        FilterArrayByIdPipe,
        // PortfolioListComponent,
        // PortfolioListItemComponent,
        CommTypeDescriptionPipe,
        CaseStatusPipe,
        SendPopupComponent,
        ReservationStatusStringPipe,
        ServicesGroupsPopupComponent,
        CommCurrStatusPipe,
        CommunicationStringPipe,
        CmpDdFilterPipe,
        CmpStatNamePipe,
        CmpDropdownComponent,
        CmpStatComponent,
        IncomingCallHDPopupComponent,
        CustomerAdministratorComponent,
        TermFormCustomerDetailsComponent,
        ComFilterComponent,
        HelperWizardPopupComponent,
        OimStatusPipe,
        CaseLogTypesPipe,
        SendTermsStepsComponent,
        DropdownComponent,
        ProgressIndicatorComponent,
        ExaminationsStatusListComponent,
        BasicDocumentViewerComponent,
        AccWidCmnRecipientNewPopupComponent,
        AccWidCmnRcpFormComponent,
        AccWidCmnPayerNewPopupComponent,
        AccWidCmnExectionPeriodComponent,
        AccPyrRecGroupPipe,
        AccWidPyrGroupPipe,
        AccWidCmnInvoiceNoteComponent,
        AccWidCmnPyrAddressComponent,
        AccWidCmnStatusBadgeComponent,
        AccWidCmnInvoiceItemsComponent,
        AccWidCmnPayerComponent,
        AccWidPyrGroupPipe,
        AccWidCmnStatusBadgeComponent,
        AccWidInvActionsComponent,
        AccWidInvoicePopupComponent,
        NoDblClickDirective,
        MergeDocumentsComponent,
        NoDblClickDirective,
        UserTagPipe,
    ],
    exports: [
        TimeListComponent,
        TimeListDetailsComponent,
        HeaderNotificationsComponent,
        HeaderNotificationsElementComponent,
        // DocumentViewerComponent,
        TaskListComponent,
        TaskListElementComponent,
        CommentListElementComponent,
        // DocumentListElementComponent,
        CustomerDetailsComponent,
        CustomerCriticalDataComponent,
        CustomerCriticalDataElementComponent,
        BusinessSubjectIconComponent,
        HdAppIconComponent,
        RenderStarsComponent,
        WaquDocumentsComponent,
        WaquCommentsComponent,
        // PaperNamePipe,
        VisitExitPipe,
        SendTermsToPatientAppComponent,
        SendHdTermsToPatientAppComponent,
        LaifSpecialistsComponent,
        SendTermsElementComponent,
        JumpToPopupComponent,
        ComAppVersionComponent,
        CaseDetailsComponent,
        TypePaymentPipe,
        OriginUrlPipe,
        VisitCaseComponent,
        CustomTagPipe,
        CustomerNamePipe,
        // DocumentSoucePipe,
        SubcontractorIconComponent,
        FilterArrayByIdPipe,
        // PortfolioListComponent,
        // PortfolioListItemComponent,
        CommTypeDescriptionPipe,
        CaseStatusPipe,
        SendPopupComponent,
        AssetTypePipe,
        // ServiceTypePipe,
        EditGroupPopupComponent,
        ServicesGroupsPopupComponent,
        ServicesGroupsComponent,
        CommCurrStatusPipe,
        CommunicationStringPipe,
        CmpDropdownComponent,
        CmpStatComponent,
        CustomerFormComponent,
        IncomingCallHDPopupComponent,
        ComFilterComponent,
        HelperWizardPopupComponent,
        DisableControlDirective,
        OimStatusPipe,
        SafePipe,
        SecondaryContactPipe,
        CaseLogTypesPipe,
        SendTermsStepsComponent,
        DropdownComponent,
        ProgressIndicatorComponent,
        ExaminationsStatusListComponent,
        BasicDocumentViewerComponent,
        AccWidCmnExectionPeriodComponent,
        AccPyrRecGroupPipe,
        AccWidPyrGroupPipe,
        AccWidCmnInvoiceNoteComponent,
        AccWidCmnPyrAddressComponent,
        AccWidCmnStatusBadgeComponent,
        AccWidCmnInvoiceItemsComponent,
        AccWidCmnPayerComponent,
        AccWidPyrGroupPipe,
        AccWidCmnStatusBadgeComponent,
        AccWidInvoicePopupComponent,
        NoDblClickDirective,
        UserTagPipe,
    ],
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NgxDatatableModule,
        RouterModule,
        SweetAlert2Module,
        TextMaskModule,
        PdfViewerModule,
        Daterangepicker,
        ColorPickerModule,
        NgScrollbarModule,
        NgSelectModule,
        EditorModule,
        CanLeaveModule,
        TrtWidgetsModule,
        BasicComponentsModule,
        QrCodeModule,
        AppBasicsModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        CustomerService,
        {
            provide: NgbDateParserFormatter,
            useValue: new NgbDateMomentParserFormatter(),
        },
        { provide: PORTAL_DATA, useValue: 'PortalData' },
        VisitService,
        ReservationService,
        NotificationService,
        DatePipe,
        DecimalPipe,
        SettingsService,
        MfToastService,
        TermsService,
        // PaperNamePipe,
        VisitExitPipe,
        TypePaymentPipe,
        OriginUrlPipe,
        CustomTagPipe,
        CustomerNamePipe,
        // DocumentSoucePipe,
        CommTypeDescriptionPipe,
        KeyValuePipe,
        CaseStatusPipe,
        SafePipe,
        SecondaryContactPipe,
        AssetTypePipe,
        // ServiceTypePipe,
        CommCurrStatusPipe,
        CommunicationStringPipe,
        CaseLogTypesPipe,
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
        SendTermsStepsComponent,
        NgbActiveModal,
    ],
})
export class ComponentModule {}
