import { Serializable } from './deserialize.service';
import { NotificationMessage } from './notificationMessage.model';

export class Notification implements Serializable<Notification> {
    //
    queueId: string;
    channel: string;
    customerId: number;
    customerName: string;
    description: string;
    sentTime: Date;
    status: string;
    //
    userId: number;
    notificationDate: Date;
    prereservationId: string;
    serviceName: string;
    id: number;
    title: string;
    color: string;
    date: Date;
    name: string;
    type: string;
    messages: NotificationMessage[];
    // unread: boolean;
    link: string;
    borderColor: string;
    backgroundColor: string;
    dataInFooter: string;
    redirect: string;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    seen: boolean;
    isFromPatientApp: boolean;
    msgBody: string;

    deserialize(input) {
        this.userId = input.userId;
        this.notificationDate = input.notificationDate;
        this.prereservationId = input.prereservationId;
        this.serviceName = input.serviceName;
        this.queueId = input.queueId;
        this.channel = input.channel;
        this.customerId = input.customerId;
        this.sentTime = input.sentTime;
        this.customerName = input.customerName;
        this.status = input.status;
        this.id = input.id;
        this.title = input.title;
        this.color = input.color;
        this.date = input.date;
        this.name = input.name;
        this.type = input.type;
        this.messages = input.messages;
        // this.unread = input.unread;
        this.link = input.link;
        this.borderColor = input.borderColor;
        this.backgroundColor = input.backgroundColor;
        this.dataInFooter = input.dataInFooter;
        this.redirect = input.redirect;
        this.data = input.data;
        this.seen = input.seen;
        this.isFromPatientApp = input.isFromPatientApp;
        this.msgBody = input.msgBody;
        return this;
    }
}

// channel:"EMAIL"
// customerId:43
// customerName:"Nina Test"
// description:"Obvestilo o rezervaciji"
// queueId:"01ab4f1b-cf1d-42d4-b680-de6949bb6faf"
// sentTime:"2018-03-21T12:14:04.265"
// status:"SENT"
