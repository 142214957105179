import { Serializable } from './deserialize.service';

export class App implements Serializable<App> {
    app: string;
    iconPath: string;
    logoPath: string;
    name: string;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    height?: any;

    deserialize(input) {
        this.app = input.app;
        this.iconPath = input.iconPath;
        this.logoPath = input.logoPath;
        this.name = input.name;
        this.height = input.height;
        return this;
    }
}
