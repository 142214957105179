<ng-container>
    <div class="m-portlet m-portlet--wizard">
        <!-- INJECTED HTML -->
        <div *ngIf="data?.nativeElement && !data?.simpleOverlaySettings; else noInjectedHtml" [innerHTML]="data?.nativeElement?.innerHTML"></div>
        <!-- NO INJECTED HTML -> ONLY TITLE + TEXT CONTENT -->
        <ng-template #noInjectedHtml>
            <div class="m-portlet__head">
                <div class="m-portlet__head-caption">
                    <div class="m-portlet__head-title">
                        <h3 class="m-portlet__head-text font-weight-bold">
                            {{ data?.simpleOverlaySettings?.title }}
                        </h3>
                    </div>
                </div>
            </div>
            <div class="m-portlet__body">
                <div style="background-color: white; font-size: 1.2rem" class="px-3">
                    {{ data?.simpleOverlaySettings?.textContent }}
                </div>
            </div>
        </ng-template>
        <!-- DONT SHOW AGAIN CHECKBOX -->
        <hr />
        <div *ngIf="data?.showButton" class="m-portlet__body pt-1">
            <label class="m-checkbox m-checkbox--brand">
                <input [(ngModel)]="dontShowAgain" type="checkbox" class="form-control form-control m-input" />
                Razumem. Ne prikazuj več tega opozorila
                <span></span>
            </label>
        </div>
    </div>
    <!-- CLOSE BUTTON -->
    <div *ngIf="data?.showButton" class="d-flex justify-content-center">
        <button (click)="close()" class="btn btn-brand m-btn m-btn--pill">OK</button>
    </div>
</ng-container>
