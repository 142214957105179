import { Injectable } from '@angular/core';
import { TelephonyService } from './../../pages/home/telephony/telephony.service';

@Injectable({
    providedIn: 'root',
})
export class AsnMenuActionsService {
    constructor(private telephony: TelephonyService) {}

    public manageActions(action: string): void {
        switch (action) {
            case 'openTelephonyPopup':
                this.telephony.show();
                break;

            default:
                break;
        }
    }
}
