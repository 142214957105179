import { DOCUMENT } from '@angular/common';
import { Directive, EventEmitter, Inject, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { NotificationService } from '../../theme/pages/home/_services';
import { Notification as NotificationEnum } from '../enums/notification.enum';
// models
import { Notification } from '../model/notification.model';
import { NotificationMessage } from '../model/notificationMessage.model';
import { HelperNavigateService } from './helper-navigate.service';
import { OneSignalService } from './onesignal.service';
import { StorageService } from './storage.service';
// other

@Directive()
@Injectable({
    providedIn: 'root',
})
export class HelperNotificationService {
    constructor(
        @Inject(DOCUMENT) private _document: HTMLDocument,
        private oneSignal: OneSignalService,
        private notifRest: NotificationService,
        private route: Router,
        private storage: StorageService,
        private navigateHelper: HelperNavigateService,
    ) {
        this.oneSignal.clickedNotifications.subscribe(notification => {
            if (notification.data) {
                const parsedNotification = this.notificationPrepareData(
                    new Notification().deserialize({
                        data: notification.data,
                        type: notification.data.type,
                    }),
                );

                if (notification.data.notificationId) {
                    this.notifRest.markNotificationAsSeen(this.storage.getSelectedContractor().id, notification.data.notificationId).subscribe();
                }

                if (parsedNotification.redirect) {
                    this.redirectSwitch(parsedNotification);
                }
            } else if (notification.url) {
                const url = new URL(notification.url);
                if (url.pathname && url.pathname !== '/') {
                    this.route.navigate([url.pathname]);
                }
            }
        });
    }

    public redirectSwitch(notification): void {
        switch (notification.redirect) {
            case 'reservation':
                this.redirectComment(notification);
                break;
            case 'normal':
                this.route.navigate([notification.link]);
                break;
            case 'assistant':
                this.redirectComment(notification);
                break;
            case 'profile':
                this.route.navigate(['settings/profile']);
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line no-fallthrough
            default:
                break;
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private redirectComment(notification): any {
        if (notification.link.includes('null') || notification.link.includes('undefined')) {
            this.route.navigate([this.navigateHelper.getCaseUrl()]);
            return;
        } else {
            this.route.navigate([notification.link]);
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/no-explicit-any
    public onNotificationSideBarOpen: Subject<Notification[]> = new Subject<any>();

    NotificationsData(data) {
        this.onNotificationSideBarOpen.next(data);
    }

    toggle() {
        this.change.emit([]);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/member-ordering, @angular-eslint/no-output-native
    @Output() change: EventEmitter<Notification[]> = new EventEmitter();

    public test(): void {
        this.onNotificationSideBarOpen.next([]);
    }

    public returnNotificationReadyForView(oldNotification: Notification): Notification {
        return this.notificationPrepareData(oldNotification);
    }

    public setNotificationFavicon(show: boolean) {
        const favicon = this._document.getElementById('appFavicon');
        const favicon16 = this._document.getElementById('appFavicon16');
        const favicon32 = this._document.getElementById('appFavicon32');
        if (show) {
            favicon.setAttribute('href', './assets/app/media/img/favicon/favicon_notification.ico');
            favicon16.setAttribute('href', './assets/app/media/img/favicon/favicon-16x16_notification.png');
            favicon32.setAttribute('href', './assets/app/media/img/favicon/favicon-32x32_notification.png');
        } else {
            favicon.setAttribute('href', './assets/app/media/img/favicon/favicon.ico');
            favicon16.setAttribute('href', './assets/app/media/img/favicon/favicon-16x16.png');
            favicon32.setAttribute('href', './assets/app/media/img/favicon/favicon-32x32.png');
        }
    }

    public notificationPrepareData(notification: Notification): Notification {
        const caseRootUrl: string = this.navigateHelper.getCaseUrl();
        switch (notification.type) {
            case NotificationEnum.GDPR_CONSENT_COMPLETED:
                notification.title = 'GDPR soglasje';
                notification.backgroundColor = '#E6F7F3';
                notification.borderColor = '#34BFA3';
                notification.link = `customers/view/${encodeURIComponent(notification.data.customerId)}`;
                notification.name = notification.data.customerName;
                notification.messages = null;
                notification.redirect = 'normal';
                notification.dataInFooter = 'customer';
                return notification;

            // avtorizacije
            case NotificationEnum.RESERVATION_CANCELED:
                notification.title = 'Termin preklican';
                notification.backgroundColor = '#FDECEF';
                notification.borderColor = '#F4516C';
                notification.link = `${caseRootUrl}${notification.data === null ? 0 : encodeURIComponent(notification.data.caseId)}`;
                notification.name = notification.customerName;
                notification.messages = null;
                notification.redirect = 'reservation';
                notification.dataInFooter = 'business';
                notification.prereservationId = notification.data === null ? 0 : notification.data.caseId;
                return notification;

            case NotificationEnum.RESERVATION_MARKED_AS_NO_SHOW:
                notification.title = 'Stranke ni bilo';
                notification.backgroundColor = '#FDECEF';
                notification.borderColor = '#F4516C';
                notification.link = `${caseRootUrl}${notification.data === null ? 0 : encodeURIComponent(notification.data.caseId)}`;
                notification.name = notification.customerName;
                notification.messages = null;
                notification.redirect = 'reservation';
                notification.dataInFooter = 'business';
                notification.prereservationId = notification.data === null ? 0 : notification.data.caseId;
                return notification;

            case NotificationEnum.RESERVATION_COMPLETED:
                notification.title = 'Avtorizacija zaključena';
                notification.backgroundColor = '#E6F7F3';
                notification.borderColor = '#34BFA3';
                notification.link = `${caseRootUrl}${notification.data === null ? 0 : encodeURIComponent(notification.data.caseId)}`;
                notification.name = notification.customerName;
                notification.messages = null;
                notification.redirect = 'reservation';
                notification.dataInFooter = 'business';
                notification.prereservationId = notification.data === null ? 0 : notification.data.caseId;
                return notification;

            case NotificationEnum.RESERVATION_UPDATED:
                notification.title = 'Termin spremenjen';
                notification.backgroundColor = '#FCE6C5';
                notification.borderColor = '#A16D16';
                notification.link = `${caseRootUrl}${notification.data === null ? 0 : encodeURIComponent(notification.data.caseId)}`;
                notification.name = notification.customerName;
                notification.messages = this.reservationUpdatedSetMessage(notification.data);
                notification.redirect = 'reservation';
                notification.dataInFooter = 'business';
                notification.prereservationId = notification.data === null ? 0 : notification.data.caseId;
                return notification;

            case NotificationEnum.RESERVATION_CREATED:
                if (_.isNil(notification.data.caseId)) {
                    // rezervacija iz patientappa
                    notification.title = 'Rezervacija ustvarjena';
                    notification.backgroundColor = '#E6F7F3';
                    notification.borderColor = '#34BFA3';
                    notification.link = `/calendar/selected/${encodeURIComponent(notification.prereservationId)}`;
                    notification.name = notification.customerName;
                    notification.messages = null;
                    notification.redirect = 'reservation';
                    notification.dataInFooter = 'customer';
                    notification.prereservationId = notification.data === null ? 0 : notification.data.caseId;
                    if (_.has(notification, 'data.bsId') && notification.data.bsId == -111) {
                        notification.isFromPatientApp = true;
                    }
                } else {
                    //rezervacija od zunaj ki je povezan s avtorizacijo
                    notification.title = 'Avtorizacija kreirana';
                    notification.backgroundColor = '#ADD8e6';
                    notification.borderColor = '#008BB8';
                    notification.link = `${caseRootUrl}${notification.data === null ? 0 : encodeURIComponent(notification.data.caseId)}`;
                    notification.name = notification.customerName;
                    notification.messages = null;
                    notification.redirect = 'reservation';
                    notification.dataInFooter = 'business';
                    notification.prereservationId = notification.data === null ? 0 : notification.data.caseId;
                }

                return notification;

            case NotificationEnum.CASE_CANCELED:
                notification.title = 'Primer preklican';
                notification.backgroundColor = '#FDECEF';
                notification.borderColor = '#F4516C';
                notification.link = `${caseRootUrl}${notification.data === null ? 0 : encodeURIComponent(notification.data.caseId)}`;
                notification.name = notification.customerName;
                notification.messages = null;
                notification.redirect = 'reservation';
                notification.dataInFooter = 'business';
                notification.prereservationId = notification.data === null ? 0 : notification.data.caseId;
                return notification;

            case NotificationEnum.ASISTANT_CASE_DOCUMENT_ADDED:
                notification.title = 'Nov dokument';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                notification.link = `${caseRootUrl}${encodeURIComponent(notification.data.caseId)}`;
                notification.name = notification.data.appUserName;
                notification.messages = this.documentAddedMessage(notification.data);
                notification.redirect = 'assistant';
                notification.prereservationId = notification.data.caseId;
                notification.dataInFooter = 'business';
                return notification;

            case NotificationEnum.ASISTANT_CASE_COMMENT_ADDED:
                notification.title = 'Nov komentar';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                notification.link = `${caseRootUrl}${encodeURIComponent(notification.data.caseId)}`;
                notification.name = notification.data.appUserName;
                notification.messages = this.commentAddedMessage(notification.data);
                notification.redirect = 'assistant';
                notification.prereservationId = notification.data.caseId;
                notification.dataInFooter = 'business';
                return notification;
            //triage
            case NotificationEnum.ASISTANT_TRIAGE_DOCUMENT_ADDED:
                notification.title = 'Nov dokument';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                notification.link = `inbox/consul/view/${encodeURIComponent(notification.data.triageId)}`;
                notification.name = notification.data.appUserName;
                notification.messages = this.documentAddedMessage(notification.data);
                notification.redirect = 'normal';
                notification.prereservationId = notification.data.triageId;
                notification.dataInFooter = 'business';
                return notification;

            case NotificationEnum.ASISTANT_TRIAGE_COMMENT_ADDED:
                notification.title = 'Nov komentar';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                notification.link = `inbox/consul/view/${encodeURIComponent(notification.data.triageId)}`;
                notification.name = notification.data.appUserName;
                notification.messages = this.commentAddedMessage(notification.data);
                notification.redirect = 'assistant';
                notification.prereservationId = notification.data.triageId;
                notification.dataInFooter = 'business';
                return notification;

            case NotificationEnum.TRIAGE_CREATED:
                notification.title = 'Nova eTriaža';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                notification.link = `inbox/consul/view/${encodeURIComponent(notification.data.triageId)}`;
                notification.name = notification.data.appUserName;
                notification.messages = null;
                notification.redirect = 'assistant';
                notification.prereservationId = notification.data.triageId;
                notification.dataInFooter = 'business';
                return notification;

            case NotificationEnum.TRIAGE_CONCLUDED:
                notification.title = 'Zaključena eTriaža';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                notification.link = `inbox/consul/view/${encodeURIComponent(notification.data.triageId)}`;
                notification.name = notification.data.appUserName;
                notification.messages = null;
                notification.redirect = 'assistant';
                notification.prereservationId = notification.data.triageId;
                notification.dataInFooter = 'business';
                return notification;

            case NotificationEnum.TRIAGE_CANCELED:
                notification.title = 'Preklicana eTriaža';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                notification.link = `inbox/consul/view/${encodeURIComponent(notification.data.triageId)}`;
                notification.name = notification.data.appUserName;
                notification.messages = null;
                notification.redirect = 'assistant';
                notification.prereservationId = notification.data.triageId;
                notification.dataInFooter = 'business';
                return notification;

            case NotificationEnum.CONTRACTOR_PRICE_LIST_CONFIRMED:
                notification.title = 'Cenik potrjen';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                notification.link = null;
                notification.name = notification.data.appUserName;
                notification.messages = this.pricelistMessage(notification.data);
                notification.redirect = '';
                notification.dataInFooter = 'business';
                return notification;

            case NotificationEnum.CONTRACTOR_PRICE_LIST_UPDATED:
                notification.title = 'Cenik spremenjen';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                notification.link = null;
                notification.name = notification.data.appUserName;
                notification.messages = this.pricelistMessage(notification.data);
                notification.redirect = '';
                notification.dataInFooter = 'business';
                return notification;

            case NotificationEnum.CONTRACTOR_USER_LINK_REQUESTED:
                notification.title = 'Povezovanje z izvajalcem';
                notification.backgroundColor = '#c291eb';
                notification.link = `settings/profile`;
                notification.redirect = 'profile';
                notification.messages = this.userLinkMessage(notification.data.contractorName);
                return notification;

            default:
                notification.title = 'Manjkajo podatki';
                notification.backgroundColor = '#EAEDEF';
                notification.borderColor = '#364045';
                return notification;
                break;
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public reservationUpdatedSetMessage(data: any): NotificationMessage[] {
        const messages: NotificationMessage[] = [];
        const msg1: NotificationMessage = new NotificationMessage().deserialize({
            message: data.newFrom,
            messageStyle: '',
            datePipe: true,
            icon: 'la la-calendar',
        });

        const msg2: NotificationMessage = new NotificationMessage().deserialize({
            message: data.originalFrom,
            messageStyle: { 'text-decoration': 'line-through', 'margin-left': '19px' },
            datePipe: true,
            icon: 'none',
        });
        messages.push(msg1, msg2);
        return messages;
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public documentAddedMessage(data: any): NotificationMessage[] {
        const messages: NotificationMessage[] = [];
        const msg1: NotificationMessage = new NotificationMessage().deserialize({
            message: data.documentDescription,
            messageStyle: '',
            datePipe: false,
            icon: 'none',
        });
        messages.push(msg1);
        return messages;
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public commentAddedMessage(data: any): NotificationMessage[] {
        const messages: NotificationMessage[] = [];
        const msg1: NotificationMessage = new NotificationMessage().deserialize({
            message: data.comment,
            messageStyle: '',
            datePipe: false,
            icon: 'none',
        });
        messages.push(msg1);
        return messages;
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public pricelistMessage(data: any): NotificationMessage[] {
        const messages: NotificationMessage[] = [];
        const msg1: NotificationMessage = new NotificationMessage().deserialize({
            message: data.priceListName,
            messageStyle: '',
            datePipe: false,
            icon: 'none',
        });
        messages.push(msg1);
        return messages;
    }
    public userLinkMessage(contractorName: string): NotificationMessage[] {
        const messages: NotificationMessage[] = [];
        const msg1: NotificationMessage = new NotificationMessage().deserialize({
            message: `${
                contractorName.charAt(0).toUpperCase() + contractorName.slice(1)
            } vas je dodal kot svojega uporabnika. Potrdite akcijo v nastavitvah svojega profila`,
            messageStyle: '',
            datePipe: false,
            icon: 'none',
        });
        messages.push(msg1);
        return messages;
    }
}
