import { DocumentSource } from '../enums/documentSource.enum';
import { Serializable } from './deserialize.service';

export class Document implements Serializable<Document> {
    // id: number; //deprecated
    documentId: string;
    documentAdded: Date;
    description: string;
    url: string;
    contentType: string;
    fromAmb: boolean;
    documentSource: DocumentSource;
    prereservationId: string;
    appUserName: string;
    thumbnail: string;
    lastPublicAccess: Date;
    shared: boolean;

    deserialize(input) {
        this.documentAdded = input.documentAdded;
        this.description = input.description;
        this.url = input.url;
        this.contentType = input.contentType;
        this.fromAmb = input.fromAmb;
        this.documentSource = input.documentSource;
        this.prereservationId = input.prereservationId;
        this.appUserName = input.appUserName;
        this.documentId = input.documentId;
        this.thumbnail = input.thumbnail;
        this.lastPublicAccess = input.lastPublicAccess;
        this.shared = input.shared;
        return this;
    }
}

export class DocumentViewerDocument extends Document {
    public isReadOnly = false;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any): this {
        this.isReadOnly = input.isReadOnly;
        return super.deserialize(input);
    }
}

// id: 1000,
// title: 'Repeating Event',
// description: 'Lorem ipsum dolor sit amet, labore',
// start: YM + '-16T16:00:00'
