import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { DATE_CONST } from './../../../../../../_shared/constants/date-constants.const';
import { Subcontractor } from './../../../../../../_shared/model/subcontractor.model';
import { HelperService } from './../../../../../../_shared/services/helper.service';
import { GetAndLoadSubcontractors } from './../../../../../../_shared/state/base/base.actions';
import { BaseState } from './../../../../../../_shared/state/base/base.state';

@Component({
    selector: 'pf-comn-details',
    templateUrl: './pf-comn-details.component.html',
    styleUrls: ['./pf-comn-details.component.scss'],
})
export class PfComnDetailsComponent implements OnInit {
    @Input()
    parent: FormGroup;

    @Output()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix, @typescript-eslint/no-explicit-any
    public onDataChange: EventEmitter<any> = new EventEmitter();

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public fromInput: any;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public toInput: any;
    public allSubcontractors: Subcontractor[];
    private onDestroy$ = new Subject<void>();

    constructor(
        private helper: HelperService,
        public store: Store,
    ) {}

    ngOnInit() {
        this.store
            .dispatch(new GetAndLoadSubcontractors())
            .pipe(
                mergeMap(() => this.store.select(BaseState.getAllSubcontractors())),
                takeUntil(this.onDestroy$),
                tap((subcontractors: Subcontractor[]) => (this.allSubcontractors = subcontractors)),
            )
            .subscribe();

        const from = this.formData?.timeSpan?.value?.from;
        this.fromInput = this.helper.getDateSettingsEmpty(from);
        // this.toInput = this.helper.getDateSettingsEmpty();
        this.toInput = { ...this.helper.getDateSettingsEmpty(), minDate: from };
    }

    get formData() {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return <any>this.parent.controls;
    }

    get formControls() {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return <any>this.parent.controls;
    }

    get formControlsTimeSpan() {
        return (<FormGroup>this.parent.controls.timeSpan).controls;
    }
    public selectedFromDate({ start }) {
        this.toInput = { ...this.toInput, minDate: start };

        (<FormControl>this.formData?.timeSpan?.controls?.from)?.setValue(start.format(DATE_CONST.format.date.view));
    }

    public selectedToDate({ start }) {
        this.fromInput = { ...this.fromInput, maxDate: start };

        (<FormControl>this.formData?.timeSpan?.controls?.to)?.setValue(start.format(DATE_CONST.format.date.view));
    }
}
