import { Serializable } from '../deserialize.service';

export class AssetGroup implements Serializable<AssetGroup> {
    group: string;
    label: string;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;

    deserialize(input) {
        if (!input || input === null) {
            return null;
        }

        Object.keys(input).forEach(key => (this[key] = input[key]));

        return this;
    }
}
