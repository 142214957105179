import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HelperService, StorageService } from '../../_shared/services';

@Injectable()
export class NotSavedGuard implements CanActivateChild {
    constructor(
        private router: Router,
        private storage: StorageService,
        private helper: HelperService,
    ) {}

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.helper.disabledNavigation) {
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line no-restricted-syntax
            console.info('Nimate shranjene podatke');
            return false;
        }
        return true;
    }
}
