import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InquiriesRequest } from '../../../../_shared/model/inquiry/inquiries-request.model';
import { Inquiry } from '../../../../_shared/model/inquiry/inquiry.model';
import { InquiryType } from './../../../../_shared/enums/inquiries/inquiry-type.enum';
import { HelperService } from './../../../../_shared/services/helper.service';
import { HttpClientService } from './../../../../_shared/services/http-client.service';
import { StorageService } from './../../../../_shared/services/storage.service';

@Injectable({
    providedIn: 'root',
})
export class InquiryService {
    constructor(
        private httpClient: HttpClientService,
        private storage: StorageService,
        private helper: HelperService,
    ) {}

    // public getInquiries(contractorid: Inquiry) {
    //     return this.httpClient.get(this.helper.getApiUrl() + `/contractors/${contractorid}/print/rules`);
    // }

    public getInquiries(cid: number, from: string, to: string, type?: InquiryType): Observable<Inquiry[]> {
        let url = `/contractors/${cid}/inquiries?from=${from}&to=${to}`;
        if (type) {
            url += `&type=${type}`;
        }
        return this.httpClient.get(this.helper.getApiUrl(2) + url);
    }

    // public updateInquiriesStatus(cid: number, id: string, status: number): Observable<any> {
    //     return this.httpClient.put(this.helper.getApiUrl(2) + `/contractors/${cid}/inquiries/${id}/status/${status}`);
    // }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateInquiry(cid: number, id: string, data: InquiriesRequest): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl(2) + `/contractors/${cid}/inquiries/${id}`, data);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateInquiryDocument(cid: number, id: string, documents: any): Observable<any> {
        return this.httpClient.post(this.helper.getApiUrl(2) + `/contractors/${cid}/inquiries/${id}/documents`, documents, {
            reportProgress: true,
            responseType: 'text',
        });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public createInquiry(contractorId: number, origin: string, type: InquiryType, customerId: number, formData: any): Observable<any> {
        return this.httpClient.post(
            this.helper.getApiUrl(2) + `/contractors/${contractorId}/inquiries?origin=${origin}&type=${type}&customerId=${customerId}`,
            formData,
            {
                reportProgress: true,
                responseType: 'text',
            },
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public removeInquiryDocuments(cid: number, id: string, ids: any): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl(2) + `/contractors/${cid}/inquiries/${id}/documents/delete`, ids);
    }

    public getNewInquiries(cid: number): Observable<number> {
        return this.httpClient.get(this.helper.getApiUrl(2) + `/contractors/${cid}/inquiries/count/new`);
    }
}
