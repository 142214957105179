import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'originUrl',
})
export class OriginUrlPipe implements PipeTransform {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(value: any): any {
        switch (value) {
            case 'SELF-EMAIL':
                return 'Poslani termini prek e-pošte';
            default:
                return value;
        }
    }
}
