import { Surfaces } from './../_model/surfaces.model';
import { Tooth } from './../_model/tooth.model';
export class ClearState {
    public static readonly type = 'ClearState';
    // constructor(public readonly services: Service[]) {}
}

export class AddTooth {
    public static readonly type = 'AddTooth';
    constructor(public readonly tooth: Tooth) {}
}

export class RemoveTooth {
    public static readonly type = 'RemoveTooth';
    constructor(public readonly tooth: Tooth) {}
}

export class UpdateToothSurfaces {
    public static readonly type = 'UpdateToothSurfaces';
    constructor(
        public readonly tooth: Tooth,
        public readonly surfaces: Surfaces,
    ) {}
}
