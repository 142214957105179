export enum ReservationMode {
    INTERNAL,
    RESERVE,
    DRAFT,
    VISIT,
    QUEUE,
}

export function whichReservation(type: ReservationMode): string {
    switch (type) {
        case ReservationMode.INTERNAL:
            return 'internal';
        case ReservationMode.RESERVE:
            return 'reservation';
        case ReservationMode.DRAFT:
            return 'draft';
        case ReservationMode.VISIT:
            return 'visit';
        case ReservationMode.QUEUE:
            return 'queue';
    }
}
