import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { EMPTY, interval } from 'rxjs';
import { catchError, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { InboxService } from './../../../theme/pages/home/_services/inbox.service';
import { InquiryService } from './../../../theme/pages/home/_services/inquiry.service';
import { NotificationService } from './../../../theme/pages/home/_services/notification.service';
import { ReservationService } from './../../../theme/pages/home/_services/reservation.service';
import { TriageService } from './../../../theme/pages/home/_services/triage.service';
import { AppModulesType } from './../../enums/appModulesType.enum';
import { WebSocketEventType } from './../../enums/webSocket/webSocketEventType.enum';
import { User } from './../../model/user.model';
import { WebSocketResponse } from './../../model/webSocket/webSocketResponse.model';
import { ActiveModulesService } from './../../services/activeModules.service';
import { HelperService } from './../../services/helper.service';
import { StorageService } from './../../services/storage.service';
import { SurveysService } from './../../services/surveys.service';
import { BaseState } from './../base/base.state';
import { WebsocketStateModel } from './websocket-state.model';
import { ClearWebSocketState, WebsocketActions } from './websocket.actions';

const WEBSOCKETSTATE_TOKEN: StateToken<WebsocketStateModel> = new StateToken('websocketmainstate');

const DEFAULT_STATE: WebsocketStateModel = {
    deviceNewFiles: undefined,
    newNotifications: undefined,
    newTelemedicineChat: undefined,
    newTriages: undefined,
    newSurveys: undefined,
    newInquiry: undefined,
};

@State<WebsocketStateModel>({
    name: WEBSOCKETSTATE_TOKEN,
    defaults: DEFAULT_STATE,
    children: [], //ce bo kdaj prov prislo
})
@Injectable({
    providedIn: 'root',
})
export class WebsocketState {
    // private selectedContractor: Contractor = this.store.selectSnapshot(BaseState.activeContractor);
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private activeWS: WebSocketSubject<any>;

    constructor(
        private helper: HelperService,
        private store: Store,
        private inboxRest: InboxService,
        private storage: StorageService,
        private activeModules: ActiveModulesService,
        private notificationService: NotificationService,
        private inquiryApi: InquiryService,
        private reservatonRest: ReservationService,
        private triageRest: TriageService,
        private surveysRest: SurveysService,
    ) {}

    @Selector([WEBSOCKETSTATE_TOKEN])
    public static getDeviceNewFiles(state: WebsocketStateModel): number {
        return state.deviceNewFiles;
    }

    @Selector([WEBSOCKETSTATE_TOKEN])
    public static getNewNofitications(state: WebsocketStateModel): number {
        return state.newNotifications;
    }

    @Selector([WEBSOCKETSTATE_TOKEN])
    public static getNewTelemedicineChat(state: WebsocketStateModel): number {
        return state.newTelemedicineChat;
    }

    @Selector([WEBSOCKETSTATE_TOKEN])
    public static getNewInquiry(state: WebsocketStateModel): number {
        return state.newInquiry;
    }
    @Selector([WEBSOCKETSTATE_TOKEN])
    public static getNewTriages(state: WebsocketStateModel): number {
        return state.newTriages;
    }
    @Selector([WEBSOCKETSTATE_TOKEN])
    public static getNewSurveys(state: WebsocketStateModel): number {
        return state.newSurveys;
    }

    @Action(WebsocketActions.InitWebSocket)
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line no-empty-pattern
    public InitWebSocket(ctx: StateContext<WebsocketStateModel>, {}: WebsocketActions.InitWebSocket) {
        if (!this.activeWS) {
            const user: User = this.store.selectSnapshot(BaseState.LoggedUser);
            const contractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);
            const getCounterInterval = 5 * 60 * 1000; //5 minut

            const subcEmpl: string | number = user.subcontractorId ? user.subcontractorId : user.employeeId;

            this.activeWS = webSocket(
                this.helper.getWebSocketUrl() + `/${contractorId}/${user.username}/${subcEmpl}?auth_token=${this.storage.getToken()?.access_token}`,
            );

            this.activeWS
                .multiplex(
                    () => ({ subscribe: 'notifications' }),
                    () => ({ unsubscribe: 'notifications' }),
                    (message: WebSocketResponse) => {
                        return message.eventType == WebSocketEventType.RESERVATION_UPDATED;
                    },
                )
                .pipe(
                    tap(
                        // TODO Ignored with eslint-interactive on 2023-11-10
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        (data: WebSocketResponse) => {
                            // povečamo št. notifikacij
                            let newNotifications: number = ctx.getState().newNotifications;
                            newNotifications++;

                            ctx.setState(
                                patch<WebsocketStateModel>({
                                    newNotifications: newNotifications,
                                }),
                            );
                        },
                        // TODO Ignored with eslint-interactive on 2023-11-10
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
                        err => {},
                        () => {
                            console.log('WS: notification, closed');
                        },
                    ),
                    // TODO Ignored with eslint-interactive on 2023-11-10
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    catchError(x => {
                        return EMPTY;
                    }),
                )
                .subscribe();

            if (this.activeModules.isAM(AppModulesType.DEVICES_INBOX)) {
                this.activeWS
                    .multiplex(
                        () => ({ subscribe: 'itero' }),
                        () => ({ unsubscribe: 'itero' }),
                        (message: WebSocketResponse) => {
                            return message.eventType == WebSocketEventType.ITEROFILES;
                        },
                    )
                    .pipe(
                        tap(
                            (data: WebSocketResponse) => {
                                ctx.setState(
                                    patch<WebsocketStateModel>({
                                        deviceNewFiles: data.iterofiles,
                                    }),
                                );
                            },
                            // TODO Ignored with eslint-interactive on 2023-11-10
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
                            err => {},
                            () => {
                                console.log('WS: itero, closed');
                            },
                        ),
                        catchError(() => {
                            return EMPTY;
                        }),
                    )
                    .subscribe();
            }

            if (this.activeModules.isAM(AppModulesType.TELEMEDICINE)) {
                this.activeWS
                    .multiplex(
                        () => ({ subscribe: 'eposvet' }),
                        () => ({ unsubscribe: 'eposvet' }),
                        (message: WebSocketResponse) => message.eventType.toUpperCase() == WebSocketEventType.CHAT,
                    )
                    .pipe(
                        tap(
                            (data: WebSocketResponse) => {
                                // let numOFTelChat:number  =ctx.getState().newTelemedicineChat
                                ctx.setState(
                                    patch<WebsocketStateModel>({
                                        newTelemedicineChat: ctx.getState().newTelemedicineChat + data?.chat?.length,
                                    }),
                                );
                            },
                            // TODO Ignored with eslint-interactive on 2023-11-10
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
                            err => {},
                            () => {
                                console.log('WS: eposvet, closed');
                            },
                        ),
                        catchError(() => {
                            return EMPTY;
                        }),
                    )
                    .subscribe();
            }

            if (this.activeModules.isAM(AppModulesType.WIDGETS)) {
                this.activeWS
                    .multiplex(
                        () => ({ subscribe: 'inquiry' }),
                        () => ({ unsubscribe: 'inquiry' }),
                        (message: WebSocketResponse) => message.eventType.toUpperCase() == WebSocketEventType.INQUIRY,
                    )
                    .pipe(
                        tap(
                            (data: WebSocketResponse) => {
                                ctx.setState(
                                    patch<WebsocketStateModel>({
                                        newInquiry: data.value,
                                    }),
                                );
                            },
                            // TODO Ignored with eslint-interactive on 2023-11-10
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
                            err => {},
                            () => {
                                console.log('WS: eposvet, closed');
                            },
                        ),
                        catchError(() => {
                            return EMPTY;
                        }),
                    )
                    .subscribe();
            }
            if (this.activeModules.isAM(AppModulesType.TRIAGE)) {
                interval(getCounterInterval)
                    .pipe(
                        takeUntil(this.activeWS),
                        mergeMap(() => this.triageRest.getUnreadTriagesCount(contractorId)),
                        tap(
                            (unreadTriages: number) => {
                                ctx.setState(
                                    patch<WebsocketStateModel>({
                                        newTriages: unreadTriages,
                                    }),
                                );
                            },
                            // TODO Ignored with eslint-interactive on 2023-11-10
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
                            err => {},
                            () => {
                                console.log('WS: triage, closed');
                            },
                        ),
                        catchError(() => {
                            return EMPTY;
                        }),
                    )
                    .subscribe();
            }

            if (this.activeModules.isAM(AppModulesType.SURVEYS)) {
                interval(getCounterInterval)
                    .pipe(
                        takeUntil(this.activeWS),
                        mergeMap(() => this.surveysRest.getUnreadSurveysCount(contractorId)),
                        tap(
                            (unreadSurveys: number) => {
                                ctx.setState(
                                    patch<WebsocketStateModel>({
                                        newSurveys: unreadSurveys,
                                    }),
                                );
                            },
                            // TODO Ignored with eslint-interactive on 2023-11-10
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
                            err => {},
                            () => {
                                console.log('WS: surveys, closed');
                            },
                        ),
                        catchError(() => {
                            return EMPTY;
                        }),
                    )
                    .subscribe();
            }
        }
    }

    @Action(WebsocketActions.CloseWebSocket)
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public CloseWebSocket(ctx: StateContext<WebsocketStateModel>) {
        if (this.activeWS) {
            this.activeWS.complete();
        }
    }

    @Action(WebsocketActions.InboxDevices.GetAndLoadNumberOfDeviceFiles)
    public GetAndLoadNumberOfDeviceFiles(ctx: StateContext<WebsocketStateModel>) {
        const deviceNewFiles: number = ctx.getState().deviceNewFiles;
        if (deviceNewFiles != undefined) {
            return;
        }
    }
    @Action(WebsocketActions.Notifications.GetAndLoadNumberOfNotifications)
    public GetAndLoadNumberOfNotifications(ctx: StateContext<WebsocketStateModel>) {
        const contractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);
        const newNotifications: number = ctx.getState().newNotifications;
        if (newNotifications != undefined) {
            return;
        }
        if (contractorId) {
            return this.notificationService.getUnreadNotifications(contractorId).pipe(
                tap((res: number) => {
                    ctx.patchState({
                        newNotifications: res,
                    });
                }),
            );
        }
    }

    @Action(WebsocketActions.Notifications.GetAndLoadNumberOfInquiry)
    public GetAndLoadNumberOfInquiry(ctx: StateContext<WebsocketStateModel>) {
        const contractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);
        const newInquiry: number = ctx.getState().newInquiry;
        if (newInquiry != undefined) {
            return;
        }

        return this.inquiryApi.getNewInquiries(contractorId).pipe(
            tap((res: number) => {
                ctx.patchState({
                    newInquiry: res,
                });
            }),
        );
    }

    @Action(ClearWebSocketState)
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line no-empty-pattern
    public ClearState(ctx: StateContext<WebsocketStateModel>, {}: ClearWebSocketState) {
        this.store.dispatch(WebsocketActions.CloseWebSocket);
        ctx.patchState(DEFAULT_STATE);
    }

    @Action(WebsocketActions.InboxDevices.SetNumberOfDeviceFiles)
    public SetNumberOfDeviceFiles(ctx: StateContext<WebsocketStateModel>, { numberOfFiles }: WebsocketActions.InboxDevices.SetNumberOfDeviceFiles) {
        ctx.setState(
            patch<WebsocketStateModel>({
                deviceNewFiles: numberOfFiles,
            }),
        );
    }

    @Action(WebsocketActions.Notifications.SetNewNotifications)
    public SetNewNotifications(
        ctx: StateContext<WebsocketStateModel>,
        { numberOfNewNotifications }: WebsocketActions.Notifications.SetNewNotifications,
    ) {
        ctx.setState(
            patch<WebsocketStateModel>({
                newNotifications: numberOfNewNotifications,
            }),
        );
    }

    @Action(WebsocketActions.Telemedicine.GetAndLoadNumberOfNotifications)
    public GetAndLoadNumberOfTelemedicine(ctx: StateContext<WebsocketStateModel>) {
        const contractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);

        if (this.activeModules.isAM(AppModulesType.TELEMEDICINE)) {
            return this.reservatonRest.getUnreadReservationForContractorList(contractorId).pipe(
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                tap((res: any[]) => {
                    // const value: number = res.reduce((acc, val) => acc + val?.numOfUnread, 0);
                    ctx.patchState({
                        newTelemedicineChat: res.length,
                    });
                }),
            );
        }
    }

    @Action(WebsocketActions.Telemedicine.ResolveNotifications)
    public ResolveNotifications(
        ctx: StateContext<WebsocketStateModel>,
        { numberOfResolvedNotifications }: WebsocketActions.Telemedicine.ResolveNotifications,
    ) {
        ctx.patchState({
            newTelemedicineChat: ctx.getState().newTelemedicineChat - numberOfResolvedNotifications,
        });
    }

    @Action(WebsocketActions.Triage.ResolveNotifications)
    public ResolveNotificationsTriages(
        ctx: StateContext<WebsocketStateModel>,
        { numberOfResolvedNotifications }: WebsocketActions.Triage.ResolveNotifications,
    ) {
        ctx.patchState({
            newTriages: ctx.getState().newTriages - numberOfResolvedNotifications,
        });
    }

    @Action(WebsocketActions.Triage.GetAndLoadNumberOfNotifications)
    public GetAndLoadNumberOfTriage(ctx: StateContext<WebsocketStateModel>) {
        const contractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);
        if (this.activeModules.isAM(AppModulesType.TELEMEDICINE)) {
            return this.triageRest.getUnreadTriagesCount(contractorId).pipe(
                tap((res: number) => {
                    ctx.patchState({
                        newTriages: res,
                    });
                }),
            );
        }
    }

    @Action(WebsocketActions.Survey.GetAndLoadNumberOfNotifications)
    public GetAndLoadNumberOfSurvey(ctx: StateContext<WebsocketStateModel>) {
        const contractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);
        if (this.activeModules.isAM(AppModulesType.SURVEYS)) {
            return this.surveysRest.getUnreadSurveysCount(contractorId).pipe(
                tap((res: number) => {
                    ctx.patchState({
                        newSurveys: res,
                    });
                }),
            );
        }
    }

    @Action(WebsocketActions.Survey.ResolveNotifications)
    public ResolveNotificationsSurveys(
        ctx: StateContext<WebsocketStateModel>,
        { numberOfResolvedNotifications }: WebsocketActions.Telemedicine.ResolveNotifications,
    ) {
        ctx.patchState({
            newSurveys: ctx.getState().newSurveys - numberOfResolvedNotifications,
        });
    }
}
