import { AfterViewInit, Component, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { environment } from '../../../../environments/environment';
import { Contractor, Customer, User } from '../../../_shared/model';
import { Notification } from '../../../_shared/model/notification.model';
import { HelperService } from '../../../_shared/services';
import { JumpToService } from '../../../_shared/services/jumpTo.service';
import { MenuService } from '../../../_shared/services/menu.service';
import { QuickSidebarService } from '../../../_shared/services/quickSidebar.service';
import { MenuItem } from './../../../_shared/model/utils/menuItem.model';

// TODO Ignored with eslint-interactive on 2023-11-10
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let mLayout: any;
@Component({
    selector: 'app-header-nav',
    templateUrl: './header-nav.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['header-nav.component.scss'],
})
export class HeaderNavComponent implements OnInit, AfterViewInit {
    public loggedingUser: User;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public userRoles: any;
    public selectedCustomer: Customer;
    public selectedContractor: Contractor;
    public translateRole = this.helper.ROLE_TRANSLATE;
    public isProduction: boolean = environment.production;
    public clock: Date;
    public notificationsList: Notification[] = [];
    public subMenuList: MenuItem[] = [];
    constructor(
        public menu: MenuService,
        private helper: HelperService,
        public router: Router,
        private jumpTo: JumpToService,
        private render: Renderer2,
        private quickSidebarService: QuickSidebarService,
        private store: Store,
    ) {
        // this.helperNotification.onNotificationSideBarOpen.subscribe((data) => {
        //     this.notificationsList = data;
        // });
    }

    ngOnInit() {
        this.menu.menuList.subscribe(res => {
            this.subMenuList = res;
            if (res.length <= 0) {
                this.render.removeClass(document.body, 'm-topbar--on');
                this.render.removeClass(document.body, 'm-header--on');
            } else {
                this.render.addClass(document.body, 'm-topbar--on');
                this.render.addClass(document.body, 'm-header--on');
            }
        });

        this.clock = new Date();
        setInterval(() => {
            this.clock = new Date();
        }, 1000);
    }

    ngAfterViewInit() {
        mLayout.initHeader();
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public evaluateActionButton(actionButton: any): void {
        actionButton.component[actionButton.function]();
    }
    public sendDataToNotificatonSidebar(): void {
        this.quickSidebarService.toggle(this.notificationsList);
    }
}
