import * as moment from 'moment';
import { Serializable } from '../deserialize.service';

export class DateRange implements Serializable<DateRange> {
    start: moment.Moment;
    end: moment.Moment;
    label: string;

    deserialize(input) {
        this.start = input.start;
        this.end = input.end;
        this.label = input.label;

        return this;
    }
}
