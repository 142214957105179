import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { Teeth } from '../_model/teeth.model';
import { Tooth } from '../_model/tooth.model';
import { AddTooth, ClearState, RemoveTooth, UpdateToothSurfaces } from './teeth.actions';
import { TeethStateModel } from './teeth.model';

const TEETHSTATE_TOKEN: StateToken<TeethStateModel> = new StateToken('teethstate');

const DEFAULT_STATE: TeethStateModel = {
    selectedTeeth: [],
    teeth: new Teeth().deserialize({
        t11: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '11', type: 1 }),
        t12: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '12', type: 4 }),
        t13: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '13', type: 4 }),
        t14: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '14', type: 1 }),
        t15: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '15', type: 1 }),
        t16: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '16', type: 1 }),
        t17: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '17', type: 1 }),
        t18: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '18', type: 1 }),

        t21: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '21', type: 1 }),
        t22: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '22', type: 1 }),
        t23: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '23', type: 1 }),
        t24: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '24', type: 1 }),
        t25: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '25', type: 1 }),
        t26: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '26', type: 1 }),
        t27: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '27', type: 1 }),
        t28: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '28', type: 1 }),

        t31: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '31', type: 1 }),
        t32: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '32', type: 1 }),
        t33: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '33', type: 1 }),
        t34: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '34', type: 1 }),
        t35: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '35', type: 1 }),
        t36: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '36', type: 1 }),
        t37: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '37', type: 2 }),
        t38: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '38', type: 1 }),

        t41: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '41', type: 1 }),
        t42: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '42', type: 1 }),
        t43: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '43', type: 1 }),
        t44: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '44', type: 1 }),
        t45: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '45', type: 1 }),
        t46: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '46', type: 1 }),
        t47: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '47', type: 1 }),
        t48: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '48', type: 1 }),
        //MILK TEETH
        t51: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '51', type: 1 }),
        t52: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '52', type: 1 }),
        t53: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '53', type: 1 }),
        t54: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '54', type: 1 }),
        t55: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '55', type: 1 }),

        t61: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '61', type: 1 }),
        t62: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '62', type: 1 }),
        t63: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '63', type: 1 }),
        t64: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '64', type: 1 }),
        t65: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '65', type: 1 }),

        t71: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '71', type: 1 }),
        t72: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '72', type: 1 }),
        t73: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '73', type: 1 }),
        t74: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '74', type: 1 }),
        t75: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '75', type: 1 }),

        t81: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '81', type: 1 }),
        t82: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '82', type: 1 }),
        t83: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '83', type: 1 }),
        t84: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '84', type: 1 }),
        t85: new Tooth().deserialize({ isChanged: false, isSelected: false, id: '85', type: 1 }),
    }),
};

@State<TeethStateModel>({
    name: TEETHSTATE_TOKEN,
    defaults: DEFAULT_STATE,
    children: [], //ce bo kdaj prov prislo
})
@Injectable()
export class TeethState {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    // static getServices() {
    //     return createSelector([BaseState], (state: BaseStateModel) => state.services);
    // }

    @Selector()
    public static getTeeth(state: TeethStateModel): Teeth {
        return state.teeth;
    }

    // @Selector()
    // public static getPTeeth(state: TeethStateModel): any {
    //     return state.pTeeth;
    // }

    @Selector()
    public static getSelectedTeets(state: TeethStateModel): Tooth[] {
        return state.selectedTeeth;
    }

    @Action(ClearState)
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line no-empty-pattern
    public ClearState(ctx: StateContext<TeethStateModel>, {}: ClearState) {
        ctx.patchState(DEFAULT_STATE);
    }

    @Action(AddTooth)
    public AddTooth({ setState }: StateContext<TeethStateModel>, { tooth }: AddTooth) {
        setState(
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            patch<TeethStateModel | any>({
                teeth: patch({
                    ['t' + tooth?.id]: patch({
                        isSelected: true,
                    }),
                }),
                selectedTeeth: append([tooth]),
            }),
        );
    }

    @Action(RemoveTooth)
    public RemoveTooth({ setState }: StateContext<TeethStateModel>, { tooth }: RemoveTooth) {
        // tooth.isSelected = false;
        setState(
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            patch<TeethStateModel | any>({
                teeth: patch({
                    ['t' + tooth?.id]: patch({
                        isSelected: false,
                    }),
                }),
                selectedTeeth: removeItem((el: Tooth) => el.id == tooth?.id),
            }),
        );
    }

    @Action(UpdateToothSurfaces)
    public UpdateToothSurfaces({ setState }: StateContext<TeethStateModel>, { tooth, surfaces }: UpdateToothSurfaces) {
        // tooth.isSelected = false;
        setState(
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            patch<TeethStateModel | any>({
                selectedTeeth: updateItem((el: Tooth) => el.id == tooth?.id, patch({ surfaces: surfaces })),
            }),
        );
    }
}
