import { Contractor } from './contractor.model';

export class Subcontractor extends Contractor {
    taxId: string;
    active: boolean;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        super.deserialize(input);
        return Object.assign(this, input);
    }
}
