import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import { Intercom } from 'ng-intercom';
import { forkJoin, throwError } from 'rxjs';
import { catchError, filter, map, mergeMap, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Helpers } from '../helpers';
import { CommonService } from '../theme/pages/home/_services';
import { ConfigGUIService } from '../theme/pages/home/_services/configGUI.service';
import { ScriptLoaderService } from '../_services/script-loader.service';
import { ContractorSettings, GuiUserContractorSettings, User } from '../_shared/model';
import { HelperService, MfToastService, PublicService, RulesService, StorageService } from '../_shared/services';
import { ActiveModulesService } from '../_shared/services/activeModules.service';
import { OneSignalService } from '../_shared/services/onesignal.service';
import { TelephonyService } from './../theme/pages/home/telephony/telephony.service';
import { SettingsService } from './../theme/pages/home/_services/settings.service';
import { AppModulesType } from './../_shared/enums/appModulesType.enum';
import { Contractor } from './../_shared/model/contractor.model';
import { GuiUserSettings } from './../_shared/model/userGuiSettings/guiUserSettings.model';
import { ContractorGroup } from './../_shared/model/utils/contractorGroup.model';
import {
    ClearState,
    SetActiveContractor,
    SetActiveModules,
    SetContractorSettings,
    SetLoggedUser,
    SetUserSettings,
} from './../_shared/state/base/base.actions';
import { WebsocketActions } from './../_shared/state/websocket/websocket.actions';
import { CaptchaComponent } from './captcha/captcha.component';
import { AlertComponent } from './_directives';
import { LoginCustom } from './_helpers/login-custom';
import { AlertService, AuthenticationService } from './_services';

@Component({
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    templateUrl: './templates/login-3.component.html',
    styleUrls: ['./templates/login-3.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AuthComponent implements OnInit {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    model: any = {};
    loading = false;
    returnUrl: string;
    public version: string = this.helper.getAppVersion();
    public isTest: boolean = environment.test;

    @ViewChild('alertSignin', { read: ViewContainerRef, static: true })
    alertSignin: ViewContainerRef;
    @ViewChild('alertSignup', { read: ViewContainerRef })
    alertSignup: ViewContainerRef;
    @ViewChild('alertForgotPass', { read: ViewContainerRef })
    alertForgotPass: ViewContainerRef;
    public showPasswordSent = false;
    public isCaptcha = false;
    public isCaptchaSolved = true;
    public isPasswordResetCaptchaSolved = false;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public passwordResetCaptchaSolution: any = null;
    @ViewChild('loginCaptcha')
    loginCaptcha: CaptchaComponent;
    @ViewChild('passwordResetCaptcha')
    passwordResetCaptcha: CaptchaComponent;

    constructor(
        private _router: Router,
        private _script: ScriptLoaderService,
        private _route: ActivatedRoute,
        private _authService: AuthenticationService,
        private _alertService: AlertService,
        private cfr: ComponentFactoryResolver,
        private _storage: StorageService,
        private rules: RulesService,
        private toast: MfToastService,
        private helper: HelperService,
        private publicRest: PublicService,
        private commonRest: CommonService,
        private intercom: Intercom,
        private configGUIRest: ConfigGUIService,
        private activeModules: ActiveModulesService,
        private oneSignal: OneSignalService,
        private store: Store,
        private telephony: TelephonyService,
        private settingsRest: SettingsService, // @Inject(DOCUMENT) private _document,
    ) {}

    ngOnInit() {
        // this.telephony.descructor();
        this.clearStorage();
        this.intercom.boot({
            app_id: environment.INTERCOM_KEY,
            // Supports all optional configuration.
            widget: {
                activator: '#intercom',
            },
            Products: 'eAmbulanta',
        });
        this.model.remember = false;
        // get return url from route parameters or default to '/'
        this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/login?returnUrl=%2Findex';
        // this._router.navigate([this.returnUrl]);

        this._script.loadScripts('body', ['assets/vendors/base/vendors.bundle.js', 'assets/demo/demo5/base/scripts.bundle.js'], true).then(() => {
            Helpers.setLoading(false);
            LoginCustom.init();
        });
    }

    signin() {
        //varnost skrit input
        if (this.model.username) {
            return;
        }

        if (!this.isCaptchaSolved) {
            this.toast.error('Dodatno preverjanje ni bilo uspešno!');
            return;
        }

        this.loading = true;
        Helpers.setLoading(true);

        this._authService
            .login(this.model.email, this.model.password)
            .pipe(
                filter(canLogin => canLogin),
                mergeMap(() => {
                    return forkJoin({
                        userSettings: this.configGUIRest.getUserGuiSettings(),
                        userData: this.commonRest.getUserData(),
                    }).pipe(
                        tap(() => {
                            this._storage.deleteAll();
                            this._storage.deleteUserData();
                            this._storage.setAppData({
                                version: this.version,
                            });
                        }),
                        map((res: { userSettings: GuiUserSettings; userData: User }) => {
                            //nastavimo userdata
                            let selectedContractorGroup: ContractorGroup = undefined;
                            //Vzamemo zadnjega izbranega contractorja, ce ga ni vzamemo tega ki je prisel z klicem
                            if (!_.isNil(res.userSettings)) {
                                this.configGUIRest.updateUserGuiSettings(res.userSettings);
                                selectedContractorGroup = res.userData.userContractors.find(
                                    el => el.contractorId === res.userSettings.lastSelectedContractorId,
                                );
                                //če contractorja nismo našli je bil uporabnik pri njem izbrisan (vzemi naslednjega contractorja)
                                if (_.isNil(selectedContractorGroup) && _.get(res, 'userData.userContractors.length') > 0) {
                                    selectedContractorGroup = res.userData.userContractors[0];
                                }
                            } else {
                                selectedContractorGroup = res.userData.userContractors.find(el => el.contractorId === res.userData.contractorId);
                            }

                            _.assign(
                                res.userData,
                                { employeeId: selectedContractorGroup.employeeId },
                                { subcontractorId: selectedContractorGroup.subcontractorId },
                            );

                            const loggedUser: User = new User().deserialize(res.userData);
                            loggedUser.username = this.model.email;
                            this._storage.setUserData(loggedUser);
                            const selectedContractor: Contractor = res.userData.contractors.find(
                                el => el.id === selectedContractorGroup.contractorId,
                            );
                            this._storage.setSelectedContractor(selectedContractor);
                            this.store.dispatch([new SetActiveContractor(selectedContractor), new SetLoggedUser(loggedUser)]);
                            return selectedContractorGroup.contractorId;
                        }),
                        mergeMap(contractorId => {
                            return forkJoin([
                                this.commonRest.getUserRoles(contractorId).pipe(
                                    tap((roles: string[]) => {
                                        if (roles.length < 1) {
                                            throw new Error('Nimate pravice za prijavo!');
                                        }
                                        this.rules.setRules();
                                    }),
                                    catchError(error => {
                                        _.set(error, 'error.error_description', 'ROLES_ERROR');
                                        this.toast.error(error.message);
                                        return throwError(error);
                                    }),
                                ),
                                this.configGUIRest.getUserContractorGuiSettings(contractorId).pipe(
                                    tap(userSettings => {
                                        const settings = new GuiUserContractorSettings().deserialize(userSettings);
                                        if (!settings?.calendar?.filter) {
                                            settings.calendar = this.helper.getDefaultCalendarFilterSettings();
                                        }

                                        this._storage.setGuiUserContractorSettings(settings);
                                        this.store.dispatch(new SetUserSettings(settings));
                                    }),
                                ),
                                this.configGUIRest.getContractorGuiSettings(contractorId).pipe(
                                    tap(contractorSettings => {
                                        if (contractorSettings) {
                                            const contractorSettingsValue = new ContractorSettings().deserialize(contractorSettings);
                                            this._storage.setContractorSettings(contractorSettingsValue);
                                            this.store.dispatch(new SetContractorSettings(contractorSettingsValue));
                                            //TODO DEPRECATED
                                            // this.activeModules.setActiveModules();
                                        }
                                    }),
                                ),
                                this.settingsRest.getContractorModules(contractorId).pipe(
                                    tap(modules => {
                                        this.store.dispatch([new SetActiveModules(modules)]);
                                    }),
                                ),
                                this.settingsRest.getContractorSettings(contractorId).pipe(
                                    tap(contractorSettings => {
                                        if (contractorSettings) {
                                            this._storage.setSettings(contractorSettings);
                                            // this.store.dispatch(new SetContractorSettings(contractorSettingsValue));
                                        }
                                    }),
                                ),
                            ]);
                        }),
                    );
                }),
            )
            .subscribe(
                () => {
                    if (this.activeModules.isAM(AppModulesType.INBOX)) {
                        this.store.dispatch(new WebsocketActions.InitWebSocket());
                    }
                    // .pipe(takeUntil(this.onDestroy$));
                    this.loading = false;
                    Helpers.setLoading(false);
                    this.oneSignal.init(true);
                    // this.tawk();
                    this.telephony.init();
                    this._router.navigate([this.returnUrl]);
                },
                error => {
                    this.isCaptcha = false;
                    this.isCaptchaSolved = true;
                    this.showAlert('alertSignin');
                    if (error?.error?.error_description === 'CaptchaNeeded') {
                        this.isCaptcha = true;
                        this.isCaptchaSolved = false;
                        this._alertService.error('Zahtevano je bilo dodatno preverjanje uporabnika!');
                    } else if (_.get(error, 'error.error', '') === 'unauthorized' || _.get(error, 'error.error', '') === 'invalid_grant') {
                        this._alertService.error('Napačno uporabniško ime ali geslo.');
                    } else {
                        console.error(error);
                        this._alertService.error('Napaka pri prijavi.');
                    }
                    Helpers.setLoading(false);
                    this.loading = false;
                },
            );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    signup() {}

    backToSingIn() {
        this.showPasswordSent = false;
        LoginCustom.displaySignInForm();
    }

    forgotPass() {
        //varnost skrit input
        if (this.model.username) {
            return;
        }

        if (!this.passwordResetCaptchaSolution) {
            this.toast.error('Napaka pri preverjanju slike!');
            return;
        }

        this.loading = true;
        this.publicRest
            .forgottenPwd({
                username: this.model.email,
                guid: this.passwordResetCaptchaSolution.guid,
                x: this.passwordResetCaptchaSolution.x,
                y: this.passwordResetCaptchaSolution.y,
            })
            .subscribe(
                () => {
                    this.isPasswordResetCaptchaSolved = false;
                    this.passwordResetCaptchaSolution = null;
                    this.showPasswordSent = true;
                    this.loading = false;
                    this.model = {};
                },
                error => {
                    this.isPasswordResetCaptchaSolved = false;
                    this.passwordResetCaptchaSolution = null;
                    if (error?.error?.errors[0]?.code === 'invalidCaptcha') {
                        this.toast.error('Napaka pri preverjanju slike!');
                    } else {
                        this.toast.error('Napaka pri spremembi gesla.');
                    }
                    this.loading = false;
                    Helpers.setLoading(false);
                },
            );
    }

    showAlert(target) {
        this[target].clear();
        const factory = this.cfr.resolveComponentFactory(AlertComponent);
        const ref = this[target].createComponent(factory);
        ref.changeDetectorRef.detectChanges();
    }

    public captchaLoginSolver(event) {
        this.publicRest.solveUserCaptcha(this.model.email, event.guid, event.x, event.y).subscribe(
            response => {
                if (!response) {
                    //this.loadCaptcha();
                    this.isCaptchaSolved = false;
                    this.loginCaptcha.loadCaptcha();
                } else {
                    this.isCaptchaSolved = true;
                }
            },
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            error => {
                this.isCaptchaSolved = false;
            },
        );
    }

    public captchaPasswordResetSolver(event) {
        this.isPasswordResetCaptchaSolved = true;
        this.passwordResetCaptchaSolution = event;
    }

    private clearStorage(): void {
        this.store.dispatch(new ClearState());
        this._storage.deleteAvailibilityData();
        this._storage.deleteActiveModules();
        this._storage.deleteSelectedTerms();
        this._authService.logout();
        this.store.dispatch(new WebsocketActions.CloseWebSocket());
        // this.telephony.init(false);
    }
}
