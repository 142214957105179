<div class="m-portlet">
    <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
                <h3 class="m-portlet__head-text">
                    {{ title }}
                </h3>
            </div>
        </div>
    </div>
    <div class="m-portlet__body mf-portlet-custome__body">
        <div *ngIf="selectedCustomer" class="m-list-search">
            <div class="m-list-search__results">
                <div class="m-list-search__result-item mf-customer">
                    <span class="m-list-search__result-item-icon">
                        <i container="body" ngbTooltip="Podatki o stranki" class="la la-user m--font-inverse-light" i18n-ngbTooltip></i>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <strong>{{ selectedCustomer?.name }} {{ selectedCustomer?.surname }}</strong>
                        <br />
                        {{ selectedCustomer?.address }},
                        <br />
                        {{ selectedCustomer?.post }} {{ selectedCustomer?.city }}
                    </span>
                </div>
                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <i container="body" ngbTooltip="Telefon stranke" class="la la-phone m--font-inverse-light" i18n-ngbTooltip></i>
                    </span>
                    <span class="m-list-search__result-item-text">
                        {{ selectedCustomer?.phone }}
                    </span>
                </div>
                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <i container="body" ngbTooltip="Email stranke" class="la la-at m--font-inverse-light" i18n-ngbTooltip></i>
                    </span>
                    <span class="m-list-search__result-item-text">
                        {{ selectedCustomer?.email }}
                    </span>
                </div>
                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <i container="body" ngbTooltip="Rojstni datum" class="la la-birthday-cake m--font-inverse-light" i18n-ngbTooltip></i>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <span *ngIf="selectedCustomer?.birthDate">
                            {{ selectedCustomer?.birthDate | date: 'dd. MM. yyyy' }}
                        </span>
                        <span *ngIf="!selectedCustomer?.birthDate" i18n>Ni vnešen</span>
                    </span>
                </div>
                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <i container="body" ngbTooltip="Spol" class="la la-transgender m--font-inverse-light"></i>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <span *ngIf="selectedCustomer?.gender">
                            <span *ngIf="selectedCustomer?.gender === 'MALE'" i18n="@@wr.customer.male">Moški</span>
                            <span *ngIf="selectedCustomer?.gender === 'FEMALE'" i18n="@@wr.customer.female">Ženska</span>
                            <span *ngIf="selectedCustomer?.gender === 'NOT_DEFINED'" i18n="@@wr.customer.not.defined">Ni vnešen</span>
                        </span>
                        <span *ngIf="!selectedCustomer?.gender" i18n="@@wr.customer.not.defined">Ni vnešen</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
