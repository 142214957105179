<div class="mf-new-user-form h-100">
    <h1 class="text-center">Registracija</h1>

    <section *ngIf="isConsent == 0" class="">
        <div class="mt-4">
            Vklopljen imate modul Telefonija, vendar smo zaznali, da vaš uporabniški račun še ni povezan s telefonskim sistemom. Ali dovolite, da v
            vašem imenu ustvarimo račun pri našem ponudniku telefonije in vam tako omogočimo telefoniranje znotraj eAmbulante?
        </div>

        <div class="d-flex w-100 p-4 justify-content-center">
            <button (click)="consent(true)" class="btn-lg btn m-btn--pill btn-outline-success mr-4">DA</button>

            <button (click)="consent(false)" class="btn-lg btn m-btn--pill btn-outline-metal">NE</button>
        </div>
        <div class="w-100">
            <hr />
            <button (click)="login()" class="btn-lg btn m-btn--pill btn-outline-success mx-auto d-block mt-5">Že imam uporabniški račun</button>
        </div>
    </section>
    <section *ngIf="isConsent == 1" class="">
        <div class="mt-4">
            <div class="pb-4">Klik na gumb NE, registracija ni možna!</div>

            <button (click)="isConsent = 0" class="btn-lg btn m-btn--pill btn-outline-success mx-auto d-block">Nazaj</button>
        </div>
    </section>
    <form *ngIf="isConsent == 2" [formGroup]="registerForm" (ngSubmit)="onSubmit()" novalidate>
        <section class="mb-3">
            <input
                type="text"
                placeholder="Ime"
                formControlName="firstName"
                class="form-control mf-aircall-input"
                [ngClass]="{ 'border-danger border': formData['firstName'].errors && isSubmited }"
            />
            <div *ngIf="formData['firstName'].errors && isSubmited" class="m--font-danger">
                <small [hidden]="!formData['firstName'].errors.required">To je obvezno polje.</small>
            </div>
        </section>

        <section class="mb-3">
            <input
                type="text"
                placeholder="Priimek"
                formControlName="lastName"
                class="form-control mf-aircall-input"
                [ngClass]="{ 'border-danger border': formData['lastName'].errors && isSubmited }"
            />
            <div *ngIf="formData['lastName'].errors && isSubmited" class="m--font-danger">
                <small [hidden]="!formData['lastName'].errors.required">To je obvezno polje.</small>
            </div>
        </section>
        <section class="mb-3">
            <input
                type="text"
                placeholder="Epošta"
                formControlName="email"
                class="form-control mf-aircall-input"
                [ngClass]="{ 'border-danger border': formData['email'].errors && isSubmited }"
            />
            <div *ngIf="formData['email'].errors && isSubmited" class="m--font-danger">
                <small [hidden]="!formData['email'].errors.required">To je obvezno polje.</small>
                <small [hidden]="!formData['email'].errors.email">Vnešena epošta je napačne oblike.</small>
            </div>
        </section>
        <button type="submit" class="btn-lg btn m-btn--pill btn-outline-success mx-auto d-block">Potrdi</button>
    </form>
    <section *ngIf="isConsent == 3" class="">
        <div class="mt-4">
            Pravkar smo izvedli registracijo računa v telefonskem sistemu Aircall. Na svoj elektronski naslov boste prejeli njihovo potrditveno
            sporočilo, v katerem morate s klikom na povezavo potrditi registracijo.

            <p class="font-weight-bold">Geslo, ki ga boste ustvarili, nato skupaj s svojim elektronskim naslovom vpišite v modul Telefonija.</p>
        </div>
        <button (click)="login()" class="btn-lg btn m-btn--pill btn-outline-success mx-auto d-block">Prijava</button>
    </section>
</div>
