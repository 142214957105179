import * as _ from 'lodash';
import { EventActions } from '../../enums/eventActions.enum';
import { Serializable } from '../deserialize.service';
import { FcEvent } from '../fcEvent.model';

export class EventReturn implements Serializable<EventReturn> {
    action: EventActions;
    fcEvent: FcEvent;
    isOpenReservation: boolean;
    prereservationId: string;

    deserialize(input) {
        if (_.isNil(input)) {
            return this;
        }
        this.action = input.action;
        this.fcEvent = new FcEvent().deserialize(input.fcEvent);
        this.isOpenReservation = input.isOpenReservation;
        this.prereservationId = input.prereservation;
        return this;
    }
}
