import * as moment from 'moment';
import { PaperType } from '../../enums/PaperType.enum';
import { Serializable } from '../deserialize.service';

export class CustomerPapersElement implements Serializable<CustomerPapersElement> {
    public paperDate: moment.Moment;
    public paperId: number;
    public visitDate: moment.Moment;
    public visitId: number;
    public paperType: PaperType;
    public prereservationId: string;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        this.paperId = input.paperId;
        this.visitId = input.visitId;
        this.paperType = input.paperType;
        if (input.prereservationId) {
            this.prereservationId = input.prereservationId;
        }
        if (input.paperDate) {
            this.paperDate = moment(input.paperDate);
        }

        if (input.visitDate) {
            this.visitDate = moment(input.visitDate);
        }
        return this;
    }
}
