import { PaymentType } from '../../enums/accounting/paymentType.enum';
import { Serializable } from '../deserialize.service';

export class ContractorAccoutingSettings implements Serializable<ContractorAccoutingSettings> {
    paymentTypes: PaymentType[];

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        if (!input || input === null) {
            return null;
        }
        this.paymentTypes = input.paymentTypes;

        return this;
    }
}
