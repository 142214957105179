import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import _ from 'lodash';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { BsType } from '../../../../../../_shared/enums/business.enum';
import { PfPCommnComunicationService } from '../_common/pf-p-comn-communication.service';
import { PaperType } from './../../../../../../_shared/enums/PaperType.enum';
import { PaperHistoryElement } from './../../../../../../_shared/model/portfolio/paperHistoryElement.model';
import { PortfolioPaper } from './../../../../../../_shared/model/portfolio/portfolioPaper.model';
import { Reservation } from './../../../../../../_shared/model/reservation.model';
import { Result } from './../../../../../../_shared/model/result.model';
import { ResultSet } from './../../../../../../_shared/model/resultSet.model';
import { Case } from './../../../../../../_shared/model/schedule/case.model';
import { User } from './../../../../../../_shared/model/user.model';
import { Visit } from './../../../../../../_shared/model/visit.model';
import { HelperService } from './../../../../../../_shared/services/helper.service';
import { MfToastService } from './../../../../../../_shared/services/mfToast.service';
import { BaseState } from './../../../../../../_shared/state/base/base.state';
import { ResultService } from './../../../_services/result.service';

@Component({
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'pf-p-termConf',
    templateUrl: './pf-p-termConf.component.html',
    styleUrls: [],
})
export class PfPTermConfComponent implements OnInit {
    constructor(
        // private storage: StorageService,
        public comm: PfPCommnComunicationService,
        private rest: ResultService,
        private toast: MfToastService,
        private helper: HelperService,
        public store: Store,
    ) {}

    public inputSetsObj: ResultSet[] = [];
    private onDestroy$ = new Subject<void>();
    private case: Case;
    public selectedContractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);
    public loggedUser: User = this.store.selectSnapshot(BaseState.LoggedUser);
    public bsType: BsType;
    @Input()
    isNew = false;

    @Input()
    paper: PortfolioPaper;

    // @Input()
    // id: any;

    @Input()
    visit: Visit;

    @Input()
    reservation: Reservation;

    ngOnInit() {
        if (this.isNew) {
            this.createReport();
        } else {
            this.loadData(this.paper.paperId || this.paper.id);
        }

        this.communications();
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        this.comm.dirty$.next(false);
        this.onDestroy$.next();
    }

    private createReport(): void {
        //TODO reservationFrom?
        if (!this.visit) {
            this.visit = {
                customer: this.reservation.customer,
                reservationFrom: this.reservation.reservationFrom,
                contractor: this.reservation.contractor,
            };
            // this.visit.customer = this.reservation.customer;
            // this.visit.reservationFrom = this.reservation.reservationFrom;
            // this.visit.contractor = this.reservation.contractor;
        }
        const content = `<p> Potrjujemo, da bo ${this.visit?.customer?.name} ${this.visit?.customer?.surname}, dne 
        ${moment(this.visit.reservationFrom)?.format('DD. MM')} ob ${moment(this.visit.reservationFrom)?.format('HH:mm')}, 
        koristil/a zdravstveno storitev v naši ustanovi. </p> <br> <p> S prijaznimi pozdravi, </p> <br> <p> ${_.get(
            this.visit,
            'contractor.subcontractors[0].name',
            '',
        )} </p>`;

        this.inputSetsObj.push(
            new ResultSet().deserialize({
                title: 'Potrdilo',
                surveyId: '-1',
                createdBy: this.loggedUser.name,
                sortId: 1,
                content: content,
            }),
        );
    }
    private copyFromHistroy(data: PaperHistoryElement): void {
        this.loadData(data.paperId);
    }

    private communications(): void {
        this.comm.onCopy.pipe(takeUntil(this.onDestroy$)).subscribe((data: PaperHistoryElement) => {
            this.copyFromHistroy(data);
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onSave.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.saveSurvey().subscribe();
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onPreview.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.openPreview(this.paper, false);
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onPrint.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.openPreview(this.paper, true);
        });

        this.comm.onSignDocument.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
            this.comm.saveSignShareAndShow(
                this.paper,
                data,
                () => this.saveSurvey(),
                casee => (this.case = casee),
                false,
                false,
                this.bsType === BsType.PREVENTIVNI,
            );
        });
    }

    private loadData(paperId: number): void {
        this.rest
            .getReservationConfirmation(paperId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(res => {
                if (res && res.surveys && res.surveys.length > 0) {
                    this.inputSetsObj = res.surveys;
                }
            });
    }

    public openPreview(paper: PortfolioPaper, print: boolean = false): void {
        const prereservation: string = this.visit?.prereservationId || this.reservation.prereservationId;
        // if(!this.visit.prereservationId){

        // }

        this.rest
            .getSurveyPaperPreviewReservation(prereservation, paper.id ? paper.id : paper.paperId)
            .pipe(
                takeUntil(this.onDestroy$),
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                map((res: any) => {
                    if (res.type !== 0) {
                        return new Blob([res.body], {
                            type: 'application/pdf',
                        });
                    }
                    // Helpers.setLoading(false);
                }),
            )
            .subscribe(
                res => {
                    if (res) {
                        this.helper.openDocumentViewer(res, res.type, 'Izvid', print);
                    }
                },
                () => {
                    this.toast.errorAndSend('Napaka pri predogledu potrdila.');
                },
            );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private saveSurvey(): Observable<any> {
        const prereservation: string = this.visit?.prereservationId || this.reservation.prereservationId;

        if (this.isNew) {
            return this.rest
                .saveReservationConfirmaton(
                    prereservation,
                    new Result().deserialize({
                        surveys: this.inputSetsObj,
                        surveyType: PaperType.CONFIRM_RESERVATION,
                    }),
                )
                .pipe(
                    takeUntil(this.onDestroy$),
                    tap(
                        res => {
                            this.paper = new PortfolioPaper().deserialize({
                                id: res,
                                prereservationId: prereservation,
                                paperType: PaperType.CONFIRM_RESERVATION,
                            });
                            this.comm.onPaperChanged.emit(this.paper);
                            this.toast.success('Spremembe so shranjene.');
                            this.isNew = false;
                            this.comm.dirty$.next(false);
                        },
                        () => {
                            this.toast.errorAndSend('Napaka pri dodajanju novega sklopa.');
                        },
                    ),
                );
        } else {
            //update
            return this.rest
                .saveReservationConfirmaton(
                    prereservation,
                    new Result().deserialize({
                        surveys: this.inputSetsObj,
                        surveyType: PaperType.CONFIRM_RESERVATION,
                    }),
                    this.paper.paperId ? this.paper.paperId : this.paper.id,
                )
                .pipe(
                    takeUntil(this.onDestroy$),
                    tap(
                        () => {
                            this.toast.success('Spremembe so shranjene.');
                            this.comm.dirty$.next(false);
                        },
                        () => this.toast.errorAndSend('Napaka pri urejanju sklopa.'),
                    ),
                );
        }
    }
}
