export enum SendContentType {
    CONFIRMATION_MAIL = 'CONFIRMATION_MAIL',
    REMAINDER = 'REMAINDER',
    MOVED = 'MOVED',
    CANCELATION = 'CANCELATION',
    SURVEY = 'SURVEY',
    TELEMEDICINE = 'TELEMEDICINE',
    SUBCONTRACTOR_CONFIRMATION_NOTICE = 'SUBCONTRACTOR_CONFIRMATION_NOTICE',
    SUBCONTRACTOR_RESERVATION_REMINDER = 'SUBCONTRACTOR_RESERVATION_REMINDER',
    PHONE_CALL = 'PHONE_CALL',
    CONSENT = 'CONSENT',
    USER_TRIAGE = 'USER_TRIAGE',
    SUBCONTRACTOR_RESERVATION_MOVED = 'SUBCONTRACTOR_RESERVATION_MOVED',
}
