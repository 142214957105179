<header data-minimize-offset="200" data-minimize-mobile-offset="200" appunwraptag="" class="m-grid__item m-header">
    <div class="m-container m-container--fluid m-container--full-height overflow-x-scroll">
        <div class="m-stack m-stack--ver m-stack--desktop">
            <!-- BEGIN: Brand -->
            <div class="m-stack__item m-brand m-brand--skin-dark m--hidden-desktop">
                <div class="m-stack m-stack--ver m-stack--general">
                    <div class="m-stack__item m-stack__item--middle m-brand__tools m--hidden-desktop text-left">
                        <!-- BEGIN: Responsive Aside Left Menu Toggler Desktop -->
                        <a
                            href="javascript:"
                            id="m_aside_left_offcanvas_toggle"
                            class="m-brand__toggler m-brand__toggler--right m--visible-tablet-and-mobile-inline-block text-left"
                        >
                            <span></span>
                        </a>
                        <!-- END -->
                    </div>
                    <div class="m-stack__item m-stack__item--middle m--hidden-desktop m-brand__logo">
                        <a routerLink="/index" class="m-brand__logo-wrapper d-flex">
                            <img alt="" src="./assets/demo/demo5/media/img/logo/logo-grey-color.svg" width="140" height="100%" class="m-auto" />
                        </a>
                    </div>
                    <div class="m-stack__item m-stack__item--middle m-brand__tools m--hidden-desktop">
                        <!-- BEGIN: Notification Menu item Mobile -->
                        <a (click)="sendDataToNotificatonSidebar()" href="#">
                            <i class="m--icon-font-size-lg2 la la-bell m--valign-middle"></i>
                            <span class="notification">
                                <span *ngIf="notificationsList.length !== 0">
                                    <span class="m-badge m-badge--danger"></span>
                                </span>
                            </span>
                        </a>
                        <!-- END -->
                    </div>
                </div>
            </div>
            <!-- END: Brand -->
            <div id="m_header_nav" class="m-stack__item m-stack__item--fluid m-header-head overflow-x-scroll">
                <!-- BEGIN: Horizontal Menu -->
                <button
                    id="m_aside_header_menu_mobile_close_btn"
                    class="m-aside-header-menu-mobile-close m-aside-header-menu-mobile-close--skin-dark"
                >
                    <i class="la la-close"></i>
                </button>
                <div
                    id="m_header_menu"
                    class="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-dark m-aside-header-menu-mobile--submenu-skin-dark"
                >
                    <div class="d-flex mf-header-menu">
                        <ng-container>
                            <div *ngIf="menu.subTitle | async" class="pl-3 mf-header--sub-title">
                                {{ menu.subTitle | async }}
                            </div>
                        </ng-container>
                    </div>
                </div>
                <!-- END: Horizontal Menu -->
                <!-- BEGIN: Topbar -->
                <div id="m_header_topbar" class="m-topbar m-stack m-stack--hor m-stack--general">
                    <div>
                        <ul class="mf-header-submenu">
                            <li
                                *ngFor="let menu of subMenuList"
                                [routerLinkActiveOptions]="{ exact: menu?.routerActiveExact }"
                                routerLinkActive="mf-header-submenu__item--active"
                                class="mf-header-submenu__item"
                            >
                                <a routerLink="{{ menu.link }}">
                                    {{ menu.title }}
                                    <span *ngIf="menu?.displayValue > 0" class="m-badge m-badge--danger">
                                        {{ menu?.displayValue }}
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div
                    id="m_header_topbar"
                    class="m-topbar m-stack m-stack--hor m-stack--general float-right m--hidden-tablet-and-mobile customActions"
                >
                    <ul class="list-unstyled d-flex h-100 mb-0 mt-2">
                        <li *ngFor="let actionButton of menu.actionButtonsMenu | async" class="justify-content-center align-self-center pr-2">
                            <button
                                [disabled]="actionButton.disabled"
                                (click)="evaluateActionButton(actionButton)"
                                class="btn m-btn m-btn--pill btn-block"
                                [ngClass]="actionButton.buttonStyle"
                            >
                                <span>
                                    <span>
                                        {{ actionButton.title }}
                                    </span>
                                </span>
                            </button>
                        </li>
                    </ul>
                </div>

                <!-- END: Topbar -->
            </div>
        </div>
    </div>
</header>
