import { AvailabilityCalendarFilter } from './../../../../../_shared/model/userGuiSettings/availabilityCalendarFilter';
export class SetFilter {
    public static readonly type = 'SetFilter';
    constructor(public readonly filter: AvailabilityCalendarFilter) {}
}

export class UpdateFilterParameter {
    public static readonly type = 'UpdateFilterParameter';
    constructor(
        public readonly name: string,
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        public readonly value: any,
    ) {}
}
