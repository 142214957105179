import { Component, ComponentFactoryResolver, ComponentRef, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { PortfolioPaper } from '../../../../../../_shared/model';
import { Case } from '../../../../../../_shared/model/schedule/case.model';
import { ExaminationState } from '../../../../../../_shared/state/examinations/examinations-state';
import { BasicDocumentViewerComponent } from '../../../../../basic-components/basic-document-viewer/basic-document-viewer.component';
import { PfPFthComponent } from '../../papers/pf-p-fth/pf-p-fth.component';
import { PfPGenericReferralComponent } from '../../papers/pf-p-genericReferral/pf-p-genericReferral.component';
import { PfPMdpsCertificateComponent } from '../../papers/pf-p-mdps-certificate/pf-p-mdps-certificate.component';
import { PfPMdpsSurveyComponent } from '../../papers/pf-p-mdps-survey/pf-p-mdps-survey.component';
import { PfPMfmReportComponent } from '../../papers/pf-p-mfm-report/pf-p-mfm-report.component';
import { PfPMFMSoapSurveyComponent } from '../../papers/pf-p-mfm-soapSurvey/pf-p-mfm-hdSurvey.component';
import { PfPOrderFormDiagComponent } from '../../papers/pf-p-orderFormDiag/pf-p-orderFormDiag.component';
import { PfPOrderFormLabComponent } from '../../papers/pf-p-orderFormLab/pf-p-orderFormLab.component';
import { PfPSoapSurveyComponent } from '../../papers/pf-p-soapSurvey/pf-p-hdSurvey.component';
import { PfPSurveyComponent } from '../../papers/pf-p-survey/pf-p-survey.component';
import { PfPTermConfComponent } from '../../papers/pf-p-termConf/pf-p-termConf.component';
import { PfPVisitConfComponent } from '../../papers/pf-p-visitConf/pf-p-visitConf.component';
import { PfPWhitereferralComponent } from '../../papers/pf-p-whitereferral/pf-p-whitereferral.component';
import { DATE_CONST } from './../../../../../../_shared/constants/date-constants.const';
import { PaperType } from './../../../../../../_shared/enums/PaperType.enum';
import { Customer } from './../../../../../../_shared/model/customer.model';
import { Event } from './../../../../../../_shared/model/event.model';
import { Reservation } from './../../../../../../_shared/model/reservation.model';
import { Visit } from './../../../../../../_shared/model/visit.model';
import { BaseState } from './../../../../../../_shared/state/base/base.state';
import { ReservationService } from './../../../_services/reservation.service';
import { VisitService } from './../../../_services/visit.service';
import { PfType } from './../enum/pf-type.enum';
import { PfHostDirective } from './pf-host.directive';

@Component({
    selector: 'pf-comn-popup',
    templateUrl: './pf-comn-popup.component.html',
    styleUrls: ['./pf-comn-popup.component.scss'],
})
export class PfComnPopupComponent implements OnInit {
    @ViewChild(PfHostDirective, { static: true }) visitHost: PfHostDirective;
    @ViewChild('dynamicContentContainer', { read: ViewContainerRef }) dynamicContentContainer: ViewContainerRef;

    @Input() customer: Customer;

    @Input() pfType: PfType;

    @Input() public paper: PortfolioPaper;

    @Input() event: Event;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() sourceVisit: any;

    //Okna
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public selectedType: any;

    //Ostalo
    public isNew = false;
    public title = '';
    // public id: any;
    public case: Case;
    public selectedContractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);
    public detailsForm: FormGroup;
    public visit: Visit;
    public reservation: Reservation;
    public paperType = PaperType;

    public isContainerEqualSize$ = new BehaviorSubject<boolean>(false);

    constructor(
        public activeModal: NgbActiveModal,
        private componentFactoryResolver: ComponentFactoryResolver,
        public fb: FormBuilder,
        private visitRest: VisitService,
        private store: Store,
        private reservationRest: ReservationService,
    ) {}

    ngOnInit() {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let apiCall: Observable<any> = of(true);
        if (
            this.paper.paperType == PaperType.FTH_POROCILO ||
            this.paper.paperType == PaperType.IZVID ||
            this.paper.paperType == PaperType.SURVEY_CUSTOMER
        ) {
            this.crateDetailsForm();
        }

        if (!this.paper?.visitId && this.paper?.prereservationId) {
            // za listine ki se dodajajo na rezervaciji brez obravnave
            apiCall = this.reservationRest.getReservation(this.selectedContractorId, this.paper?.prereservationId).pipe(
                tap(reservation => {
                    this.reservation = reservation;
                    if (!this.paper?.from && this.detailsForm) {
                        //Ni ročno nastavljenega trajanja obravnave (v pf-comn-details) --> vzemi FROM iz rezervacije
                        (<FormGroup>this.detailsForm.controls?.timeSpan).controls.from.setValue(
                            moment(reservation.reservationFrom).format(DATE_CONST.format.date.view),
                        );
                    }
                }),
            );
        } else if (this.paper?.visitId) {
            //listine dodane na obravnavi
            apiCall = this.visitRest.getVisit(this.selectedContractorId, this.paper?.visitId).pipe(
                tap((visit: Visit) => {
                    this.visit = visit;
                    if (!this.paper?.from && this.detailsForm) {
                        //Ni ročno nastavljenega trajanja obravnave (v pf-comn-details) --> vzemi FROM iz obravnave
                        (<FormGroup>this.detailsForm.controls?.timeSpan).controls.from.setValue(
                            moment(visit.reservationFrom).format(DATE_CONST.format.date.view),
                        );
                    }
                }),
            );
        }
        apiCall.subscribe(() => this.selectReport());
    }

    public crateDetailsForm(): void {
        this.detailsForm = this.fb.group({
            timeSpan: this.fb.group({
                from: this.fb.control({
                    value: this.paper.from
                        ? moment(this.paper.from).format(DATE_CONST.format.date.view)
                        : moment().format(DATE_CONST.format.date.view),
                    disabled: false,
                }),
                to: this.fb.control({
                    value: this.paper.to ? moment(this.paper.to).format(DATE_CONST.format.date.view) : '',
                    disabled: false,
                }),
            }),
        });
        if (this.pfType == PfType.CUSTOMER) {
            this.detailsForm.addControl('subcontractor', this.fb.control({ value: '', disabled: false }, Validators.required));
        }
    }

    public selectReport(): void {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.selectedType);

        const viewContainerRef = this.visitHost.viewContainerRef;
        viewContainerRef.clear();
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let tmpComp: any;
        switch (this.selectedType) {
            case PfPSurveyComponent: {
                tmpComp = viewContainerRef.createComponent(componentFactory).instance as PfPSurveyComponent;
                tmpComp.prereservationId = this.reservation?.prereservationId || this.visit?.prereservationId;
                tmpComp.customer = this.customer;
                break;
            }
            case PfPFthComponent: {
                tmpComp = viewContainerRef.createComponent(componentFactory).instance as PfPFthComponent;
                break;
            }
            case PfPWhitereferralComponent: {
                tmpComp = viewContainerRef.createComponent(componentFactory).instance as PfPWhitereferralComponent;
                break;
            }
            case PfPVisitConfComponent: {
                tmpComp = viewContainerRef.createComponent(componentFactory).instance as PfPVisitConfComponent;
                tmpComp.visit = this.visit;
                break;
            }
            case PfPTermConfComponent: {
                tmpComp = viewContainerRef.createComponent(componentFactory).instance as PfPTermConfComponent;
                tmpComp.visit = this.visit;
                tmpComp.reservation = this.reservation;
                break;
            }
            case PfPGenericReferralComponent: {
                tmpComp = viewContainerRef.createComponent(componentFactory).instance as PfPGenericReferralComponent;
                tmpComp.customer = this.customer;
                tmpComp.subcontractor = this.event?.contractor?.subcontractors[0];
                break;
            }
            case PfPSoapSurveyComponent: {
                tmpComp = viewContainerRef.createComponent(componentFactory).instance as PfPSoapSurveyComponent;
                tmpComp.prereservationId = this.reservation?.prereservationId || this.visit?.prereservationId;
                break;
            }
            case PfPMFMSoapSurveyComponent: {
                tmpComp = viewContainerRef.createComponent(componentFactory).instance as PfPMFMSoapSurveyComponent;
                tmpComp.prereservationId = this.reservation?.prereservationId || this.visit?.prereservationId;
                tmpComp.termData = this.event;
                break;
            }
            case PfPOrderFormLabComponent: {
                tmpComp = viewContainerRef.createComponent(componentFactory).instance as PfPOrderFormLabComponent;
                tmpComp.subcontractor = this.event.contractor.subcontractors[0];
                tmpComp.customer = this.customer;
                break;
            }
            case PfPOrderFormDiagComponent: {
                tmpComp = viewContainerRef.createComponent(componentFactory).instance as PfPOrderFormDiagComponent;
                tmpComp.subcontractor = this.event.contractor.subcontractors[0];
                tmpComp.customer = this.customer;
                break;
            }
            case PfPMdpsCertificateComponent: {
                tmpComp = viewContainerRef.createComponent(componentFactory).instance as PfPMdpsCertificateComponent;
                tmpComp.subcontractor = this.event?.contractor?.subcontractors[0];
                tmpComp.customer = this.customer;
                tmpComp.visit = this.visit;
                break;
            }
            case PfPMdpsSurveyComponent: {
                tmpComp = viewContainerRef.createComponent(componentFactory).instance as PfPMdpsSurveyComponent;
                tmpComp.prereservationId = this.reservation?.prereservationId || this.visit?.prereservationId;
                tmpComp.customer = this.customer;
                tmpComp.visit = this.visit;
                tmpComp.subcontractor = this.event?.contractor?.subcontractors[0];

                const contentFactory = this.componentFactoryResolver.resolveComponentFactory(BasicDocumentViewerComponent);
                const contentRef: ComponentRef<BasicDocumentViewerComponent> = contentFactory.create(this.dynamicContentContainer.injector);
                const dynamicComponentInstance = contentRef.instance;
                this.store
                    .selectOnce(ExaminationState.mdpsSurveyDocumentViewerSettings$)
                    .pipe(
                        switchMap(settings => {
                            dynamicComponentInstance.settings = settings;
                            return dynamicComponentInstance.embeddedPreviewState;
                        }),
                    )
                    .subscribe(state => {
                        this.isContainerEqualSize$.next(state);
                    });
                this.dynamicContentContainer.insert(contentRef.hostView);
                break;
            }
            case PfPMfmReportComponent: {
                tmpComp = viewContainerRef.createComponent(componentFactory).instance as PfPMfmReportComponent;
                tmpComp.prereservationId = this.reservation?.prereservationId || this.visit?.prereservationId;
                tmpComp.termData = this.event;
                break;
            }
        }
        tmpComp.paper = this.paper;
        tmpComp.isNew = this.isNew;
        // tmpComp.id = this.id;
        // tmpComp.visit = this.visit;
        // tmpComp.reservation = this.reservation;
        tmpComp.detailsForm = this.detailsForm;
        tmpComp.pfType = this.pfType;
        tmpComp.bsType = this.event?.bsType;
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public readonly PaperType = PaperType;
}
