import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { AuthGuard } from '../auth/_guards';
import { ThemeComponent } from './frames/default/theme.component';

const routes: Routes = [
    {
        path: '',
        component: ThemeComponent,
        canLoad: [AuthGuard],
        // 'canLoadChild': [NotSavedGuard],
        children: [
            {
                path: 'index',
                loadChildren: () => import('./pages/home/index/index.module').then(m => m.IndexModule),
            },
            {
                path: '',
                redirectTo: 'index',
                pathMatch: 'full',
            },
            {
                path: 'calendar',
                loadChildren: () => import('./pages/home/main-calendar/main-calendar.module').then(m => m.MainCalendarModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: 'menu_main_calendar__Visible',
                        redirectTo: 'index',
                    },
                },
            },
            {
                path: 'calendar',
                loadChildren: () => import('./pages/home/terms/terms.module').then(m => m.TermsModule),
            },
            {
                path: 'tasks',
                loadChildren: () => import('./pages/home/tasks/tasks.module').then(m => m.TasksModule),
                data: {
                    permissions: {
                        only: 'menu_main_tasks__Visible',
                        redirectTo: 'index',
                    },
                },
            },
            {
                path: 'customers',
                loadChildren: () => import('./pages/home/customer/customer.module').then(m => m.CustomerModule),
            },
            {
                path: 'treatments',
                loadChildren: () => import('./pages/home/treatment/treatment.module').then(m => m.TreatmentModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: 'menu_main_treatments__Visible',
                        redirectTo: 'index',
                    },
                },
            },
            {
                path: 'accounting',
                loadChildren: () => import('./pages/home/accounting/accounting.module').then(m => m.AccountingModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: 'menu_main_accountingVisible',
                        redirectTo: 'index',
                    },
                },
            },
            {
                path: 'communication',
                loadChildren: () => import('./pages/home/communication/communication.module').then(m => m.CommunicationModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: 'menu_main_communicationVisible',
                        redirectTo: 'index',
                    },
                },
            },
            {
                path: 'reports',
                loadChildren: () => import('./pages/home/reports/reports.module').then(m => m.ReportsModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: 'menu_main_reports__Visible',
                        redirectTo: 'index',
                    },
                },
            },
            {
                path: 'settings',
                loadChildren: () => import('./pages/settings/settings-home.module').then(m => m.SettingsHomeModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: 'settings_enabled',
                        redirectTo: 'index',
                    },
                },
            },
            {
                path: 'waiting-queue',
                loadChildren: () => import('./pages/home/waiting-queue/waiting-queue.module').then(m => m.WaitingQueueModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: 'menu_main_waiting_queue__Visible',
                        redirectTo: 'index',
                    },
                },
            },
            {
                path: 'case',
                loadChildren: () => import('./pages/home/case/case.module').then(m => m.CaseModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: 'menu_main_case__Visible',
                        redirectTo: 'index',
                    },
                },
            },
            {
                path: 'inbox',
                loadChildren: () => import('./pages/home/inbox/inbox.module').then(m => m.InboxModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: 'menu_main_inbox__Visible',
                        redirectTo: 'index',
                    },
                },
            },
            {
                path: 'case/:caseId',
                loadChildren: () => import('./pages/home/case/case.module').then(m => m.CaseModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: 'menu_main_case__Visible',
                        redirectTo: 'index',
                    },
                },
            },
            {
                path: 'consul',
                loadChildren: () => import('./pages/home/consul/consul.module').then(m => m.ConsulModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: 'menu_main_triage__Visible',
                        redirectTo: 'index',
                    },
                },
            },
            {
                path: 'dentist',
                loadChildren: () => import('./pages/home/dentist/dentist.module').then(m => m.DentistModule),
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: 'menu_main_dentist__Visible',
                        redirectTo: 'index',
                    },
                },
            },
            {
                path: 'halo-doc',
                loadChildren: () => import('./pages/home/hallo-doc/hallo-doc.module').then(m => m.HalloDocModule),
            },
            {
                path: 'telephony',
                loadChildren: () => import('./pages/home/telephony/telephony.module').then(m => m.TelephonyModule),
            },
            {
                path: 'marketing',
                loadChildren: () => import('./pages/home/marketing/marketing.module').then(m => m.MarketingModule),
                canLoad: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: 'menu_main_marketing__Visible',
                        redirectTo: 'index',
                    },
                },
            },
            {
                path: 'oim',
                loadChildren: () => import('./pages/home/oim-tracking/oim-tracking.module').then(m => m.OimTrackingModule),
            },
            {
                path: 'documentation',
                loadChildren: () =>
                    import('./pages/home/documentation-overview/documentation-overview.module').then(m => m.DocumentationOverviewModule),
            },
            {
                path: 'subcontractor-availability',
                loadChildren: () =>
                    import('./pages/home/subcontractor-availability/subcontractor-availability.module').then(m => m.Subcontractor_availabilityModule),
            },
        ],
    },
    {
        path: '**',
        redirectTo: 'index',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ThemeRoutingModule {}
