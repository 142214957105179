<div *ngIf="numberOfItems > 0" class="m-portlet">
    <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
            <div ngbTooltip="Verzije podpisanih dokumentov" class="m-portlet__head-title">
                <h3 class="m-portlet__head-text" i18n="@@versions.list.title">Zgodovina izbranega dokumenta</h3>
            </div>
        </div>
    </div>
    <div class="m-portlet__body">
        <div class="mf-table mf-table__no-header">
            <div class="mf-table-body">
                <div *ngIf="allVersions.length < 1" i18n="@@versions.list.no.data">Ni preteklih poročil</div>
                <div
                    *ngFor="let item of allVersions?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)"
                    class="mf-table-body__item d-flex justify-content-between"
                >
                    <span>{{ item.dateSigned | date: 'dd.MM.yyyy HH:mm' }}</span>
                    <span i18n="@@versions.version.text">Verzija: {{ item?.version }}</span>
                    <span>
                        <a
                            (click)="$event.preventDefault(); previewDocument(item)"
                            ngbTooltip="Predogled"
                            href="#"
                            class="btn btn-outline-success m-btn m-btn--icon m-btn--icon-only m-btn--pill mr-2"
                        >
                            <i class="la la-search"></i>
                        </a>
                        <a
                            *ngIf="case?.caseId && !item?.caseDocument && isCaseActive"
                            (click)="$event.preventDefault(); appendDocumentToAuth(item)"
                            ngbTooltip="Poveži z avtorizacijo"
                            href="#"
                            class="btn btn-outline-success m-btn m-btn--icon m-btn--icon-only m-btn--pill mr-2"
                        >
                            <i class="la la-link"></i>
                        </a>
                        <span
                            *ngIf="case?.caseId && item?.caseDocument && isCaseActive"
                            ngbTooltip="Povezan z avtorizacijo"
                            style="cursor: default"
                            class="btn btn-outline-dark disabled m-btn m-btn--icon m-btn--icon-only m-btn--pill mr-2"
                        >
                            <i class="la la-link"></i>
                        </span>
                    </span>
                </div>

                <ngb-pagination
                    *ngIf="numberOfItems > itemsPerPage"
                    [(page)]="currentPage"
                    [collectionSize]="numberOfItems"
                    [pageSize]="itemsPerPage"
                    [maxSize]="2"
                    [directionLinks]="true"
                    class="d-flex justify-content-center align-self-end"
                ></ngb-pagination>
            </div>
        </div>
    </div>
</div>
