import { LogoType } from '../enums/logoType.enum';
import { Serializable } from './deserialize.service';
import { ResultSet } from './resultSet.model';
import { Subcontractor } from './subcontractor.model';

export class Result implements Serializable<Result> {
    surveys: ResultSet[];
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    diagnose: any;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    diagnoses: any;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    surveyType: any;
    logo: LogoType;
    footNote: string;
    from: string;
    to: string;
    subcontractorId: number;
    subcontractor: Subcontractor;
    fromTime: string;
    toTime: string;

    deserialize(input) {
        if (input.surveys) {
            this.surveys = input.surveys.map(el => new ResultSet().deserialize(el));
        }
        if (input?.logo !== undefined) {
            this.logo = input.logo;
        }
        this.diagnose = input.diagnose;
        this.diagnoses = input.diagnoses;
        this.surveyType = input.surveyType;
        this.from = input.from;
        this.to = input.to;
        this.subcontractorId = input.subcontractorId;
        this.subcontractor = input.subcontractor;
        this.fromTime = input.fromTime;
        this.toTime = input.toTime;
        if (input.footNote) {
            this.footNote = input.footNote;
        }
        return this;
    }
}
// public class Comment {
//     public String id;
//     public Date commentAdded;
//     public String comment;
