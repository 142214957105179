<div class="m-content">
    <div class="m-portlet">
        <div class="m-portlet__head">
            <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                    <h3 class="m-portlet__head-text" i18n="@@visit.pf.survey.title">Diagnoza</h3>
                </div>
            </div>
        </div>
        <div class="m-portlet__body">
            <ng-select
                [(ngModel)]="selectedDiagnoses"
                [items]="diagnose$ | async"
                [multiple]="true"
                [hideSelected]="true"
                [typeahead]="searchInput$"
                [loading]="searching"
                [notFoundText]="'Ni zadetkov'"
                [addTag]="true"
                [addTagText]="'Vnesite lastno diagnozo'"
                [typeToSearchText]="'Vnesite iskani niz...'"
                (change)="this.comm.dirty$.next(true)"
                placeholder="Iskanje diagnoz"
                bindLabel="fullValue"
                loadingText="Iščem..."
                class="mf-ng-select-custom"
                i18n-placeholder="@@app.visit.portfolio.survery.diagnose.search.paceholder"
            >
                <ng-template ng-label-tmp let-item="item" let-clear="clear">
                    <span [ngClass]="{ 'm--font-boldest': !item.code }">
                        <span (click)="clear.call(undefined, item)" class="ng-value-icon left">×</span>
                        <span *ngIf="item.name" class="ng-value-label">{{ item.name }}</span>
                        <span *ngIf="item.fullValue && !item.name" class="ng-value-label">{{ item.fullValue }}</span>
                    </span>
                </ng-template>
            </ng-select>
        </div>
    </div>
    <div>
        <pf-p-comn-input-text *ngFor="let set of inputSetsObj" [data]="set" (onChange)="this.comm.dirty$.next(true)"></pf-p-comn-input-text>
    </div>
</div>
