import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'CustomTagPipe',
})
export class CustomTagPipe implements PipeTransform {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(input: any): any {
        switch (input) {
            case 'TAKEOVER_REMINDER':
                return 'Prevzem';
            case 'CONTROL_REMINDER':
                return 'Kontrola';
            case 'ANNUAL_REMINDER':
                return 'Letna kontrola';
            default:
                return 'Manjka prevod';
        }
    }
}
