import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { AppModulesType } from '../enums/appModulesType.enum';
import { ContractorSettings, MfModule } from '../model';
import { GenericModuleSettings } from '../model/app/genericModuleSettings';
import { Module } from './../model/app/module.model';
import { BaseState } from './../state/base/base.state';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root',
})
export class ActiveModulesService {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public allModules: any[] = [
        {
            id: AppModulesType.ACCOUNTING,
            name: 'Accounting',
            menuId: 'ACCOUNTING',
        },
        {
            id: AppModulesType.PATIENT_APP,
            name: 'patientApp',
            menuId: '',
        },
        {
            id: AppModulesType.CASE,
            name: 'patientApp',
            menuId: 'CASE',
        },
        {
            id: AppModulesType.WAITING_QUEUE,
            name: 'patientApp',
            menuId: 'WAITING-QUEUE',
        },
        {
            id: AppModulesType.TRIAGE,
            name: 'eTriaža',
            menuId: 'TRIAGE',
        },
        {
            id: AppModulesType.TREATMENTS,
            name: 'Zdravljenje',
            menuId: 'TREATMENTS',
        },
        {
            id: AppModulesType.TELEMEDICINE,
            name: 'ePosvet',
            menuId: 'TELEMEDICINE',
        },
        {
            id: AppModulesType.INBOX,
            name: 'Inbox',
            menuId: 'INBOX',
        },
        {
            id: AppModulesType.DEVICES_INBOX,
            name: 'Inbox for devices',
            menuId: 'DEVICES_INBOX',
        },
        {
            id: AppModulesType.COVID,
            name: 'Covid',
            menuId: '',
        },
        {
            id: AppModulesType.AVAIL_PARTNERS,
            name: 'AVAIL_PARTNERS',
            menuId: '',
        },
        {
            id: AppModulesType.WIDGET_INQUIRIES,
            name: 'WIDGET_INQUIRIES',
            menuId: 'INBOX/WIDGET_INQUIRIES',
        },
        {
            id: AppModulesType.WIDGETS,
            name: 'WIDGETS',
            menuId: 'SETTINGS/CLINIC/WIDGETS',
        },
        {
            id: AppModulesType.WIDGETS_PAYMENT_GATEWAY,
            name: 'Payment gateway for widget',
            menuId: 'SETTINGS/CLINIC/WIDGETS',
        },
        {
            id: AppModulesType.SEND_CONTENT_CONTRACTOR,
            name: 'Obveščanje - izvajalec meni',
            menuId: '',
        },
        {
            id: AppModulesType.INQUIRIES,
            name: 'Centralni modul za povprasevanja',
            menuId: 'INQUIRIES',
        },
        {
            id: AppModulesType.DENTIST,
            name: 'Modul za zobozdravnike, status zob',
            menuId: 'TERMS/VISIT/TEETH-STATUS',
        },
        {
            id: AppModulesType.HELLO_DOCTOR,
            name: 'HELLO_DOCTOR',
            menuId: 'HELLO_DOCTOR',
        },
        {
            id: AppModulesType.SURVEYS,
            name: 'SURVEYS',
            menuId: 'SURVEYS',
        },
        {
            id: AppModulesType.KANBAN,
            name: 'KANBAN',
            menuId: 'KANBAN',
        },
        {
            id: AppModulesType.TELEPHONY,
            name: 'TELEPHONY',
            menuId: 'TELEPHONY',
        },
        {
            id: AppModulesType.WEB_COUPONS,
            name: 'Spletni kupni za widget',
            menuId: 'WEB_COUPONS',
        },
        {
            id: AppModulesType.MARKETING,
            name: 'Marketings',
            menuId: 'MARKETING',
        },
        {
            id: AppModulesType.OIM_TRACKING,
            name: 'OIM sledenje',
            menuId: 'OIM_TRACKING',
        },
        {
            id: AppModulesType.ONBOARDING_WIZARD,
            name: 'ONBOARDING_WIZARD',
            menuId: '',
        },
        {
            id: AppModulesType.DOCUMENTATION_OVERVIEW,
            name: 'DOCUMENTATION_OVERVIEW',
            menuId: 'DOCUMENTATION_OVERVIEW',
        },
        {
            id: AppModulesType.PREVENTIVE,
            name: 'PREVENTIVE',
            menuId: '',
        },
        {
            id: AppModulesType.RECURRING,
            name: 'RECURRING',
            menuId: '',
        },
        {
            id: AppModulesType.ESZBO,
            name: 'ESZBO',
            menuId: '',
        },
        {
            id: AppModulesType.XML_VOID,
            name: 'XML_VOID',
            menuId: '',
        },
    ];

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private aMChanges$: Subject<any> = new Subject();

    // public activeModules: any[] = [];

    constructor(
        private storage: StorageService,
        private store: Store,
    ) {}

    /**
     * setActiveModules
     */
    public setActiveModules() {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let activeModules: any[];
        const cs: ContractorSettings = this.storage.getContractorSettings();
        const savedActiveMod: string[] = _.get(cs, 'activeModules.active', []);
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line prefer-const
        activeModules = this.allModules.filter((el: MfModule) => {
            if (savedActiveMod.includes(el.id)) {
                return true;
            }
            return false;
        });
        this.storage.setActiveModules(activeModules);
        this.aMChanges$.next(activeModules); //TODO
    }

    public isAM(module: AppModulesType): boolean {
        return this.store
            .selectSnapshot(BaseState.GetActiveModules)
            ?.map((el: Module<GenericModuleSettings>) => el.name)
            .includes(module);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getAMOnChange(): Observable<any> {
        return this.aMChanges$;
    }

    // public getAMMenu(): string[] {
    //     return this.storage.getActiveModules().map((el: MfModule) => el.menuId);
    // }
}
