import { Serializable } from '../deserialize.service';
import { PrinterConfigGuiSettings } from './printerConfigGuiSettings.model';

export class ConfigGuiSettings implements Serializable<ConfigGuiSettings> {
    printer: PrinterConfigGuiSettings;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        if (!input || input === null) {
            return null;
        }
        this.printer = new PrinterConfigGuiSettings().deserialize(input.printer);
        return this;
    }
}
