<div style="max-width: 400px; margin-left: auto; margin-right: auto" class="m-portlet mf-portlet-gray__gray-background">
    <div class="m-portlet__body mt-5 pt-2">
        <div class="d-flex justify-content-center m-3">
            <!-- LOGO -->

            <img
                *ngIf="reservationType === 'HDD' || reservationType === 'HDP' || reservationType === ''"
                src="../../../../assets/app/media/img/hd/Logo_HD_grad+gray.svg"
            />
            <img
                *ngIf="reservationType === 'MDFTD' || reservationType === 'MDFTP'"
                src="../../../../assets/app/media/img/logos/MEDIFIT_Logo-01.svg"
                width="250"
            />
            <img
                *ngIf="reservationType === 'LAIFD' || reservationType === 'LAIFP'"
                src="../../../../assets/app/media/img/logos/laif_black.svg"
                height="80"
            />
        </div>
        <div class="d-flex justify-content-center m-3">
            <div>
                Kliče stranka:
                <b>{{ customer }}</b>
            </div>
        </div>

        <div class="d-flex justify-content-center mf-portfolio">
            <div class="w-50 m-1">
                <button (click)="acceptCall()" type="button" class="btn btn-outline-accent m-btn m-btn--icon m-btn--pill btn-block">
                    <span>
                        <span i18n="@@7360768062259200">SPREJMI KLIC</span>
                    </span>
                </button>
            </div>
        </div>
        <div class="d-flex justify-content-center mf-portfolio mt-1">
            <div class="w-50 m-1">
                <button (click)="declineCall()" type="button" class="btn btn-outline-accent m-btn m-btn--icon m-btn--pill btn-block">
                    <span>
                        <span i18n="@@4534656999358464">ZAVRNI KLIC</span>
                    </span>
                </button>
            </div>
        </div>
    </div>
</div>
