import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';
import { AppModulesType } from '../../../_shared/enums/appModulesType.enum';
import { ActiveModulesService } from '../../../_shared/services/activeModules.service';
import { TelephonyService } from '../../pages/home/telephony/telephony.service';

@Directive({
    selector: '[tphCallNumber]',
})
export class TphCallNumberDirective {
    @Input()
    tphCallNumber: string;

    @Input()
    isCompact = true;

    constructor(
        private elRef: ElementRef,
        private servie: TelephonyService,
        private renderer: Renderer2,
        private am: ActiveModulesService,
    ) {}
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngAfterViewInit(): void {
        if (this.am.isAM(AppModulesType.TELEPHONY)) {
            if (this.isCompact) {
                this.renderer.addClass(this.elRef.nativeElement, 'mf-call-number__icon');
            } else {
                this.renderer.addClass(this.elRef.nativeElement, 'mf-call-number');
            }
            // this.renderer.appendChild(this.elRef.nativeElement,)
        }
        // this.elRef.nativeElement.classList.push('mf-call-number');
        // this.elRef.nativeElement.style.color = 'blue';
        // this.elRef.nativeElement.style.fontSize = '20px';
    }

    @HostListener('click', ['$event'])
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    clickEvent(event: any) {
        if (this.am.isAM(AppModulesType.TELEPHONY) && this.tphCallNumber.length > 0) {
            event.preventDefault();
            event.stopPropagation();
            this.servie.show(this.tphCallNumber);
            this.servie.callNumber(this.tphCallNumber);
            // this.servie.callNumber(this.elRef.nativeElement.innerText);
        }
        // this.clicks.next(event);
    }

    // @HostBinding('class')
    // elementClass = 'mf-call-number';
}
