import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'customer-administrator',
    templateUrl: './customer-administrator.component.html',
    styleUrls: ['./customer-administrator.component.scss'],
})
export class CustomerAdministratorComponent implements OnInit {
    @Input()
    parent: FormGroup;

    @Input() set setIsSubmited(value) {
        this.isSubmited = value;
    }
    isSubmited: boolean;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit() {}

    get formData() {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return <any>(<FormGroup>this.parent.get('customerAdministrator')).controls;
    }
}
