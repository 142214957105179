import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, merge, switchMap, takeUntil, tap } from 'rxjs/operators';
import { BsType } from '../../../../../../_shared/enums/business.enum';
import { PaperType } from '../../../../../../_shared/enums/PaperType.enum';
import { PaperHistoryElement, PortfolioPaper, WhiteReferral } from '../../../../../../_shared/model';
import { WhiteReferralApi } from '../../../../../../_shared/model/portfolio/whiteReferralApi.model';
import { HelperService, MfToastService } from '../../../../../../_shared/services';
import { CommonService, ResultService } from '../../../_services';
import { PfPCommnComunicationService } from '../_common/pf-p-comn-communication.service';
import { Customer } from './../../../../../../_shared/model/customer.model';
import { Case } from './../../../../../../_shared/model/schedule/case.model';
import { Subcontractor } from './../../../../../../_shared/model/subcontractor.model';
import { BaseState } from './../../../../../../_shared/state/base/base.state';

@Component({
    selector: 'pf-p-whitereferral',
    templateUrl: './pf-p-whitereferral.component.html',
    styleUrls: ['./pf-p-whitereferral.component.scss'],
})
export class PfPWhitereferralComponent implements OnInit {
    @Input()
    isNew = false;

    @Input()
    paper: PortfolioPaper;

    @Input()
    subcontractor: Subcontractor;

    @Input()
    customer: Customer;

    public wfForm: FormGroup;
    public selectedContractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);
    // public visit: Visit;
    private onDestroy$ = new Subject<void>();
    private case: Case;
    public bsType: BsType;

    constructor(
        private fb: FormBuilder,
        public activeModal: NgbActiveModal,
        private commonRest: CommonService,
        private rest: ResultService,
        // private storage: StorageService,
        private toast: MfToastService,
        private helper: HelperService,
        private datePipe: DatePipe,
        private comm: PfPCommnComunicationService,
        // private visitRest: VisitService,
        private store: Store,
    ) {}

    ngOnInit() {
        this.createForm();

        if (!this.isNew) {
            this.loadData(this.paper.id);
        }

        this.communications();
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        this.comm.dirty$.next(false);
        this.onDestroy$.next();
    }

    private loadData(paperId: number): void {
        this.rest
            .getWhiteReferral(this.selectedContractorId, paperId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((res: WhiteReferralApi) => {
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const authArray: any = {
                    item1: false,
                    item2: false,
                    item3: false,
                };

                res.authorization.split(',').map(el => {
                    if (el === '1') {
                        authArray.item1 = true;
                        return;
                    }
                    if (el === '2') {
                        authArray.item2 = true;

                        return;
                    }
                    if (el === '3') {
                        authArray.item3 = true;
                        return;
                    }
                });
                // res.authorizationArray = authArray;
                this.wfForm.patchValue(res);
                this.wfForm.controls.referralDateString.setValue(this.datePipe.transform(<Date>res.referralDate, 'dd.MM.yyyy'));

                this.wfForm.controls.rdp.setValue({
                    code: res.rdpCode,
                    name: res.rdpDescription,
                });
                this.wfForm.controls.mkb.setValue({
                    code: res.mkbCode,
                    name: res.mkbDescription,
                });
                this.wfForm.controls.authorizationArray.setValue([authArray]);
            });
    }
    private communications(): void {
        this.comm.onCopy.pipe(takeUntil(this.onDestroy$)).subscribe((data: PaperHistoryElement) => {
            this.comm.dirty$.next(true);
            this.copyFromHistroy(data);
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onSave.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.saveSurvey().subscribe();
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onPreview.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.openPreview(this.paper, false);
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.comm.onPrint.pipe(takeUntil(this.onDestroy$)).subscribe((data: boolean) => {
            this.openPreview(this.paper, true);
        });

        this.comm.onSignDocument.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
            this.comm.saveSignShareAndShow(
                this.paper,
                data,
                () => this.saveSurvey(),
                casee => (this.case = casee),
                false,
                false,
                this.bsType === BsType.PREVENTIVNI,
            );
        });
    }

    public openSignedPreview(paper: PortfolioPaper): void {
        this.comm.openSignedPreviewAppend(paper, this.case, 'Bela napotnica').subscribe(
            () => {
                this.toast.success('Dokument dodan k avtorizaciji');
            },
            data => {
                if (data !== 'close-no-error') {
                    this.toast.error('Napaka pri dodajanju dokumenta k avtorizaciji');
                }
            },
        );
    }

    private copyFromHistroy(data: PaperHistoryElement): void {
        this.loadData(data.paperId);
    }

    public createForm(): void {
        this.wfForm = this.fb.group({
            //Veljavnost
            validity: new FormControl(''),
            validityMonth: new FormControl({ value: '', disabled: true }),
            //Obseg pooblastila
            authorizationArray: new FormArray([this.authorizationFormGroup()]),
            //Razlog obravnave
            reasonOfTreatment: new FormControl({ value: '', disabled: false }),
            //Prednostni kriterij
            priorityCriteria: new FormControl({ value: '', disabled: false }),
            //St. el. napotnice
            referralNumber: new FormControl({ value: '', disabled: false }),
            // Stopnja nujnosti
            priorityLevel: new FormControl({ value: '', disabled: false }),
            //šifra izvajalca
            performerId: new FormControl({ value: '', disabled: false }),
            //RDP
            rdp: new FormControl({ value: '', disabled: false }),
            //MKB
            mkb: new FormControl({ value: '', disabled: false }),
            //Vrsta specialnosti napotnega zdravnika
            doctorSpeciality: new FormControl({ value: '', disabled: false }),
            //Kraj
            referralPlace: new FormControl({ value: '', disabled: false }),
            //Dne
            referralDateString: new FormControl({ value: '', disabled: false }),
            //1 - Kratke opredelitev
            referralText1: new FormControl({ value: '', disabled: false }),
            //2 - Aktivni zdravstveni problem
            referralText2: new FormControl({ value: '', disabled: false }),
            //3 - Kljucne najdbe
            referralText3: new FormControl({ value: '', disabled: false }),
            //4 - Kljucni nenormalni lab
            referralText4: new FormControl({ value: '', disabled: false }),
            //5 - Opravljene diag. priskave
            referralText5: new FormControl({ value: '', disabled: false }),
            //6 - Potek dosedanjega zdravljenja
            referralText6: new FormControl({ value: '', disabled: false }),
        });
        this.formManager();
    }

    private authorizationFormGroup(): FormGroup {
        return new FormGroup({
            item1: new FormControl(false),
            item2: new FormControl(false),
            item3: new FormControl(false),
        });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/no-explicit-any
    public tmceSettings: any = {
        menubar: false,
        statusbar: true,
        resize: true,
        toolbar: 'bold | italic | ',
        plugins: 'paste',
    };

    private formManager(): void {
        this.wfForm.controls.validity.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((selected: string) => {
            if (selected === '2') {
                this.wfForm.controls.validityMonth.enable();
            } else {
                this.wfForm.controls.validityMonth.disable();
            }
        });
        this.wfForm?.valueChanges?.subscribe(() => {
            if (this.wfForm?.touched) {
                this.comm.dirty$.next(true);
            }
        });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public searchingRDP = false;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public searchFailed = false;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public hideSearchingWhenUnsubscribed = new Observable(() => () => (this.searchingRDP = false));

    public searchRDP = (text$: Observable<string>) =>
        text$.pipe(
            takeUntil(this.onDestroy$),
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => (this.searchingRDP = true)),
            switchMap(rdp =>
                this.rest.searchRDP(rdp).pipe(
                    tap(result => {
                        if (result.length < 1) {
                            this.searchFailed = true;
                        } else {
                            this.searchFailed = false;
                        }
                    }),
                    catchError(() => {
                        return of([]);
                    }),
                ),
            ),
            tap(() => (this.searchingRDP = false)),
            merge(this.hideSearchingWhenUnsubscribed),
        );

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public searchingMKB = false;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public searchFailedMKB = false;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public hideSearchingWhenUnsubscribedMBK = new Observable(() => () => (this.searchingMKB = false));

    public searchMKB = (text$: Observable<string>) =>
        text$.pipe(
            takeUntil(this.onDestroy$),
            debounceTime(300),
            distinctUntilChanged(),
            tap(() => (this.searchingMKB = true)),
            switchMap(rdp =>
                this.commonRest.searchDiagnose(rdp).pipe(
                    tap(result => {
                        if (result.length < 1) {
                            this.searchFailedMKB = true;
                        } else {
                            this.searchFailedMKB = false;
                        }
                    }),
                    catchError(() => {
                        return of([]);
                    }),
                ),
            ),
            tap(() => (this.searchingMKB = false)),
            merge(this.hideSearchingWhenUnsubscribedMBK),
        );

    public formatter(x: { name: string; code: string }) {
        if (!x.name) {
            return;
        }
        if (!x.code) {
            return x.name;
        }
        return `${x.name} [${x.code}]`;
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public onSubmit(): void {}

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private saveSurvey(): Observable<any> {
        if (this.isNew) {
            return this.rest.newWhiteReferral(this.selectedContractorId, this.paper.visitId, this.prepareSaveObj()).pipe(
                takeUntil(this.onDestroy$),
                tap(
                    (id: string) => {
                        this.paper = new PortfolioPaper().deserialize({
                            id: id,
                            visitId: this.paper.visitId,
                            paperType: PaperType.WHITE_REFERRAL,
                        });
                        this.comm.onPaperChanged.emit(this.paper);

                        this.toast.success('Spremembe so shranjene.');
                        this.isNew = false;
                        this.comm.dirty$.next(false);
                    },
                    () => this.toast.errorAndSend('Napaka pri dodajanju novega sklopa.'),
                ),
            );
        } else {
            //update
            return this.rest.updateWhiteReferral(this.selectedContractorId, this.paper.visitId, this.paper.id, this.prepareSaveObj()).pipe(
                takeUntil(this.onDestroy$),
                tap(
                    () => {
                        this.toast.success('Spremembe so shranjene.');
                        this.comm.dirty$.next(false);
                    },
                    () => this.toast.errorAndSend('Napaka pri urejanju sklopa.'),
                ),
            );
        }
    }

    private prepareSaveObj(): WhiteReferralApi {
        const formData: WhiteReferral = this.wfForm.value;
        const data: WhiteReferralApi = new WhiteReferralApi().deserialize(formData);

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data.authorization = this.wfForm.value.authorizationArray.map((element: any) => {
            const result: string[] = [];
            if (element.item1) {
                result.push('1');
            }
            if (element.item2) {
                result.push('2');
            }
            if (element.item3) {
                result.push('3');
            }
            return result.join(',');
        })[0];

        //RDP
        data.rdpCode = _.get(formData, 'rdp.code', '');
        data.rdpDescription = _.get(formData, 'rdp.name', '');
        //MKB
        data.mkbCode = _.get(formData, 'mkb.code', '');
        data.mkbDescription = _.get(formData, 'mkb.name', '');
        //DATE
        data.referralDate = moment(formData.referralDateString, 'DD.MM.YYYY');
        //TYPE
        data.referralType = 'WHITE';
        return data;
    }

    public openPreview(paper: PortfolioPaper, print: boolean = false): void {
        this.rest
            .printPortfolioPaper(this.selectedContractorId, paper.id)
            .pipe(
                takeUntil(this.onDestroy$),
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                map((res: any) => {
                    if (res.type !== 0) {
                        return new Blob([res.body], {
                            type: 'application/pdf',
                        });
                    }
                    // Helpers.setLoading(false);
                }),
            )
            .subscribe(
                res => {
                    if (res) {
                        this.helper.openDocumentViewer(res, res.type, 'Izvid', print);
                    }
                },
                () => {
                    this.toast.errorAndSend('Napaka pri predogledu poročila.');
                },
            );
    }

    get customerNumberContainers(): FormArray {
        return this.wfForm.get('authorizationArray') as FormArray;
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onDateChange(date: any): void {
        this.wfForm.controls.referralDateString.setValue(date.start.format('DD.MM.YYYY'));
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/no-explicit-any
    public calendarOptions: any = {
        autoUpdateInput: false,
        singleDatePicker: true,
        autoApply: true,
        locale: {
            format: 'DD.MM.YYYY',
            cancelLabel: 'Prekliči',
            applyLabel: 'Potrdi',
            customRangeLabel: 'Izberi ročno',
            daysOfWeek: ['Ne', 'Po', 'To', 'Sr', 'Če', 'Pe', 'So'],
            monthNames: ['Januar', 'Februar', 'Marec', 'April', 'Maj', 'Junij', 'Julij', 'Avgust', 'September', 'Oktober', 'November', 'December'],
            firstDay: 1,
        },
        alwaysShowCalendars: false,
    };
}
