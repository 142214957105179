import { Serializable } from '../deserialize.service';
import { ContractorAccoutingSettings } from './contractorAccoutingSettings.model';
import { ContractorBookingSettings } from './contractorBookingSettings.model';
import { ContractorHaloDoctorSettings } from './contractorHaloDoctorSettings.model';
import { ContractorMenuSettings } from './contractorMenuSettings.model';
import { ContractorModulesSettings } from './contractorModulesSettings.model';
import { ContractorPortfolioSettings } from './ContractorPortfolioSettings.interface';

export class ContractorSettings implements Serializable<ContractorSettings> {
    menu: ContractorMenuSettings;
    activeModules: ContractorModulesSettings;
    accounting: ContractorAccoutingSettings;
    booking: ContractorBookingSettings;
    haloDoctor: ContractorHaloDoctorSettings;
    portfolio: ContractorPortfolioSettings;

    constructor() {
        this.menu = new ContractorMenuSettings();
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        if (!input || input === null) {
            return null;
        }
        if (input.menu) {
            this.menu = new ContractorMenuSettings().deserialize(input.menu);
        }
        if (input.activeModules) {
            this.activeModules = new ContractorModulesSettings().deserialize(input.activeModules);
        }

        if (input.accounting) {
            this.accounting = new ContractorAccoutingSettings().deserialize(input.accounting);
        }

        if (input.booking) {
            this.booking = new ContractorBookingSettings().deserialize(input.booking);
        }
        if (input.haloDoctor) {
            this.haloDoctor = new ContractorHaloDoctorSettings().deserialize(input.haloDoctor);
        }
        if (input.portfolio) {
            this.portfolio = input.portfolio;
        }

        return this;
    }
}
