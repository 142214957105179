<div [style.background-color]="notification?.backgroundColor" (click)="messageRead()" class="d-flex mr-2 rounded">
    <div
        *ngIf="!notification?.seen"
        [style.border-color]="notification?.borderColor"
        style="border: 3px solid; border-radius: 0.25rem 0 0 0.25rem"
    ></div>
    <div class="p-2 w-100">
        <span class="m-list-timeline__text">
            <div class="w-100 d-flex justify-content-between">
                <strong [style.color]="notification.borderColor">
                    {{ notification?.title }}
                </strong>
                <small *ngIf="notification?.link !== null" (click)="redirectSwitch()" style="cursor: pointer">
                    PRIKAZ
                    <i class="la la-angle-right align-bottom"></i>
                </small>
            </div>
            <div>
                <small (click)="copyPreId($event)" style="cursor: pointer" ngbTooltip="Kopiraj šifro" class="align-top mb-1">
                    <i *ngIf="notification.prereservationId" class="la la-copy"></i>
                    <span>
                        <u>{{ notification.prereservationId }}</u>
                    </span>
                </small>
            </div>
            <div *ngIf="notification?.messages !== null" class="text-dark bg-light p-2 rounded">
                <div *ngFor="let msg of notification?.messages">
                    <i style="font-size: 15px" [ngClass]="msg.icon"></i>
                    <span *ngIf="msg.datePipe" [ngStyle]="msg?.messageStyle">
                        {{ msg.message | date: 'dd.MM.yyyy, HH:mm' }}
                    </span>
                    <span *ngIf="!msg.datePipe" [ngStyle]="msg?.messageStyle">{{ msg?.message }}</span>
                </div>
            </div>
            <div class="w-100 d-flex justify-content-between mt-3">
                <div>
                    <small [style.color]="notification?.borderColor" class="">
                        <common-business-subject-icon
                            *ngIf="notification?.data !== null && (notification?.dataInFooter === 'business' || notification?.data?.bsId == -111)"
                            [setBsId]="notification?.data?.bsId"
                        ></common-business-subject-icon>
                        <div *ngIf="notification?.dataInFooter === 'customer'" class="mt-1">
                            <i style="font-size: 15px; margin-right: 3px" class="la la-user"></i>
                            <small>{{ notification?.name }}</small>
                        </div>
                    </small>
                </div>
                <div>
                    <small [style.color]="notification?.borderColor">{{ notification?.notificationDate | date: 'dd.MM.yyyy, HH:mm' }}</small>
                </div>
            </div>
        </span>
    </div>
</div>
