import * as moment from 'moment';
import { Serializable } from '../deserialize.service';

export class PrintPOSInvoiceDetails implements Serializable<PrintPOSInvoiceDetails> {
    public invoiceId: string;
    public cashier: string;
    public typeOfInvoice: string;
    public location: string;
    public date: moment.Moment;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        this.invoiceId = input.invoiceId;
        this.cashier = input.cashier;
        this.typeOfInvoice = input.typeOfInvoice;
        this.location = input.location;
        this.date = moment(input.date);
        return this;
    }
}
