// services
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
// models
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
//rxjs
import { Subject } from 'rxjs';
import { Notification } from '../../../../_shared/model/notification.model';
import { HelperService, StorageService } from '../../../../_shared/services';
import { HelperNotificationService } from '../../../../_shared/services/helper-notification.service';
import { NotificationService, ReservationService, VisitService } from '../../../pages/home/_services';

@Component({
    selector: 'app-header-notification-element',
    templateUrl: './header-notification-element.component.html',
    styles: [],
})
export class HeaderNotificationElementComponent implements OnInit {
    @Output()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public messageClick: EventEmitter<any> = new EventEmitter();

    @Output()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix, @typescript-eslint/no-explicit-any
    public onMessageNavigate: EventEmitter<any> = new EventEmitter();

    // @Input() public notificationId: number;
    // @Input() public prereservationId: string;
    // @Input() public notificationDate: Date;
    // @Input() public title: string;
    // @Input() public name: string;
    // @Input() public messages: NotificationMessage[];
    // @Input() public backgroundColor: string;
    // @Input() public borderColor: string;
    // @Input() public unread: boolean;
    // @Input() public index: number;
    // @Input() public dataInFooter: string;
    // @Input() public link: string;
    // @Input() public redirect: string;
    // @Input() public data: any;

    @Input() public notification: Notification;
    @Input() public index: number;

    private onDestroy$ = new Subject<void>();

    constructor(
        private route: Router,
        private reservRest: ReservationService,
        private storage: StorageService,
        private visitRest: VisitService,
        private helper: HelperService,
        private notifRest: NotificationService,
        private notificationHelper: HelperNotificationService,
        private toast: ToastrService,
    ) {}

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit() {}

    public messageRead(): void {
        this.messageClick.emit(this.index);
    }

    public redirectSwitch(): void {
        this.notifRest.markNotificationAsSeen(this.storage.getSelectedContractor().id, this.notification.id).subscribe();

        this.notificationHelper.redirectSwitch(this.notification);

        this.onMessageNavigate.emit(true);
    }

    // private redirectReservation(prereservationId: string): any {
    //     return this.reservRest
    //         .getReservation(this.storage.getSelectedContractor().id, prereservationId)
    //         .pipe(takeUntil(this.onDestroy$))
    //         .subscribe((res) => {
    //             if (!_.isNil(res.visitId)) {
    //                 this.visitRest
    //                     .isVisitConcluded(this.storage.getSelectedContractor().id, res.visitId)
    //                     .subscribe((result) => {
    //                         this.helper.navigateToVisitOrReservation(res);
    //                     });
    //             } else {
    //                 this.helper.navigateToVisitOrReservation(res);
    //             }
    //         });
    // }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public copyPreId(event: any) {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let nav: any;
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line prefer-const
        nav = window.navigator;
        let text = event.target.textContent.trim();
        if (!text) {
            return;
        }
        if (text.includes('ID:')) {
            text = text.split('ID:')[1].trim();
        }
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        nav.clipboard.writeText(text).then(function () {});
        this.toast.info('Šifra dodana v odložišče.');
    }
}
