import { Serializable } from '../deserialize.service';

export class WQInfo implements Serializable<WQInfo> {
    public caseid: string;
    public preresrvationid: string;
    public internalid: string;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        this.caseid = input.caseid;
        this.preresrvationid = input.preresrvationid;
        this.internalid = input.internalid;
        return this;
    }
}
