import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'rxjs';
import { Observable } from 'rxjs';
import { HelperService, StorageService } from '../../../../_shared/services';
import { HttpClientService } from '../../../../_shared/services/http-client.service';

@Injectable()
export class CommunicationService {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    token: any;

    constructor(
        private http: HttpClient,
        private httpClient: HttpClientService,
        private storage: StorageService,
        private helper: HelperService,
    ) {}
    //getCommunicationLogInfo()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getCommunicationList(contractorid: number, from: string, to: string): Observable<any> {
        return this.httpClient.get(
            this.helper.getCommunicationUrl() + '/contractors/' + contractorid + '/communication/' + '?from=' + from + '&to=' + to,
        );
    }

    //getCommunicationLogInfo()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getCommunicationListForReservation(contractorid: number, prereservationid: string, from: string, to: string): Observable<any> {
        return this.httpClient.get(
            this.helper.getCommunicationUrl() + '/contractors/' + contractorid + '/reservations/' + prereservationid + '?from=' + from + '&to=' + to,
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getCommunicationListForVisit(contractorid: number, visitId: any, from: string, to: string): Observable<any> {
        return this.httpClient.get(
            this.helper.getCommunicationUrl() + '/contractors/' + contractorid + '/reservations/' + visitId + '?from=' + from + '&to=' + to,
        );
    }

    //getLogInfo()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getEventDetails(contractorid: number, queueid: string): Observable<any> {
        return this.httpClient.get(this.helper.getCommunicationUrl() + '/contractors/' + contractorid + '/communication/' + queueid, {
            responseType: 'text',
        });
    }
}
