import _ from 'lodash';
import { LabDetailsResponseDto } from './lab-observation-response-dto';
import { LabOrderInfoResponseDto } from './lab-order-info-response-dto';
import { ObservationResultVm, ObservationVm } from './observation-vm';

export class ObservationsManager {
    public getViewModel(data: LabDetailsResponseDto[]): ObservationVm[] {
        const groupedData = data?.reduce((acc, entry) => {
            const specimenId = entry.specimen?.id ?? 'others';

            if (!acc[specimenId]) {
                acc[specimenId] = [];
            }
            acc[specimenId].push(entry);
            return acc;
        }, {});

        const rearrangedData = Object.entries(groupedData).map(([key, value]) => ({
            specimen: key === 'others' ? { id: null, name: 'others' } : { id: parseInt(key), name: value[0].specimen.name },
            entries: value,
        }));
        return rearrangedData as ObservationVm[];
    }

    public getResultViewModel(data: LabOrderInfoResponseDto): ObservationResultVm[] {
        const groupedData = data?.resultObservations?.reduce((acc, entry) => {
            const observationGroupName = entry.observationGroup.name ? entry.observationGroup.name : 'others';

            if (!acc[observationGroupName]) {
                acc[observationGroupName] = [];
            }
            acc[observationGroupName].push(entry);
            return acc;
        }, {});

        if (_.isEmpty(groupedData)) {
            return null;
        }

        const rearrangedData = Object.entries(groupedData).map(([key, value]) => ({
            observationGroup:
                key === 'others' ? { code: null, name: 'others' } : { code: parseInt(key).toString(), name: value[0].observationGroup.name },
            resultEntries: value,
        }));
        return rearrangedData as ObservationResultVm[];
    }

    public getOriginalOrderViewModel(data: LabOrderInfoResponseDto): ObservationVm[] {
        const groupedData = data?.orderObservations?.reduce((acc, entry) => {
            const observationGroupName = entry.specimen.name ?? 'others';

            if (!acc[observationGroupName]) {
                acc[observationGroupName] = [];
            }
            acc[observationGroupName].push(entry);
            return acc;
        }, {});

        if (_.isEmpty(groupedData)) {
            return null;
        }

        const rearrangedData = Object.entries(groupedData).map(([key, value]) => ({
            specimen: key === 'others' ? { id: null, name: 'others' } : { id: parseInt(key), name: value[0].specimen.name },
            entries: value,
        }));
        return rearrangedData as ObservationVm[];
    }
}
