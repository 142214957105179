import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'commStatus',
})
export class CommCurrStatusPipe implements PipeTransform {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(type: any): any {
        switch (type) {
            case 'DELIVERED':
                return 'Dostavljeno';
            case 'SENT':
                return 'Poslano';
            case 'FAILED':
                return 'Napaka';
            case 'UNKNOWN':
                return 'Neznano';
            case 'ACEPTED':
                return 'Sprejeti';
            default:
                return type;
        }
    }
}
