<div style="margin-left: -80px; margin-top: -20px" class="m-page-loader m-page-loader--base m-page-loader--non-block">
    <div class="m-blockui">
        <span>Nalagam ...</span>
        <span>
            <div class="m-loader m-loader--brand m-port"></div>
        </span>
    </div>
</div>

<router-outlet></router-outlet>

<app-quick-sidebar></app-quick-sidebar>
<telemedicine></telemedicine>
