import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Helpers } from '../../helpers';
import { CommonService } from '../../theme/pages/home/_services';
import { StorageService } from '../../_shared/services';
import { JumpToService } from '../../_shared/services/jumpTo.service';
import { AuthenticationService } from '../_services';
import { MenuService } from './../../_shared/services/menu.service';
import { ClearState } from './../../_shared/state/base/base.actions';
import { ClearTreatmentState } from './../../_shared/state/treatment/treatment.actions';
import { ClearWebSocketState } from './../../_shared/state/websocket/websocket.actions';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class LogoutComponent implements OnInit {
    constructor(
        private _router: Router,
        private _authService: AuthenticationService,
        private commonRest: CommonService,
        // private helper: HelperService,
        private jumpTo: JumpToService,
        private ls: StorageService,
        private store: Store,
        private menu: MenuService,
    ) {}

    ngOnInit(): void {
        Helpers.setLoading(true);
        this.store.dispatch(new ClearState());
        this.ls.deleteAvailibilityData();
        this.ls.deleteActiveModules();
        this.ls.deleteSelectedTerms();
        this.menu.resetMenu();
        // this.ls.deleteState();
        // reset login status
        // this.commonRest.deleteAppPosition().subscribe();
        // this.jumpTo.removeHotKeys();
        this._authService.logout();
        this._router.navigate(['/login']);
        this.store.dispatch([new ClearState(), new ClearTreatmentState(), new ClearWebSocketState()]);
    }
}
