import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HelloDoctorWebSocketActions } from './../../../../_shared/enums/hdWebSocketActions.enum';
import { GuiUserSettings } from './../../../../_shared/model/userGuiSettings/guiUserSettings.model';
import { HdWebsocketService } from './../../../../_shared/services/hd-websocket.service';
import { StorageService } from './../../../../_shared/services/storage.service';

@Component({
    selector: 'asn-hd-status',
    templateUrl: './asn-hd-status.component.html',
    styleUrls: ['./asn-hd-status.component.scss'],
})
export class AsnHdStatusComponent implements OnInit {
    private onDestroy$ = new Subject<void>();
    public userGuiSettings: GuiUserSettings;

    constructor(
        private storage: StorageService,
        private hdws: HdWebsocketService,
    ) {}

    ngOnInit() {
        this.storage.guiUserSettingsSubjec.pipe(takeUntil(this.onDestroy$)).subscribe((settings: GuiUserSettings) => {
            this.userGuiSettings = settings;
            this.sendStatus();
        });

        this.hdws.onReconnect.subscribe(() => {
            this.sendStatus();
        });

        // this.storage.guiUserContractorSettingsSubjec
        // .pipe(takeUntil(this.onDestroy$))
        // .subscribe((settings: GuiUserContractorSettings) => {
        //     this.userSettings = settings;
        //     if (this.userSettings?.haloDoctor?.isActive) {
        //         this.hdws.send({
        //             action: HelloDoctorWebSocketActions.FREE,
        //         });
        //     } else {
        //         this.hdws.send({
        //             action: HelloDoctorWebSocketActions.BUSY,
        //         });
        //     }
        // });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    private sendStatus(): void {
        if (this.userGuiSettings?.haloDoctor?.isActive !== false) {
            this.hdws.send({
                action: HelloDoctorWebSocketActions.FREE,
            });
        } else {
            this.hdws.send({
                action: HelloDoctorWebSocketActions.BUSY,
            });
        }
    }
}
