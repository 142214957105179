import * as moment from 'moment';
import { Serializable } from '../deserialize.service';

export class BookingAllowanceRequest implements Serializable<BookingAllowanceRequest> {
    public customerId: number;
    public serviceId: number;
    public subcontractorId: number;
    public startDate: moment.Moment;
    public visitId: number;
    public prereservationId: string;
    public widgetId: string;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public bsId: any;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        this.customerId = input.customerId;
        this.serviceId = input.serviceId;
        this.startDate = input.startDate;
        if (input?.visitId) {
            this.visitId = input.visitId;
        }
        this.prereservationId = input.prereservationId;
        this.subcontractorId = input.subcontractorId;
        this.widgetId = input.widgetId;
        this.bsId = input.bsId;
        return this;
    }
}
