import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogoutComponent } from './auth/logout/logout.component';
import { PublicComponent } from './public/public.component';

const routes: Routes = [
    {
        path: 'login',

        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    },
    { path: 'logout', component: LogoutComponent },
    { path: '', redirectTo: 'index', pathMatch: 'full' },
    // { path: 'login', loadChildren: './auth/auth.module#AuthModule' },
    {
        path: 'public',
        component: PublicComponent,
        children: [
            {
                path: 'download-files',
                loadChildren: () => import('./public/download-files/download-files.module').then(m => m.DownloadFilesModule),
            },
            {
                path: 'renew-password',
                loadChildren: () => import('./public/renew-pwd/renew-pwd.module').then(m => m.RenewPwdModule),
            },
            {
                path: 'survey',
                loadChildren: () => import('./public/survey/survey.module').then(m => m.SurveyModule),
            },
            {
                path: 'consent',
                loadChildren: () => import('./public/GDPR/GDPR.module').then(m => m.GDPRModule),
            },
            {
                path: 'documents',
                loadChildren: () => import('./public/public-documents/public-documents.module').then(m => m.PublicDocumentsModule),
            },
            {
                path: 'get-password',
                loadChildren: () => import('./public/get-pwd/get-pwd.module').then(m => m.GetPwdModule),
            },
            {
                path: 'connect-user',
                loadChildren: () => import('./public/connect-user/connect-user.module').then(m => m.ConnectUserModule),
            },
            {
                path: 'covid',
                loadChildren: () => import('./theme/pages/home/covid/covid.module').then(m => m.CovidModule),
            },
        ],
    },
    {
        path: 'documentation-overview',
        loadChildren: () =>
            import('./theme/pages/home/documentation-overview/documentation-overview.module').then(m => m.DocumentationOverviewModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
