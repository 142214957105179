import * as _ from 'lodash';
import { CriticalData } from './criticalData.model';
import { Serializable } from './deserialize.service';
import { GenderType } from './genderType.model';

export class Customer implements Serializable<Customer> {
    id: number;
    name: string;
    surname: string;
    fullName: string;
    address: string;
    city: string;
    country: string;
    phone: string;
    mobilePhone: string;
    email: string;
    post: string;
    birthDate: Date;
    sendMail = false;
    sendSms = false;
    kzz: string;
    emso: string;
    taxNo: string;
    medifitId: number;
    gender: GenderType;
    createdFrom: string;
    canShareDataWithInsurance: boolean;
    //prikaz brisanih strank na starih terminih
    deleted: boolean;
    //povezovanje strank (superCustomer)
    dataCarrier: boolean;
    customerSuperId: number;
    importantComments: { comments: CriticalData[] };
    birthDateString: string;
    jobCode?: string;
    jobTitle?: string;

    deserialize(input) {
        if (_.isNil(input)) {
            return undefined;
        }

        this.id = input.id;
        this.name = input.name;
        this.surname = input.surname;
        this.address = input.address;
        this.city = input.city;
        this.country = input.country;
        this.phone = input.phone;
        this.mobilePhone = input.mobilePhone;
        this.email = input.email;
        this.post = input.post;
        this.birthDate = input.birthDate;
        this.sendMail = input.sendMail;
        this.sendSms = input.sendSms;
        this.kzz = input.kzz;
        this.emso = input.emso;
        this.taxNo = input.taxNo;
        this.medifitId = input.medifitId;
        this.createdFrom = input.createdFrom;
        this.canShareDataWithInsurance = input.canShareData;
        this.gender = input.gender;
        this.dataCarrier = input.dataCarrier;
        this.customerSuperId = input.customerSuperId;
        this.importantComments = input.importantComments;
        this.birthDateString = input.birthDateString;
        this.jobCode = input.jobCode;
        this.jobTitle = input.jobTitle;
        return this;
    }

    public getFullName(): string {
        return `${this.name} ${this.surname}`;
    }
}
