import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Subcontractor } from '../../../../../../../_shared/model';
import { CommonService } from '../../../../_services';
import { BaseState } from './../../../../../../../_shared/state/base/base.state';

@Component({
    selector: 'pf-p-wr-subcontractor',
    templateUrl: './pf-p-wr-subcontractor.component.html',
    styles: [],
})
export class PfPWrSubcontractorComponent implements OnInit {
    @Input()
    subcontracor: Subcontractor;

    public selectedSubcontractor: Subcontractor;
    private onDestroy$ = new Subject<void>();
    public selectedContractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);

    constructor(
        private commonRest: CommonService,
        private store: Store,
    ) {}

    ngOnInit() {
        const subcontrId = this.subcontracor?.id;
        this.commonRest
            .getSubcontractor(this.selectedContractorId, subcontrId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((res: Subcontractor) => {
                this.selectedSubcontractor = res;
            });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        this.onDestroy$.next();
    }
}
