import { Serializable } from './deserialize.service';

export class TimeListSecText implements Serializable<TimeListSecText> {
    text: string;
    icon: string;

    deserialize(input) {
        this.text = input.text;
        this.icon = input.icon;
        return this;
    }
}
