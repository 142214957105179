import { Serializable } from '../deserialize.service';
import { AppGuiSettings } from './appGuiSettings.model';
import { CalendarUserGuiSettings } from './calendarUserGuiSettings.model';
import { ConfigGuiSettings } from './configGuiSettings.model';
import { GuiUserContractorHd } from './guiUserContractorHd.model';
import { ResultUserGuiSettings } from './resultUserGuiSettings.model';

export class GuiUserContractorSettings implements Serializable<GuiUserContractorSettings> {
    calendar: CalendarUserGuiSettings;
    result: ResultUserGuiSettings;
    app: AppGuiSettings;
    config: ConfigGuiSettings;
    haloDoctor: GuiUserContractorHd;

    constructor() {
        this.calendar = new CalendarUserGuiSettings();
        this.result = new ResultUserGuiSettings();
        this.app = new AppGuiSettings();
        this.config = new ConfigGuiSettings();
        this.haloDoctor = new GuiUserContractorHd();
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        if (!input || input === null) {
            return this;
        }
        if (input.calendar) {
            this.calendar = new CalendarUserGuiSettings().deserialize(input.calendar);
        }
        if (input.result) {
            this.result = new ResultUserGuiSettings().deserialize(input.result);
        }

        if (input.app) {
            this.app = new AppGuiSettings().deserialize(input.app);
        }

        if (input.config) {
            this.config = new ConfigGuiSettings().deserialize(input.config);
        }

        if (input.haloDoctor) {
            this.haloDoctor = new GuiUserContractorHd().deserialize(input.haloDoctor);
        }

        return this;
    }
}

// public filter: any = {
//     showResources: false,
//     showOnlyAvailable: false,
//     showWeekend: false
// };
