import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CaseService } from '../../theme/pages/home/_services/case.service';
import { BusinessCaseResponse } from '../model';
import { SecondaryContact } from '../model/case/secondary-contact.model';

export class SecondaryContactHelper {
    public static createSecondaryContact$(
        businessCase$: BehaviorSubject<BusinessCaseResponse>,
        caseService: CaseService,
    ): Observable<SecondaryContact> {
        return businessCase$.pipe(
            switchMap(businessCase => {
                if (businessCase && this.isGenerali(businessCase.bsid) && businessCase.caseId && businessCase.caseId.length) {
                    return caseService
                        .getCaseSecondaryContact(businessCase.bsid, businessCase.caseId)
                        .pipe(catchError(() => of(null as SecondaryContact)));
                } else {
                    return of(null as SecondaryContact);
                }
            }),
            map(result => {
                if (result?.phone?.length > 0 || result?.email.length > 0) {
                    return result;
                }
                return null as SecondaryContact;
            }),
        );
    }

    private static isGenerali(bsId: number): boolean {
        return bsId === 1 || bsId === 11;
    }
}
