import { Pipe, PipeTransform } from '@angular/core';
import { AuthorizationType } from '../../../../../_shared/enums/authorizationType.enum';

@Pipe({
    name: 'caseLogTypes',
})
export class CaseLogTypesPipe implements PipeTransform {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(value: any, args?: any): any {
        if (args) {
            switch (value) {
                case AuthorizationType.CASE_CREATED:
                case AuthorizationType.PUBLIC_HEALTH_CREATED:
                case AuthorizationType.TRIAGE_CREATED:
                case AuthorizationType.POLICYHOLDER_REMOVE:
                case AuthorizationType.EVENT_MSG_UPDATED:
                case AuthorizationType.CASE_SURVEY_CREATED:
                    return `m-${args}-metal`;
                case AuthorizationType.EVENT_UPDATED:
                case AuthorizationType.RESERVATION_UPDATED:
                    return `m-${args}-brand`;
                case AuthorizationType.RESERVATION_CANCELED:
                case AuthorizationType.CASE_CANCELED:
                case AuthorizationType.TRIAGE_CANCELED:
                case AuthorizationType.TRIAGE_REJECTED:
                    return `m-${args}-danger`;
                case AuthorizationType.VISIT_CONCLUDED:
                case AuthorizationType.TREATMENT_CONCLUDED:
                case AuthorizationType.TRIAGE_CONCLUDED:
                case AuthorizationType.TRIAGE_ANSWERED:
                case AuthorizationType.EVENT_CREATED:
                case AuthorizationType.POLICYHOLDER_ADD:
                case AuthorizationType.SECOND_OPINION_CREATED:
                case AuthorizationType.CASE_SURVEY_ANSWERED:
                    return `m-${args}-success`;
                default:
                    return `m-${args}-accent`;
            }
        } else {
            switch (value) {
                //exist
                case AuthorizationType.CASE_CREATED:
                    return 'Avtorizacija ustvarjena.';
                //exist
                case AuthorizationType.PUBLIC_HEALTH_CREATED:
                    return 'Naročanje v javno zdravstvo';
                //exist
                case AuthorizationType.RESERVATION_CREATED:
                    return 'Termin rezerviran';
                //exist
                case AuthorizationType.WQ_ITEM_CREATED:
                    return 'Rezervacija v čakalni vrsti ustvarjena.';
                //exist
                case AuthorizationType.RESERVATION_UPDATED:
                    return 'Rezervacija spremenjena';
                //exist
                case AuthorizationType.VISIT_CONCLUDED:
                    return 'Obravnava zaključena';
                case AuthorizationType.TREATMENT_CONCLUDED:
                    return 'Obravnava zaključena';
                //exist
                case AuthorizationType.RESERVATION_CANCELED:
                    return 'Rezervacija preklicana in postavljena v čakalno vrsto';
                //exist
                case AuthorizationType.CASE_CANCELED:
                    return 'Avtorizacija preklicana.';
                //exist
                case AuthorizationType.VISIT_CREATED:
                    return 'Obravnava ustvarjena';
                case AuthorizationType.TRIAGE_CREATED:
                    return 'eTriaža ustvarjena.';
                case AuthorizationType.TRIAGE_CONCLUDED:
                    return 'eTriaža odgovorjena.';
                case AuthorizationType.TRIAGE_CANCELED:
                    return 'eTriaža preklicana.';
                case AuthorizationType.TRIAGE_REJECTED:
                    return 'eTriaža zavrnjena';
                case AuthorizationType.TRIAGE_ANSWERED:
                    return 'eTriaža odgovorjena.';
                case AuthorizationType.TRIAGE_REQUEST_SENT:
                    return 'eTriaža poslana.';
                case AuthorizationType.COVERAGE_PRICE_UPDATED:
                    return 'Urejanje cene/kritja storitve.';

                case AuthorizationType.EVENT_CREATED:
                    return 'Dogodek je bil ustvarjen';

                case AuthorizationType.EVENT_UPDATED:
                    return 'Dogodek je bil spremenjen';

                case AuthorizationType.POLICYHOLDER_ADD:
                    return 'Na dogodek je bila dodana stranka';
                case AuthorizationType.POLICYHOLDER_REMOVE:
                    return 'Stranka je bila odstranjena iz dogodka';

                case AuthorizationType.EVENT_MSG_UPDATED:
                    return 'Na dogodku je bilo dodano sporočilo';

                case AuthorizationType.CASE_SURVEY_CREATED:
                    return 'Stranki je bila poslana vloga';

                case AuthorizationType.CASE_SURVEY_ANSWERED:
                    return 'Stranka je potrdila vlogo';

                case AuthorizationType.SECOND_OPINION_CREATED:
                    return 'Drugo mnenje je bilo ustvarjeno';
                default:
                    return value;
            }
        }
    }
}

// visit - obravanva
// case - avtorizacija
// rezervacija v ČV -> WQ
