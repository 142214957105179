import { KeyValue } from '@angular/common';
import { FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { EMPTY, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DATE_CONST } from '../../../../../../../_shared/constants/date-constants.const';
import { MdpsCertificateBase } from './mdps-certificate-base';

export interface ICertificateWeaponModel {
    licensedPhysicianFullName?: string;
    certificateNumber?: string;
    customerFullName?: string;
    customerAddress?: string;
    customerBirthDate?: string;
    customerBirthPlace?: string;
    assessmentStatus: string;
    assessmentExplanation?: string;
    cost?: number;
    dateOfSignature: string;
    documentIdAndLocation: string;
}

export class CertificateWeaponsModel extends MdpsCertificateBase implements ICertificateWeaponModel {
    licensedPhysicianFullName?: string;
    certificateNumber?: string;
    customerFullName?: string;
    customerAddress?: string;
    customerBirthDate?: string;
    customerBirthPlace?: string;
    assessmentStatus: string;
    assessmentExplanation?: string;
    cost?: number;
    dateOfSignature: string;
    documentIdAndLocation: string;

    public assessmentOptions = this.getAssessmentOptions();

    public createForm(): FormGroup {
        return this.fb.group({
            licensedPhysicianFullName: this.fb.control({ value: null, disabled: false }),

            customerFullName: this.fb.control({ value: null, disabled: false }),
            customerAddress: this.fb.control({ value: null, disabled: false }),
            customerBirthDate: this.fb.control({ value: null, disabled: false }),
            customerBirthPlace: this.fb.control({ value: null, disabled: false }),
            documentIdAndLocation: this.fb.control({ value: null, disabled: false }),

            assessmentResult: this.fb.control({ value: { key: 'a' }, disabled: false }, Validators.required),
            assessmentExplanation: this.fb.control({ value: null, disabled: false }),

            cost: this.fb.control({ value: null, disabled: false }),
            dateOfSignature: this.fb.control({ value: null, disabled: false }),
            placeOfSignature: this.fb.control({ value: null, disabled: false }),
        });
    }

    public getPaperData(paperId: number): void {
        this.resultService
            .getWeaponCertificateData(this.visit.contractor.id, this.visit.visitId, paperId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(res => {
                this.applyDataToForm(res);
            });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public saveDocument$(): Observable<any> {
        if (!this.form.valid) {
            return EMPTY;
        }
        const dto = this.getDataModelFromForm();
        return this.resultService.saveWeaponCertificate(this.visit.contractor.id, this.visit.visitId, dto);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateDocument$(): Observable<any> {
        const paperId = this.paper.id ? this.paper.id : this.paper.paperId;
        const dto = this.getDataModelFromForm();
        return this.resultService.updateWeaponCertificateData(this.visit.contractor.id, this.visit.visitId, paperId, dto);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected getPaperPreview$(): Observable<any> {
        return this.resultService.getPortfolioPaper(this.visit.contractor.id, this.paper.id ? this.paper.id : this.paper.paperId);
    }

    public selectOption(item: KeyValue<string, string>): void {
        this.form.controls['assessmentResult'].setValue(item);
    }

    private getAssessmentOptions(): Array<KeyValue<string, string>> {
        const itemsMap = new Array<KeyValue<string, string>>();
        itemsMap.push({
            key: 'a',
            value: 'Je zdravstveno zmožen za posest orožja',
        });
        itemsMap.push({
            key: 'b',
            value: 'Je zdravstveno zmožen za posest in nošenje orožja',
        });
        itemsMap.push({
            key: 'c',
            value: 'Ni zdravstveno zmožen',
        });
        return itemsMap;
    }

    private applyDataToForm(model: ICertificateWeaponModel): void {
        if (model?.assessmentStatus?.length) {
            switch (model?.assessmentStatus) {
                case '1':
                    this.form.controls['assessmentResult'].setValue(this.assessmentOptions.find(item => item.key === 'a'));
                    break;
                case '2':
                    this.form.controls['assessmentResult'].setValue(this.assessmentOptions.find(item => item.key === 'b'));
                    break;
                case '3':
                    this.form.controls['assessmentResult'].setValue(this.assessmentOptions.find(item => item.key === 'c'));
                    break;
            }
        }

        if (model?.assessmentExplanation?.length) {
            this.form.controls['assessmentExplanation'].setValue(model?.assessmentExplanation);
        }

        if (model?.documentIdAndLocation?.length) {
            this.form.controls['documentIdAndLocation'].setValue(model?.documentIdAndLocation);
        }

        if (model?.customerBirthPlace?.length) {
            this.form.controls['customerBirthPlace'].setValue(model?.customerBirthPlace);
        }
    }

    private getDataModelFromForm(): ICertificateWeaponModel {
        const model = {
            licensedPhysicianFullName: this.visit.contractor.subcontractors[0]?.name,
            customerFullName: this.visit.customer.fullName,
            customerAddress: `${this.visit.customer.address ? this.visit.customer.address : ''}, ${
                this.visit.customer.post != null ? this.visit.customer.post : ''
            }, ${this.visit.customer.city != null ? this.visit.customer.city : ''}`,
            customerBirthDate: moment(this.visit.customer.birthDate).format(DATE_CONST.format.date.api),
            customerBirthPlace: this.form.controls['customerBirthPlace'].value?.length ? this.form.controls['customerBirthPlace'].value : '',
            assessmentStatus: '',
            assessmentExplanation: this.form.controls['assessmentExplanation'].value?.length ? this.form.controls['assessmentExplanation'].value : '',
            cost: null,
            dateOfSignature: moment().format(DATE_CONST.format.date.api),
            documentIdAndLocation: this.form.controls['documentIdAndLocation'].value?.length ? this.form.controls['documentIdAndLocation'].value : '',
        } as ICertificateWeaponModel;

        const selectedKey = (this.form.controls['assessmentResult'].value as KeyValue<string, string>).key;
        switch (selectedKey) {
            case 'a':
                model.assessmentStatus = '1';
                break;
            case 'b':
                model.assessmentStatus = '2';
                break;
            case 'c':
                model.assessmentStatus = '3';
                break;
        }
        return model;
    }
}
