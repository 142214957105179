import { Serializable } from '../deserialize.service';

export class AppGuiSettings implements Serializable<AppGuiSettings> {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public termsAndConditions: any;
    public isMenuMinimized: boolean;
    public customerDocumentViewType: string;
    public newReleaseVersion: string;

    deserialize(input) {
        if (!input || input === null) {
            return null;
        }

        Object.keys(input).forEach(key => (this[key] = input[key]));
        // this.termsAndConditions = input.termsAndConditions;
        this.customerDocumentViewType = input.customerDocumentViewType;
        return this;
    }
}
