<div class="m-portlet">
    <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
                <h3 class="m-portlet__head-text" i18n="app.visit.portfolio.comn.actions.title.text">Aktivnosti</h3>
            </div>
        </div>
    </div>
    <div
        *ngIf="
            (paper?.paperType == 'HELLODOC_REFERRAL' ||
                paper?.paperType == 'HELLODOC_IZVID' ||
                paper?.paperType == 'MFMEDICAL_IZVID' ||
                paper?.paperType == 'MEDIFIT_MEDICAL_REPORT') &&
            !disabled
        "
        class="m-portlet__body row"
    >
        <div class="col-3">
            <div class="d-flex justify-content-center">
                <div>
                    <button (click)="resultsPreview()" class="btn btn-outline-accent m-btn m-btn--icon m-btn--pill btn-block p-4">
                        <span>
                            <i class="la la-eye la-2x"></i>
                        </span>
                    </button>
                </div>
            </div>
            <div class="d-flex text-center mt-2 justify-content-center">Pregled dokumenta</div>
        </div>
        <div class="col-3">
            <div class="d-flex justify-content-center">
                <div>
                    <button (click)="resultsPrint()" class="btn btn-outline-accent m-btn m-btn--icon m-btn--pill btn-block p-4">
                        <span>
                            <i class="la la-print la-2x"></i>
                        </span>
                    </button>
                </div>
            </div>
            <div class="d-flex text-center mt-2 justify-content-center">Natisni dokument</div>
        </div>
        <div class="col-3">
            <div class="d-flex justify-content-center">
                <div>
                    <button (click)="sendPaper()" class="btn btn-outline-accent m-btn m-btn--icon m-btn--pill btn-block p-4">
                        <span>
                            <i class="la la-send la-2x"></i>
                        </span>
                    </button>
                </div>
            </div>
            <div class="d-flex text-center mt-2 justify-content-center">Pošlji dokument</div>
        </div>
        <div class="col-3">
            <div class="d-flex justify-content-center">
                <div>
                    <button (click)="signDocument()" class="btn btn-outline-accent m-btn m-btn--icon m-btn--pill btn-block p-4">
                        <span class="mx-auto">
                            <i class="la la-pencil la-2x"></i>
                        </span>
                    </button>
                </div>
            </div>
            <div class="d-flex text-center mt-2 justify-content-center">Podpiši listino</div>
        </div>
    </div>
    <div
        *ngIf="
            paper?.paperType !== 'HELLODOC_REFERRAL' &&
            paper?.paperType !== 'HELLODOC_IZVID' &&
            paper?.paperType !== 'MFMEDICAL_IZVID' &&
            paper?.paperType !== 'MEDIFIT_MEDICAL_REPORT'
        "
        class="m-portlet__body"
    >
        <button *ngIf="!disabled" (click)="resultsPreview()" class="btn btn-outline-accent m-btn m-btn--icon m-btn--pill btn-block">
            <span>
                <span i18n>Predogled dokumenta</span>
            </span>
        </button>
        <button *ngIf="!disabled" (click)="resultsPrint()" class="btn btn-outline-accent m-btn m-btn--icon m-btn--pill btn-block">
            <span>
                <span i18n>Natisni dokument</span>
            </span>
        </button>
        <button *ngIf="!disabled" (click)="sendPaper()" class="btn btn-outline-accent m-btn m-btn--icon m-btn--pill btn-block">
            <span>
                <span i18n>Pošlji dokument</span>
            </span>
        </button>
        <button
            *ngIf="!disabled && paper?.paperType !== 'CONFIRM_RESERVATION'"
            (click)="signDocument()"
            class="btn btn-outline-accent m-btn m-btn--icon m-btn--pill btn-block"
        >
            <span>
                <span i18n>Podpiši listino</span>
            </span>
        </button>
        <button (click)="saveResults()" class="btn btn-outline-accent m-btn m-btn--icon m-btn--pill btn-block">
            <span>
                <span>Shrani dokument</span>
            </span>
        </button>
    </div>

    <div
        *ngIf="
            paper?.paperType == 'HELLODOC_REFERRAL' ||
            paper?.paperType == 'HELLODOC_IZVID' ||
            paper?.paperType == 'HELLODOC_IZVID' ||
            paper?.paperType == 'MEDIFIT_MEDICAL_REPORT'
        "
        class="m-portlet__foot pl-3 pr-3 d-flex justify-content-between"
    >
        <div class="w-25">
            <button (click)="saveResults()" class="btn btn-outline-accent m-btn m-btn--icon m-btn--pill btn-block">
                <span>Shrani</span>
            </button>
        </div>
        <div class="w-25">
            <button (click)="deletePaper()" class="btn btn-outline-danger m-btn m-btn--icon m-btn--pill btn-block">
                <span>Izbriši</span>
            </button>
        </div>
        <div class="w-25">
            <button (click)="closePopup()" class="btn btn-outline-warning m-btn m-btn--icon m-btn--pill btn-block">
                <span>Zapri</span>
            </button>
        </div>
    </div>
    <div
        *ngIf="
            paper?.paperType !== 'HELLODOC_REFERRAL' &&
            paper?.paperType !== 'HELLODOC_IZVID' &&
            paper?.paperType !== 'HELLODOC_IZVID' &&
            paper?.paperType !== 'MEDIFIT_MEDICAL_REPORT'
        "
        class="m-portlet__foot pl-3 pr-3"
    >
        <button *ngIf="!disabled" (click)="deletePaper()" class="btn btn-outline-danger m-btn m-btn--icon m-btn--pill btn-block">
            <span>
                <span>Izbriši listino</span>
            </span>
        </button>

        <button (click)="closePopup()" class="btn btn-outline-warning m-btn m-btn--icon m-btn--pill btn-block">
            <span>
                <i class="la la-close"></i>
                <span>Zapri</span>
            </span>
        </button>
    </div>
</div>
