import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterArrayById',
})
export class FilterArrayByIdPipe implements PipeTransform {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(value: any[], removeThisIs?: any): any {
        return value.filter(el => el.id != removeThisIs);
    }
}
