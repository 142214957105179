import * as _ from 'lodash';
import { Serializable } from '../deserialize.service';

export class PrintPOSCustomer implements Serializable<PrintPOSCustomer> {
    id: number;
    name: string;
    surname: string;
    address: string;
    city: string;
    country: string;
    phone: string;
    email: string;
    post: string;
    birthDate: Date;
    sendMail = false;
    sendSms = false;
    kzz: string;
    emso: string;
    taxNo: string;
    medifitId: number;

    deserialize(input) {
        if (_.isNil(input)) {
            return undefined;
        }

        this.id = input.id;
        this.name = input.name;
        this.surname = input.surname;
        this.address = input.address;
        this.city = input.city;
        this.country = input.country;
        this.phone = input.phone;
        this.email = input.email;
        this.post = input.post;
        this.birthDate = input.birthDate;
        this.sendMail = input.sendMail;
        this.sendSms = input.sendSms;
        this.kzz = input.kzz;
        this.emso = input.emso;
        this.taxNo = input.taxNo;
        this.medifitId = input.medifitId;
        return this;
    }
}
