import { CustomerSharedList } from './../common/model/customer-shared-list.model';
// TODO Ignored with eslint-interactive on 2023-11-10
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace PublicCommon {
    export class SetAuthData {
        public static readonly type = 'SetAuthData';
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        constructor(public readonly auth: any) {}
    }

    export class SetCustomerSharedList {
        public static readonly type = 'SetCustomerSharedList';
        constructor(public readonly data: CustomerSharedList) {}
    }
}
