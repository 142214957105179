import * as _ from 'lodash';
import { Serializable } from '../../deserialize.service';

export class InvoiceContractor implements Serializable<InvoiceContractor> {
    address: string;
    addressName: string;
    addressNumber: string;
    bankAccount: string;
    country: string;
    description: string;
    email: string;
    id: number;
    mobilePhone: string;
    name: string;
    phone: string;
    postName: string;
    postNumber: string;
    registrationNumber: string;
    subContractorId: string;
    subContractorName: string;
    taxId: string;
    www: string;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        if (_.isNil(input)) {
            return undefined;
        }

        this.address = input.address;
        this.addressName = input.addressName;
        this.addressNumber = input.addressNumber;
        this.bankAccount = input.bankAccount;
        this.country = input.country;
        this.description = input.description;
        this.email = input.email;
        this.id = input.id;
        this.mobilePhone = input.mobilePhone;
        this.name = input.name;
        this.phone = input.phone;
        this.postName = input.postName;
        this.postNumber = input.postNumber;
        this.registrationNumber = input.registrationNumber;
        this.subContractorId = input.subContractorId;
        this.subContractorName = input.subContractorName;
        this.taxId = input.taxId;
        this.www = input.www;

        return this;
    }
}
