import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    // selector: '.m-grid__item.m-grid__item--fluid.m-grid.m-grid--ver-desktop.m-grid--desktop.m-body.m-page__container',
    templateUrl: './public.component.html',
    styleUrls: ['public.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class PublicComponent implements OnInit {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit() {}
}
