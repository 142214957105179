import { Pipe, PipeTransform } from '@angular/core';
import { SendContentType } from '../enums/sendContentType.enum';

@Pipe({
    name: 'commStringPipe',
})
export class CommunicationStringPipe implements PipeTransform {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public transform(type: any, argument: number = 0) {
        //3 skupine textov - Pravila pošiljanja
        const text = [
            ['Sporočilo pošlji', 'ur po potrditvi', 'vendar nikoli pred', 'in najkasneje ob'], //1 Skupina
            ['Sporočilo pošlji', 'ur pred terminom', 'vendar nikoli pred', 'in najkasneje ob'], //2 Skupina
            ['Anketo pošlji', 'ur po obravnavi,', 'vendar nikoli pred', 'in nikoli po'], //3 Skupina
        ];
        switch (type) {
            //1 Skupina
            case SendContentType.MOVED:
            case SendContentType.CONFIRMATION_MAIL:
            case SendContentType.CANCELATION:
            case SendContentType.SUBCONTRACTOR_CONFIRMATION_NOTICE:
            case SendContentType.PHONE_CALL:
            case SendContentType.CONSENT:
            case SendContentType.USER_TRIAGE:
                return text[0][argument];
            //2 Skupina
            case SendContentType.SUBCONTRACTOR_RESERVATION_REMINDER:
            case SendContentType.REMAINDER:
            case SendContentType.TELEMEDICINE:
                return text[1][argument];
            //3 Skupina
            case SendContentType.SURVEY:
                return text[2][argument];
        }
    }
}
