import { Component, EventEmitter, Inject, InjectionToken, OnInit, Output } from '@angular/core';
// TODO Ignored with eslint-interactive on 2023-11-10
// eslint-disable-next-line @typescript-eslint/ban-types
export const PORTAL_DATA = new InjectionToken<{}>('PortalData');
@Component({
    selector: 'app-helper-wizard-popup',
    templateUrl: './helper-wizard-popup.component.html',
    styleUrls: ['./helper-wizard-popup.component.scss'],
})
export class HelperWizardPopupComponent implements OnInit {
    constructor(@Inject(PORTAL_DATA) public data) {}
    @Output() closeClick = new EventEmitter<boolean>();
    public dontShowAgain = false;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit() {}

    close() {
        this.closeClick.emit(this.dontShowAgain);
    }
}
