import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import _ from 'lodash';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ParamsDataService {
    constructor(
        private activeRoute: ActivatedRoute,
        private location: Location,
    ) {}

    public patchParamsData(inputParamsArray: { key: string; value: string }[]) {
        //UPDATE obstoječe parametre
        //ADD neobstoječe parametre
        //primer: inputParamsArray = [{key: 'status', value='OPEN'}, {key: 'test', value: '123}] -> currentURL?status=OPEN&test=123
        const currentParams = this.location.path()?.split('?')[1]
            ? this.location
                  .path()
                  ?.split('?')[1]
                  .split('&')
                  ?.map(element => {
                      return { key: element.split('=')[0], value: element.split('=')[1] };
                  })
            : [];
        const newParams = _.cloneDeep(currentParams);
        let newURL = '';

        inputParamsArray?.forEach((param: { key: string; value: string }) => {
            const existingParamIndex = currentParams?.findIndex(element => element.key === param.key);
            if (existingParamIndex !== -1) {
                //parameter že obstaja --> update
                newParams[existingParamIndex] = param;
            } else {
                //parameter še ne obstaja -> dodaj
                newParams.push(param);
            }
        });

        newParams.forEach((parameter, index) => {
            //build nov URL
            if (index) {
                newURL += `&${parameter.key}=${parameter.value}`;
            } else {
                newURL += `?${parameter.key}=${parameter.value}`;
            }
        });
        this.location.go(this.location.path()?.split('?')[0], newURL);
    }

    public setParamsData(inputParamsArray: { key: string; value: string }[]) {
        //OVERRIDE obstoječe parametre z novimi
        //če je inputParamsArray = [] --> počisti vse parametre
        let newURL = '';
        inputParamsArray.forEach((param: { key: string; value: string }, index) => {
            if (index) {
                newURL += `&${param.key}=${param.value}`;
            } else {
                newURL += `?${param.key}=${param.value}`;
            }
        });
        this.location.go(this.location.path()?.split('?')[0], newURL);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public getParamsData(): Observable<any> {
        //GET data from parameters in URL
        return this.activeRoute.queryParams;
    }
}
