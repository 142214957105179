import { Component, Input, OnInit } from '@angular/core';
// models
import { Business } from '../../../_shared/model/business.model';
// services
import { HelperService } from '../../../_shared/services';

@Component({
    selector: 'common-business-subject-icon',
    templateUrl: './business-subject-icon.component.html',
    styles: [],
})
export class BusinessSubjectIconComponent implements OnInit {
    @Input() set setBsId(value) {
        if (value) {
            this.bs = value;
            this.loadIcon();
        }
    }
    public bs;

    @Input()
    public size = 24;

    @Input()
    public onlyIcon = false;

    partner: Business;

    constructor(private helper: HelperService) {}

    public loadIcon() {
        this.partner = this.helper.getBusiness(this.bs);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit() {}
}
