import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'documentSouceName',
})
export class DocumentSoucePipe implements PipeTransform {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    transform(type: any, args: any = 'text'): any {
        const allData = {
            text: {
                RESERVATION: 'Rezervacija',
                VISIT: 'Obravnava',
                CUSTOMER: 'Stranka',
                CASE: 'Avtorizacija',
                TRIAGE: 'eTriaža',
            },
            icon: {
                RESERVATION: 'la la-calendar',
                VISIT: 'la la-calendar-check-o',
                CUSTOMER: 'la la-user',
                CASE: 'la la-stethoscope',
                TRIAGE: 'la la-search',
            },
        };
        return allData[args][type];

        // switch (type) {
        //     case DocumentSource.RESERVATION:
        //         return 'Rezervacija';
        //     case DocumentSource.VISIT:
        //         return 'Obravnava';
        //     case DocumentSource.CUSTOMER:
        //         return 'Stranka';
        //     case DocumentSource.CASE:
        //         return 'Avtorizacija';
        //     case DocumentSource.TRIAGE:
        //         return 'eTriaža';

        //     default:
        //         return '';
        // }
    }
}
