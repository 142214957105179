import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { SendInvoiceNotificationInfo } from './../../../_shared/model/accounting/invoice/sendInvoiceNotificationInfo.model';

@Component({
    selector: 'app-send-popup',
    templateUrl: './send-popup.component.html',
    styles: [],
})
export class SendPopupComponent implements OnInit {
    public sendInvoiceForm: FormGroup;
    public sendPayerInvoiceForm: FormGroup;
    @Input()
    receiver: string;

    @Input()
    emailTo: string;

    @Input()
    smsTo: string;

    @Input()
    emailToPayer: string;

    @Input()
    smsToPayer: string;

    public sendToCustomer = 1; //1 -> send, 0 -> dont send
    public sendToPayer = 1; //1 -> send, 0 -> dont send
    public isSubmited = false;

    constructor(
        private fb: FormBuilder,
        public activeModal: NgbActiveModal,
    ) {}

    ngOnInit() {
        this.createForm();
        if (this.emailToPayer || this.smsToPayer) {
            this.createPayerForm();
        }
    }
    public radioChanged(radioType: string | 'customer' | 'payer') {
        //update validatorje ko obklukaš/odklukaš customer ali payer
        if (radioType === 'customer') {
            if (this.sendToCustomer) {
                this.sendToCustomer = 0;
                this.sendInvoiceForm.get('emailTo').clearValidators();
                this.sendInvoiceForm.get('emailTo').updateValueAndValidity();
                this.sendInvoiceForm.get('receiver').clearValidators();
                this.sendInvoiceForm.get('receiver').updateValueAndValidity();
                this.sendInvoiceForm.get('phoneTo').clearValidators();
                this.sendInvoiceForm.get('phoneTo').updateValueAndValidity();
            } else {
                this.sendToCustomer = 1;
                if (!_.isNil(this.receiver)) {
                    this.sendInvoiceForm.get('receiver').setValidators([Validators.required]);
                    this.sendInvoiceForm.get('receiver').updateValueAndValidity();
                }
                this.sendInvoiceForm.get('emailTo').setValidators([Validators.email, Validators.required]);
                this.sendInvoiceForm.get('emailTo').updateValueAndValidity();
                this.sendInvoiceForm.get('phoneTo').setValidators([Validators.required]);
                this.sendInvoiceForm.get('phoneTo').updateValueAndValidity();
            }
        } else {
            if (this.sendToPayer) {
                this.sendToPayer = 0;
                this.sendPayerInvoiceForm.get('emailTo').clearValidators();
                this.sendPayerInvoiceForm.get('emailTo').updateValueAndValidity();
                this.sendPayerInvoiceForm.get('phoneTo').clearValidators();
                this.sendPayerInvoiceForm.get('phoneTo').updateValueAndValidity();
            } else {
                this.sendToPayer = 1;
                this.sendPayerInvoiceForm.get('emailTo').setValidators([Validators.email, Validators.required]);
                this.sendPayerInvoiceForm.get('emailTo').updateValueAndValidity();
                this.sendPayerInvoiceForm.get('phoneTo').setValidators([Validators.required]);
                this.sendPayerInvoiceForm.get('phoneTo').updateValueAndValidity();
            }
        }
    }
    public createForm(): void {
        //form za vnos stranke
        this.sendInvoiceForm = this.fb.group({
            receiver: new FormControl({ value: this.receiver, disabled: false }, [Validators.required]),
            emailTo: new FormControl({ value: this.emailTo, disabled: false }, [Validators.email, Validators.required]),
            phoneTo: new FormControl({ value: this.smsTo, disabled: false }, [Validators.required]),
            comment: '',
        });
        if (_.isNil(this.receiver)) {
            this.sendInvoiceForm.controls['receiver'].clearValidators();
            this.sendInvoiceForm.updateValueAndValidity();
        }
    }
    public createPayerForm(): void {
        //form za vnos plačnika
        this.sendPayerInvoiceForm = this.fb.group({
            emailTo: new FormControl({ value: this.emailToPayer, disabled: false }, [Validators.email, Validators.required]),
            phoneTo: new FormControl({ value: this.smsToPayer, disabled: false }, [Validators.required]),
        });
    }

    public onSubmitSendInvoice(): void {
        if (this.smsToPayer || this.emailToPayer) {
            //stranka + plačnik form
            const sendInformationArray = [];
            if (
                this.sendInvoiceForm.valid &&
                !_.get(this.sendInvoiceForm, 'value.phoneTo', '').includes('_') &&
                this.sendPayerInvoiceForm.valid &&
                !_.get(this.sendPayerInvoiceForm, 'value.phoneTo', '').includes('_')
            ) {
                if (this.sendToCustomer) {
                    sendInformationArray.push(
                        new SendInvoiceNotificationInfo().deserialize({
                            receiver: this.sendInvoiceForm.value.receiver,
                            phone: this.sendInvoiceForm.value.phoneTo.replace(/\s/g, ''),
                            email: this.sendInvoiceForm.value.emailTo,
                        }),
                    );
                }
                if (this.sendToPayer) {
                    sendInformationArray.push(
                        new SendInvoiceNotificationInfo().deserialize({
                            phone: this.sendPayerInvoiceForm.value.phoneTo.replace(/\s/g, ''),
                            email: this.sendPayerInvoiceForm.value.emailTo,
                        }),
                    );
                }
                this.activeModal.close(sendInformationArray);
            }
        } else {
            //stranka form
            if (this.sendInvoiceForm.valid && !_.get(this.sendInvoiceForm, 'value.phoneTo', '').includes('_')) {
                this.activeModal.close(
                    new SendInvoiceNotificationInfo().deserialize({
                        receiver: this.sendInvoiceForm.value.receiver,
                        phone: this.sendInvoiceForm.value.phoneTo.replace(/\s/g, ''),
                        email: this.sendInvoiceForm.value.emailTo,
                    }),
                );
            }
        }
    }
}
