import { Serializable } from '../deserialize.service';

export class ActionButton implements Serializable<ActionButton> {
    title: string;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: any;
    function: string;
    buttonStyle: string;
    disabled: boolean;

    deserialize(input) {
        if (!input || input === null) {
            return null;
        }

        Object.keys(input).forEach(key => (this[key] = input[key]));

        return this;
    }
}
