import * as moment from 'moment';
import { RecurringType } from './../../enums/recurringType.enum';
import { Asset } from './../asset.model';
import { Serializable } from './../deserialize.service';
import { Service } from './../service.model';

export class CreateTreatmentPrereservationsRequest implements Serializable<CreateTreatmentPrereservationsRequest> {
    from: moment.Moment;
    to: moment.Moment;
    recurringType: RecurringType;
    service: Service;
    asset: Asset;
    draft: boolean;
    numOfPrereservations: number;
    excludePrereservationId: string;
    includeStartDate: boolean;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        return Object.assign(this, input);
    }
}
