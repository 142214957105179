import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppModulesType } from '../../../../_shared/enums/appModulesType.enum';
import { StorageService } from '../../../../_shared/services';
import { ActiveModulesService } from '../../../../_shared/services/activeModules.service';
import { CallStateEnum } from '../call-state.enum';
import { TelemedicineService } from '../telemedicine.service';

@Component({
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'telemedicine',
    templateUrl: './telemedicine.component.html',
    styleUrls: ['./telemedicine.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TelemedicineComponent implements OnInit {
    public callState: CallStateEnum = CallStateEnum.NONE;
    public callStates = CallStateEnum;
    public windowSize = 'modal';
    public windowPosition = 'bottom-right';
    public isTelemedicineModuleActive = false;
    public termData;

    constructor(
        private telemedService: TelemedicineService,
        private am: ActiveModulesService,
        private storage: StorageService,
    ) {}

    ngOnInit() {
        this.termData = this.storage.getSelectedTerm();
        this.isTelemedicineModuleActive = this.am.isAM(AppModulesType.TELEMEDICINE);
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.am.getAMOnChange().subscribe(modules => {
            this.isTelemedicineModuleActive = this.am.isAM(AppModulesType.TELEMEDICINE);
        });

        this.telemedService.getCallState().subscribe(callState => {
            this.callState = callState;
        });

        this.telemedService.getWindowSize().subscribe(size => {
            this.windowSize = size;
        });

        this.telemedService.getWindowPosition().subscribe((position: string) => {
            this.windowPosition = position;
        });
    }
}
