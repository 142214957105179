import {
    HttpErrorResponse,
    HttpHandler,
    HttpHeaderResponse,
    HttpInterceptor,
    HttpProgressEvent,
    HttpRequest,
    HttpResponse,
    HttpSentEvent,
    HttpUserEvent,
    HttpXsrfTokenExtractor,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { AuthenticationService } from './auth/_services';
import { Helpers } from './helpers';
import { HelperService, MfToastService, StorageService } from './_shared/services';
import { HttpClientService } from './_shared/services/http-client.service';

//https://github.com/IntertechInc/http-interceptor-refresh-token/tree/master/src/app
@Injectable({
    providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        private injector: Injector,
        private tokenExtractor: HttpXsrfTokenExtractor,
        private toast: MfToastService,
        private helper: HelperService,
    ) {}

    private router = this.injector.get(Router);

    private isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    intercept(
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        request: HttpRequest<any>,
        next: HttpHandler,
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {
        const storage = this.injector.get(StorageService);

        const token = _.get(storage.getToken(), 'access_token', undefined);
        return next.handle(this.addTokenToRequest(request, token, '')).pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    if (request.url === this.helper.getAuthUrl()) {
                        return throwError(err);
                    }

                    switch ((<HttpErrorResponse>err).status) {
                        case 401:
                            //TODO ni najlepsa resitev
                            if (_.get(<HttpErrorResponse>err, 'error.error_description', '').indexOf('Invalid refresh token') !== -1) {
                                this.router.navigate(['/login']);
                            }
                            return this.handle401Error(request, next);
                        case 400:
                            return throwError(err);
                        case 403:
                            return throwError(err);
                        case 429:
                            this.toast.warning('Preveč zahtevkov na sekundo.');
                            Helpers.setLoading(false);
                            return throwError('Preveč zahtevkov na sekundo.');
                        default:
                            return throwError(err);
                    }
                } else {
                    return throwError(err);
                }
            }),
        );
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars
    private addTokenToRequest(request: HttpRequest<any>, token: string, XSRFToken: string): HttpRequest<any> {
        let useToken = true;

        const isPublic: boolean = _.get(this.router, 'url', '').indexOf('public') !== -1;
        if (request.body && _.isString(request.body)) {
            if (_.get(request, 'body', '').indexOf('refresh_token') !== -1) {
                useToken = false;
            } else if (_.get(request, 'body', '').indexOf('username') !== -1) {
                useToken = false;
            } else if (request.url.indexOf('webdriver') !== -1) {
                return request.clone();
            }
        } else if (isPublic) {
            useToken = false;
        }
        if (!token) {
            useToken = false;
        }
        if (useToken) {
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line no-empty
            if (!token) {
            }
            return request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`,
                    'X-Flood': this.floodHeader(),
                },
            });
        } else {
            if (_.isNil(token) && !isPublic) {
                this.router.navigate(['/login']);
            }
            return request.clone({
                setHeaders: {
                    'X-Flood': this.floodHeader(),
                },
            });
        }
    }

    private floodHeader(): string {
        const storage = this.injector.get(StorageService);
        const helper = this.injector.get(HelperService);
        let floodId = storage.getFloodId();
        if (_.isNil(floodId)) {
            floodId = helper.uuid();
            storage.setFloodId(floodId);
        }
        return floodId;
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        const httpClientMF = this.injector.get(HttpClientService);
        const authService = this.injector.get(AuthenticationService);

        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            return httpClientMF.refreshToken().pipe(
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                switchMap((token: any) => {
                    const accessToken = _.get(token, 'access_token', undefined);
                    if (accessToken) {
                        this.tokenSubject.next(accessToken);
                        // localStorage.setItem('currentUser', JSON.stringify(user));
                        return next.handle(this.addTokenToRequest(request, accessToken, ''));
                    }

                    // TODO Ignored with eslint-interactive on 2023-11-10
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    return <any>authService.logout();
                }),
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                catchError(err => {
                    // TODO Ignored with eslint-interactive on 2023-11-10
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    return <any>authService.logout();
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                }),
            );
        } else {
            this.isRefreshingToken = false;

            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addTokenToRequest(request, token, ''));
                }),
            );
        }
    }
}
