import { Serializable } from './deserialize.service';
import { NotificationData } from './notificationData.model';

export class OneSignalNotification implements Serializable<OneSignalNotification> {
    //
    content: string;
    heading: string;
    icon: string;
    id: string;
    url: string;

    data: NotificationData;

    deserialize(input) {
        this.content = input.content;
        this.heading = input.heading;
        this.icon = input.icon;
        this.id = input.id;
        this.url = input.url;

        if (input.data) {
            this.data = new NotificationData().deserialize(input.data);
        }

        return this;
    }
}
