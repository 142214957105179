<form [formGroup]="wfForm" (ngSubmit)="onSubmit()" novalidate autocomplete="off">
    <div class="row mf-wr-wrapper">
        <div class="col-md col-xl">
            <!-- Naziv zdravnika -->
            <pf-p-wr-subcontractor *ngIf="subcontractor" [subcontracor]="subcontractor"></pf-p-wr-subcontractor>

            <!-- Zavarovana oseba -->
            <pf-p-wr-customer *ngIf="customer" [customer]="customer"></pf-p-wr-customer>

            <!-- Stopnja nujnosti -->
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">Stopnja nujnosti</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body">
                    <div class="m-radio-list">
                        <label class="m-radio m-radio--state-brand">
                            <input type="radio" formControlName="priorityLevel" value="1" />
                            Nujno
                            <span></span>
                        </label>
                        <label class="m-radio m-radio--state-brand">
                            <input type="radio" formControlName="priorityLevel" value="4" />
                            Zelo Hitro
                            <span></span>
                        </label>
                        <label class="m-radio m-radio--state-brand">
                            <input type="radio" formControlName="priorityLevel" value="2" />
                            Hitro
                            <span></span>
                        </label>
                        <label class="m-radio m-radio--state-brand">
                            <input type="radio" formControlName="priorityLevel" value="3" />
                            Redno
                            <span></span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">Prednostni kriterij</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body">
                    <div class="m-radio-list">
                        <label class="m-radio m-radio--state-brand">
                            <input type="radio" formControlName="priorityCriteria" value="1" />
                            Nosečnost
                            <span></span>
                        </label>
                        <label class="m-radio m-radio--state-brand">
                            <input type="radio" formControlName="priorityCriteria" value="2" />
                            Dojenje
                            <span></span>
                        </label>
                        <label class="m-radio m-radio--state-brand">
                            <input type="radio" formControlName="priorityCriteria" value="3" />
                            Preprečitev nastanka potreb po dodatnih zdravstvenih storitvah
                            <span></span>
                        </label>
                        <label class="m-radio m-radio--state-brand">
                            <input type="radio" formControlName="priorityCriteria" value="4" />
                            Ocena nezmožnosti za delo
                            <span></span>
                        </label>
                    </div>
                </div>
            </div>
            <!-- St. elektronske napotnice -->
        </div>
        <div class="col-md col-xl">
            <!-- Veljavnost -->
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">Veljavnost</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body">
                    <div class="m-radio-list">
                        <label class="m-radio m-radio--state-brand">
                            <input type="radio" formControlName="validity" value="1" />
                            Enkratno
                            <span></span>
                        </label>
                        <div class="d-flex">
                            <label class="m-radio m-radio--state-brand">
                                <input type="radio" formControlName="validity" value="2" />
                                Za obdobje
                                <span></span>
                            </label>
                            <div class="pl-1 pr-1">
                                <input
                                    formControlName="validityMonth"
                                    tabindex="3"
                                    type="text"
                                    autocomplete="off"
                                    style="width: 30px"
                                    class="form-control form-control-sm m-input"
                                />
                            </div>
                            <div>mesecev</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Obseg pooblastila -->
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">Obseg pooblastila</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body">
                    <div *ngFor="let item of customerNumberContainers.controls; let i = index" formArrayName="authorizationArray">
                        <div [formGroupName]="i" class="m-checkbox-list">
                            <label class="m-checkbox m-checkbox--state-brand">
                                <input type="checkbox" formControlName="item1" value="1" />
                                Pregled in mnenje
                                <span></span>
                            </label>
                            <label class="m-checkbox m-checkbox--state-brand">
                                <input type="checkbox" formControlName="item2" value="2" />
                                Zdravljenje osnovne bolezni/ poškodbe njenih posledic
                                <span></span>
                            </label>
                            <label class="m-checkbox m-checkbox--state-brand">
                                <input type="checkbox" formControlName="item3" value="3" />
                                Napotitev na zdravljenje k drugim specialistom in v bolnišnici
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Razlog obravnave-->
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">Razlog obravnave</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body">
                    <div class="m-radio-list">
                        <label class="m-radio m-radio--state-brand">
                            <input type="radio" formControlName="reasonOfTreatment" value="1" />
                            Bolezen
                            <span></span>
                        </label>
                        <label class="m-radio m-radio--state-brand">
                            <input type="radio" formControlName="reasonOfTreatment" value="2" />
                            Poškodba izven dela
                            <span></span>
                        </label>
                        <label class="m-radio m-radio--state-brand">
                            <input type="radio" formControlName="reasonOfTreatment" value="3" />
                            Poklicna bolezen
                            <span></span>
                        </label>
                        <label class="m-radio m-radio--state-brand">
                            <input type="radio" formControlName="reasonOfTreatment" value="4" />
                            Poškodba pri delu
                            <span></span>
                        </label>
                        <label class="m-radio m-radio--state-brand">
                            <input type="radio" formControlName="reasonOfTreatment" value="5" />
                            Poškodba izven dela po tretji osebi
                            <span></span>
                        </label>
                        <label class="m-radio m-radio--state-brand">
                            <input type="radio" formControlName="reasonOfTreatment" value="7" />
                            Transplantacija
                            <span></span>
                        </label>
                    </div>
                </div>
            </div>

            <!-- Prednostni kriterij-->

            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">Št. elektronske napotnice</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body d-flex">
                    <span style="line-height: 32px" class="pr-2">
                        <i container="body" class="la la-i-cursor m--font-inverse-light"></i>
                    </span>
                    <input
                        formControlName="referralNumber"
                        tabindex="3"
                        type="text"
                        autocomplete="off"
                        class="form-control form-control-sm m-input"
                    />
                </div>
            </div>
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">Šifra izvajalca</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body d-flex">
                    <span style="line-height: 32px" class="pr-2">
                        <i container="body" class="la la-i-cursor m--font-inverse-light"></i>
                    </span>
                    <input formControlName="performerId" tabindex="3" type="text" autocomplete="off" class="form-control form-control-sm m-input" />
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md col-xl">
            <!-- MKB -->
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">RDP</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body d-flex mf-custom-type-ahead">
                    <span style="line-height: 32px" class="pr-2">
                        <div *ngIf="searchingRDP" style="height: 30px" class="m-loader m-loader--brand pr-4"></div>
                        <i *ngIf="!searchingRDP" class="la la-i-cursor m--font-inverse-light"></i>
                    </span>
                    <input
                        [ngbTypeahead]="searchRDP"
                        [resultTemplate]="rt"
                        [inputFormatter]="formatter"
                        formControlName="rdp"
                        tabindex="3"
                        type="text"
                        autocomplete="off"
                        class="form-control form-control-sm m-input mf-custom-type-ahead"
                    />
                    <ng-template #rt let-r="result" let-t="term">
                        <strong>{{ r.name }}</strong>
                        [{{ r.code }}]
                    </ng-template>
                </div>
            </div>

            <!-- Vrsta specialnosti napotnega zdravnika-->
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">Vrsta specialnosti napotnega zdravnika</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body d-flex">
                    <span style="line-height: 32px" class="pr-2">
                        <i class="la la-i-cursor m--font-inverse-light"></i>
                    </span>
                    <input
                        formControlName="doctorSpeciality"
                        tabindex="3"
                        type="text"
                        autocomplete="off"
                        class="form-control form-control-sm m-input"
                    />
                </div>
            </div>
        </div>

        <div class="col-md col-xl">
            <!-- MKB -->
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">MKB</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body d-flex mf-custom-type-ahead">
                    <span style="line-height: 32px" class="pr-2">
                        <div *ngIf="searchingMKB" style="height: 30px" class="m-loader m-loader--brand pr-4"></div>
                        <i *ngIf="!searchingMKB" container="body" class="la la-i-cursor m--font-inverse-light"></i>
                    </span>
                    <input
                        [ngbTypeahead]="searchMKB"
                        [resultTemplate]="rt"
                        [inputFormatter]="formatter"
                        formControlName="mkb"
                        tabindex="3"
                        type="text"
                        autocomplete="off"
                        class="form-control form-control-sm m-input mf-custom-type-ahead"
                    />
                    <ng-template #rt let-r="result" let-t="term">
                        <strong>{{ r.name }}</strong>
                        [{{ r.code }}]
                    </ng-template>
                </div>
            </div>

            <!-- Vrsta specialnosti napotnega zdravnika-->
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">Kraj in dne</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body d-flex">
                    <div class="d-flex w-100">
                        <span style="line-height: 32px" class="pr-2">
                            <i class="la la-map-marker m--font-inverse-light"></i>
                        </span>
                        <input
                            formControlName="referralPlace"
                            tabindex="3"
                            type="text"
                            autocomplete="off"
                            placeholder="Kraj"
                            class="form-control form-control-sm m-input"
                        />
                    </div>
                    <div class="d-flex w-100">
                        <span style="line-height: 32px" class="pr-2">
                            <i class="la la-calendar m--font-inverse-light"></i>
                        </span>
                        <input
                            [options]="calendarOptions"
                            (selected)="onDateChange($event)"
                            formControlName="referralDateString"
                            tabindex="3"
                            type="text"
                            autocomplete="off"
                            placeholder="Dne"
                            daterangepicker
                            class="form-control form-control-sm m-input"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md col-xl">
            <!-- referralText1 -->
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">1. Kratka opredelitev problema in specifično vprašanje:</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body">
                    <editor [init]="tmceSettings" formControlName="referralText1"></editor>
                </div>
            </div>

            <!-- referralText2 -->
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">2. Aktivni zdravstveni problem:</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body">
                    <editor [init]="tmceSettings" formControlName="referralText2"></editor>
                </div>
            </div>

            <!-- referralText3 -->
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">3. Ključne najdbe pri kliničnem pregledu:</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body">
                    <editor [init]="tmceSettings" formControlName="referralText3"></editor>
                </div>
            </div>

            <!-- referralText4 -->
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">4. Ključni nenormalni laboratorijski izvidi:</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body">
                    <editor [init]="tmceSettings" formControlName="referralText4"></editor>
                </div>
            </div>

            <!-- referralText5 -->
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">5. Opravljene diagnostične preiskave pred napotitvijo:</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body">
                    <editor [init]="tmceSettings" formControlName="referralText5"></editor>
                </div>
            </div>

            <!-- referralText6 -->
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">6. Potek dosedanjega zdravljenja:</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body mf-portlet-custome__body">
                    <editor [init]="tmceSettings" formControlName="referralText6"></editor>
                </div>
            </div>
        </div>
    </div>
</form>
