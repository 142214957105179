import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { mergeMap } from 'rxjs/operators';
import { Notification } from '../../../_shared/model/notification.model';
import { StorageService } from '../../../_shared/services';
import { HelperNotificationService } from '../../../_shared/services/helper-notification.service';
import { OneSignalService } from '../../../_shared/services/onesignal.service';
import { QuickSidebarService } from '../../../_shared/services/quickSidebar.service';
import { WebsocketActions } from '../../../_shared/state/websocket/websocket.actions';
import { NotificationService } from '../../pages/home/_services';

@Component({
    selector: 'app-quick-sidebar',
    templateUrl: './quick-sidebar.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./quick-sidebar.component.scss'],
})
export class QuickSidebarComponent implements OnInit {
    // public notificationsList: Notification[] = [];
    public notificationsListSlice: Notification[] = [];
    public isOpen = false;
    private indexFrom = 0;
    private indexTo = 10;

    constructor(
        private storage: StorageService,
        private quickSidebarService: QuickSidebarService,
        private notifRest: NotificationService,
        private helperNotification: HelperNotificationService,
        private oneSignal: OneSignalService,
        public store: Store,
    ) {}
    ngOnInit() {
        this.quickSidebarService.onChange.subscribe(data => {
            this.clearNotifications();
            this.isOpen = data.isOpen;
            if (this.isOpen) {
                this.loadHistorty();

                // this.notificationsList = data!.value;
                // this.notificationsListSlice = this.notificationsList.slice(0, this.sliceIndex);
            }
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.oneSignal.receivedNotifications.subscribe(notification => {
            this.clearNotifications();
            this.loadHistorty();
        });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-empty-function
    public messageRead(index: number): void {}

    public markAllAsRead(): void {
        this.notifRest
            .markAllNotificationsAsSeen(this.storage.getSelectedContractor().id)
            .pipe(mergeMap(() => this.store.dispatch(new WebsocketActions.Notifications.SetNewNotifications(0))))
            .subscribe(() => this.quickSidebarService.seenNotificationChange({ type: 'all' }));
        this.notificationsListSlice.map(el => (el.seen = true));
        this.helperNotification.setNotificationFavicon(false);
    }

    public closeSidebar(notify: boolean = false): void {
        if (notify) {
            this.quickSidebarService.seenNotificationChange({ type: 'one' });
        }
        this.quickSidebarService.toggle();
        this.clearNotifications();
    }
    public clearNotifications(): void {
        this.notificationsListSlice = [];
        // this.notificationsList = [];
        this.indexFrom = 0;
        this.indexTo = 10;
    }

    public loadNext(): void {
        this.indexFrom += 10;
        this.indexTo += 10;
        this.loadHistorty();
    }

    public loadHistorty(): void {
        this.notifRest.getNotificationsItems(this.storage.getSelectedContractor().id, this.indexFrom, this.indexTo).subscribe(res => {
            this.notificationsListSlice.push(
                ...(res.map(oldNotification => {
                    return this.helperNotification.returnNotificationReadyForView(oldNotification);
                }) || []),
            );
        });
    }
}
