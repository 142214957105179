<div class="m-portlet">
    <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
                <h3 class="m-portlet__head-text">
                    {{ data.title }}
                </h3>
            </div>
        </div>
    </div>
    <div class="m-portlet__body mf-portlet-custome__body">
        <editor [(ngModel)]="data.content" [init]="tmceSettings"></editor>
    </div>
</div>
