// services
import { Business } from '../business.model';
// models
import { Comment } from '../comment.model';
import { Contractor } from '../contractor.model';
import { Customer } from '../customer.model';
import { Serializable } from '../deserialize.service';
import { AuthorityCase } from './authorityCase.model';

export class TriageResponse implements Serializable<TriageResponse> {
    id: number;
    appUserName: string;
    bsid: number;
    created: Date;
    caseAuthority: AuthorityCase;
    comments: Comment[];
    contractorComments: Comment[];
    contractor: Contractor;
    customer: Customer;
    status: string;
    serviceId: number;
    serviceName: string;
    price: number;
    business: Business;
    seen: boolean;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        if (input.id) {
            this.id = input.id;
            this.appUserName = input.appUserName;
            this.bsid = input.bsid;
            this.created = new Date(input.created);
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line no-self-assign
            this.comments = this.comments;
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line no-self-assign
            this.contractorComments = this.contractorComments;
            this.caseAuthority = new AuthorityCase().deserialize(input.caseAuthority);
            this.contractor = new Contractor().deserialize(input.contractor);
            this.customer = new Customer().deserialize(input.customer);
            this.status = input.status;
            this.serviceId = input.serviceId;
            this.serviceName = input.serviceName;
            this.price = input.price;
            this.business = new Business().deserialize(input.business);
            this.seen = input.seen;
            return this;
        }
    }
}
