import { Serializable } from '../deserialize.service';
import { Service } from '../service.model';
import { Asset } from './../asset.model';

export class ContractorHaloDoctorSettings implements Serializable<ContractorHaloDoctorSettings> {
    public selectedAsset: Asset;
    public selectedService: Service;
    public selectedServicePhoneCall: Service;
    public selectedBookingService: Service;

    deserialize(input) {
        this.selectedAsset = input.selectedAsset;
        this.selectedService = input.selectedService;
        this.selectedServicePhoneCall = input.selectedServicePhoneCall;
        this.selectedBookingService = input.selectedBookingService;
        return this;
    }
}
