<div [ngClass]="{ 'mf-admin-bg': formData?.isActive?.value }">
    <div [formGroup]="parent">
        <div formGroupName="customerAdministrator">
            <div class="m-list-search__result-item">
                <span class="m-list-search__result-item-icon">
                    <i ngbTooltip="Drug kontakt" class="la la-envelope-square m--font-inverse-light"></i>
                </span>
                <span class="m-list-search__result-item-text">
                    <label class="m-checkbox m-checkbox--brand my-2">
                        <input type="checkbox" formControlName="isActive" />
                        Drug kontakt
                        <i
                            ngbTooltip="Kontaktna oseba mi je posredovala pravno veljavno podlago, ki izkazuje skrbništvo nad stranko, po katerem je upravičena do prejema informacij v zvezi s to storitvijo, vključno z morebitnimi posebnimi vrstami osebnih podatkov, kot so npr. izvidi."
                            class="la la-exclamation-triangle"
                        ></i>
                        <span></span>
                    </label>
                </span>
            </div>
            <section *ngIf="formData['isActive']?.value" class="pb-4">
                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <i
                            ngbTooltip="Ime in priimek"
                            class="la la-user m--font-inverse-light"
                            i18n-ngbTooltip="@@fa106098f4b22df6f1678e36cb9c9c8683f31072"
                        ></i>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <input
                            formControlName="name"
                            type="text"
                            placeholder="Ime"
                            class="form-control m-input capitalize"
                            i18n-placeholder="@@4f8e8a32a405e7b6b267c8a3aebc6086c0af6f57"
                        />
                    </span>
                </div>
                <div *ngIf="formData['name'].errors && isSubmited" class="m--font-danger pl-5">
                    <small [hidden]="!formData['name'].errors.required" i18n="@@d893942a3663a86744e2d9730fa1f2e7f7653fc6">
                        Ime je obvezno polje.
                    </small>
                </div>

                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon"></span>
                    <span class="m-list-search__result-item-text">
                        <input
                            formControlName="surname"
                            type="text"
                            placeholder="Priimek"
                            class="form-control m-input capitalize"
                            i18n-placeholder="@@359f4a90716983d72c0eea56dc3c4eb3b1adf76e"
                        />
                    </span>
                </div>
                <div *ngIf="formData['surname'].errors && isSubmited" class="m--font-danger pl-5">
                    <small [hidden]="!formData['surname'].errors.required" i18n="@@4509d3738b445020a6dcc819215e33ebb0035967">
                        Priimek je obvezno polje.
                    </small>
                </div>
                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <span
                            ngbTooltip="Elektronska pošta"
                            class="la la-envelope m--font-inverse-light"
                            i18n-ngbTooltip="@@b1fb7bf9ae1c705e5eb894408a74181f7c52a649"
                        ></span>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <input
                            formControlName="email"
                            type="text"
                            placeholder="Email"
                            class="form-control m-input"
                            i18n-placeholder="@@244aae9346da82b0922506c2d2581373a15641cc"
                        />
                    </span>
                </div>
                <div class="m-list-search__result-item">
                    <span class="m-list-search__result-item-icon">
                        <span
                            [tphCallNumber]="formData['phone'].value"
                            [isCompact]="true"
                            ngbTooltip="Kontaktni telefon"
                            class="la la-phone m--font-inverse-light"
                            i18n-ngbTooltip="@@457040b8a75dd15c92036f0aa4def4a50a453311"
                        ></span>
                    </span>
                    <span class="m-list-search__result-item-text">
                        <input
                            formControlName="phone"
                            type="text"
                            placeholder="Telefon"
                            class="form-control m-input"
                            i18n-placeholder="@@cbbc5fc52b2dbad2f6313f78cd491475b7df01a9"
                        />
                    </span>
                </div>
                <div *ngIf="formData['phone'].errors && isSubmited" class="m--font-danger pl-5">
                    <small [hidden]="!formData['phone'].errors.required" i18n="@@717b944dd0c817285308b7a5e0e8d613873a9e7c">
                        Telefon je obvezno polje.
                    </small>
                    <small [hidden]="!formData['phone'].errors.minlength">Dolžina telefonske številke je prekratka. Vnesite vsaj 9 števil.</small>
                </div>
            </section>
        </div>
    </div>
</div>
