import * as _ from 'lodash';
import { Serializable } from '../../deserialize.service';
import { InvoiceDetails } from './invoiceDetails.model';

export class InvoiceMain implements Serializable<InvoiceMain> {
    cashierName: string;
    companyInfo: string;
    printCopy: string;
    userId: string;
    invoiceDetails: InvoiceDetails;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        if (_.isNil(input)) {
            return undefined;
        }

        this.cashierName = input.cashierName;
        this.companyInfo = input.companyInfo;
        this.printCopy = input.printCopy;
        this.userId = input.userId;
        if (_.has(input, 'invoiceDetails')) {
            this.invoiceDetails = new InvoiceDetails().deserialize(input.invoiceDetails);
        }
        return this;
    }
}
