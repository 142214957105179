export enum AuthorizationType {
    // working
    CASE_CREATED = 'CASE_CREATED',
    PRERESERVATION_CREATED = 'PRERESERVATION_CREATED',
    // working
    RESERVATION_CREATED = 'RESERVATION_CREATED',
    // working
    RESERVATION_CANCELED = 'RESERVATION_CANCELED',
    // working
    WQ_ITEM_CREATED = 'WQ_ITEM_CREATED',
    WQ_SEPARATED_ITEM_CREATED = 'WQ_SEPARATED_ITEM_CREATED',
    // working
    RESERVATION_UPDATED = 'RESERVATION_UPDATED',
    // working
    VISIT_CONCLUDED = 'VISIT_CONCLUDED',
    TREATMENT_CONCLUDED = 'TREATMENT_CONCLUDED',
    // working
    CASE_CANCELED = 'CASE_CANCELED',
    // working
    VISIT_CREATED = 'VISIT_CREATED',
    NOSHOW = 'NOSHOW',
    TRIAGE_CREATED = 'TRIAGE_CREATED',
    TRIAGE_CONCLUDED = 'TRIAGE_CONCLUDED',
    TRIAGE_CANCELED = 'TRIAGE_CANCELED',
    TRIAGE_REJECTED = 'TRIAGE_REJECTED',
    TRIAGE_ANSWERED = 'TRIAGE_ANSWERED',
    TRIAGE_REQUEST_SENT = 'TRIAGE_REQUEST_SENT',
    RESERVATION_CONNECTED = 'RESERVATION_CONNECTED',
    RESERVATION_DRAFT_CREATED = 'RESERVATION_DRAFT_CREATED',
    COVERAGE_PRICE_UPDATED = 'COVERAGE_PRICE_UPDATED',
    SECOND_OPINION_CREATED = 'SECOND_OPINION_CREATED',

    PUBLIC_HEALTH_CREATED = 'PUBLIC_HEALTH_CREATED',
    CASE_SURVEY_CREATED = 'CASE_SURVEY_CREATED',
    CASE_SURVEY_ANSWERED = 'CASE_SURVEY_ANSWERED',

    //Event
    EVENT_CREATED = 'EVENT_CREATED',
    EVENT_UPDATED = 'EVENT_UPDATED',
    POLICYHOLDER_REMOVE = 'POLICYHOLDER_REMOVE',
    POLICYHOLDER_ADD = 'POLICYHOLDER_ADD',

    EVENT_MSG_UPDATED = 'EVENT_MSG_UPDATED',
}

// visit - obravanva
// case avtorizacija
// rezervacija v ČV -> WQ
