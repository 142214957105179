import * as _ from 'lodash';
import { PaymentType } from '../../../enums/accounting/paymentType.enum';
import { Serializable } from '../../deserialize.service';

export class IrPaymentTypeInfo implements Serializable<IrPaymentTypeInfo> {
    paymentType: PaymentType;
    amount: number;
    paymentTypeName: string;
    id: string;
    couponId?: number;
    couponLabel: string;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        if (_.isNil(input)) {
            return undefined;
        }

        this.paymentType = input.paymentType;
        this.amount = input.amount;
        this.paymentTypeName = input.paymentTypeName;
        this.id = input.id;
        this.couponId = input.couponId;
        this.couponLabel = input.couponLabel;
        return this;
    }
}
