import { DatePipe } from '@angular/common';
// import swal from 'sweetalert2';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import _ from 'lodash';
import { DocumentSource } from '../../../../_shared/enums/documentSource.enum';
import { FilePreviewType } from '../../../../_shared/enums/filePreviewType.enum';
import { OriginUrl } from '../../../../_shared/enums/originUrl.enum';
import { ReservationType } from '../../../../_shared/enums/schedule/reservationType.enum';
import { Document } from '../../../../_shared/model';
import { HelperService } from '../../../../_shared/services';
import { HelperNavigateService } from '../../../../_shared/services/helper-navigate.service';
import { StorageService } from '../../../../_shared/services/storage.service';
import { DocumentsService } from '../../../pages/home/_services/documents.service';

// import * as FileSaver from 'file-saver';

@Component({
    selector: 'common-document-list-element',
    templateUrl: './document-list-element.component.html',
    styleUrls: ['./document-list-element.component.scss'],
})
export class DocumentListElementComponent implements OnInit {
    public reservationType: typeof ReservationType = ReservationType;
    public originUrl: typeof OriginUrl = OriginUrl;
    @Input()
    public document: Document;

    @Input()
    public isReadOnly = false;

    @Input()
    public showCreationLocation = false;

    @Input()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public termData: any;

    @Input()
    public customClass: string = null;

    public shareBtn = true;

    @Input()
    set setShareBtn(hideButton: boolean) {
        this.shareBtn = hideButton;
    }

    @Output()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onDelete: EventEmitter<Document>;

    @Output()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onOpenDocument: EventEmitter<Document>;

    @Output()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onDownloadDocument: EventEmitter<Document>;

    @Output()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onShareDocument: EventEmitter<Document>;

    @Output()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    public onStopShareDocument: EventEmitter<Document> = new EventEmitter();

    @Output()
    public attachToHD: EventEmitter<Document>;

    @ViewChild('thumbnailPhoto', { static: true })
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    thumbnailPhoto: any;

    @Input()
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    set setThumbnail(thumbnail: any) {
        if (!thumbnail) {
            return;
        }
        this.thumbnailMode = _.get(thumbnail, 'thumbnailMode', false);
        this.thumbnailStyle = thumbnail.customerDocumentViewType;
        //set Thumbnail size
        if (this.document.contentType.includes('image') || (this.document.contentType.includes('video') && this.thumbnailMode)) {
            const photoSize = {
                height: this.thumbnailStyle === FilePreviewType.LARGE ? 130 : 75,
                width: this.thumbnailStyle === FilePreviewType.LARGE ? 170 : 100,
            };
            this.docuRest
                .getDocumentThumbnails(this.storage.getSelectedContractor().id, this.document.documentId, undefined, photoSize.width)
                .subscribe(el => {
                    if (el.body) {
                        this.thumbnailPhoto.nativeElement.src = URL.createObjectURL(el.body);
                        this.loaded = true;
                    }
                });
        } else {
            this.thumbnailPhoto.nativeElement.src = this.getIconType();
            this.loaded = true;
        }
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/ban-types
    styleThumbnail(elementName: string): Object {
        if (this.thumbnailStyle === 'list') {
            return;
        } else {
            if (elementName === 'photo') {
                if (this.thumbnailStyle === FilePreviewType.LARGE) {
                    return { height: '120px', width: '125px' };
                } else if (this.thumbnailStyle === FilePreviewType.SMALL) {
                    return { height: '75px', width: '80px' };
                }
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line no-constant-condition
            } else if ((elementName = 'div')) {
                if (this.thumbnailStyle === FilePreviewType.LARGE) {
                    return { width: '110px' };
                } else if (this.thumbnailStyle === FilePreviewType.SMALL) {
                    return { width: '60px' };
                }
            }
        }
    }

    constructor(
        private helper: HelperService,
        private helperNavigate: HelperNavigateService,
        private docuRest: DocumentsService,
        private storage: StorageService,
        private datePipe: DatePipe,
    ) {
        this.onDelete = new EventEmitter();
        this.onOpenDocument = new EventEmitter();
        this.onDownloadDocument = new EventEmitter();
        this.onShareDocument = new EventEmitter();
        this.attachToHD = new EventEmitter();
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public thumbnailStyle: string;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public thumbnailMode = false;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public loaded = false;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    public ngOnInit() {}

    public deleteDocument(): void {
        this.onDelete.emit(this.document);
    }

    public openDocument(): void {
        this.onOpenDocument.emit(this.document);
    }

    public downloadDocument(): void {
        this.onDownloadDocument.emit(this.document);
    }

    public attachToHdChat(): void {
        this.attachToHD.emit(this.document);
    }

    public getIconType(): string {
        return this.helper.getIconType(this.document.contentType);
    }
    public openSource(document: Document): void {
        if (document.documentSource === DocumentSource.RESERVATION) {
            this.helperNavigate.navigateToReservaton(document.prereservationId);
        } else if (document.documentSource === DocumentSource.VISIT) {
            this.helperNavigate.navigateToVisitPrereservation(document.prereservationId);
        }
    }

    public get tooltip(): string {
        const text = 'Stranka ima dostop do dokumenta na povezavi.';
        if (this.document?.lastPublicAccess) {
            return `${text} (Zadnji dostop stranke: ${this.datePipe.transform(this.document?.lastPublicAccess, 'dd.MM.yyyy HH:mm')})`;
        }
        return `${text} (Stranka še ni dostopala do dokumenta)`;
    }
}
