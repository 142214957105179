import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { AppModulesType } from './../../../../_shared/enums/appModulesType.enum';
import { ReservationType } from './../../../../_shared/enums/schedule/reservationType.enum';
import { Customer } from './../../../../_shared/model/customer.model';
import { PreReservation } from './../../../../_shared/model/preReservation.model';
import { User } from './../../../../_shared/model/user.model';
import { ActiveModulesService } from './../../../../_shared/services/activeModules.service';
import { HelperNavigateService } from './../../../../_shared/services/helper-navigate.service';
import { MfToastService } from './../../../../_shared/services/mfToast.service';
import { StorageService } from './../../../../_shared/services/storage.service';
import { BaseState } from './../../../../_shared/state/base/base.state';
import { CommonService } from './../_services/common.service';
import { ReservationService } from './../_services/reservation.service';
import { TermsService } from './../_services/terms.service';
import { VisitService } from './../_services/visit.service';
import { TphCallEnded } from './model/tph-call-ended.model';
import { TphIncomingCall } from './model/tph-incoming-call.model';
import { TelephonyService } from './telephony.service';

@Component({
    selector: 'telephony-main',
    templateUrl: './telephony.component.html',
    styles: [],
})
export class TelephonyComponent implements OnInit {
    public isThelephonyModule = false;
    public isWindowShown = true;
    public isUnregistered = false;
    public customer: Customer[];
    public contractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);
    public isHDmoduleActive = false;

    constructor(
        private activeModules: ActiveModulesService,
        private service: TelephonyService,
        private store: Store,
        private commonRest: CommonService,
        private navigateHelper: HelperNavigateService,
        private reservationRest: ReservationService,
        private visitRest: VisitService,
        private storage: StorageService,
        private helperNavigate: HelperNavigateService,
        private toast: MfToastService,
        private termsRest: TermsService,
    ) {}

    ngOnInit() {
        this.isHDmoduleActive = this.activeModules.isAM(AppModulesType.HELLO_DOCTOR);
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const user: User = this.store.selectSnapshot(BaseState.LoggedUser);
        //Začasno onemogočimo registracijo, ker se pokaže čez prijavljenega uporabnika -> prijavljen uporabnik (AC) je generični, v ea pa konkretni
        // this.service.isUserRegistered(this.contractorId, user.email).subscribe((isExistingUser: boolean) => {
        //     this.isUnregistered = !isExistingUser;
        //     if (isExistingUser) {
        //         this.service.initPhone();
        //     }
        // });
        this.service.initPhone();

        this.service.showWindow
            .pipe(
                mergeMap((phoneNumber: string) => {
                    if (!phoneNumber) {
                        return of(undefined);
                    }
                    // const searchNumber: string = phoneNumber.slice(4, phoneNumber.length);
                    return this.searchCustomer(phoneNumber);
                }),
            )
            .subscribe((customer: Customer[]) => {
                this.isWindowShown = true;
                this.customer = customer;
            });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.service.hiddeWindow.subscribe(x => {
            this.isWindowShown = false;
        });

        this.service.onIncomingCall
            .pipe(
                mergeMap((data: TphIncomingCall) => {
                    const searchNumber: string = '0' + data?.from.slice(4, data?.from.length);
                    return this.searchCustomer(searchNumber);
                }),
            )
            .subscribe((customer: Customer[]) => {
                this.customer = customer;
            });

        this.service.onCallEnded
            .pipe(
                mergeMap((data: TphCallEnded) => {
                    return this.service.saveCall(this.contractorId, data?.callId);
                }),
            )
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function
            .subscribe((x: any) => {});
    }

    private searchCustomer(searchNumber: string): Observable<Customer[]> {
        return this.commonRest.searchCustomers(searchNumber).pipe();
    }
    public closeWindow(): void {
        this.isWindowShown = false;
    }

    public onRegister(): void {
        this.isUnregistered = false;
        this.service.initPhone();
    }

    public openCustomer(customer: Customer): void {
        this.navigateHelper.openCustomer(customer.id);
    }

    public createNewReservation(customer: Customer): void {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const data: any = {
            contractor: {
                id: Number(this.storage.getSelectedContractor().id),
                subcontractors: [
                    {
                        id: this.storage.getUserData().subcontractorId,
                    },
                ],
            },
            from: moment(),
            //DEFAULT trajanje storitve 15min
            to: moment().add(15, 'minutes'),
            visitTime: moment().toDate(),
            service: {
                id: this.storage.getContractorSettings()?.haloDoctor?.selectedService?.id,
                name: this.storage.getContractorSettings()?.haloDoctor?.selectedService?.name,
            },
            services: [
                //TODO spremeni storitev za tel. klic
                {
                    id: this.storage.getContractorSettings()?.haloDoctor?.selectedService?.id,
                    name: this.storage.getContractorSettings()?.haloDoctor?.selectedService?.name,
                },
            ],
            asset: {
                name: this.storage.getContractorSettings()?.haloDoctor?.selectedAsset?.name,
                id: this.storage.getContractorSettings()?.haloDoctor?.selectedAsset?.id,
            },
            customer: customer,
            forceUpdate: true,
            telemed: true,
            reservationType: ReservationType.EA,
        };

        this.reservationRest
            .sendPreResevation(data)
            .pipe(
                mergeMap(res =>
                    this.reservationRest.sendResevation(res).pipe(
                        mergeMap(() => {
                            return this.visitRest.createVisitFromPrereservation(res.prereservationId, data.customer.id).pipe(
                                tap(visitId => {
                                    this.helperNavigate.navigateToVisit(visitId);
                                }),
                                catchError(() => {
                                    this.toast.error('Napaka pri kreiranju obravnave');
                                    return this.termsRest.cancelReservation(new PreReservation().deserialize(res));
                                }),
                            );
                        }),
                    ),
                ),
            )
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            .subscribe(() => {});
    }
}
