import * as moment from 'moment';
import { Serializable } from '../deserialize.service';
import { PriceListElement } from './priceListElement.model';

export class PriceList implements Serializable<PriceList> {
    id: number;
    listName: string;
    fromDate: moment.Moment;
    toDate: moment.Moment;
    priceList: PriceListElement[];
    confirmed: boolean;
    active: boolean;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        this.id = input.id;
        this.listName = input.listName;
        this.fromDate = moment(input.fromDate);
        this.toDate = moment(input.toDate);
        if (input.priceList) {
            this.priceList = input.priceList.map((el: PriceListElement) => new PriceListElement().deserialize(el));
        }
        this.confirmed = input.confirmed;
        this.active = input.active;
        return this;
    }
}
