import { Serializable } from '../deserialize.service';

export class PrinterConfigGuiSettings implements Serializable<PrinterConfigGuiSettings> {
    isPOSPrinterActive = false;
    paperPOSType = 1;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        if (!input || input === null) {
            return null;
        }
        this.isPOSPrinterActive = input.isPOSPrinterActive;
        this.paperPOSType = input.paperPOSType;
        return this;
    }
}
