import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import {
    CalendarFilterSettings,
    CalendarUserGuiSettings,
    Contractor,
    ContractorSettings,
    GuiUserContractorSettings,
    ResultUserGuiSettings,
} from '../../../../_shared/model';
import { HelperService, StorageService } from '../../../../_shared/services';
import { HttpClientService } from '../../../../_shared/services/http-client.service';
import { HdServciesSettings } from './../../../../_shared/model/settings/hdServciesSettings.model';
import { GuiUserContractorHd } from './../../../../_shared/model/userGuiSettings/guiUserContractorHd.model';
import { GuiUserSettings } from './../../../../_shared/model/userGuiSettings/guiUserSettings.model';
import { GlobalSettings } from './../../../../_shared/model/utils/global-settings.model';
import { SetContractorSettings } from './../../../../_shared/state/base/base.actions';

@Injectable({
    providedIn: 'root',
})
export class ConfigGUIService {
    constructor(
        private storage: StorageService,
        private helper: HelperService,
        private httpClient: HttpClientService,
        private store: Store,
    ) {}

    public onToggleMenu: Subject<boolean> = new Subject();

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateUserGuiAppSettings(cid: number, termsAndConditions: any): boolean {
        let data: GuiUserContractorSettings = this.storage.getGuiUserSettings();
        if (data === null && !data) {
            data = new GuiUserContractorSettings();
        }
        data.app.termsAndConditions = termsAndConditions;
        return this.updateUserContractorGuiSettings(cid, data);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public updateUserGuiAppSettingsHD(cid: number, param: string, value: any): boolean {
        let data: GuiUserContractorSettings = this.storage.getGuiUserSettings();
        if (data === null && !data) {
            data = new GuiUserContractorSettings();
        }
        if (data.haloDoctor == undefined) {
            data.haloDoctor = new GuiUserContractorHd();
        }
        data.haloDoctor[param] = value;
        return this.updateUserContractorGuiSettings(cid, data);
    }

    public updateUserGuiAppSettingsNewRelease(cid: number, newReleaseVersion: string): boolean {
        let data: GuiUserContractorSettings = this.storage.getGuiUserSettings();
        if (data === null && !data) {
            data = new GuiUserContractorSettings();
        }
        data.app.newReleaseVersion = newReleaseVersion;
        return this.updateUserContractorGuiSettings(cid, data);
    }

    public updateUserGuiAppSettingsToggleMenu(cid: number): boolean {
        let data: GuiUserContractorSettings = this.storage.getGuiUserSettings();
        if (data === null && !data) {
            data = new GuiUserContractorSettings();
        }
        _.set(data, 'app.isMenuMinimized', !_.get(data, 'app.isMenuMinimized', true));
        // tukaj je treba takoj shranit
        this.storage.setGuiUserContractorSettings(data);
        this.onToggleMenu.next(_.get(data, 'app.isMenuMinimized', true));
        return this.updateUserContractorGuiSettings(cid, data);
    }

    public updateUserGuiPrinterPOSPaperType(cid: number, type: number): boolean {
        let data: GuiUserContractorSettings = this.storage.getGuiUserSettings();
        if (data === null && !data) {
            data = new GuiUserContractorSettings();
        }
        _.set(data, 'config.printer.paperPOSType', type);
        return this.updateUserContractorGuiSettings(cid, data);
    }

    public updateUserGuiPrinterPOSActive(cid: number, active: boolean, paperType: number): boolean {
        let data: GuiUserContractorSettings = this.storage.getGuiUserSettings();
        if (data === null && !data) {
            data = new GuiUserContractorSettings();
        }
        _.set(data, 'config.printer.isPOSPrinterActive', active);
        _.set(data, 'config.printer.paperPOSType', paperType);

        return this.updateUserContractorGuiSettings(cid, data);
    }

    public updateUserGuiCalendarFrequency(cid: number, timeFrequency: number): boolean {
        let data: GuiUserContractorSettings = this.storage.getGuiUserSettings();
        if (data === null && !data) {
            data = new GuiUserContractorSettings();
        }
        data.calendar.timeFrequency = timeFrequency;
        return this.updateUserContractorGuiSettings(cid, data);
    }

    public updateUserGuiCalendarFilter(cid: number, guiSettings: CalendarFilterSettings): boolean {
        let data: GuiUserContractorSettings = this.storage.getGuiUserSettings();
        if (data === null && !data) {
            data = new GuiUserContractorSettings();
        }
        data.calendar.filter = guiSettings;
        return this.updateUserContractorGuiSettings(cid, data);
    }

    public updateUserGuiCalendarSubcontractors(cid: number, guiSettings: Contractor[]): boolean {
        let data: GuiUserContractorSettings = this.storage.getGuiUserSettings();
        if (data === null && !data) {
            data = new GuiUserContractorSettings();
        }
        data.calendar.subconstractors = guiSettings;
        return this.updateUserContractorGuiSettings(cid, data);
    }

    public updateUserGuiCalendar(cid: number, guiSettings: CalendarUserGuiSettings): boolean {
        let data: GuiUserContractorSettings = this.storage.getGuiUserSettings();
        if (data === null && !data) {
            data = new GuiUserContractorSettings().deserialize({
                calendar: {},
            });
        }
        data.calendar = guiSettings;
        return this.updateUserContractorGuiSettings(cid, data);
    }

    public updateUserContractorGuiSettings(cid: number, guiSettings: GuiUserContractorSettings): boolean {
        let res = true;
        this.httpClient.put(this.helper.getApiUrl() + '/app/settings/' + cid, guiSettings).subscribe(
            () => {
                this.storage.setGuiUserContractorSettings(guiSettings);
                res = true;
            },
            () => {
                res = false;
            },
        );
        return res;
    }

    public updateUserGuiResult(cid: number, guiSettings: ResultUserGuiSettings): boolean {
        let data: GuiUserContractorSettings = this.storage.getGuiUserSettings();
        if (data === null && !data) {
            data = new GuiUserContractorSettings().deserialize({
                result: {},
            });
        }
        data.result = guiSettings;
        return this.updateUserContractorGuiSettings(cid, data);
    }

    public updateUserGuiSettings(guiSettings: GuiUserSettings): boolean {
        let res = true;
        this.httpClient.put(this.helper.getApiUrl() + `/app/settings/user`, guiSettings).subscribe(
            () => {
                this.storage.setUserGuiSettings(guiSettings);
                res = true;
            },
            () => {
                res = false;
            },
        );
        return res;
    }

    public getUserGuiSettings(): Observable<GuiUserSettings> {
        return this.httpClient.get(this.helper.getApiUrl() + `/app/settings/user`);
    }

    public getUserContractorGuiSettings(cid: number): Observable<GuiUserContractorSettings> {
        return this.httpClient.get(this.helper.getApiUrl() + `/app/settings/${cid}`);
    }

    public getGlobalSettings(): Observable<GlobalSettings> {
        return this.httpClient.get(this.helper.getApiUrl() + `/app/settings/`);
    }

    public getContractorGuiSettings(cid: number): Observable<ContractorSettings> {
        return this.httpClient.get(this.helper.getApiUrl() + `/app/settings/contractor/${cid}`);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public setContractorGuiSetting(cid: number, data: any): Observable<ContractorSettings> {
        this.storage.setContractorSettings(data);
        this.store.dispatch(new SetContractorSettings(data));

        return this.httpClient.put(this.helper.getApiUrl() + `/app/settings/contractor/${cid}`, data);
    }

    public setContractorHDBookingService(cid: number, data: HdServciesSettings): Observable<ContractorSettings> {
        return this.httpClient.post(this.helper.getApiUrl() + `/contractors/${cid}/hd/bookingservice `, data);
    }

    public getContractorHDBookingService(cid: number): Observable<HdServciesSettings> {
        return this.httpClient.get(this.helper.getApiUrl() + `/contractors/${cid}/hd/bookingservice `).pipe();
    }
}
