<a class="documentation__element text-center m-2">
    <div style="font-size: smaller; display: inline-block; min-width: 90px" class="mt-1">
        {{ paper.paperType | paperName: 'short' }}
    </div>
    <div class="d-flex justify-content-center m-1">
        <div class="d-flex align-items-center mr-2">
            <i
                ngbTooltip="{{ paper.shared ? 'Poslano' : 'Ni poslano' }}"
                class="la la-envelope"
                [ngClass]="{
                    'text-danger': !paper.shared,
                    'text-success': paper.shared
                }"
            ></i>
        </div>
        <div class="d-flex align-items-center">
            <i
                ngbTooltip="{{ paper.signedPaper ? 'Podpisano' : 'Ni podpisano' }}"
                class="la la-shield"
                [ngClass]="{
                    'text-danger': !paper.signedPaper,
                    'text-success': paper.signedPaper
                }"
            ></i>
        </div>
    </div>
</a>
