import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { Helpers } from '../../../helpers';
import { ScriptLoaderService } from '../../../_services/script-loader.service';
import { MenuService } from '../../../_shared/services/menu.service';
import { AppModulesType } from './../../../_shared/enums/appModulesType.enum';
import { ActionsService } from './../../../_shared/services/actions.service';
import { ActiveModulesService } from './../../../_shared/services/activeModules.service';
import { TelephonyService } from './../../pages/home/telephony/telephony.service';

// TODO Ignored with eslint-interactive on 2023-11-10
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let mApp: any;
// TODO Ignored with eslint-interactive on 2023-11-10
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let mUtil: any;
// TODO Ignored with eslint-interactive on 2023-11-10
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let mLayout: any;
@Component({
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    templateUrl: './theme.component.html',
    styleUrls: ['./theme.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ThemeComponent implements OnInit {
    public isFooterVisible = true;

    public isTelephonyModule: boolean = undefined;

    constructor(
        private _script: ScriptLoaderService,
        private _router: Router,
        private menu: MenuService,
        private telephony: TelephonyService,
        private activeModules: ActiveModulesService,
        private permissionsService: NgxPermissionsService,
        private actions: ActionsService,
    ) {}
    ngOnInit() {
        this.actions.onEndContractorChange.subscribe(() => {
            this.ngOnInit();
        });
        this.permissionsService.hasPermission('menu_main_telephony__Visible').then(res => {
            if (res) {
                this.telephony.initTelephony
                    // .pipe(takeUntil(this.onDestroy$))
                    // TODO Ignored with eslint-interactive on 2023-11-10
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .subscribe(x => {
                        this.isTelephonyModule = this.activeModules.isAM(AppModulesType.TELEMEDICINE);
                    });
                this.isTelephonyModule = this.activeModules.isAM(AppModulesType.TELEPHONY);
            }
        });

        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this._script.loadScripts('body', ['assets/vendors/base/vendors.bundle.js', 'assets/demo/demo5/base/scripts.bundle.js'], true).then(result => {
            Helpers.setLoading(false);
        });

        this._router.events.subscribe(route => {
            // this.menu.showDefaultMenu();
            if (route instanceof NavigationStart) {
                try {
                    // TODO Ignored with eslint-interactive on 2023-11-10
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (<any>mLayout).closeMobileAsideMenuOffcanvas();
                    // TODO Ignored with eslint-interactive on 2023-11-10
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (<any>mLayout).closeMobileHorMenuOffcanvas();
                    // TODO Ignored with eslint-interactive on 2023-11-10
                    // eslint-disable-next-line no-empty
                } catch (e) {}
                // (<any>mApp).scrollTop();
                Helpers.setLoading(true);
                // hide visible popover
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (<any>$('[data-toggle="m-popover"]')).popover('hide');
            }
            if (route instanceof NavigationEnd) {
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (<any>mApp).init();
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (<any>mUtil).init();
                Helpers.setLoading(false);
                // content m-wrapper animation
                const animation = 'm-animate-fade-in-up';
                $('.m-wrapper')
                    // TODO Ignored with eslint-interactive on 2023-11-10
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    .one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function (e) {
                        $('.m-wrapper').removeClass(animation);
                    })
                    .removeClass(animation)
                    .addClass(animation);
            }
        });
    }
}
