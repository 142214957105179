import * as moment from 'moment';
import { Serializable } from '../deserialize.service';

export class GenericReferralApi implements Serializable<GenericReferralApi> {
    //type
    public referralType: string;
    //form inputs
    public doctorSpeciality: string;
    public referralPlace: string;
    public referralDate: moment.Moment | Date;
    public reasonOfTreatment: string;
    //text inputs
    public referralText1: string;
    public referralText2: string;
    //naročilnice
    public orderDate: moment.Moment;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public services: any;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        this.referralType = input.referralType;
        this.reasonOfTreatment = input.reasonOfTreatment;
        this.doctorSpeciality = input.doctorSpeciality;
        this.referralPlace = input.referralPlace;
        if (input.referralDate) {
            this.referralDate = moment(input.referralDate);
        }
        if (input.orderDate) {
            this.orderDate = moment(input.orderDate);
        }
        this.referralText1 = input.referralText1;
        if (input.referralText2) {
            this.referralText2 = input.referralText2;
        }
        // referralText2  --> prestavljen na backend
        // this.referralText2 = '';
        // if (input.referralText2) {
        //     this.referralText2 += ` <p> ${input.referralText2} </p>`;
        // }
        // if (input.referralText3) {
        //     this.referralText2 += `<p> <b> Anamneza </b> \n ${input.referralText3} </p>`;
        // }
        // if (input.referralText4) {
        //     this.referralText2 += `<p> <b>  Status  </b> \n ${input.referralText4}  </p>`;
        // }
        // if (input.referralText5) {
        //     this.referralText2 += `<p> <b> Mnenje </b> \n ${input.referralText5} </p> `;
        // }
        // if (input.referralText6) {
        //     this.referralText2 += `<p> <b> Načrt nadaljnje obravnave </b> \n ${input.referralText6} </p>`;
        // }
        if (input.services) {
            this.services = input.services;
        }

        return this;
    }
}
