import { TreatmentReservationRequest } from '../../model/treatment/treatmentReservationRequest';
import { ReservationRequest } from './../../model/reservationRequest.model';
import { EmptySlot } from './../../model/schedule/empty-slot.model';
import { TreatmentNote } from './../../model/treatment/treatment-notes.model.';
import { TreatmentGUI } from './../../model/treatment/treatmentGUI.model';

export class ClearTreatmentState {
    public static readonly type = '[User] ClearTreatmentState';
}

// TODO Ignored with eslint-interactive on 2023-11-10
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace FormItemActions {
    export class UpdateFormItem {
        public static readonly type = '[FormItemActions] UpdateFormItem';
        constructor(
            public readonly internalId: string,
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            public readonly value: any,
        ) {}
    }
}
// TODO Ignored with eslint-interactive on 2023-11-10
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace TreatmentActions {
    export class CreateNewTreatment {
        public static readonly type = '[Treatment] CreateNewTreatment';
    }

    export class UpdateReservationsTreatment {
        public static readonly type = '[Treatment] UpdateReservationsTreatment';
    }

    export class UpdateNotesTreatment {
        public static readonly type = '[Treatment] UpdateNotesTreatment';
    }

    export class UpdateTreatment {
        public static readonly type = '[Treatment] UpdateTreatment';
        constructor(public readonly treatment: TreatmentGUI) {}
    }

    export class SetTreatment {
        public static readonly type = '[Term] SetTreatment';
        constructor(public readonly treatment: TreatmentGUI) {}
    }

    export class LoadTreatment {
        public static readonly type = '[Term] LoadTreatment';
        constructor(public readonly treatment: TreatmentGUI) {}
    }

    export class LoadTreatmentPortfolio {
        public static readonly type = '[Treatment] LoadTreatmentPortfolio';
        // constructor(public readonly treatment: TreatmentGUI) {}
    }
}

// TODO Ignored with eslint-interactive on 2023-11-10
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Notes {
    export class SetAllNotes {
        public static readonly type = '[Notes] SetAllNotest';
        constructor(public readonly notes: TreatmentNote[]) {}
    }

    export class ToggleDetails {
        public static readonly type = '[Notes] ToggleDetails';
        constructor(public readonly noteId: number) {}
    }

    export class RemoveNote {
        public static readonly type = '[Notes] RemoveNote';
        constructor(public readonly note: TreatmentNote) {}
    }

    export class UpdateNotes {
        public static readonly type = '[Notes] UpdateNotes';
        constructor(public readonly notes: TreatmentNote[]) {}
    }

    export class UpdateNote {
        public static readonly type = '[Notes] UpdateNote';
        constructor(public readonly note: TreatmentNote) {}
    }

    export class UpdateNoteParameter {
        public static readonly type = '[Note] UpdateNoteParameter';
        constructor(
            public readonly name: string,
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            public readonly value: any,
            public readonly internalId: string,
        ) {}
    }

    export class AddNewNote {
        public static readonly type = '[Note] AddNewNote';
        // constructor(public readonly newTerm: EmptySlot) {}
        // constructor(public readonly numberOfNewTerms: number) {}
    }
}
// TODO Ignored with eslint-interactive on 2023-11-10
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Term {
    export class InitTermGenerator {
        public static readonly type = '[Term] InitTermGenerator';
        // constructor(public readonly treatment: TreatmentGUI) {}
    }
    export class DeleteTermReservation {
        public static readonly type = '[Term] CreateNewReservation';
        constructor(public readonly term: TreatmentReservationRequest) {}
    }

    export class CreateNewReservations {
        public static readonly type = '[Term] CreateNewReservations';
        constructor(public readonly allTerms: string[]) {}
    }

    export class UpdateReservations {
        public static readonly type = '[Term] UpdateReservations';
        constructor(public readonly internalIds: string[]) {}
    }

    export class AddBaseTerm {
        public static readonly type = '[Term] AddBaseTerm';
        constructor(
            public readonly baseTerm: ReservationRequest,
            public readonly isDefault: boolean = true,
        ) {}
    }

    export class UpdateBaseTerm {
        public static readonly type = '[Term] UpdateBaseTerm';
        constructor(public readonly baseTerm: ReservationRequest) {}
    }

    export class AddNewTerm {
        public static readonly type = '[Term] AddNewTerm';
        constructor(public readonly newTerm: EmptySlot) {}
        // constructor(public readonly numberOfNewTerms: number) {}
    }

    export class GenerateNewTerms {
        public static readonly type = '[Term] GenerateNewTerms';
        // constructor(public readonly newTerm: GenTreatmentFormData) {}
        // constructor(public readonly numberOfNewTerms: number) {}
    }

    export class RemoveTerm {
        public static readonly type = '[Term] RemoveTerm';
        constructor(public readonly internalId: string) {}
    }

    export class UpdateTerm {
        public static readonly type = '[Term] UpdateTerm';
        constructor(public readonly term: TreatmentReservationRequest) {}
    }

    export class UpdateAllTerms {
        public static readonly type = '[Term] UpdateAllTerm';
        constructor(public readonly term: ReservationRequest) {}
    }

    export class SetAllTerms {
        public static readonly type = '[Term] SetAllTerms';
        constructor(public readonly terms: TreatmentReservationRequest[]) {}
    }
    export class ToggleDetails {
        public static readonly type = '[Term] ToggleDetails';
        constructor(public readonly termId: string) {}
    }

    export class SetDetailsOpen {
        public static readonly type = '[Term] SetDetailsOpen';
        constructor(
            public readonly termId: string,
            public readonly isOpen: boolean,
        ) {}
    }

    export class SetAllDetails {
        public static readonly type = '[Term] SetAllDetails';
        constructor(public readonly isOpen: boolean) {}
    }

    export class SetFormValidation {
        public static readonly type = '[Term] FormValidation';
        constructor(
            public readonly isValid: boolean,
            public readonly termId: string,
        ) {}
    }

    export class UpdateTermParameter {
        public static readonly type = '[Term] UpdateTerm parameter';
        constructor(
            public readonly name: string,
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            public readonly value: any,
            public readonly termId: string,
        ) {}
    }

    export class CheckTermAvailability {
        public static readonly type = '[Term] Check term availability';
        constructor(public readonly termId: string) {}
    }
}

// export class GetAndLoadSubcontractors {
//     public static readonly type = '[User] GetSubcontractors';
// }
