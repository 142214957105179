import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { mergeMap, takeUntil, tap } from 'rxjs/operators';
import { AppModulesType } from './../../../../_shared/enums/appModulesType.enum';
import { MenuItem } from './../../../../_shared/model/utils/menuItem.model';
import { ActiveModulesService } from './../../../../_shared/services/activeModules.service';
import { MenuService } from './../../../../_shared/services/menu.service';
import { BaseState } from './../../../../_shared/state/base/base.state';
import { WebsocketActions } from './../../../../_shared/state/websocket/websocket.actions';
import { WebsocketState } from './../../../../_shared/state/websocket/websocket.state';
import { InquiryService } from './../../../pages/home/_services/inquiry.service';

@Component({
    selector: 'asn-status',
    templateUrl: './asn-status.component.html',
    styles: [],
})
export class AsnStatusComponent implements OnInit {
    @Input()
    menuItem: MenuItem;

    public value = 0;
    public telemedicineNewNotifications = 0;
    public devicesNewNotifications = 0;
    public triageNewNotifications = 0;
    public surveysNewNotifications = 0;
    private onDestroy$: Subject<void> = new Subject<void>();
    private contractorId: number = this.store.selectSnapshot(BaseState.activeContractorId);
    constructor(
        private activeModules: ActiveModulesService,
        private store: Store,
        private inquiryApi: InquiryService,
        private menuService: MenuService,
    ) {}

    ngOnInit() {
        switch (this.menuItem.id) {
            case 'MARKETING':
                this.inquiryApi.getNewInquiries(this.contractorId).subscribe((num: number) => (this.value = num));

                this.store
                    .dispatch(new WebsocketActions.Notifications.GetAndLoadNumberOfInquiry())
                    .pipe(
                        mergeMap(() => this.store.select(WebsocketState.getNewInquiry)),
                        tap((newInquiry: number) => {
                            if (newInquiry) {
                                this.value = newInquiry;
                            }
                            this.menuService.updateSubmenuItem('marketing', 'MARKETING/INQUIRIES', newInquiry + '');
                        }),
                        takeUntil(this.onDestroy$),
                    )
                    .subscribe();

                break;

            case 'INBOX':
                if (this.activeModules.isAM(AppModulesType.DEVICES_INBOX)) {
                    this.store
                        .dispatch(new WebsocketActions.InboxDevices.GetAndLoadNumberOfDeviceFiles())
                        .pipe(
                            mergeMap(() => this.store.select(WebsocketState.getDeviceNewFiles)),
                            tap((deviceNewFiles: number) => {
                                // TODO;
                                if (deviceNewFiles != undefined) {
                                    this.devicesNewNotifications = deviceNewFiles;
                                    this.calcNewNotifications();
                                }
                            }),
                            takeUntil(this.onDestroy$),
                        )
                        .subscribe();
                }

                if (this.activeModules.isAM(AppModulesType.TELEMEDICINE)) {
                    this.store
                        .dispatch(new WebsocketActions.Telemedicine.GetAndLoadNumberOfNotifications())
                        .pipe(
                            mergeMap(() => this.store.select(WebsocketState.getNewTelemedicineChat)),
                            tap((newTelemed: number) => {
                                if (newTelemed != undefined) {
                                    if (newTelemed < 0) {
                                        newTelemed = 0;
                                    }
                                    this.menuService.updateSubmenuItem('inbox', 'inboxEposvet', newTelemed + '');
                                    // this.value += newTelemed;
                                    this.telemedicineNewNotifications = newTelemed;
                                    this.calcNewNotifications();
                                }
                            }),
                            takeUntil(this.onDestroy$),
                        )
                        .subscribe();
                }
                if (this.activeModules.isAM(AppModulesType.TRIAGE)) {
                    this.store
                        .dispatch(new WebsocketActions.Triage.GetAndLoadNumberOfNotifications())
                        .pipe(
                            mergeMap(() => this.store.select(WebsocketState.getNewTriages)),
                            tap((newTriage: number) => {
                                if (newTriage != undefined) {
                                    if (newTriage < 0) {
                                        newTriage = 0;
                                    }
                                    this.menuService.updateSubmenuItem('inbox', 'inboxConsul', newTriage + '');
                                    this.triageNewNotifications = newTriage;
                                    this.calcNewNotifications();
                                }
                            }),
                            takeUntil(this.onDestroy$),
                        )
                        .subscribe();
                }
                if (this.activeModules.isAM(AppModulesType.SURVEYS)) {
                    this.store
                        .dispatch(new WebsocketActions.Survey.GetAndLoadNumberOfNotifications())
                        .pipe(
                            mergeMap(() => this.store.select(WebsocketState.getNewSurveys)),
                            tap((newSurvey: number) => {
                                if (newSurvey != undefined) {
                                    if (newSurvey < 0) {
                                        newSurvey = 0;
                                    }
                                    this.menuService.updateSubmenuItem('inbox', 'inboxSurveys', newSurvey + '');
                                    this.surveysNewNotifications = newSurvey;
                                    this.calcNewNotifications();
                                }
                            }),
                            takeUntil(this.onDestroy$),
                        )
                        .subscribe();
                }
                break;

            case 'HELLO_DOCTOR':
                break;

            default:
                break;
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy(): void {
        this.onDestroy$.next();
    }

    private calcNewNotifications(): void {
        this.value = this.telemedicineNewNotifications + this.devicesNewNotifications + this.triageNewNotifications + this.surveysNewNotifications;
    }
}
