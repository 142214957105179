import { AssetType } from './../enums/assetType.enum';
import { Serializable } from './deserialize.service';

export class Asset implements Serializable<Asset> {
    id: number;
    name: string;
    assetType: AssetType;

    deserialize(input) {
        if (input) {
            this.id = input.id;
            if (input.comentatorName) {
                this.name = input.comentatorName;
            } else {
                this.name = input.name;
            }
        }
        this.assetType = input.assetType;
        return this;
    }
}
