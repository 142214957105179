import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { from, Observable } from 'rxjs';
import { Payer } from './../../../_shared/model/accounting/payer.model';
import { Customer } from './../../../_shared/model/customer.model';
import { ShareDocument } from './../../../_shared/model/utils/share-document.model';
import { SendPopupComponent } from './../../components/send-popup/send-popup.component';

@Injectable({
    providedIn: 'root',
})
export class DocumentViewerService {
    constructor(private modalService: NgbModal) {}
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public shareDocument(sd?: ShareDocument): Observable<any> {
        const modalNewTerm = this.modalService.open(SendPopupComponent, {
            backdrop: 'static',
            keyboard: false,
            windowClass: 'mf-transparent-popup mf-gray-background-popup mf-screen-height-popup mf-popup-with__title',
        });

        modalNewTerm.componentInstance.emailTo = sd?.email;
        modalNewTerm.componentInstance.smsTo = sd?.phone;

        return from(modalNewTerm.result);

        // modalNewTerm.result.then(
        //     (data: SendInvoiceNotificationInfo) => {
        //         const id: number = paper.id !== undefined ? paper.id : paper.paperId;
        //         // let apiCall: Observable<any>;
        //         // const visitid: string = paper.visitId;
        //         // if (paper?.visitId) {
        //         //     apiCall = this.visitRest.shareDocument(paper?.visitId, id, data);
        //         // } else {
        //         //     //uporabi prereservation
        //         //     apiCall = this.visitRest.sendPaperPrereservation(paper.prereservationId, id, data);
        //         // }
        //         this.documentApi
        //             .sharePaper(this.contractorId, this.termData?.customer?.id, id, data)
        //             .pipe(takeUntil(this.onDestroy$))
        //             .subscribe(
        //                 (res) => {
        //                     this.toast.success('Listina uspešno deljena.');
        //                     paper.shared = true;
        //                 },
        //                 () => {
        //                     this.toast.error('Napaka pri pošiljanju listine.');
        //                 },
        //             );
        //     },
        //     (result) => {},
        // );
        // if (this.type === PfType.TERM) {
        //     let email: string = this.termData?.customer?.email;
        //     let phone: string = this.termData?.customer?.phone;

        //     if (this.customer) {
        //         email = this.customer.email;
        //         phone = this.customer.phone;
        //     }
        //     modalNewTerm.componentInstance.emailTo = email;
        //     modalNewTerm.componentInstance.smsTo = phone;
        // } else if (this.type === PfType.CUSTOMER) {
        //     let email: string = this.storage.getSelectedCustomer()?.email;
        //     let phone: string = this.storage.getSelectedCustomer()?.phone;

        //     if (this.customer) {
        //         email = this.customer.email;
        //         phone = this.customer.phone;
        //     }
        //     modalNewTerm.componentInstance.emailTo = email;
        //     modalNewTerm.componentInstance.smsTo = phone;
        // }
    }
    public shareDocumentCustomerAndPayer(customer: Customer, payer: Payer | Customer) {
        const modalNewTerm = this.modalService.open(SendPopupComponent, {
            backdrop: 'static',
            keyboard: false,
            windowClass: 'mf-transparent-popup mf-gray-background-popup mf-screen-height-popup mf-popup-with__title',
        });

        //customer data
        modalNewTerm.componentInstance.emailTo = customer?.email;
        modalNewTerm.componentInstance.smsTo = customer?.phone;
        //payer data
        modalNewTerm.componentInstance.emailToPayer = payer?.email;
        modalNewTerm.componentInstance.smsToPayer = payer?.phone;
        return from(modalNewTerm.result);
    }
}
