<div class="m-portlet">
    <div class="m-portlet__head">
        <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
                <h3 class="m-portlet__head-text" i18n="@@histoy-results-list">Pretekla poročila stranke</h3>
            </div>
        </div>
    </div>
    <div *ngIf="!G100" class="m-portlet__body">
        <div class="mf-table mf-table__no-header">
            <div class="mf-table-body">
                <div *ngIf="numberOfElements < 1">Ni preteklih poročil</div>
                <div
                    *ngFor="let item of (allVisits | async)?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)"
                    class="mf-table-body__item"
                >
                    <span class="mf-table__text mf-column-rh-1 mf-table-hr__date">
                        {{ item.paperDate | date: 'dd.MM.yyyy' }}
                    </span>
                    <span class="mf-table__text mf-column-rh-2">
                        <a
                            (click)="$event.preventDefault(); resultsPreview(item, false)"
                            href="#"
                            class="btn btn-outline-success m-btn m-btn--icon m-btn--icon-only m-btn--pill mr-2"
                        >
                            <i class="la la-search"></i>
                        </a>
                        <a
                            (click)="$event.preventDefault(); copyData(item)"
                            href="#"
                            class="btn btn-outline-success m-btn m-btn--icon m-btn--icon-only m-btn--pill"
                        >
                            <i class="la la-copy"></i>
                        </a>
                    </span>
                </div>
                <ngb-pagination
                    *ngIf="numberOfElements > itemsPerPage"
                    [(page)]="currentPage"
                    [collectionSize]="numberOfElements"
                    [pageSize]="itemsPerPage"
                    [maxSize]="2"
                    [directionLinks]="true"
                    class="d-flex justify-content-center align-self-end"
                ></ngb-pagination>
            </div>
        </div>
    </div>

    <div *ngIf="G100" class="m-portlet__body">
        <div class="mf-table mf-table__no-header">
            <div class="mf-table-body">
                <div *ngIf="numberOfElements < 1">Ni preteklih dokumentacij</div>
                <div *ngFor="let item of allVisits | async" class="">
                    <h6 class="mb-3"><strong>Prenesi dokumentacijo iz predhodne obravnave</strong></h6>
                    <span class="">
                        <a (click)="$event.preventDefault(); copyData(item)" href="#" class="btn btn-outline-success m-btn btn-block m-btn--pill">
                            Prenesi dokumentacijo
                        </a>
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
