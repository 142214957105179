interface FsDocument extends HTMLDocument {
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
    msExitFullscreen?: () => void;
    mozCancelFullScreen?: () => void;
    webkitExitFullscreen?: () => void;
}

interface FsDocumentElement extends HTMLElement {
    msRequestFullscreen?: () => void;
    mozRequestFullScreen?: () => void;
    webkitRequestFullscreen?: () => void;
}

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class FullScreenService {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    isFullScreen(): boolean {
        const fsDoc = <FsDocument>document;

        return !!(fsDoc.fullscreenElement || fsDoc.mozFullScreenElement || fsDoc.webkitFullscreenElement || fsDoc.msFullscreenElement);
    }

    toggleFullScreen(element: FsDocumentElement): boolean {
        if (!this.isFullScreen()) {
            const fsDocElem = <FsDocumentElement>element;

            if (fsDocElem.requestFullscreen) {
                fsDocElem.requestFullscreen();
            } else if (fsDocElem.msRequestFullscreen) {
                fsDocElem.msRequestFullscreen();
            } else if (fsDocElem.mozRequestFullScreen) {
                fsDocElem.mozRequestFullScreen();
            } else if (fsDocElem.webkitRequestFullscreen) {
                fsDocElem.webkitRequestFullscreen();
            }
            return true;
        } else {
            this.turnOffFullScreen();
            return false;
        }
    }

    turnOffFullScreen() {
        if (this.isFullScreen()) {
            const fsDoc = <FsDocument>document;
            if (fsDoc.exitFullscreen) {
                fsDoc.exitFullscreen();
            } else if (fsDoc.msExitFullscreen) {
                fsDoc.msExitFullscreen();
            } else if (fsDoc.mozCancelFullScreen) {
                fsDoc.mozCancelFullScreen();
            } else if (fsDoc.webkitExitFullscreen) {
                fsDoc.webkitExitFullscreen();
            }
        }
    }
}
