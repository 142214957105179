import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppBasicsModule } from '@meddev/fe-shared';
import { CommonService } from '../theme/pages/home/_services';
import { TokenInterceptor } from '../token.interceptor';
import { PublicService } from '../_shared/services';
import { SharedModule } from '../_shared/shared.module';
import { AuthRoutingModule } from './auth-routing.routing';
import { AuthComponent } from './auth.component';
import { CaptchaComponent } from './captcha/captcha.component';
import { LogoutComponent } from './logout/logout.component';
import { AlertComponent } from './_directives';
import { AuthGuard } from './_guards';
import { NotSavedGuard } from './_guards/not-saved.guard';
import { AlertService, AuthenticationService } from './_services';

@NgModule({
    declarations: [AuthComponent, AlertComponent, LogoutComponent, CaptchaComponent],
    imports: [CommonModule, FormsModule, HttpClientModule, AuthRoutingModule, SharedModule, AppBasicsModule],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        AuthGuard,
        AlertService,
        AuthenticationService,
        NotSavedGuard,
        CommonService,
        PublicService,
    ],
})
export class AuthModule {}
