import { Serializable } from '../deserialize.service';
import { GuiUserContractorHd } from './guiUserContractorHd.model';

export class GuiUserSettings implements Serializable<GuiUserSettings> {
    lastSelectedContractorId: number;
    lastSelectedStaffId: number;
    haloDoctor: GuiUserContractorHd;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        if (!input || input === null) {
            return null;
        }
        if (input.haloDoctor) {
            this.haloDoctor = new GuiUserContractorHd().deserialize(input.haloDoctor);
        }
        this.lastSelectedContractorId = input.lastSelectedContractorId;
        this.lastSelectedStaffId = input.lastSelectedStaffId;

        return this;
    }
}
