import { Serializable } from './../../../../../_shared/model/deserialize.service';
import { Surfaces } from './surfaces.model';

export class Tooth implements Serializable<Tooth> {
    id: string;
    isChanged: boolean;
    isSelected: boolean;
    type: number;
    surfaces: Surfaces;

    deserialize(input) {
        this.isChanged = input.isChanged;
        this.isSelected = input.isSelected;
        this.id = input.id;
        this.type = input.type;
        this.surfaces = input.surfaces;
        return this;
    }
}
