import { Serializable } from '../deserialize.service';

export class AvgPerformance implements Serializable<AvgPerformance> {
    n: number;
    answers: AvgPerformanceAnswers[];

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        this.n = input.n;
        if (input.answers && input.answers.length > 0) {
            this.answers = input.answers.map((el: AvgPerformanceAnswers) => new AvgPerformanceAnswers().deserialize(el));
        }
        return this;
    }
}

export class AvgPerformanceAnswers implements Serializable<AvgPerformanceAnswers> {
    id: number;
    avg: number;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        this.id = input.id;
        this.avg = input.avg;
        return this;
    }
}
