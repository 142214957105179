import { ExaminationFieldResponseDto } from '../../../../../../../../_shared/model/examinations/examination-field-response-dto';
import { ReservationExaminationFieldDto } from '../../../../../../../../_shared/model/examinations/reservation-examination-field-dto';
import { SaveReservationExaminationFieldsRequestDto } from '../../../../../../../../_shared/model/examinations/save-reservation-examination-fields-request-dto';

export class ManagedExaminationManager {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    public getFieldsToSave(fieldsWithValues: ReservationExaminationFieldDto[] | null): SaveReservationExaminationFieldsRequestDto | null {
        return { fields: fieldsWithValues } as SaveReservationExaminationFieldsRequestDto;
    }

    public getFieldsToDelete(allFields: ExaminationFieldResponseDto[], fieldsWithValues: ReservationExaminationFieldDto[] | null): number[] | null {
        return allFields
            .filter(field => field?.value)
            .filter(field => !fieldsWithValues?.some(fieldWithValue => fieldWithValue.fieldId === field.id))
            .map(field => field.id);
    }
}
