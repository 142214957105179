<div class="m-content">
    <div id="modalPopup" class="row">
        <div class="col-sm-12 col-lg-8">
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">Delovna diagnoza</h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body">
                    <ng-form #diagnosisForm="ngForm">
                        <ng-select
                            [(ngModel)]="selectedDiagnoses"
                            [items]="diagnose$ | async"
                            [multiple]="true"
                            [hideSelected]="true"
                            [typeahead]="searchInput$"
                            [loading]="searching"
                            [notFoundText]="'Ni zadetkov'"
                            [addTag]="true"
                            [addTagText]="'Vnesite lastno diagnozo'"
                            [typeToSearchText]="'Vnesite iskani niz...'"
                            (change)="this.comm.dirty$.next(true)"
                            name="diagnosis"
                            placeholder="Iskanje diagnoz"
                            required
                            bindLabel="fullValue"
                            loadingText="Iščem..."
                            class="mf-ng-select-custom"
                            [ngClass]="{
                                'border border-danger rounded': this.sign && !diagnosisForm.valid
                            }"
                        >
                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                <span [ngClass]="{ 'm--font-boldest': !item.code }">
                                    <span (click)="clear.call(undefined, item)" class="ng-value-icon left">×</span>
                                    <span *ngIf="item.name" class="ng-value-label">{{ item.name }}</span>
                                    <span *ngIf="item.fullValue && !item.name" class="ng-value-label">{{ item.fullValue }}</span>
                                </span>
                            </ng-template>
                        </ng-select>
                    </ng-form>
                    <div *ngIf="diagnosisForm.invalid && this.sign" class="text-danger pt-2">To polje je obvezno.</div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-lg-4 pl-lg-0">
            <div class="m-portlet">
                <div class="m-portlet__head">
                    <div class="m-portlet__head-caption">
                        <div class="m-portlet__head-title">
                            <h3 class="m-portlet__head-text">
                                {{ inputObj.medicalCareType.title }}
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="m-portlet__body">
                    <ng-form #medicalCareForm="ngForm">
                        <div class="d-flex">
                            <div class="mf-consent-buttons mt-2 d-flex w-100 mb-2">
                                <label class="m-radio m-radio--brand ml-2 mr-3 mb-0">
                                    <input
                                        #medicalCareType="ngModel"
                                        [(ngModel)]="inputObj.medicalCareType.content"
                                        [value]="'INJURY'"
                                        (click)="inputObj.medicalCareType.content = medicalCareType.value === 'INJURY' ? null : 'INJURY'"
                                        type="radio"
                                        name="medicalCareType"
                                        required="{{ !notRequired }}"
                                    />
                                    Poškodba
                                    <span></span>
                                </label>
                                <label class="m-radio m-radio--brand mb-0">
                                    <input
                                        #medicalCareType="ngModel"
                                        [(ngModel)]="inputObj.medicalCareType.content"
                                        [value]="'SICK'"
                                        (click)="inputObj.medicalCareType.content = medicalCareType.value === 'SICK' ? null : 'SICK'"
                                        type="radio"
                                        name="medicalCareType"
                                        required="{{ !notRequired }}"
                                    />
                                    Bolezen
                                    <span></span>
                                </label>
                            </div>
                        </div>

                        <div *ngIf="medicalCareForm.invalid && this.sign" class="text-danger pt-2">To polje je obvezno.</div>
                    </ng-form>
                </div>
            </div>
        </div>
    </div>
    <div class="m-portlet">
        <div class="m-portlet__head">
            <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                    <h3 class="m-portlet__head-text">{{ inputObj.anamnesis.title }}</h3>
                </div>
            </div>
        </div>
        <div class="m-portlet__body mf-portlet-custome__body">
            <ng-form #anamnesisForm="ngForm">
                <editor
                    [(ngModel)]="inputObj.anamnesis.content"
                    [init]="tmceSettings"
                    required
                    id="anamnesis"
                    name="anamnesis"
                    [ngClass]="{
                        'border border-danger': this.sign && !anamnesisForm.valid
                    }"
                ></editor>
            </ng-form>
            <div *ngIf="anamnesisForm.invalid && this.sign" class="text-danger pt-2">To polje je obvezno.</div>
        </div>
    </div>
    <div class="m-portlet">
        <div class="m-portlet__head">
            <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                    <h3 class="m-portlet__head-text">{{ inputObj.status.title }}</h3>
                </div>
            </div>
        </div>
        <div class="m-portlet__body mf-portlet-custome__body">
            <ng-form #statusForm="ngForm">
                <editor
                    [(ngModel)]="inputObj.status.content"
                    [init]="tmceSettings"
                    name="status"
                    id="status"
                    [ngClass]="{
                        'border border-danger': this.sign && !statusForm.valid
                    }"
                ></editor>
            </ng-form>
            <div *ngIf="statusForm.invalid && this.sign" class="text-danger pt-2">To polje je obvezno.</div>
        </div>
    </div>
    <div class="m-portlet">
        <div class="m-portlet__head">
            <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                    <h3 class="m-portlet__head-text">
                        {{ inputObj.pastMedicalConditions.title }}
                        <small class="text-dark">(Se je stranka zaradi navedenih težav predhodno že zdravila oziroma obiskala zdravnika)</small>
                    </h3>
                </div>
            </div>
        </div>
        <div class="m-portlet__body mf-portlet-custome__body">
            <ng-form #pastMedicalConditionsForm="ngForm">
                <div class="d-flex">
                    <div class="mf-consent-buttons mt-2 d-flex w-100 mb-2">
                        <label class="m-radio m-radio--brand ml-2 mr-3 mb-0">
                            <input
                                #pastMedicalConditions="ngModel"
                                [(ngModel)]="inputObj.pastMedicalConditions.content"
                                [value]="'TRUE'"
                                (click)="inputObj.pastMedicalConditions.content = pastMedicalConditions.value === 'TRUE' ? null : 'TRUE'"
                                type="radio"
                                name="pastMedicalConditions"
                                required="{{ !notRequired }}"
                            />
                            Da
                            <span></span>
                        </label>
                        <label class="m-radio m-radio--brand mb-0">
                            <input
                                #pastMedicalConditions="ngModel"
                                [(ngModel)]="inputObj.pastMedicalConditions.content"
                                [value]="'FALSE'"
                                (click)="inputObj.pastMedicalConditions.content = pastMedicalConditions.value === 'FALSE' ? null : 'FALSE'"
                                type="radio"
                                name="pastMedicalConditions"
                                required="{{ !notRequired }}"
                            />
                            Ne
                            <span></span>
                        </label>
                    </div>
                </div>

                <div *ngIf="pastMedicalConditionsForm.invalid && this.sign" class="text-danger pt-2">To polje je obvezno.</div>
            </ng-form>
        </div>
        <div *ngIf="inputObj.pastMedicalConditions.content === 'TRUE'" class="m-portlet">
            <div class="m-portlet__head">
                <div class="m-portlet__head-caption">
                    <div class="m-portlet__head-title">
                        <h3 class="m-portlet__head-text">{{ inputObj.pastMedicalConditionsDescription.title }}</h3>
                    </div>
                </div>
            </div>
            <div class="m-portlet__body mf-portlet-custome__body">
                <ng-form #pastMedicalConditionsDescriptionForm="ngForm">
                    <editor
                        [(ngModel)]="inputObj.pastMedicalConditionsDescription.content"
                        [init]="tmceSettings"
                        id="pastMedicalConditionsDescription"
                        name="pastMedicalConditionsDescription"
                        required="{{ !notRequired }}"
                        [ngClass]="{
                            'border border-danger': this.sign && !pastMedicalConditionsDescriptionForm.valid
                        }"
                    ></editor>
                </ng-form>
                <div *ngIf="pastMedicalConditionsDescriptionForm.invalid && this.sign" class="text-danger pt-2">To polje je obvezno.</div>
            </div>
        </div>
    </div>
    <div class="m-portlet">
        <div class="m-portlet__head">
            <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                    <h3 class="m-portlet__head-text">{{ inputObj.accidentDate.title }}</h3>
                </div>
            </div>
        </div>
        <div class="m-portlet__body mf-portlet-custome__body">
            <ng-form #accidentDateForm="ngForm">
                <input
                    [(ngModel)]="inputObj.accidentDate.content"
                    [options]="calendarOptions"
                    (selected)="setDate($event)"
                    (change)="setDate($event)"
                    required="{{ !notRequired }}"
                    type="text"
                    name="daterangeInput"
                    daterangepicker
                    autocomplete="off"
                    class="form-control m-input"
                    [ngClass]="{
                        'border border-danger': this.sign && !accidentDateForm.valid
                    }"
                />
            </ng-form>
            <div *ngIf="accidentDateForm.invalid && this.sign" class="text-danger pt-2">To polje je obvezno.</div>
        </div>
    </div>
    <div class="m-portlet">
        <div class="m-portlet__head">
            <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                    <h3 class="m-portlet__head-text">{{ inputObj.opinionAndFurtherTreatment.title }}</h3>
                </div>
            </div>
        </div>
        <div class="m-portlet__body mf-portlet-custome__body">
            <ng-form #opinionAndFurtherTreatmentForm="ngForm">
                <editor
                    [(ngModel)]="inputObj.opinionAndFurtherTreatment.content"
                    [init]="tmceSettings"
                    required
                    id="opinionAndFurtherTreatment"
                    name="opinionAndFurtherTreatment"
                    [ngClass]="{
                        'border border-danger': this.sign && !opinionAndFurtherTreatmentForm.valid
                    }"
                ></editor>
            </ng-form>
            <div *ngIf="opinionAndFurtherTreatmentForm.invalid && this.sign" class="text-danger pt-2">To polje je obvezno.</div>
        </div>
    </div>
    <div class="m-portlet">
        <div class="m-portlet__head">
            <div class="m-portlet__head-caption">
                <div class="m-portlet__head-title">
                    <h3 class="m-portlet__head-text">{{ inputObj.medicalOperationNeeded.title }}</h3>
                </div>
            </div>
        </div>
        <div class="m-portlet__body mf-portlet-custome__body">
            <ng-form #medicalOperationNeededForm="ngForm">
                <editor
                    [(ngModel)]="inputObj.medicalOperationNeeded.content"
                    [init]="tmceSettings"
                    required="{{ !notRequired }}"
                    id="medicalOperationNeeded"
                    name="medicalOperationNeeded"
                    [ngClass]="{
                        'border border-danger': this.sign && !medicalOperationNeededForm.valid
                    }"
                ></editor>
            </ng-form>
            <div *ngIf="medicalOperationNeededForm.invalid && this.sign" class="text-danger pt-2">To polje je obvezno.</div>
        </div>
    </div>
</div>
