import * as _ from 'lodash';
import { Service } from '../service.model';
import { EventNew } from './eventNew.model';

export class VisitNew extends EventNew {
    visitTime: string;
    services: Service[];
    // visitid: any;
    deserialize(input) {
        if (_.isNil(input)) {
            return undefined;
        }
        super.deserialize(input);
        this.visitTime = input.visitTime;
        if (!_.isNil(input.services)) {
            this.services = input.services.map(el => new Service().deserialize(el));
        }
        // this.visitid = input.visitid;
        return this;
    }
}
