<div>
    <div [className]="this.customClass" class="m-widget4__item hover-to-show document-element w-100 d-flex rowItem border-0">
        <div class="w-100 d-flex align-items-center">
            <div (click)="openDocument()">
                <div *ngIf="!thumbnailMode" class="m-widget4__img m-widget4__img--icon mf-t-icon">
                    <img src="{{ getIconType() }}" alt="" class="doc-type-icon" />
                </div>
                <div [hidden]="!thumbnailMode || !loaded" class="m-widget4__img m-widget4__img--icon mf-t-icon">
                    <img #thumbnailPhoto [ngStyle]="styleThumbnail('photo')" style="align-self: center" alt="Predogled" />
                </div>
                <!-- ni thumbnail ni loaded  ni thumbnail je loaded   je thumbnail ni loaded -->
                <div *ngIf="!((thumbnailMode && loaded) || !thumbnailMode)">
                    <div class="text-center">
                        <div style="width: 30px; display: inline-block" class="m-loader m-loader--brand"></div>
                    </div>
                </div>
            </div>
            <div *ngIf="!thumbnailMode" (click)="openDocument()" class="mf-description-list-view w-100">
                <div class="d-flex">
                    <div [ngbTooltip]="document.description || document.fileName" [openDelay]="800" class="m-widget4__text">
                        {{ document.description || document.fileName || '/' }}
                    </div>
                </div>
                <small>
                    {{ document.documentAdded | date: 'dd.MM.yyyy HH:mm' }}{{ document.documentAdded ? '' : '/' }}
                    <i
                        [ngbTooltip]="document.documentSource | documentSouceName"
                        class="mr-2"
                        [ngClass]="document.documentSource | documentSouceName: 'icon'"
                    ></i>

                    <i *ngIf="document?.shared" [ngbTooltip]="tooltip" class="la la-eye"></i>
                </small>
                <small *ngIf="document?.documentSource === 'MODEL_WRONG'" class="text-danger">(Napaka v modelu)</small>
            </div>
            <div [hidden]="thumbnailMode" class="">
                <div
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    class="btn m-btn m-btn--icon m-btn--icon-only m-btn--pill popup-to-show"
                >
                    <i class="la la-ellipsis-v"></i>
                </div>
                <div class="dropdown-menu">
                    <div
                        *ngIf="document.documentSource === 'VISIT' || document.documentSource === 'RESERVATION'"
                        (click)="openSource(document)"
                        class="dropdown-item mf-t-deleteDocument"
                        i18n="@@documents.element.menu.element.opensource"
                    >
                        Odpri izvor
                    </div>
                    <div
                        *ngIf="!isReadOnly"
                        (click)="deleteDocument()"
                        class="dropdown-item mf-t-deleteDocument"
                        i18n="@@46363e937e9d01e5a3cec4bcbbad5e0eaaafd833"
                    >
                        Briši
                    </div>
                    <div (click)="downloadDocument()" class="dropdown-item mf-t-downloadDocument" i18n="@@c4664c3eaefc0931feec2d18b797d2471d7dc6d8">
                        Prenesi
                    </div>
                    <div
                        *ngIf="
                            shareBtn &&
                            !document.shared &&
                            document?.documentSource !== 'CONTRACT' &&
                            document?.documentSource !== 'WORKORDER' &&
                            document?.documentSource !== 'MODEL' &&
                            document?.documentSource !== 'MODEL_WRONG'
                        "
                        (click)="onShareDocument.emit(document)"
                        class="dropdown-item mf-t-downloadDocument"
                    >
                        Pošlji
                    </div>
                    <div
                        *ngIf="document?.shared && document?.documentSource !== 'CONTRACT' && document?.documentSource !== 'WORKORDER'"
                        (click)="onStopShareDocument.emit(document)"
                        class="dropdown-item dropDownItem"
                    >
                        Prekliči deljenje
                    </div>

                    <div
                        *ngIf="
                            termData?.telemed &&
                            (termData.reservationType == reservationType.HDP ||
                                termData.reservationType == reservationType.SAVAP ||
                                termData.reservationType == reservationType.SAVAD ||
                                termData.reservationType == reservationType.MDFTD ||
                                termData.reservationType == reservationType.MDFTP ||
                                termData.reservationType == reservationType.WIDGET_VOUCHER ||
                                termData.reservationType == reservationType.HDD ||
                                termData?.originUrl == originUrl.G100_01 ||
                                termData?.originUrl == originUrl.G100_03 ||
                                termData?.originUrl == originUrl.LAIF)
                        "
                        (click)="attachToHdChat()"
                        class="dropdown-item mf-t-downloadDocument"
                    >
                        Dodaj na ePosvet klepet
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="thumbnailMode" class="d-flex align-items-center">
        <div [ngStyle]="styleThumbnail('div')" class="text-truncate">
            {{ document.description || document.fileName }}
        </div>
        <div style="width: 20px" class="m-widget4__ext mf-t-editDocument float-right">
            <div
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                class="btn m-btn m-btn--icon m-btn--icon-only m-btn--pill popup-to-show"
            >
                <i class="la la-ellipsis-v"></i>
            </div>
            <div class="dropdown-menu">
                <div
                    *ngIf="document.documentSource === 'VISIT' || document.documentSource === 'RESERVATION'"
                    (click)="openSource(document)"
                    class="dropdown-item mf-t-deleteDocument"
                    i18n="@@documents.element.menu.element.opensource"
                >
                    Odpri izvor
                </div>
                <div
                    *ngIf="!isReadOnly"
                    (click)="deleteDocument()"
                    class="dropdown-item mf-t-deleteDocument"
                    i18n="@@46363e937e9d01e5a3cec4bcbbad5e0eaaafd833"
                >
                    Briši
                </div>
                <div (click)="downloadDocument()" class="dropdown-item mf-t-downloadDocument" i18n="@@c4664c3eaefc0931feec2d18b797d2471d7dc6d8">
                    Prenesi
                </div>
                <div (click)="onShareDocument.emit(document)" class="dropdown-item mf-t-downloadDocument">Pošlji</div>
            </div>
        </div>
    </div>
</div>
