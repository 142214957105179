import { Component, Input, OnInit } from '@angular/core';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { PaperType } from '../../../../../../_shared/enums/PaperType.enum';
import { PortfolioPaper } from '../../../../../../_shared/model/portfolio/portfolioPaper.model';
import { HelperService } from '../../../../../../_shared/services/helper.service';
import { StorageService } from '../../../../../../_shared/services/storage.service';
import { ResultService } from '../../../_services/result.service';
import { PfPCommnComunicationService } from './../../papers/_common/pf-p-comn-communication.service';

@Component({
    selector: 'pf-comn-template',
    templateUrl: './pf-comn-template.component.html',
    styles: [],
})
export class PfComnTemplateComponent implements OnInit {
    public paperTypeEnum = PaperType;
    constructor(
        private helper: HelperService,
        private comm: PfPCommnComunicationService,
        private resultRest: ResultService,
        private storage: StorageService,
        private toast: ToastrService,
    ) {}

    ngOnInit() {
        this.getData();
        //Update ID of SAVED template
        this.comm.onTemplateSaveFTH.subscribe(data => {
            if (!_.isNil(data.id) && !_.isNil(data.name)) {
                this.getData(data.id);
            }
        });
        this.comm.onTemplateSaveSurvey.subscribe(data => {
            if (!_.isNil(data.id) && !_.isNil(data.name)) {
                this.getData(data.id);
            }
        });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/member-ordering
    @Input()
    paper: PortfolioPaper;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public allTemplates = [];
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/no-explicit-any
    public selectedTemplate: any;

    public getData(selectedId?: number) {
        if (
            this.paper.paperType === PaperType.FTH_POROCILO ||
            this.paper.paperType === PaperType.IZVID ||
            this.paper.paperType === PaperType.HELLODOC_IZVID ||
            this.paper.paperType === PaperType.MFMEDICAL_IZVID ||
            this.paper.paperType === PaperType.MEDIFIT_MEDICAL_REPORT ||
            this.paper.paperType === PaperType.SURVEY_CUSTOMER
        ) {
            this.resultRest.getDocumentTemplates(this.storage.getSelectedContractor().id, this.paper.paperType).subscribe(templates => {
                this.allTemplates = templates ? templates : [];
                //select created template
                if (selectedId) {
                    this.allTemplates.forEach(template => {
                        if (template.id == selectedId) {
                            this.selectedTemplate = template;
                        }
                    });
                }
            });
        }
    }
    public saveTemplate() {
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const alertSettings: any = {
            input: 'text',
            type: 'info',
            preConfirm: repotTitle => {
                if (_.isNil(repotTitle) || repotTitle === '') {
                    Swal.showValidationMessage(`Naziv je obvezno polje.`);
                }
            },
        };
        this.helper.displayAlert('Shranjevanje predloge', 'Vnesite naziv predloge.', alertSettings).then(result => {
            if (result.value) {
                if (this.paper.paperType === PaperType.FTH_POROCILO) {
                    this.comm.onTemplateSaveFTH.next(result.value);
                } else if (
                    this.paper.paperType === PaperType.IZVID ||
                    this.paper.paperType === PaperType.HELLODOC_IZVID ||
                    this.paper.paperType === PaperType.MFMEDICAL_IZVID ||
                    this.paper.paperType === PaperType.MEDIFIT_MEDICAL_REPORT ||
                    this.paper.paperType === PaperType.SURVEY_CUSTOMER
                ) {
                    this.comm.onTemplateSaveSurvey.next(result.value);
                }
            }
        });
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public selectTemplate(data: any) {
        this.selectedTemplate = data;
        this.comm.onTemplateSelect.emit(data);
    }
    public deleteTemplate() {
        this.helper.displayAlert('Brisanje predloge', 'Izbrana predloga bo izbrisana. Želite nadaljevati?').then(result => {
            if (result.value) {
                this.resultRest.deleteDocumentTemplates(this.storage.getSelectedContractor().id, this.selectedTemplate.id).subscribe(
                    () => {
                        // Remove from array
                        this.allTemplates = this.allTemplates.filter(template => {
                            return template.id !== this.selectedTemplate.id;
                        });
                        this.toast.success('Uspešno izbrisana predloga.');
                        this.selectedTemplate = undefined;
                        this.comm.resetValues.emit();
                    },
                    () => {
                        this.toast.error('Napaka pri brisanju predloge.');
                    },
                );
            }
        });
    }
}
