import * as _ from 'lodash';
import { Serializable } from '../../deserialize.service';

export class PivotDef implements Serializable<PivotDef> {
    id: number;
    name: string;
    definition: string;
    deserialize(input) {
        if (_.isNil(input)) {
            return undefined;
        }

        this.id = input.id;
        this.name = input.name;
        this.definition = input.definition;

        return this;
    }
}
