import * as _ from 'lodash';
import { Serializable } from '../deserialize.service';
// import { Asset } from '../asset.model';
import { Subcontractor } from '../subcontractor.model';
import { CustomerSearchType } from './../../../theme/pages/home/main-calendar/main-cal-view-filters/customer-search-type.enum';
import { Asset } from './../asset.model';

export class CalendarFilterSettings implements Serializable<CalendarFilterSettings> {
    visible = true;
    showResources: boolean;
    showPlanning: boolean;
    showOnlyAvailable: boolean;
    showExternalAvailable: boolean;
    showExternalCustomerAvailable: boolean;
    //samo za filter-> razpolozljivost
    showInternal: boolean;
    showWeekend: boolean;
    showHolidays: boolean;
    showServicesColor?: boolean;
    show24h?: boolean;
    from: string;
    to: string;
    timeFrequncy: number;
    selectedSubcontractors?: Subcontractor[];
    selectedAssets?: Asset[];
    customerSearchType?: CustomerSearchType;
    // selectedCalendarView: string;

    deserialize(input) {
        if (!input || input === null) {
            return null;
        }
        this.selectedAssets = input.selectedAssets;
        // this.selectedCalendarView = input.selectedCalendarView;
        this.selectedSubcontractors = input.selectedSubcontractors;
        this.showResources = input.showResources;
        this.showOnlyAvailable = input.showOnlyAvailable;
        this.showExternalAvailable = input.showExternalAvailable;
        this.showExternalCustomerAvailable = input.showExternalCustomerAvailable;
        this.showInternal = input.showInternal;
        this.showWeekend = input.showWeekend;
        this.showHolidays = input.showHolidays;
        this.show24h = input.show24h;
        this.showServicesColor = input.showServicesColor;
        this.showPlanning = input.showPlanning;
        if (!_.isNil(input.visible)) {
            this.visible = input.visible;
        }
        if (!_.isNil(input.from)) {
            this.from = input.from;
        }
        if (!_.isNil(input.to)) {
            this.to = input.to;
        }
        if (!_.isNil(input.timeFrequncy)) {
            this.timeFrequncy = input.timeFrequncy;
        }
        if (!_.isNil(input.customerSearchType)) {
            this.customerSearchType = input.customerSearchType;
        }

        return this;
    }
}
