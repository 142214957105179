<!-- BEGIN: Left Aside -->
<button id="m_aside_left_close_btn" appunwraptag="" class="m-aside-left-close m-aside-left-close--skin-dark">
    <i class="la la-close"></i>
</button>
<div id="m_aside_left" class="m-grid__item m-aside-left m-aside-left--skin-dark mf-custom-aside-left">
    <!-- BEGIN: Aside Menu -->
    <div
        id="m_ver_menu"
        data-menu-vertical="true"
        data-menu-scrollable="true"
        data-menu-dropdown-timeout="500"
        style="height: 400px"
        class="m-aside-menu m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark"
    >
        <div class="m-stack__item m-brand m-brand--skin-dark m--hidden-tablet-and-mobile fixed-top">
            <div class="m-stack m-stack--ver m-stack--general">
                <div class="m-stack__item m-stack__item--middle m-brand__logo m--hidden-tablet-and-mobile">
                    <a routerLink="/index" class="m-brand__logo-wrapper d-flex">
                        <img alt="" src="./assets/demo/demo5/media/img/logo/logo-grey-color.svg" width="140" height="100%" class="m-auto" />
                    </a>
                </div>
                <div class="m-stack__item m-stack__item--middle m-brand__tools m--hidden-mobile">
                    <!-- BEGIN: Left Aside Minimize Toggle Mobile -->
                    <a
                        (click)="toggleMenu()"
                        id="m_aside_left_minimize_toggle2"
                        class="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-desktop-inline-block"
                    >
                        <span></span>
                    </a>
                    <!-- END -->
                </div>
            </div>
        </div>
        <ul class="m-menu__nav m-menu__nav--dropdown-submenu-arrow">
            <li
                *ngFor="let menuItem of mainMenu"
                [routerLinkActiveOptions]="{ exact: false }"
                routerLinkActive="m-menu__item--active  m-menu__item--open"
                data-menu-submenu-toggle="hover"
                aria-haspopup="true"
                class="m-menu__item m-menu__item--submenu"
            >
                <!-- MENUITEM WITHOUTH SUBMENU -->
                <ng-container *ngIf="!menuItem?.action">
                    <a
                        *ngIf="!menuItem?.submenu"
                        [disableTooltip]="!isMenuMinimized"
                        routerLink="{{ menuItem.link }}"
                        ngbTooltip="{{ menuItem.title }}"
                        container="body"
                        placement="right"
                        class="m-menu__link"
                    >
                        <i class="m-menu__link-icon" [ngClass]="menuItem.icon"></i>
                        <span class="m-menu__link-title">
                            <span class="m-menu__link-wrap">
                                <span class="m-menu__link-text">
                                    {{ menuItem.title }}
                                </span>

                                <asn-status [menuItem]="menuItem"></asn-status>
                            </span>
                        </span>
                    </a>
                    <!-- MENUITEM WITH SUBMENU -->
                    <ng-container *ngIf="menuItem?.submenu">
                        <a href="#" class="m-menu__link m-menu__toggle">
                            <i class="m-menu__link-icon" [ngClass]="menuItem?.icon"></i>

                            <span class="m-menu__link-title">
                                <span class="m-menu__link-wrap">
                                    <span class="m-menu__link-text">
                                        {{ menuItem.title }}
                                    </span>
                                    <i class="m-menu__ver-arrow la la-angle-right"></i>
                                </span>
                            </span>
                        </a>
                        <div class="m-menu__submenu">
                            <span class="m-menu__arrow"></span>
                            <ul class="m-menu__subnav">
                                <li
                                    *ngFor="let subMenuItem of menuItem?.submenu"
                                    routerLinkActive="m-menu__item--active"
                                    routerLinkActiveOptions="{ exact: false }"
                                    aria-haspopup="true"
                                    data-menu-submenu-toggle="hover"
                                    class="m-menu__item m-menu__item--submenu"
                                >
                                    <a
                                        *ngIf="subMenuItem?.link; else noLink"
                                        routerLink="{{ subMenuItem?.link }}"
                                        routerLinkActiveOptions="{ exact: true }"
                                        class="m-menu__link m-menu__toggle"
                                    >
                                        <i *ngIf="subMenuItem?.icon" class="m-menu__link-icon" [ngClass]="subMenuItem?.icon"></i>
                                        <span class="m-menu__link-title">
                                            <span class="m-menu__link-wrap">
                                                <span class="m-menu__link-text">
                                                    {{ subMenuItem?.title }}
                                                </span>
                                            </span>
                                        </span>
                                    </a>
                                    <ng-template #noLink>
                                        <a href="#" class="m-menu__link m-menu__toggle">
                                            <span class="m-menu__link-text">
                                                {{ subMenuItem?.title }}
                                            </span>
                                        </a>
                                    </ng-template>
                                </li>
                            </ul>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="menuItem?.action">
                    <a
                        *ngIf="menuItem?.action"
                        [disableTooltip]="!isMenuMinimized"
                        (click)="menuAction(menuItem?.action)"
                        href="#"
                        ngbTooltip="{{ menuItem.title }}"
                        container="body"
                        placement="right"
                        class="m-menu__link"
                    >
                        <i class="m-menu__link-icon" [ngClass]="menuItem.icon"></i>
                        <span class="m-menu__link-title">
                            <span class="m-menu__link-wrap">
                                <span class="m-menu__link-text">
                                    {{ menuItem.title }}
                                </span>

                                <asn-status [menuItem]="menuItem"></asn-status>
                            </span>
                        </span>
                    </a>
                </ng-container>
            </li>
            <li class="m-menu__section">
                <h4 class="m-menu__section-text" i18n="@@layouts.asidenav.menu.user.title">Uporabnik</h4>
                <i class="m-menu__section-icon la la-ellipsis-h"></i>
            </li>
            <li *ngxPermissionsOnly="['menu_main_notifications__Visible']" aria-haspopup="true" class="m-menu__item">
                <a (click)="openNotificatonSidebar()" href="#" class="m-menu__link m-dropdown__toggle">
                    <i class="m-menu__link-icon la la-bell"></i>
                    <span class="m-menu__link-title">
                        <span class="m-menu__link-wrap">
                            <span class="m-menu__link-text" i18n="@@layouts.asidenav.menu.item.notification.title">Obvestila</span>
                            <span *ngIf="numberOfNotifications !== 0" class="m-menu__link-badge">
                                <span class="m-badge m-badge--danger">
                                    {{ numberOfNotifications }}
                                </span>
                            </span>
                        </span>
                    </span>
                </a>
            </li>
            <li
                *ngxPermissionsOnly="['menu_main_settings__Visible']"
                aria-haspopup="true"
                data-menu-submenu-toggle="hover"
                routerLinkActive="m-menu__item--active m-menu__item--open"
                routerLinkActiveOptions="{ exact: false }"
                class="m-menu__item m-menu__item--submenu"
            >
                <a href="#" class="m-menu__link m-menu__toggle">
                    <i class="m-menu__link-icon la la-cog"></i>
                    <span class="m-menu__link-text">Nastavitve</span>
                    <i class="m-menu__ver-arrow la la-angle-right"></i>
                </a>
                <div class="m-menu__submenu">
                    <span class="m-menu__arrow"></span>
                    <ul class="m-menu__subnav">
                        <li
                            *ngFor="let menuItem of settingsMenu"
                            routerLinkActive="m-menu__item--active"
                            routerLinkActiveOptions="{ exact: false }"
                            aria-haspopup="true"
                            data-menu-submenu-toggle="hover"
                            class="m-menu__item m-menu__item--submenu"
                        >
                            <a
                                *ngIf="menuItem.link; else noLink"
                                routerLink="{{ menuItem.link }}"
                                routerLinkActiveOptions="{ exact: true }"
                                class="m-menu__link m-menu__toggle"
                            >
                                <span class="m-menu__link-title">
                                    <span class="m-menu__link-wrap">
                                        <span class="m-menu__link-text">
                                            {{ menuItem.title }}
                                        </span>
                                    </span>
                                </span>
                            </a>
                            <ng-template #noLink>
                                <a href="#" class="m-menu__link m-menu__toggle">
                                    <span class="m-menu__link-text">
                                        {{ menuItem.title }}
                                    </span>
                                </a>
                            </ng-template>

                            <div *ngIf="menuItem.submenu" style="display: block" class="m-menu__submenu">
                                <span class="m-menu__arrow"></span>
                                <ul class="m-menu__subnav">
                                    <li
                                        *ngFor="let sub of menuItem.submenu"
                                        routerLinkActiveOptions="{ exact: false }"
                                        aria-haspopup="true"
                                        routerLinkActive="m-menu__item--active"
                                        class="m-menu__item m-menu__item--submenu"
                                    >
                                        <a routerLink="{{ sub.link }}" routerLinkActiveOptions="{ exact: false }" class="m-menu__link m-menu__toggle">
                                            <span class="m-menu__link-title">
                                                <span class="m-menu__link-wrap">
                                                    <span class="m-menu__link-text">
                                                        {{ sub.title }}
                                                    </span>
                                                </span>
                                            </span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </li>

            <li aria-haspopup="true" data-menu-submenu-toggle="hover" class="m-menu__item m-menu__item--submenu">
                <a href="#" ngbTooltip="{{ selectedContractor?.name }}" disableTooltip="true" class="m-menu__link m-menu__toggle">
                    <i class="m-menu__link-icon la la-hospital-o"></i>
                    <span class="m-menu__link-text">
                        {{ selectedContractor?.name }}
                    </span>
                    <i *ngIf="loggedingUser?.contractors.length > 1" class="m-menu__ver-arrow la la-angle-right"></i>
                </a>
                <div *ngIf="loggedingUser?.contractors.length > 1" class="m-menu__submenu">
                    <span class="m-menu__arrow"></span>
                    <ul class="m-menu__subnav">
                        <li
                            *ngFor="let contr of loggedingUser?.contractors | filterArrayById: selectedContractor.id"
                            routerLinkActive="m-menu__item--active"
                            routerLinkActiveOptions="{ exact: false }"
                            aria-haspopup="true"
                            class="m-menu__item"
                        >
                            <a (click)="setContractor(contr)" href="#" class="m-menu__link">
                                <span class="m-menu__link-text">{{ contr?.name }}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
            <li
                aria-haspopup="true"
                data-menu-submenu-toggle="hover"
                routerLinkActive="m-menu__item--active m-menu__item--open"
                routerLinkActiveOptions="{ exact: false }"
                class="m-menu__item m-menu__item--submenu"
            >
                <a href="#" class="m-menu__link m-menu__toggle">
                    <div class="m-menu__link-icon mf-menu-icon__user">
                        <common-subcontractor-icon [user]="loggedingUser"></common-subcontractor-icon>
                    </div>
                    <span class="m-menu__link-text">
                        {{ loggedingUser?.name }}
                    </span>
                    <i class="m-menu__ver-arrow la la-angle-right"></i>
                </a>
                <div class="m-menu__submenu">
                    <span class="m-menu__arrow"></span>
                    <ul class="m-menu__subnav">
                        <li
                            *ngxPermissionsOnly="['menu_main_settings_profile__Visible']"
                            routerLinkActive="m-menu__item--active"
                            routerLinkActiveOptions="{ exact: true }"
                            aria-haspopup="true"
                            class="m-menu__item"
                        >
                            <a routerLink="/settings/profile" class="m-menu__link">
                                <span class="m-menu__link-text">Profil</span>
                            </a>
                        </li>
                        <li
                            routerLinkActive="m-menu__item--active"
                            routerLinkActiveOptions="{ exact: true }"
                            aria-haspopup="true"
                            class="m-menu__item"
                        >
                            <a href="https://podpora.medifit.si/eambulanta" target="_blank" class="m-menu__link">
                                <span class="m-menu__link-text">
                                    Pomoč
                                    <i class="la la-external-link"></i>
                                </span>
                            </a>
                        </li>
                        <li
                            routerLinkActive="m-menu__item--active"
                            routerLinkActiveOptions="{ exact: true }"
                            aria-haspopup="true"
                            class="m-menu__item"
                        >
                            <a (click)="closeIncomingCallPopup()" routerLink="/logout" class="m-menu__link">
                                <span class="m-menu__link-text">Odjava</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
        <div *ngIf="isTest">
            <span class="mf-notificiation-of-test-instance text-center">TEST</span>
        </div>
        <div class="mf-version-number"><mds-app-info></mds-app-info></div>
    </div>
    <!-- END: Aside Menu -->
</div>
<!-- END: Left Aside -->
