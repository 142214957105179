import * as _ from 'lodash';
import { Serializable } from '../deserialize.service';

export class ConnectCase implements Serializable<ConnectCase> {
    bsid: number;
    caseId: string;
    caseInternalId: number;
    deserialize(input) {
        if (_.isNil(input)) {
            return undefined;
        }
        this.bsid = input.bsid;
        this.caseId = input.caseId;
        this.caseInternalId = input.caseInternalId;

        return this;
    }
}
