export enum ReservationType {
    HDD = 'HDD',
    HDP = 'HDP',
    SAVAP = 'SAVAP',
    SAVAD = 'SAVAD',
    MDFTD = 'MDFTD',
    MDFTP = 'MDFTP',
    ASISTENT = 'ASISTENT',
    WIDGET = 'WIDGET',
    EA = 'EA',
    PREVENTIVNI = 'PREVENTIVNI',
    WIDGET_VOUCHER = 'WIDGET_VOUCHER',
    LAIFD = 'LAIFD',
    LAIFP = 'LAIFP',
}
