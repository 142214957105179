<h1 class="mf-popup-title">{{ title }}</h1>
<div class="m-portlet mf-portlet-gray__gray-background">
    <div class="m-portlet__body">
        <div class="m-content">
            <div class="row">
                <div
                    class="mf-content-portfolio-overflow height-auto-sm"
                    [ngClass]="{
                        'col-12 col-xl-6': (isContainerEqualSize$ | async) === true,
                        'col-12 col-xl-9': (isContainerEqualSize$ | async) === false
                    }"
                >
                    <ng-template pfHost></ng-template>
                </div>

                <div
                    [ngClass]="{
                        'col-12 col-xl-6': (isContainerEqualSize$ | async) === true,
                        'col-12 col-xl-3': (isContainerEqualSize$ | async) === false
                    }"
                >
                    <div class="mf-content-portfolio-overflow">
                        <ng-container #dynamicContentContainer></ng-container>
                        <ng-container *ngIf="detailsForm">
                            <pf-comn-details [parent]="detailsForm"></pf-comn-details>
                        </ng-container>
                        <pf-comn-template *ngIf="paper?.paperType !== 'MEDIFIT_MEDICAL_REPORT'" [paper]="paper"></pf-comn-template>
                        <pf-comn-history-list
                            [G100]="event?.originUrl === 'G100_03'"
                            [paper]="paper"
                            [customer]="customer"
                            [event]="event"
                            [sourceVisit]="sourceVisit"
                        ></pf-comn-history-list>
                        <pf-comn-versions-list [setPaper]="paper" [setCase]="case"></pf-comn-versions-list>
                        <pf-comn-actions
                            [case]="case"
                            [disabled]="isNew"
                            [paper]="paper"
                            [customer]="customer"
                            [detailsForm]="detailsForm"
                        ></pf-comn-actions>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
