export class ClearWebSocketState {
    public static readonly type = '[state] ClearWebSocketState';
}

// TODO Ignored with eslint-interactive on 2023-11-10
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace WebsocketActions {
    export class InitWebSocket {
        public static readonly type = '[WS] InitWebSocket';
    }

    export class CloseWebSocket {
        public static readonly type = '[WS] CloseWebSocket';
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace InboxDevices {
        export class GetAndLoadNumberOfDeviceFiles {
            public static readonly type = '[Inbox] GetAndLoadNumberOfDeviceFiles';
        }

        export class SetNumberOfDeviceFiles {
            public static readonly type = '[Inbox] SetNumberOfDeviceFiles';
            constructor(public readonly numberOfFiles: number) {}
        }
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace Notifications {
        export class GetAndLoadNumberOfInquiry {
            public static readonly type = '[Inbox] GetAndLoadNumberOfInquiry';
        }
        export class GetAndLoadNumberOfNotifications {
            public static readonly type = '[Inbox] GetAndLoadNumberOfNotifications';
        }
        export class SetNewNotifications {
            public static readonly type = '[Inbox] SetNewNotifications';
            constructor(public readonly numberOfNewNotifications: number) {}
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace Telemedicine {
        export class GetAndLoadNumberOfNotifications {
            public static readonly type = '[Telemedicine] GetAndLoadNumberOfNotifications';
        }
        export class SetNewNotifications {
            public static readonly type = '[Telemedicine] SetNewNotifications';
            constructor(public readonly numberOfNewNotifications: number) {}
        }

        export class ResolveNotifications {
            public static readonly type = '[Telemedicine] ResolveNotifications';
            constructor(public readonly numberOfResolvedNotifications: number) {}
        }
    }
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace Triage {
        export class ResolveNotifications {
            public static readonly type = '[Triage] ResolveNotifications';
            constructor(public readonly numberOfResolvedNotifications: number) {}
        }
        export class GetAndLoadNumberOfNotifications {
            public static readonly type = '[Triage] GetAndLoadNumberOfNotifications';
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-namespace
    export namespace Survey {
        export class ResolveNotifications {
            public static readonly type = '[Survey] ResolveNotifications';
            constructor(public readonly numberOfResolvedNotifications: number) {}
        }
        export class GetAndLoadNumberOfNotifications {
            public static readonly type = '[Survey] GetAndLoadNumberOfNotifications';
        }
    }
}
