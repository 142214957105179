import * as _ from 'lodash';
import { Serializable } from '../deserialize.service';

export class PrintPOSContractor implements Serializable<PrintPOSContractor> {
    id: number;
    name: string;
    address: string;
    description: string;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    locations: any[];
    phone: string;
    post: string;
    postNumber: string;
    email: string;
    academicTitle: string;
    education: string;
    IBAN: string;
    taxId: string;
    registrationNumber: string;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        if (_.isNil(input)) {
            return undefined;
        }
        this.id = input.id;
        this.name = input.name;
        this.address = input.address;
        this.description = input.description;
        this.locations = input.locations;
        this.phone = input.phone;
        this.post = input.post;
        this.postNumber = input.postNumber;
        this.email = input.email;
        this.academicTitle = input.academicTitle;
        this.education = input.education;
        this.IBAN = input.IBAN;
        this.taxId = input.taxId;
        this.registrationNumber = input.registrationNumber;
        return this;
    }
}
