import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-send-terms-steps',
    templateUrl: './send-terms-steps.component.html',
    styles: [],
})
export class SendTermsStepsComponent implements OnInit {
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}

    @Input() step = 1;
    @Input() customClass: string = null;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    unsupportedServices: any[];
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Input() set setUnsupportedServices(value: any[]) {
        this.unsupportedServices = value;
        this.getSteps(this.serviceId);
    }

    serviceId: number;
    @Input() set setServiceId(value: number) {
        this.serviceId = value;
        this.getSteps(this.serviceId);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public currentStep: any = {};
    public flowSteps = [];

    public steps = [
        {
            step: 1,
            total: 0,
            description: 'Napotitev na preventivo',
        },
        {
            step: 2,
            total: 0,
            description: 'Stranka napotena na preventivo',
        },
        {
            step: 3,
            total: 0,
            description: 'Stranka naročena na termin',
        },
        {
            step: 4,
            total: 0,
            description: 'Termin pri zunanjem izvajalcu zaključen',
        },
        {
            step: 5,
            total: 0,
            description: 'Stranka napotena na follow up posvet',
        },
        {
            step: 6,
            total: 0,
            description: 'Stranka naročena na follow up posvet',
        },
        {
            step: 7,
            total: 0,
            description: 'Deljenje dokumentacije zunanjega izvajalca',
        },
    ];

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit(): void {}

    public getSteps(serviceId) {
        const service = this.unsupportedServices?.find(el => el.id == serviceId);
        if (service) {
            this.flowSteps = this.steps.filter(x => service?.steps.some(y => y === x.step));
        } else {
            this.flowSteps = this.steps;
        }

        this.getCurrentStep();
    }

    public getCurrentStep() {
        this.currentStep = {};

        if (this.flowSteps) {
            const stepInfo = this.flowSteps?.filter(s => s.step === this.step);
            stepInfo[0].total = this.flowSteps?.length;
            this.currentStep = {
                total: stepInfo[0].total,
                description: stepInfo[0].description,
                step: this.flowSteps.indexOf(stepInfo[0]) + 1,
            };
        } else {
            const stepInfo = this.steps;
            stepInfo[0].total = this.steps.length;
            this.currentStep = {
                total: stepInfo[0].total,
                description: stepInfo[0].description,
                step: stepInfo[0].step,
            };
        }
    }
}
