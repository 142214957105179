import { Component, Input, OnInit } from '@angular/core';
// services
import { HelperService } from '../../../_shared/services';

@Component({
    selector: 'common-hd-app-icon',
    templateUrl: './hd-app-icon.component.html',
    styles: [],
})
export class HdAppIconComponent implements OnInit {
    @Input() set setApp(value) {
        if (value) {
            this.app = value;
            this.loadIcon();
        }
    }

    @Input()
    public height = 24;

    @Input()
    public width: number;

    @Input()
    public onlyIcon = false;

    @Input()
    public showLogo = false;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    app: any;

    constructor(private helper: HelperService) {}

    public loadIcon() {
        this.app = this.helper.getApp(this.app);
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method, @typescript-eslint/no-empty-function
    ngOnInit() {}
}
