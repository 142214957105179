import { Component, Input } from '@angular/core';
import { defer, Observable, of } from 'rxjs';
import { scan } from 'rxjs/operators';
import { HelperService } from './../../_shared/services/helper.service';
import { CanLeaveService } from './can-leave.service';

@Component({
    selector: 'mf-can-leave',
    template: '<ng-template><ng-content></ng-content></ng-template>',
})
export class CanLeaveComponent {
    constructor(
        private canLeave: CanLeaveService,
        private helper: HelperService,
    ) {
        this.canLeave.allowDeactivation(this.canLeave$);
    }

    /** When true, pops-up a dialog asking for user's consent to leave*/
    @Input() dontLeave = false;
    @Input() textHeader: string;
    @Input() textBody: string;

    private get canLeave$(): Observable<boolean> {
        return defer(() =>
            this.dontLeave
                ? this.helper
                      .displayAlert(
                          this.textHeader ? this.textHeader : 'Na strani imate neshranjene spremembe.',
                          this.textBody ? this.textBody : 'Želite nadaljevati?',
                      )
                      .then(el => {
                          if (el.value) {
                              return true;
                          } else {
                              return false;
                          }
                      })
                : of(true),
        ).pipe(scan((granted, allow) => granted || allow, false));
    }
}
