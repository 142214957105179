import { Serializable } from '../deserialize.service';

export class PrintPOSInvoiceTax implements Serializable<PrintPOSInvoiceTax> {
    public issuer: string;
    public ZOI: string;
    public EOR: string;
    public QRData: string;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        this.issuer = input.issuer;
        this.ZOI = input.ZOI;
        this.EOR = input.EOR;
        this.QRData = input.QRData;

        return this;
    }
}
