import { Serializable } from '../deserialize.service';
import { Service } from '../service.model';

export class PriceListElement implements Serializable<PriceListElement> {
    id: number;
    service: Service;
    discount: number;
    finalPrice: number;
    selected: boolean;
    price: number;
    serviceName: string;
    serviceId: number;
    length: number;

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        this.id = input.id;
        if (input.service) {
            this.service = new Service().deserialize(input.service);
        }
        this.discount = input.discount;
        this.finalPrice = input.finalPrice;
        this.selected = input.selected;
        this.price = input.price;
        this.serviceName = input.serviceName;
        this.serviceId = parseInt(input.serviceId);
        this.length = input.length;
        return this;
    }
}
