import { ReservationRequest } from '../reservationRequest.model';
import { AvailabilityStatus } from './../../enums/treatment/availabilityStatus.enum';
import { Slot } from './../slot.model';

export class TreatmentReservationRequest extends ReservationRequest {
    treatmentResId: string;
    // isFormValid: boolean = true;
    availabilityStatus: AvailabilityStatus = AvailabilityStatus.NOT_CHECKED;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    termTypeObj: any;
    prereservationId: string;
    includeStartDate = false;
    aggregateId: number;
    freeSlots: Slot[];
    visitId: number;
    internalId: string;
    isDetailsOpend: boolean;
    itemType: string;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        super.deserialize(input);
        return Object.assign(this, input);
    }
}
