import { ExaminationViewItem } from '../../../theme/pages/home/terms/visit/selected-term/examinations/model/examination-view-item';
import { ReservationExaminationFieldDto } from '../../model/examinations/reservation-examination-field-dto';

export class SetDefaultState {
    public static readonly type = 'SetDefaultState';
}

export class LoadExaminations {
    public static readonly type = 'LoadExaminations';
}

export class SetPreReservation {
    public static readonly type = 'SetPreReservation';
    constructor(
        public readonly preReservationId: string,
        public readonly contractorId: number,
    ) {}
}

export class SetSelectedExamination {
    public static readonly type = 'SetSelectedExamination';
    constructor(public readonly selectedReservationExaminationId: number) {}
}

export class SaveReservationExaminations {
    public static readonly type = 'SaveReservationExaminations';
    constructor(public readonly items: ExaminationViewItem[]) {}
}

export class SaveSelectedExamination {
    public static readonly type = 'saveSelectedExamination';
    constructor(
        public readonly fieldsWithValues: ReservationExaminationFieldDto[] | null,
        public readonly comment: string | null,
    ) {}
}
