import { Serializable } from './deserialize.service';
import { TimeListSecText } from './timeListSecText.model';

export class TimeList implements Serializable<TimeList> {
    id: number;
    from: Date;
    primText: string;
    secText: TimeListSecText[];
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    classColor: string;

    deserialize(input) {
        this.from = input.from;
        this.primText = input.primText;
        this.data = input.data;
        this.classColor = input.classColor;
        if (input.secText) {
            this.secText = input.secText.map(text => (text = new TimeListSecText().deserialize(text)));
        }
        return this;
    }
}
