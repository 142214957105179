import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../theme/pages/home/_services';
import { HelperService, StorageService } from '../../_shared/services';
import { HelperNotificationService } from '../../_shared/services/helper-notification.service';
import { TelephonyService } from './../../theme/pages/home/telephony/telephony.service';

@Injectable()
export class AuthenticationService {
    constructor(
        private http: HttpClient,
        private helper: HelperService,
        private helperNotification: HelperNotificationService,
        private storage: StorageService,
        private commonRest: CommonService,
        private telephony: TelephonyService,
    ) {}

    login(username: string, password: string): Observable<boolean> {
        const options = {
            headers: new HttpHeaders({
                authorization: 'Basic ' + environment.AUTHPASS,
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
        };
        return this.http
            .post(
                this.helper.getAuthUrl(),
                'username=' + encodeURIComponent(username?.trim()) + '&password=' + encodeURIComponent(password) + '&grant_type=password',
                options,
            )
            .pipe(
                map(token => {
                    this.storage.saveToken(token);
                    return true;
                }),
                // TODO Ignored with eslint-interactive on 2023-11-10
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                catchError((e: any) => {
                    // this.commonRest.sendWarning(
                    //     'Napaka pri prijavi - *' + username + '* production:' + environment.production,
                    //     e,
                    //     'AuthenticationService',
                    // );
                    console.error('Napaka pri: AuthenticationService');
                    return observableThrowError(e);
                }),
            );
    }

    logout() {
        this.storage.clearToken();
        this.storage.deleteAll();
        this.storage.deleteUserData();
        this.storage.deleteAppData();
        this.storage.deleteContractorSettings();
        this.helperNotification.setNotificationFavicon(false);
        // this.telephony.descructor();
    }
}
