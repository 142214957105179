import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MedicalDocumentationModel } from '../model/medical/medicalDocumentation.model';
import { HttpClientService } from './http-client.service';
import { HelperService } from './index';

@Injectable({
    providedIn: 'root',
})
export class MedicalService {
    constructor(
        private httpClient: HttpClientService,
        private helper: HelperService,
    ) {}

    public setMedicalDocumentation(data: MedicalDocumentationModel): Observable<any> {
        return this.httpClient.post(this.helper.getMedicalUrl(`medical-documentation`), data);
    }

    public deleteMedicalDocumentation(portfolioPaperId: any): Observable<any> {
        return this.httpClient.delete(this.helper.getMedicalUrl(`medical-documentation/${portfolioPaperId}`));
    }
}
