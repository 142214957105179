export enum HelloDoctorWebSocketActions {
    CALL = 'CALL',
    DECLINE = 'DECLINE',
    REMOVE_FROM_LIST = 'REMOVE_FROM_LIST',
    ADD_TO_LIST = 'ADD_TO_LIST',
    REFRESH_CLIENT_LIST = 'REFRESH_CLIENT_LIST',
    SUBCONTRACTORS_LIST = 'SUBCONTRACTORS_LIST',
    LOCK = 'LOCK',
    ERROR = 'ERROR', //napaka --> nekdo je lockal pred tabo subContractor --> id od zdravnika ki je zalockal pred tabo
    SUCCESS = 'SUCCESS', //uspešno si lockal klic --> subContractor --> id od zdravnika ki je uspešno zalockal
    CALL_ENDED = 'CALL_ENDED',
    IDENTIFY = 'IDENTIFY',
    BUSY = 'BUSY',
    FREE = 'FREE',
}
