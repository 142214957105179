import { Serializable } from './deserialize.service';

export class ConsentBoolean implements Serializable<ConsentBoolean> {
    title: string;
    id: number;
    canShareData: boolean;

    deserialize(input) {
        if (!input || input === null) {
            return this.default();
        }
        this.title = input.title;
        this.id = input.id;
        this.canShareData = input.canShareData;

        return this;
    }

    public default(): ConsentBoolean {
        return new ConsentBoolean().deserialize({
            title: 'Uporaba podatkov',
            id: 4,
            canShareData: false,
        });
    }
}
