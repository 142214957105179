import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { User } from '../model';

@Pipe({
    name: 'userTag',
})
export class UserTagPipe implements PipeTransform {
    transform(user: User): string {
        const name: string[] = _.get(user, 'name', '? ?').trim().split(/\s+/);
        if (name.length > 1) {
            return `${name[0][0]}${name[1][0]}`;
        } else {
            return `${name[0][0]}`;
        }
    }
}
