export enum InvoiceStatusType {
    NEW = 'NEW',
    CREATED = 'CREATED',
    DELETED = 'DELETED',
    STORNO = 'STORNO',
    PARTIAL_STORNO = 'PARTIAL_STORNO',
    OPEN = 'OPEN',
    GROUP_INVOICE = 'GROUP_INVOICE',
    CLOSED = 'CLOSED',
}
