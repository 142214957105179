import { GenericModuleSettings } from '../../model/app/genericModuleSettings';
import { Module } from './../../model/app/module.model';
import { Contractor } from './../../model/contractor.model';
import { Service } from './../../model/service.model';
import { Subcontractor } from './../../model/subcontractor.model';
import { User } from './../../model/user.model';
import { ContractorSettings } from './../../model/userGuiSettings/contractorSettings.model';
import { GuiUserContractorSettings } from './../../model/userGuiSettings/guiUserContractorSettings.model';
export class AddServices {
    public static readonly type = 'AddServices';
    constructor(public readonly services: Service[]) {}
}

export class ClearState {
    public static readonly type = 'ClearState';
    // constructor(public readonly services: Service[]) {}
}

export class ClearServiceCache {
    public static readonly type = 'ClearServiceCache';
    // constructor(public readonly services: Service[]) {}
}

export class GetAndLoadServices {
    public static readonly type = 'GetServices';
    // constructor(public readonly services: Service[]) {}
}

export class SetActiveContractor {
    public static readonly type = 'SetActiveContractor';
    constructor(public readonly contractor: Contractor) {}
}

export class SetCalendarFilter {
    public static readonly type = 'SetCalendarFilter';
    constructor(
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        public filter: any,
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        public timeFrequency?: any,
    ) {}
}

export class SetUserSettings {
    public static readonly type = 'SetUserSettings';
    constructor(public readonly userGuiSettings: GuiUserContractorSettings) {}
}

export class SetContractorSettings {
    public static readonly type = 'SetContractorSettings';
    constructor(public readonly contractorSettings: ContractorSettings) {}
}
export class SetDefaultUserSettings {
    public static readonly type = 'SetDefaultUserSettings';
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(public readonly settings: any) {}
}
export class SetCalendarSubcontractors {
    public static readonly type = 'SetCalendarSubcontractors';
    constructor(public readonly newSelectedSubcontractors: Subcontractor[]) {}
}
export class SetLoggedUser {
    public static readonly type = '[User] SetLoggedUser';
    constructor(public readonly user: User) {}
}

export class GetAndLoadSubcontractors {
    public static readonly type = '[User] GetSubcontractors';
}

export class GetAndLoadAssets {
    public static readonly type = '[User] GetAssets';
}

export class ChangeWizardTodoStatus {
    public static readonly type = 'ChangeWizardTodoStatus';
    constructor(public readonly settings: { completed: boolean; id: number }) {}
}
export class GetAndLoadWizardSettings {
    public static readonly type = 'GetAndLoadWizardSettings';
}

//TODO
export class SetToken {
    public static readonly type = '[app] SetToken';
    constructor(public readonly user: User) {}
}

export class SetActiveModules {
    public static readonly type = '[app] SetActiveModules';
    constructor(public readonly modules: Module<GenericModuleSettings>[]) {}
}
