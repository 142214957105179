import { Contractor } from '../contractor.model';
import { Serializable } from '../deserialize.service';
import { CalendarFilterSettings } from './calendarFilterSettings.model';

export class CalendarUserGuiSettings implements Serializable<CalendarUserGuiSettings> {
    filter: CalendarFilterSettings;
    subconstractors: Contractor[];
    timeFrequency: number;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        if (!input || input === null) {
            return null;
        }
        this.filter = new CalendarFilterSettings().deserialize(input.filter);
        if (input.subconstractors) {
            this.subconstractors = input.subconstractors.map(sub => new Contractor().deserialize(sub));
        }
        this.timeFrequency = input.timeFrequency;
        return this;
    }
}
