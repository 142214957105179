import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import moment from 'moment';
import { Subject } from 'rxjs';
import { map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { PopupPaper, PopupPaperData } from '../../../../_shared/model/portfolio/popup-paper';
import { PfType } from '../portfolio-papers/_common/enum/pf-type.enum';
import { CommonService, ReservationService } from '../_services';
import { tags } from './../../../../_shared/constants/customTags.const';
import { AppModulesType } from './../../../../_shared/enums/appModulesType.enum';
import { PaperType } from './../../../../_shared/enums/PaperType.enum';
import { ReservationType } from './../../../../_shared/enums/schedule/reservationType.enum';
import { VisitExitType } from './../../../../_shared/enums/visitExitType.enum';
import { PortfolioPaper } from './../../../../_shared/model/portfolio/portfolioPaper.model';
import { Reservation } from './../../../../_shared/model/reservation.model';
import { EventNew } from './../../../../_shared/model/schedule/eventNew.model';
import { PaperNamePipe } from './../../../../_shared/pipes/paper-name.pipe';
import { ActiveModulesService } from './../../../../_shared/services/activeModules.service';
import { HelperNavigateService } from './../../../../_shared/services/helper-navigate.service';
import { HelperService } from './../../../../_shared/services/helper.service';
import { MfToastService } from './../../../../_shared/services/mfToast.service';
import { ParamsDataService } from './../../../../_shared/services/params-data.service';
import { StorageService } from './../../../../_shared/services/storage.service';
import { BaseState } from './../../../../_shared/state/base/base.state';
import { TermsService } from './../_services/terms.service';
import { DocumentationElement } from './_model/documentation-element';

@Component({
    selector: 'app-documentation-overview',
    templateUrl: './documentation-overview.component.html',
    styleUrls: ['./documentation-overview.component.scss'],
})
export class DocumentationOverviewComponent implements OnInit {
    constructor(
        private activeModules: ActiveModulesService,
        public helper: HelperService,
        private termsRest: TermsService,
        private store: Store,
        private navigate: HelperNavigateService,
        private paperNamePipe: PaperNamePipe,
        private modalService: NgbModal,
        private reservationRest: ReservationService,
        private toastr: MfToastService,
        private paramsData: ParamsDataService,
        private storage: StorageService,
        private common: CommonService,
    ) {}
    public isDocumentationModuleActive = false;
    public isHDModuleActive = false;
    public onDestroy$ = new Subject<void>();
    private contractorId = this.store.selectSnapshot(BaseState.activeContractorId);
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public options: any = this.helper.getDateRangeSettngs();
    public allReservationsData: DocumentationElement[] = [];
    public allReservationsDataCopy: DocumentationElement[] = [];
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public allSubcontractors: any = [];
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public daterange: any = {};
    public searchString = '';
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public searchSubcontractorsString: any;
    public pfType = PfType;
    public userRoles: string[] = [];

    ngOnInit(): void {
        this.isDocumentationModuleActive = this.activeModules.isAM(AppModulesType.DOCUMENTATION_OVERVIEW);
        this.isHDModuleActive = this.activeModules.isAM(AppModulesType.HELLO_DOCTOR);
        this.userRoles = this.storage.getUserRoles();
        this.daterange.start = moment().subtract(1, 'week');
        this.daterange.end = moment();
        this.paramsData.getParamsData().subscribe(paramsData => {
            this.useDataFromParams(paramsData);
            this.getReservationsData();
        });
        this.getAllSubcontractors();
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    ngOnDestroy() {
        this.onDestroy$.next();
    }

    get isAbleToChangeStatus() {
        return this.userRoles?.includes('ROLE_OWNER') || this.userRoles?.includes('ROLE_SEKRETAR');
    }
    public useDataFromParams({ from, to, searchString }) {
        //fill TIME filter from URL parameters
        if (from && to && moment(from).isValid() && moment(to).isValid()) {
            const newStart = moment(from).isValid() ? moment(from) : undefined;
            const newEnd = moment(to).isValid() ? moment(to) : undefined;
            this.daterange.start = newStart;
            this.daterange.end = newEnd;
            this.options = { ...this.options, startDate: newStart, endDate: newEnd };
        }
        //fill TEXT filters (generic search string) from URL parameters
        if (searchString) {
            this.searchString = searchString;
        }
    }

    public filterData(type?) {
        if (type === 'Subcontractors') {
            const subcontrator = this.searchSubcontractorsString?.name?.toLowerCase();
            this.allReservationsData = this.allReservationsDataCopy?.filter(
                reservation => reservation?.subcontractorName?.toLowerCase().includes(subcontrator ? subcontrator : ''),
            );
        } else {
            this.allReservationsData = this.allReservationsDataCopy?.filter(
                reservation =>
                    reservation?.customerName?.toLowerCase().includes(this.searchString) ||
                    reservation?.subcontractorName?.toLowerCase().includes(this.searchString) ||
                    reservation?.serviceName?.toLowerCase().includes(this.searchString),
            );
        }
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public selectedDate(value: any, datepicker?: any) {
        datepicker.start = value.start;
        datepicker.end = value.end;
        this.daterange.start = value.start;
        this.daterange.end = value.end;
        this.daterange.label = value.label;
        this.paramsData.patchParamsData([
            { key: 'from', value: moment(this.daterange.start).format('YYYY-MM-DD') },
            { key: 'to', value: moment(this.daterange.end).format('YYYY-MM-DD') },
        ]);
        this.getReservationsData();
    }

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public onActivate(event: any) {
        event.type === 'click' && event.cellElement.blur();
    }

    public getReservationsData() {
        this.termsRest
            .getReservationsDocumentationData(this.contractorId, {
                from: this.daterange.start.format('YYYY-MM-DD'),
                to: this.daterange.end.format('YYYY-MM-DD'),
                subcontractorIds:
                    this.userRoles?.includes('ROLE_SUBCONTRACTOR') && !this.userRoles?.includes('ROLE_OWNER')
                        ? [this.storage.getUserData()?.subcontractorId]
                        : [],
            })
            .pipe(
                takeUntil(this.onDestroy$),
                map(allReservations => allReservations.filter(res => res?.customerId)),
                map(reservations => {
                    return reservations.map(res => {
                        if (res?.hdUnansweredCall) {
                            // return { ...res, hdConcludeStatus: VisitExitType.UNANSWERED_CALL }; hide unanswered
                            return;
                        }
                        return res;
                    });
                }),
            )
            .subscribe(reservations => {
                this.allReservationsData = reservations;
                this.allReservationsDataCopy = [...this.allReservationsData];
                this.filterData();
            });
    }
    public updateFilter(type?) {
        if (!type) {
            this.paramsData.patchParamsData([{ key: 'searchString', value: this.searchString }]);
        }
        this.filterData(type);
    }

    private getRecurringReservation(reservation: Reservation): EventNew {
        return new EventNew().deserialize({
            forceUpdate: true,
            contractor: reservation.contractor,
            service: reservation.service,
            recurringId: reservation.recurringId,
            isRecurring: true,
            customer: reservation.customer,
            customTagId: 1,
            recurringType: tags.RECURRING_NEW.obj.SINGLE.sid,
            reservationType: ReservationType.EA,
            asset: reservation.asset,
            telemed: false,
            from: reservation.reservationFrom,
            to: reservation.reservationTo,
            recurring: tags.RECURRING_NEW.obj.SINGLE.sid,
        });
    }

    public onSelect({ selected }) {
        if (selected[0]?.recurringId) {
            //ponavljajoc -> kreiraj termin + redirect
            let newPrereservationId: string;
            this.reservationRest
                .getReservationRecurring(this.contractorId, selected[0].prereservationId)
                .pipe(
                    takeUntil(this.onDestroy$),
                    switchMap(reservation => {
                        const fullReservationData = new Reservation().deserialize({
                            contractor: reservation.contractor,
                            service: reservation.service,
                            customer: reservation.customer,
                            asset: reservation.asset,
                            recurringId: selected[0].recurringId,
                            reservationFrom: selected[0].reservationFrom,
                            reservationTo: selected[0].reservationTo,
                        });
                        return this.reservationRest.sendPreResevation(this.getRecurringReservation(fullReservationData)).pipe(
                            mergeMap(eventData => {
                                newPrereservationId = eventData.prereservationId;
                                return this.reservationRest.sendResevation(eventData);
                            }),
                        );
                    }),
                )
                .subscribe(
                    () => {
                        this.navigate.navigateToReservaton(newPrereservationId, selected[0].isHd || this.isHDModuleActive, {
                            telemed: selected[0].telemed,
                            phoneCall: selected[0].phoneCall,
                        });
                    },
                    () => {
                        this.toastr.error('Napaka pri kreiranju ponavljajočega termina');
                    },
                );
        } else {
            //ni ponavljajoc -> redirect
            this.navigate.navigateToReservaton(selected[0]?.prereservationId, selected[0].isHd || this.isHDModuleActive, {
                telemed: selected[0].telemed,
                phoneCall: selected[0].phoneCall,
            });
        }
    }

    public popupPaper(event, type: string, paper: PortfolioPaper, data: DocumentationElement): void {
        const paperPopupData = {
            prereservationId: data.prereservationId,
            // termData: this.termData,
            // sourceVisit: this.sourceVisit,
            case: { bsid: data?.bsId, caseId: data?.caseId },
            customer: { id: data?.customerId },
            pfType: this.pfType.TERM,
        } as PopupPaperData;

        new PopupPaper(this.modalService, this.paperNamePipe)
            .show(PaperType[type], paperPopupData, paper)
            .subscribe(() => this.getReservationsData());
    }
    getRowClass(row: DocumentationElement): string {
        if (row.contractorUnreadChat) return 'row-error';
        return 'row-no-error';
    }

    getIconClass(row): string {
        if (row.phoneCall) {
            // || row.serviceName?.includes('tel') || row.serviceName?.includes('Tel') //če bo problem vrnit nazaj ta preverjanja
            //telefonski klic
            return 'la-phone';
        } else if (row.telemed) {
            // || row.serviceName?.includes('video') || row.serviceName?.includes('Video') || row.serviceName === 'Halo Doktor' //če bo problem vrnit nazaj ta preverjanja
            //video posvet
            return 'la-video-camera';
        } else {
            //fizični pregled
            return 'la-hand-paper-o';
        }
    }

    getDocumentationStatus(row: DocumentationElement): 'MISSING' | 'IN_PROGRESS' | 'DONE' {
        let status: 'MISSING' | 'IN_PROGRESS' | 'DONE' = 'MISSING';
        const containsMFMIzvid = () =>
            row?.papers.some(paper => paper.paperType === PaperType.MFMEDICAL_IZVID || paper.paperType === PaperType.MEDIFIT_MEDICAL_REPORT);
        const containsHDIzvid = () => row?.papers.some(paper => paper.paperType === PaperType.HELLODOC_IZVID);
        // TODO Ignored with eslint-interactive on 2023-11-10
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const containsHDRefferal = () => row?.papers.some(paper => paper.paperType === PaperType.HELLODOC_REFERRAL);
        const containsNotCanceledBookings = () => row.sentBookings?.some(booking => !booking.canceled);
        const allPapersSharedAndSigned = () => row?.papers.every(paper => paper.shared && paper.signedPaper);

        if (row?.documentationStatus) {
            //ročno overridan status
            return row.documentationStatus;
        }

        if (row?.isHd || row?.bsId || row?.reservationType === ReservationType.WIDGET_VOUCHER) {
            switch (row?.hdConcludeStatus) {
                //custom pogoji glede na izhod
                case VisitExitType.HD_REFERRAL:
                    if (containsHDIzvid() || containsMFMIzvid()) {
                        status = 'IN_PROGRESS';
                    }
                    break;
                case VisitExitType.PHYSICAL_EXAM:
                    if ((containsHDIzvid() || containsMFMIzvid()) && containsNotCanceledBookings()) {
                        status = 'IN_PROGRESS';
                    }
                    break;
                case VisitExitType.PUBLIC_HEALTH:
                case VisitExitType.SELF_HELP:
                case VisitExitType.LIFE_STYLE:
                    if (containsHDIzvid() || containsMFMIzvid()) {
                        status = 'IN_PROGRESS';
                    }
                    break;
                case VisitExitType.TECHNICAL_ISSUE:
                case VisitExitType.UNANSWERED_CALL:
                    status = 'DONE';
                    break;
            }
            if (status === 'IN_PROGRESS' && allPapersSharedAndSigned()) {
                //vse listine podpisane + poslane -> DONE
                status = 'DONE';
            }
        } else {
            if (containsMFMIzvid() || row?.papers.some(paper => paper.paperType === PaperType.IZVID)) {
                //ne HD termini
                status = 'DONE';
            }
        }
        return status;
    }

    public changeTermStatus(term: DocumentationElement, status: 'MISSING' | 'IN_PROGRESS' | 'DONE') {
        this.termsRest.changeDocumentationStatus(this.contractorId, term.prereservationId, status).subscribe(() => {
            term.documentationStatus = status;
        });
    }

    getAllSubcontractors() {
        this.common.getAllSubcontractors(this.contractorId).subscribe(res => {
            this.allSubcontractors = res;
        });
    }
}
