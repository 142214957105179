import { Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { environment } from '../../../environments/environment';
import { User } from './../model/user.model';
import { StorageService } from './storage.service';

// const bugsnagClient = bugsnag({
//     apiKey: '4c8b0c632c20c7d5d987858bb90936a3',
//     appVersion: environment.VERSION,
//     autoBreadcrumbs: false,
// });
@Injectable()
export class ErrorNotifyService {
    constructor(private storage: StorageService) {}

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public send(error: any, severity?: any): void {
        const bugsnagClient = Bugsnag.start({
            apiKey: '4c8b0c632c20c7d5d987858bb90936a3',
            appVersion: environment.VERSION,
            releaseStage: 'production',
        });
        if (this.storage.getUserData()) {
            const user: User = this.storage.getUserData();
            bugsnagClient?.setUser(user.id + '', user.email, `${user.name} ${user.surname}`);
        }
        if (error) {
            bugsnagClient?.notify(new Error(error), severity);
        } else {
            bugsnagClient?.notify(new Error('neznano'), severity);
        }
    }
}
