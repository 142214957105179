import * as _ from 'lodash';
import { Customer } from '../customer.model';
import { Serializable } from '../deserialize.service';
import { ServiceGroup } from './../serviceGroup.model';

export class EventUpdate implements Serializable<EventUpdate> {
    dateFrom: Date;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dateTo: any;
    subcontractorId: number;
    customer: Customer;
    serviceId: number;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    assetId: any;
    comment: string;
    forceUpdate = false;
    sendSms = false;
    sendEmail = false;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customTagId: any;
    title: string;
    newCustomer: boolean;
    telemed: boolean;
    serviceGroup: ServiceGroup;
    kanbanStatus: string;

    //case
    bsId: number;
    caseId: string;
    caseInternalId: string;
    deserialize(input) {
        if (_.isNil(input)) {
            return this;
        }
        this.dateFrom = input.dateFrom;
        this.dateTo = input.dateTo;
        this.subcontractorId = input.subcontractorId;
        this.customer = new Customer().deserialize(input.customer);
        this.serviceId = input.serviceId;
        this.assetId = input.assetId;
        this.comment = input.comment;
        this.sendSms = input.sendSms;
        this.sendEmail = input.sendEmail;
        this.customTagId = input.customTagId;
        this.title = input.title;
        this.newCustomer = input.newCustomer;
        this.telemed = input.telemed;
        this.serviceGroup = input.serviceGroup;
        this.bsId = input.bsId;
        this.caseId = input.caseId;
        this.caseInternalId = input.caseInternalId;
        this.forceUpdate = input.forceUpdate;
        this.kanbanStatus = input.kanbanStatus;
        return this;
    }
}
