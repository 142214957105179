import * as _ from 'lodash';
import * as moment from 'moment';
import { InvoiceStatusType } from '../../../enums/accounting/invoiceStatusType.enum';
import { Serializable } from '../../deserialize.service';
import { InvoiceDocumentType } from './../../../enums/accounting/invoiceDocumentType.enum';
import { Service } from './../../service.model';
import { IrInvoiceData } from './irInvoiceData.model';
import { IrInvoiceService } from './irInvoiceService.model';
import { IrLocalePrint } from './irLocalePrint.model';
import { IrPaymentTypeInfo } from './irPaymentTypeInfo.model';

export class InvoiceReport implements Serializable<InvoiceReport> {
    invoiceId: number;
    visitId: number;
    contractorId: number;
    subcontractorId: number;
    isCopy: boolean;
    messageID: string;
    dateTime: moment.Moment;
    localePrint: IrLocalePrint;
    invoiceData: IrInvoiceData;
    invoiceServiceList: IrInvoiceService[];
    status: InvoiceStatusType;
    paymentTypes: IrPaymentTypeInfo[];
    printNo: number;
    groupPayerCustomerId: number;
    groupPayerPartnerId: number;
    groupCustomerId: number;
    groupVisitId: number;
    invoiceDocumentType: InvoiceDocumentType;
    groupInvoiceServiceList: Service[];
    paidDate: moment.Moment;
    isVisitConnectedInvoice: boolean;
    isCoupon: boolean;
    shared: boolean;
    lastPublicAccess: moment.Moment | Date;
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        if (_.isNil(input)) {
            return undefined;
        }

        this.invoiceId = input.invoiceId;
        this.visitId = input.visitId;
        this.contractorId = input.contractorId;
        this.subcontractorId = input.subcontractorId;
        this.isCopy = input.isCopy;
        this.messageID = input.messageID;
        this.dateTime = input.dateTime;

        this.localePrint = input.localePrint;
        this.invoiceData = new IrInvoiceData().deserialize(input.invoiceData);
        this.invoiceServiceList = input.invoiceServiceList;
        this.status = input.status;
        if (_.isNil(this.status)) {
            this.status = InvoiceStatusType.NEW;
        }
        if (!_.isNil(input.paymentTypes)) {
            // TODO Ignored with eslint-interactive on 2023-11-10
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            this.paymentTypes = input.paymentTypes.map((el: any) => new IrPaymentTypeInfo().deserialize(el));
        }
        this.printNo = input.printNo;
        this.groupPayerCustomerId = input.groupPayerCustomerId;
        this.groupPayerPartnerId = input.groupPayerPartnerId;
        this.groupCustomerId = input.groupCustomerId;
        this.groupVisitId = input.groupVisitId;
        this.invoiceDocumentType = input.invoiceDocumentType;
        this.groupInvoiceServiceList = input.groupInvoiceServiceList;
        this.paidDate = input.paidDate;
        this.isVisitConnectedInvoice = input.isVisitConnectedInvoice;
        this.isCoupon = input.isCoupon;
        this.shared = input.shared;
        this.lastPublicAccess = input.lastPublicAccess;
        // this.cashierName = input.cashierName;
        // this.companyInfo = input.companyInfo;
        // this.printCopy = input.printCopy;
        // this.userId = input.userId;
        // if (_.has(input, 'invoiceDetails')) {
        //     this.invoiceDetails = new InvoiceDetails().deserialize(input.invoiceDetails);
        // }
        return this;
    }
}
