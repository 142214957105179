// import { enableProdMode, TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// import { AppModule } from './app/app.module';
// import { environment } from './environments/environment';

// if (environment.production) {
//     enableProdMode();
// }

// // use the require method provided by webpack
// declare const require;
// // we use the webpack raw-loader to return the content as a string
// const translations = require(`raw-loader!./locale/translated/eAmbulanta_Slovenian.xtb`);
// // const translations = require(`raw-loader!./locale/messages_eAmbulanta.xlf`);

// platformBrowserDynamic()
//     .bootstrapModule(AppModule, {
//         providers: [
//             { provide: TRANSLATIONS, useValue: translations },
//             { provide: TRANSLATIONS_FORMAT, useValue: 'xtb' }
//         ]
//     })
//     .catch(err => console.log(err));

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
