import { Serializable } from '../deserialize.service';

export class ContractorBookingSettings implements Serializable<ContractorBookingSettings> {
    customTags;
    bookingTypes: string[];

    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}
    // TODO Ignored with eslint-interactive on 2023-11-10
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    deserialize(input: any) {
        if (!input || input === null) {
            return null;
        }
        this.customTags = input.customTags;
        this.bookingTypes = input.bookingTypes;
        return this;
    }
}
