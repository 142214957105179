import { InvoiceStatusType } from '../enums/accounting/invoiceStatusType.enum';
import { PaymentType } from './../enums/accounting/paymentType.enum';
import { Customer } from './customer.model';
import { Serializable } from './deserialize.service';

export class Invoice implements Serializable<Invoice> {
    invoiceId: number;
    invoiceNumber: string;
    invoiceDate: Date;
    contractorId: number;
    contractorName: string;
    subcontractorId: number;
    subcontractorName: string;
    amount: number;
    customer: Customer;
    status: InvoiceStatusType;
    paidDate: Date;
    paymentType: PaymentType;

    deserialize(input) {
        this.invoiceId = input.invoiceId;
        this.invoiceNumber = input.invoiceNumber;
        this.invoiceDate = input.invoiceDate;
        this.contractorId = input.contractorId;
        this.contractorName = input.contractorName;
        this.subcontractorId = input.subcontractorId;
        this.subcontractorName = input.subcontractorName;
        this.amount = input.amount;
        if (input.contractorId && input.contractorName) {
            this.customer = new Customer().deserialize({ id: input.contractorId, name: input.customerName });
        }
        this.status = input.status;
        this.paidDate = input.paidDate;
        this.paymentType = input.paymentType;
        return this;
    }
}

// public Long invoiceId;
//     public String invoiceNumber;
//     public Date invoiceDate;
//     public Long contractorId;
//     public String contractorName;
//     public Long subcontractorId;
//     public String subcontractorName;
//     public BigDecimal amount;
